import React, {Component} from 'react';
import {config, SERVICE_API,DISPATCH_ID} from "../libs/utils/Const";
import {ApiClient} from "../libs/apiClient/ApiClient";
import $ from 'jquery';
import {connect} from 'react-redux';
import CurrencyFormat from 'react-currency-format';

let apiClient = ApiClient.getInstance();

class ProfieldModel extends Component {
    render() {
        const {order} = this.props;
        // if(order && order.body.length >= 2){
        //     //console.log("Mas de dos",order);
        // }
        //console.log("Datos para la consulta",order);
        return (
            <div className="modal fade" id="dataProfiel" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{border: "none", textAlign: 'center'}}>
                <div className="modal-dialog modal-lg" role="document" style={{margin: '1.75rem auto'}}>
                    <div className="modal-content">
                        <div className="modal-header" style={{background: config.navBar.backgroundColor}}>
                            <h5 className="modal-title" style={{color: config.navBar.textColor}}>Detalle de la cuenta</h5>
                            <button type="button" style={{color: config.navBar.textColor}} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body bg3">
                            {order && order.body && order.body.map(item => {
                                return <div className="container" key={item}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h5>Código</h5>
                                            <label>{item.CardCode}</label>
                                        </div>
                                        <div className="col-md-6">
                                            <h5>Socio de negocio</h5>
                                            <label>{item.CardName}</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h5>Condiciones de pago</h5>
                                            <label>{item.CondicionPago}</label>
                                        </div>
                                        <div className="col-md-6">
                                            <h5>Límite de crédito</h5>
                                            <label>
                                                <CurrencyFormat 
                                                    value={item.LimiteCredito || 0} 
                                                    displayType={'text'} 
                                                    thousandSeparator={true} 
                                                    fixedDecimalScale={true} 
                                                    decimalScale={2} 
                                                    prefix={config.general.currency}>
                                                </CurrencyFormat>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h5>Documentos vencidos</h5>
                                            <label>{item.DocsVencidos}</label>
                                        </div>
                                        <div className="col-md-6">
                                            <h5>Total facturado</h5>
                                            <label>
                                                <CurrencyFormat 
                                                    value={item.Facturado} 
                                                    displayType={'text'} 
                                                    thousandSeparator={true} 
                                                    fixedDecimalScale={true} 
                                                    decimalScale={2} 
                                                    prefix={config.general.currency}>
                                                </CurrencyFormat>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h5>Total pagado</h5>
                                            <label>
                                                <CurrencyFormat 
                                                    value={item.Pagado} 
                                                    displayType={'text'} 
                                                    thousandSeparator={true} 
                                                    fixedDecimalScale={true} 
                                                    decimalScale={2} 
                                                    prefix={config.general.currency}>
                                                </CurrencyFormat>
                                            </label>
                                        </div>
                                        <div className="col-md-6">
                                            <h5>Total vencidos</h5>
                                            <label>
                                                <CurrencyFormat 
                                                    value={item.MontoVencido} 
                                                    displayType={'text'} 
                                                    thousandSeparator={true} 
                                                    fixedDecimalScale={true} 
                                                    decimalScale={2} 
                                                    prefix={config.general.currency}>
                                                </CurrencyFormat>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer,
        shoppingCartReducer: store.ShoppingCartReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
        setItemDetailsSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SET_ITEM_DETAILS, value}),
        setItemsSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SET_ITEMS, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProfieldModel);
