import React, {Component} from 'react';
import {Session, QuotationDetailModel} from "../../components";
import {DISPATCH_ID, SERVICE_API, SERVICE_RESPONSE, VIEW_NAME, config} from "../../libs/utils/Const";
import {ApiClient} from "../../libs/apiClient/ApiClient";
import {connect} from "react-redux";
import moment from 'moment';
import $ from 'jquery';
import CurrencyFormat from 'react-currency-format';

const apiClient = ApiClient.getInstance();

require('datatables.net-bs4');

class QuotaionViews extends Component {

     constructor(props) {
        super(props);
        this.state = {
            orders: [],
            order: {
                header: {},
                body: []
            },
            typeCondition : 0,
            tableToExcel : [],
            guia: []
        };
        this.table = null;
    }

    async componentDidMount() {
        // const {enableSpinner} = this.props;
        // enableSpinner(true);
        // let response = await apiClient.getQuotation();

        // enableSpinner(false);
        // if (response.status === SERVICE_RESPONSE.SUCCESS) {
        //     this.setState({
        //         orders: response.data,
        //     });
        //     $('#tablaQuotation').DataTable({
        //         "paging": true,
        //         "info": false,
        //         "searching": false,
        //         "language": {
        //             "lengthMenu": "Mostrar _MENU_ registros por página",
        //             "zeroRecords": "No se encontraron registros",
        //             "info": "Mostrando página _PAGE_ de _PAGES_",
        //             "infoEmpty": "No existen registros",
        //             "infoFiltered": "(filtrado de _MAX_ entradas)",
        //             "loadingRecords": "Buscando...",
        //             "processing": "Procesando...",
        //             "search": "Buscar:",
        //             "paginate": {
        //                 "first": "Primero",
        //                 "last": "Último",
        //                 "next": "Siguiente",
        //                 "previous": "Anterior"
        //             }
        //         }
        //     });
        // }
        let user = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'CurrentUser'));
        this.setState({
            typeCondition : user ? user.GroupNum : 0
        });
        this.fillDataBilling();
        

    }

    fillDataBilling = () =>{
        this.table =  $('#tablaQuotation').DataTable({
            "paging": true,
            "info": false,
            "searching": false,
            //"bDestroy": true,	
            "order": [[ 0, 'desc' ]],
            "language": {
                "lengthMenu": "Mostrar _MENU_ registros por página",
                "zeroRecords": "No se encontraron registros",
                "info": "Mostrando página _PAGE_ de _PAGES_",
                "infoEmpty": "No existen registros",
                "infoFiltered": "(filtrado de _MAX_ entradas)",
                "loadingRecords": "Buscando...",
                "processing": "Procesando...",
                "search": "Buscar:",
                "paginate": {
                    "first": "Primero",
                    "last": "Último",
                    "next": "Siguiente",
                    "previous": "Anterior"
                }
            }
        });
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.props.orders != nextProps.orders) {
            //console.log("hay nuevos datos");
            this.table.destroy();
            this.table = null;
        }
        return true;
    }

    componentDidUpdate() {
        if (this.table == null) {
            this.fillDataBilling();
        }
    }

    openOrder = async docEntry => {
        const {enableSpinner, notificationReducer: {showAlert}} = this.props;
        enableSpinner(true);
        let response = await apiClient.getDataQuotation(docEntry);
        enableSpinner(false);

        if (response.status === SERVICE_RESPONSE.SUCCESS) {
            this.setState({
                order: response.data,
                guia: response.data.statusGuia || [],
            });

            $('#quotationModal').modal('show');
            return;
        }

        showAlert({type: 'error', message: "Aviso: "+response.message})
    };

    docChangeName(status){
        let result = '';
        switch (status) {
            case 'O':
                result = "Abierta";
                break;
            case 'C':
                result = "Cerrada";
                break;
            default:
                break;
        }
        return result;
    };
    docChangeNameFMB(target,base){
        let result = '';
        let response = target != -1 ? target : base
        
        switch (response) {
            // case 23:
            //     result = "Sin atender";
            //     break;
            case 17:
                result = "Atendido";
                break;
            case 15:
                result = "Surtido";
                break;
            case 13:
                result = "Facturado";
                break;
            default:
                result = 'Sin atender';
                break;
        }
        return result;
    };

    

    render() {
        const { order, typeCondition, tableToExcel, guia} = this.state;
        const { orders,getData,handleInputDateInicio, handleInputDateFinal, fechaFinal,fechaInicio, fechamin, fechamax, isLoaded,configReducer:{currency}}  = this.props;

        return (
            <div className="content-fluid" style={{marginTop:95,backgroundColor: config.Back.backgroundColor}}>
                <QuotationDetailModel order={order} guia = {guia}/>
                <div className="row text-center" style={{marginBottom: 16, marginTop: 16}}>
                    <div className="col-md-12">
                        <h3>Ofertas de ventas</h3>
                    </div>
                </div>
                <div className="row text-center" style={{marginBottom: 16, marginTop: 16}}>
                    <div className=" row col-md-4">
                        <h4 className="pr-2">Desde:</h4>
                        <input 
                            id="fechaInicio"
                            type="date" 
                            className="form-control col-md-6" 
                            name="fechauno" 
                            min={fechamin}
                            max={fechamax}
                            value = {!isLoaded ? fechamin : fechaInicio}
                            onChange = {(event) => handleInputDateInicio(event)}/>
                    </div>
                    <div className="row col-md-4 pb-3">
                        <h4 className="pr-2">Hasta:</h4>
                        <input 
                            id="FechaFin"
                            type="date" 
                            className="form-control col-md-6" 
                            name="fechados" 
                            min={fechamin}
                            max={fechamax}
                            value = {fechaFinal}
                            onChange = {(event) => handleInputDateFinal(event)}/>
                    </div>
                    <div className="col-md-2 pb-2">
                        <button
                            onClick={()=>getData(3)}
                            className="btn botonResumen" 
                            style={{
                                backgroundColor: config.navBar.menuCategoriesBackgroundHover,
                                color: config.navBar.textColor2,
                                fontWeight: "bold",
                            }}>
                            Ver facturas
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 table-responsive tableReports">
                        <table id="tablaQuotation" className="table table-striped scrolltable">
                            <thead>
                                <tr style={{backgroundColor: config.shoppingList.summaryList, color: "white"}}>
                                    <th scope="col">No. de cotización</th>
                                    <th scope="col">Fecha de creación</th>
                                    <th scope="col">Estado</th>
                                    <th scope="col-lg-1">Estado del pedido</th>
                                    <th scope="col">Valor total</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!!orders && orders.map((order, index) => {
                                    return (<tr key={index}>
                                        <th scope="row">{order.DocNum}</th>
                                        <td>{moment(order.Fecha).utc().format('DD-MM-YYYY')}</td>
                                        <td>{this.docChangeName(order.DocStatus)}</td>
                                        <td className="col-lg-auto" style={{width: '250px'}}>{this.docChangeNameFMB(order.TargetType, order.BaseType)}</td>
                                        <td className="text-right">
                                            <CurrencyFormat 
                                                value={order.Total} 
                                                displayType={'text'} 
                                                thousandSeparator={true} 
                                                fixedDecimalScale={true} 
                                                decimalScale={2} 
                                                prefix={currency.MainCurncy !== (order.DocCur === 'MXN' ? 'MXP' : order.DocCur)  ? '' : currency.DocCurrCod}
                                                suffix = {' ' + currency.MainCurncy}>
                                            </CurrencyFormat>
                                        </td>
                                        <td>
                                            <sapn onClick={() => this.openOrder(order.DocEntry)}>
                                                <i className={config.icons.detail} style={{color: config.shoppingList.summaryList, paddingRight: 6}}></i>
                                                Detalle
                                            </sapn>
                                        </td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuotaionViews);