import React, {Component} from 'react';
import {NavBar, Session, ItemsList, ItemDetailsModal, Footer, Slider, CarouselDashboard, Suscription,TopNavBar, CarouselTrademarks} from "../../components";
import {config, DISPATCH_ID, VIEW_NAME, ROLES} from "../../libs/utils/Const";
import { ApiClient } from "../../libs/apiClient/ApiClient";
import {connect} from "react-redux";
import './ItemsView.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const apiClient = ApiClient.getInstance();

const responsiveMarcas = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      partialVisibilityGutter: 10 // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    }
  }

class ItemsRoutletView extends Component {

    search = '';

    changeQuantity = (index, item, newQuantity, addItem) => {
        const {itemsReducer : { addShoppingCart, deleteShoppingCart }} = this.props;
        if(addItem){
            addShoppingCart({item, quantity: (newQuantity || '1')})
        }else{
            deleteShoppingCart({item, deleteAll: false});
        }
    };

    changeBackOrder= (item, addItem) => {
        const {itemsReducer : {deleteBackOrder, addBackOrder}} = this.props;
        if(addItem){
            addBackOrder({item, quantity: 1})
        }else{
            deleteBackOrder({item, deleteAll: false});
        }
    };

  

    render() {
        const {history,itemsReducer : { itemsFilter, searchItemsFilter, updateFavorite, deleteShoppingCart, openItemDetails }} = this.props;
        //imprimir las variabls que recibe el itemList
        return (
            <div className="content-fluid" style={{marginTop: 150,backgroundColor:"#EFEFEF", paddingLeft:0, paddingRight:0}}>
                <Session history={history} view={VIEW_NAME.ITEMS_ROUTLET_VIEW}/>
                <NavBar/>
                <div className="bannerRedCompensas margenS" style={{backgroundColor:"#EFEFEF"}}>
                    <img id = "scrollDownPlease" className="img-fluid"
                        src={require('../../images/gbtl/ROutlet/banner.png')}
                        alt="Segundo NavBar"
                    />
                </div>
                {/* Si es un video */}
                {/* {config.dashboard === 'Video' && <Slider />} */}
                {/* Si es un video */}
                {/* {config.dashboard === 'Carousel' && <CarouselDashboard />} */}
                <TopNavBar/><br/>
                <CarouselTrademarks/><br/>
                {/* <Carousel style={{ justifyContent: "center", alignItems: "center"}} autoPlay infinite={true} draggable partialVisible={true} responsive={responsiveMarcas} autoPlaySpeed={5000} removeArrowOnDeviceType={["desktop","tablet", "mobile"]}>
                    <div className="text-center" style={{marginTop: "auto", marginBottom: "auto"}}>
                        <img className="img-fluid"
                            src={require('../../images/gbtl/ROutlet/Outlet-02.png')}
                            alt="Segundo NavBar"
                        />
                    </div>
                    <div className="text-center" style={{marginTop: "auto", marginBottom: "auto"}}>
                        <img className="img-fluid"
                            src={require('../../images/gbtl/ROutlet/Outlet-03.png')}
                            alt="Segundo NavBar"
                        />
                    </div>
                    <div className="text-center" style={{marginTop: "auto", marginBottom: "auto"}}>
                        <img className="img-fluid"
                            src={require('../../images/gbtl/ROutlet/Outlet-04.png')}
                            alt="Segundo NavBar"
                        />
                    </div>
                    <div className="text-center" style={{marginTop: "auto", marginBottom: "auto"}}>
                        <img className="img-fluid"
                            src={require('../../images/gbtl/ROutlet/Outlet-05.png')}
                            alt="Segundo NavBar"
                        />
                    </div>
                    <div className="text-center" style={{marginTop: "auto", marginBottom: "auto"}}>
                        <img className="img-fluid"
                            src={require('../../images/gbtl/ROutlet/Outlet-06.png')}
                            alt="Segundo NavBar"
                        />
                    </div>
                    <div className="text-center" style={{marginTop: "auto", marginBottom: "auto"}}>
                        <img className="img-fluid"
                            src={require('../../images/gbtl/ROutlet/Outlet-07.png')}
                            alt="Segundo NavBar"
                        />
                    </div>
                    <div className="text-center" style={{marginTop: "auto", marginBottom: "auto"}}>
                        <img className="img-fluid"
                            src={require('../../images/gbtl/ROutlet/Outlet-08.png')}
                            alt="Segundo NavBar"
                        />
                    </div>
                    <div className="text-center" style={{marginTop: "auto", marginBottom: "auto"}}>
                        <img className="img-fluid"
                            src={require('../../images/gbtl/ROutlet/Outlet-09.png')}
                            alt="Segundo NavBar"
                        />
                    </div>
                    <div className="text-center" style={{marginTop: "auto", marginBottom: "auto"}}>
                        <img className="img-fluid"
                            src={require('../../images/gbtl/ROutlet/Outlet-10.png')}
                            alt="Segundo NavBar"
                        />
                    </div>
                    <div className="text-center" style={{marginTop: "auto", marginBottom: "auto"}}>
                        <img className="img-fluid"
                            src={require('../../images/gbtl/ROutlet/Outlet-11.png')}
                            alt="Segundo NavBar"
                        />
                    </div>
                </Carousel><br/> */}
                {/* sección de marcas */}
                {/* <TopNavBar/> */}
                {/* <div className="marcas text-center" style={{display: "flex",justifyContent: "center", alignItems: "center"}}>
                    <img src={config.trademarks.marcasRegistradas} className="Img-fluid"></img>
                </div> */}
                {/**Comineza funcion */}
                {/* NOTA: No se puede ejecutar el componente porque no se escribe ninguna palabra */}
                <div className="row" style={{margin:0, paddingBottom: 20}}>
                    <div className="col-md-12 col-sm-12" style={{margin:0, minHeight: '70vh'}}>
                        {itemsFilter.length !== 0 ? (
                            <ItemsList
                                items={itemsFilter}
                                updateFavorite={updateFavorite}
                                openDetails={openItemDetails}
                                changeQuantity={this.changeQuantity}
                                deleteShoppingCart={deleteShoppingCart}
                                changeBackOrder={this.changeBackOrder}
                                viewOne={'whiteView'}
                            />
                        ) : (
                            <div style={{paddingTop:100, margin:0, textAlign: 'center'}}>No se encontraron productos con su búsqueda: <strong>{searchItemsFilter}</strong>
                                <br/>
                                <br/>
                                <i style={{fontSize: 70}} className={config.icons.search}></i>
                            </div>
                        )}

                    </div>
                </div>
                {/* <Suscription/> */}
                {/**Aqui termina la funcion  */}
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
        sessionReducer: store.SessionReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setItemsSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SET_ITEMS, value}),
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
        setItemsFilterSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value}),
        setLocation:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_LOCATION, value}),
        setTotalRows : value => dispatch({type: DISPATCH_ID.ITEMS_SET_TOTALROWS, value}),
      
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ItemsRoutletView);