import React, { Component } from 'react';
import { config, SERVICE_API, VIEW_NAME, DISPATCH_ID } from "../libs/utils/Const";
import CurrencyFormat from 'react-currency-format';
import { connect } from "react-redux";
import '../index.css';
import $ from 'jquery';
import { ApiClient } from "../libs/apiClient/ApiClient";
import ReactTooltip from 'react-tooltip';
import Tooltip from 'react-light-tooltip'
import Popover from 'react-bootstrap/Popover'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import {Promociones} from '../components/index';

let apiClient = ApiClient.getInstance();


class ShoppingCartList extends Component {
    state = {
        promo: [],
        itemPromo:[],
        promociones:[],
        seller: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser')),
    }

    componentDidMount = async () => {
        $(document).ready(function(){
            $('[data-toggle="popover"]').popover();
        });
        await this.cargarDatos();
    };

    cargarDatos = async () => {
        let user = localStorage.getItem(config.general.localStorageNamed + 'CurrentUser');
        user = JSON.parse(user) || {};
        let datos ={
            CardCode : user.CardCode,
            Canal : user.U_SYP_RICO_CCANAL || '',
            Region : user.U_SYP_RICO_CREGION || '',
            SubCanal : user.U_SYP_RICO_CSBCAN || ''
        }
        let newdatas = await apiClient.getPromo(datos);
        if(newdatas.status !== 1){
           return;
        }
        if(newdatas.data.length > 0){
            this.setState({
                promociones: newdatas.data,
            });
            await this.validateDisparador();
        }
    };

    validateDisparador = async () => {
        const {promociones} = this.state;
        // const {enableSpinner, shoppingCartReducer: {items}} = this.props;
        const {enableSpinner, data ,refeshState} = this.props;
       //let data = items;
        //  console.log('con>promociones',promociones);
        let arrayPromoVol = [];
        let NotificacionIems = [];
        let itemsNotificacion = [];
        //##########---VALIDA ARTICULOS POR TIPO DE DISPARADOR----###################
        for (let index = 0; index < promociones.length; index++) {//----promociones
            const promo = promociones[index];
            let arrayItems = [];
            for (let index = 0; index < promo.length; index++) {//----Articulos por promoción
                const disparador = promo[index];
                if (disparador.fkTipoDisparador === 1) {  //---VOLUMEN--------------------------------
                    for (let index = 0; index < data.length; index++) {//----Articulos por promoción
                        const item = data[index];
                        if (item.ItemCode === disparador.idProducto) {
                            arrayItems.push(disparador);
                        }
                    };
                }else if (disparador.fkTipoDisparador === 2) { //--------------------------------MONTO--------------------------------

                }
            }
            if(arrayItems.length > 0){
                arrayPromoVol.push(arrayItems);

            }
        }
        if(arrayPromoVol.length > 0){
            let itemsPromo = [];

            let bonifNoti =[];
            for (let index = 0; index < arrayPromoVol.length; index++) {
                const promociones = arrayPromoVol[index];
                for (let index = 0; index < promociones.length; index++) {
                    const item = promociones[index];
                    itemsPromo.push(item);
                }
            }
            enableSpinner(true);
            bonifNoti = await apiClient.getBonificacion(itemsPromo);
            enableSpinner(false);
            for (let index = 0; index < itemsPromo.length; index++) {
                const element = itemsPromo[index];
                 const result = NotificacionIems.find(itemDisp => itemDisp.idProducto === element.idProducto);
                 if(!result){
                    NotificacionIems.push(element);
                 }
            }


            // console.log('con>--------###############################');
            if(bonifNoti.data.length > 0){
                for (let index = 0; index < itemsPromo.length; index++) {
                    const item = itemsPromo[index];
                    let notificacion ={
                        itemDisparador: item.idProducto,
                        tipoVenta: item.fkSubTipo,
                        bonificacion : [],
                        idDisparador: item.disparador,
                        itemQuantity: item.cantidad,
                    }
                    // console.log('con>--------');
                    for (let index = 0; index < bonifNoti.data.length; index++) {
                        const element = bonifNoti.data[index];
                        let itemsTemp = [];
                        // console.log('con>element',element);
                        for (let index = 0; index < element.length; index++) {
                            const bonificacion = element[index];
                            if(item.disparador === bonificacion.disparador && item.relacion === bonificacion.relacion){
                                itemsTemp.push(bonificacion);
                            }
                        }

                    //    console.log('con>*****************************************',itemsTemp);
                       let items = [];
                        let registrados = [];
                        for (let index = 0; index < itemsTemp.length; index++) {
                            const element = itemsTemp[index];
                             let include = registrados.includes(element.indexPack);
                             if(!include){
                                let arrayTemp = itemsTemp.filter(item => (item.indexPack === element.indexPack));
                                registrados.push(element.indexPack);
                                items.push(arrayTemp);
                                // console.log('con>arrayTemp',arrayTemp);
                             }


                        }   
                        
                        if(itemsTemp.length > 0){
                            notificacion.bonificacion = items;
                        }

                    }

                    itemsNotificacion.push(notificacion);
                }



                
                //  console.log('con<<>',itemsNotificacion);
                for (let index = 0; index < NotificacionIems.length; index++) {
                    const element = NotificacionIems[index];
                    let notificacion =[];
                    for (let index = 0; index < itemsNotificacion.length; index++) {
                        const promo = itemsNotificacion[index];
                        if(element.idProducto === promo.itemDisparador){
                            notificacion.push(promo);
                        }
                    }
                    element.notificacion = notificacion;
                }
                // if(filters.length > 0){
                //     NotificacionIems.push(filters);
                // }
            }
        }
        //  console.log('con>NotificacionIems', NotificacionIems);
        //##########---DISPARADOR TIPO VOLUMEN VALIDA SUBTIPO----###################
        let promoECD = [];
        let promoEDC = [];
        for (let index = 0; index < arrayPromoVol.length; index++) {
            const itemsPromo = arrayPromoVol[index];
            for (let index = 0; index < itemsPromo.length; index++) {
                const item = itemsPromo[index];
                if (item.fkSubTipo === 1) {  //---En la compra de--------------------------------
                    promoECD.push(itemsPromo);
                    break;
                } else if (item.fkSubTipo === 2) {//---En la compra decualquera de--------------------------------
                    promoEDC.push(itemsPromo);
                    break;
                }
            }
        }

        // console.log('con>promoECD',promoECD);
        // console.log('con>promoEDC',promoEDC);


        let itemsPromo = [];
         //##########--- EN LA COMPRA DE, VALIDA STOCK----###################
        for (let index = 0; index < promoECD.length; index++) {
            const promo = promoECD[index];
            for (let index = 0; index < promo.length; index++) {
                const element = promo[index];
                for (let index = 0; index < data.length; index++) {
                    const item = data[index];
                    if((parseInt(item.quantity,10) >= element.cantidad) && (item.ItemCode === element.idProducto)){
                        element.newQuantity = parseInt(parseInt(item.quantity,10) / element.cantidad);
                        itemsPromo.push(element);
                    }
                }
            }
        }
         //##########--- EN LA COMPRA DE CUALQUIERA DE, VALIDA STOCK  ----###################
        for (let index = 0; index < promoEDC.length; index++) {
            const promo = promoEDC[index];
            // console.log('con>*----');
            let sumTotal = 0;
            for (let index = 0; index < promo.length; index++) {
                const element = promo[index];

                for (let index = 0; index < data.length; index++) {
                    const item = data[index];

                     if(item.ItemCode === element.idProducto){
                        sumTotal+=parseInt(item.quantity,10);
                     }

                }
            }
            if(sumTotal >= promo[0].cantidad){
                for (let index = 0; index < promo.length; index++) {
                    const elementPromo = promo[index];
                    elementPromo.newQuantity = parseInt(sumTotal / promo[0].cantidad);
                    itemsPromo.push(elementPromo);
                }
            }
        }

        let arrayRelacional = [];
        let itemsBonificacion;
        //  console.log('con>itemsPromo',itemsPromo);
        if(itemsPromo.length > 0){
            enableSpinner(true);
                itemsBonificacion = await apiClient.getBonificacion(itemsPromo);
                //  console.log('con>itemsBonificacion.data',itemsBonificacion.data);
            enableSpinner(false);
            if(itemsBonificacion.data.length > 0){
                for (let index = 0; index < itemsPromo.length; index++) {
                    const itemsList = itemsPromo[index];
                    // console.log('con>itemsList',itemsList);
                    for (let index = 0; index < itemsBonificacion.data.length; index++) {
                        const bonificacionList = itemsBonificacion.data[index];

                        for (let index = 0; index < bonificacionList.length; index++) {
                            const element = bonificacionList[index];
                            if(itemsList.bonificacion === element.bonificacion && itemsList.relacion === element.relacion){
                                let promo ={
                                    itemDisparador: itemsList.idProducto,
                                    tipoDisparador: itemsList.fkSubTipo,
                                    bonificacion : element,
                                    tipoSeleccion: element.idTipoSeleccion,
                                    use : false,
                                    idDisparador: element.disparador,
                                    itemQuantity: itemsList.cantidad ,
                                    homeItemQuantity: itemsList.newQuantity,
                                }
                                arrayRelacional.push(promo);
                            }
                        }

                    }
                }

            }
        }
        //   console.log('con>arrayRelacional',arrayRelacional);
        refeshState(arrayRelacional,NotificacionIems);
    };

    changeQuantity = async (index, item, event) => {
        const { changeQuantity, notificationReducer: {showAlert} } = this.props;
        let newQuantity = event.nativeEvent.target.value;
        let onHand = item.OnHandPrincipal;

        if (newQuantity > onHand) {
            if (onHand !== 0 ) {
                showAlert({type: 'warning', message: "Se excede el número de articulos disponibles de este producto", timeOut: 2500});
            }
            newQuantity = onHand;
        }

        let user = localStorage.getItem(config.general.localStorageNamed + 'CurrentUser');
        user = JSON.parse(user) || {};

        let data = {
            CardCode: user.CardCode
        }


        // color azul rinti
        // document.getElementById(item.ItemCode).style.backgroundColor = "#005DA8";
        // document.getElementById(item.ItemCode).style.borderColor = "#005DA8";

        changeQuantity(index, item, newQuantity, true); // add
        this.validateDisparador();
    };
    

    //BOTON AGREGAR AL CARRITO

    changLocalQuantity = (index, item, event)=>{
        // const {shoppingCartReducer: { items },setShoppingCart } = this.props;
        // let newQuantity =  event.nativeEvent.target.value;
        // if (newQuantity % 1 == 0) {
        //     items.map( itemLocal => {
        //         if (itemLocal.ItemCode === item.ItemCode) {
        //             itemLocal.quantity = newQuantity;
        //         }
        //     });
        //      // color success
        //     document.getElementById(item.ItemCode).style.backgroundColor = "#28a745"; 
        //     document.getElementById(item.ItemCode).style.borderColor = "#28a745"; 
        //     setShoppingCart(items);
        // }
        // else{
        //     return;
        // }

        const { shoppingCartReducer: { items } , setShoppingCart , notificationReducer: { showAlert }} = this.props;
        let newQuantity = event.nativeEvent.target.value;

        if (newQuantity % 1 == 0 && newQuantity > 0 ) {
            items.map(items => {
                if (items.ItemCode === item.ItemCode){
                    if (newQuantity > item.OnHandPrincipal) {
                        if ((items.OnHand !== 0 ) ) {
                            showAlert({ type: 'warning', message: "Se excede el número de articulos disponibles de este producto en el almacén.", timeOut: 2500 });
                            newQuantity = Math.trunc(items.OnHandPrincipal);
                            items.quantity = newQuantity;
                        }else{
                            showAlert({ type: 'warning', message: "Este articulo no tiene stock.", timeOut: 2500 });
                            newQuantity = Math.trunc(items.OnHand);
                            items.quantity = newQuantity;
                        }
                    }else{
                        items.quantity = newQuantity;
                    }
                    
                }
            });
            setShoppingCart(items);
        }
        else{
            return;
        }
        
    }
    
    //////////////////////////

    validateQuantity = (index, item, e) => {
        if (!e.target.value) {
            let event = {
                nativeEvent: {
                    target: {
                        value: 1,
                    }
                }
            };
            this.changeQuantity(index, item, event);
        }
    };

    validateStock = item => {
        if ((item.quantity > item.OnHandPrincipal) && !!item.OnHandPrincipal) {
            return (
                <div className='col-12' style={{ color: 'red', fontSize: 15, padding: 0 }}>
                    La cantidad seleccionada excede la existencia.
                </div>
            )
        }
    };

    validateStockBonificacion = item => {
        if ((item.cantidad > item.Stock) && !!item.Stock) {
            return (
                <div className='col-12 font-weight-bold ' style={{ color: 'red', fontSize: 15, padding: 0 }}>
                    El artículo no tiene stock para la bonificación.
                </div>
            )
        }
    };

    deleteItemLocal = async ({ item = null, deleteAll = null })=>{
        const {deleteItem} = this.props;
        await deleteItem({ item, deleteAll: false });
        this.validateDisparador();
    };

    renderBonificaciones =(element,ItemCode)=>{
        const {deleteItemPromo} = this.props;
        const {seller} = this.state;
        let itemRegalo = element.bonificacion;
        if(ItemCode === element.itemDisparador){
            return (
                <div className='row' style={{ padding: 0, textAlign: 'center' }}>
                    <div className='col-sm-2' style={{ margin: 0 }}>
                    <img
                        className="img-fluid "
                        style={{
                            backgroundColor: 'white',
                            maxHeight: 80
                        }}
                        src={itemRegalo.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + itemRegalo.PicturName) : require('../images/noImage.png')}
                        alt=""
                    />
                    </div>
                    <div className='col-sm-10'>
                        <div className="container p-0">
                            <div className="row">
                                <div className="col-12" style={{ borderBottom: '1px solid rgb(124, 124, 125)' }}>
                                    <div className='text-left'>
                                        {itemRegalo.ItemName}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                        <div className="col-md-12 table-responsive">
                            <table className="table" style={{margin:'0px'}}>
                                <thead>
                                    <tr >
                                        <th scope="col" style={{padding:'0px'}}>Código</th>
                                        <th scope="col" style={{padding:'0px'}}>Cantidad</th>
                                        {seller ? seller.U_FMB_Handel_Perfil != '0' ? 
                                            <th scope="col" style={{padding:'0px'}}></th>
                                            : "" : ""
                                        }
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <td style={{padding:'0px'}}>
                                        <i className="fa fa-gift" data-toggle="tooltip"  title={'Artículo de regalo'} style={{ cursor: 'pointer', paddingRight:"5px", color:'#005DA8' }}/>
                                        {itemRegalo.idProducto}
                                    </td>
                                    <td style={{padding:'0px'}}>

                                        <input
                                            disabled={true}
                                            id={'input-quantity-' + itemRegalo.idProducto + 1}
                                            type="number"
                                            min="1"
                                            value={itemRegalo.cantidad ? Number(itemRegalo.cantidad) : ''}
                                            className="form-control"
                                            name={'quantity' + itemRegalo.idProducto}
                                            placeholder="Cantidad"
                                            style={{ textAlign: 'center' }}/>
                                    </td>
                                    <td style={{padding:'0px'}}>
                                        { itemRegalo.idTipoSeleccion === 2 &&
                                            <i className="fa fa-trash" style={{ cursor: 'pointer', color:"red"}} onClick={() => deleteItemPromo(element)} />}
                                    </td>
                                    {seller ? seller.U_FMB_Handel_Perfil != '0' ? 
                                        <td style={{padding:'0px'}}>
                                            {this.validateStockBonificacion(itemRegalo)}
                                        </td>
                                        : "" : ""
                                    }
                                    <td></td>
                                </tbody>

                            </table>

                        </div>
                        </div>
                        </div>
                    </div>
                </div>
            )
        }
    };

    renderBoxesProm = (itemPromo,itemCode) =>{
        const {selectItemsPromo} = this.props;
        if((itemPromo.itemDisparador ===  itemCode)  &&  itemPromo.tipoSeleccion === 2 ){
            return  (
                    <i className="fas fa-box-open" data-toggle="tooltip"  title={itemPromo.bonificacion.nombre} style={{ cursor: 'pointer', paddingRight:"5px", color:'#28a745', fontSize: 'x-large' }} onClick = {() => selectItemsPromo(itemPromo,itemCode)} />
            )
       }
    };

    render() {
        const { data, view,deleteItemPromo, sendTo, itemsReducer: { addShoppingCart, deleteShoppingCart }, shoppingCartReducer: { items },itemPromociones ,selectItemsPromo,itemsRegalos,itemsRegalosSelect,itemDisparador,boxes,itemsNotificacion, businessPartnerInfo,configReducer:{currency}} = this.props;
        const {seller} = this.state;
        return (
            <div>
                {/* overflowY:'auto' */}
                <div >                    
                {data.map((item, index) => {
                    return (
                        <div key={index} className=' text-left card ' style={{ border: 'none'}}>
                            <div className='row' style={{  textAlign: 'center'}}>
                                {/* {item.U_FMB_Handel_Promo !== null && item.U_FMB_Handel_Promo !== '' && item.U_FMB_Handel_Promo !== 'null' && 
                                    <div className='col-12' style={{marginBottom: 5, padding: 5, backgroundColor: config.navBar.backgroundColor, color: config.navBar.textColor}}>
                                        Promoción--
                                    </div>
                                } */}
                                <div className='col-sm-2' style={{ margin: 0}}>
                                    <img className="img-fluid" style={{ backgroundColor: 'white', maxHeight: 80}}
                                        src={item.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + item.PicturName) : require('../images/noImage.png')}
                                        alt="" />

                                    {itemsNotificacion.map((element)=>{
                                        return (
                                            <div className="input-group details">
                                                {item.ItemCode === element.idProducto &&
                                                <Promociones
                                                itemsNotificacion = {element.notificacion}/>}
                                            </div>
                                        )})
                                    }
                                </div>

                                <div className='col-sm-10'>
                                    <div className="container p-0">
                                        <div className="row">
                                            <div className="col-12" style={{ borderBottom: '1px solid rgb(124, 124, 125)'}}>
                                                <div className='text-left' style={{color: "#808080"}}>
                                                    {item.ItemName}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12 table-responsive">
                                                <table className="table" style={{margin:'0px'}}>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" style={{padding:'0px'}}>Clave</th>
                                                            <th scope="col" style={{padding:'0px'}}>Cantidad</th>
                                                            {seller ? seller.U_FMB_Handel_Perfil != '0' ? 
                                                                <th scope="col" style={{padding:'0px'}}>Cant. Disponible</th>
                                                                : "" : ""
                                                            }
                                                            {item.DiscountPercentSpecial !== undefined && item.DiscountPercentSpecial != 0 &&
                                                                <th scope="col" style={{padding:'0px'}}>Precio or. sin IVA</th>
                                                            }
                                                            {item.DiscountPercentSpecial !== undefined && item.DiscountPercentSpecial != 0 &&
                                                                <th scope="col" style={{padding:'0px'}}>Precio or. con IVA</th>
                                                            }
                                                            {item.DiscountPercentSpecial !== undefined && item.DiscountPercentSpecial != 0 &&
                                                                <th scope="col" style={{padding:'0px'}}>% Descuento aplicado</th>
                                                            }
                                                            <th scope="col" style={{padding:'0px'}}>Precio unitario</th>
                                                            {/* <th scope="col" style={{padding:'0px'}}>Precio con IVA</th> */}
                                                            <th scope="col" style={{padding:'0px'}}>Total</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <td style={{color: "#0076B8"}}>
                                                            {item.ItemCode}
                                                        </td>
                                                        <td>
                                                            <input
                                                                disabled={!(!!item.OnHandPrincipal)}
                                                                id={'input-quantity-' + item.ItemCode + index}
                                                                min="1"
                                                                type="number" min="1" value={item.quantity ? Number(item.quantity) : ''}
                                                                className="form-control mb-1" name={'quantity' + item.ItemCode}
                                                                placeholder="1" style={{ textAlign: 'center', borderRadius: 20, borderColor: "#666666", color: "#666666" }}
                                                                // onChange={(event) => {  this.changeQuantity(index, item, event) }}
                                                                // onBlur={(e) => this.validateQuantity(index, item, e)}
                                                                onChange={(event) => { this.changLocalQuantity(index, item, event) }}
                                                                onBlur={(event) => { this.changeQuantity(index, item, event) }}
                                                                onFocus={(event) => event.target.select()}
                                                                
                                                            />
                                                        </td>
                                                        {/* {seller ? seller.U_FMB_Handel_Perfil != '0' ? 
                                                            <td>
                                                                {parseInt(item.OnHandPrincipal)}
                                                            </td>
                                                            : "" : ""
                                                        }
                                                        {item.DiscountPercentSpecial !== undefined && item.DiscountPercentSpecial != 0 &&
                                                            <td style={{color: "#666666"}}>
                                                                <CurrencyFormat
                                                                    value={item.PriceBeforeDiscount || 0}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    fixedDecimalScale={true}
                                                                    decimalScale={2}
                                                                    prefix={currency.MainCurncy !== item.Currency  ? '' : currency.DocCurrCod}
                                                                    suffix = {' ' + item.currency}>
                                                                </CurrencyFormat>
                                                            </td>
                                                        }
                                                        {item.DiscountPercentSpecial !== undefined && item.DiscountPercentSpecial != 0 &&
                                                            <td style={{color: "#666666"}}>
                                                                <CurrencyFormat
                                                                value={item.PriceTaxBeforeDiscount || 0}
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                prefix={currency.MainCurncy !== item.Currency  ? '' : currency.DocCurrCod}
                                                                suffix = {' ' + item.currency}>
                                                                </CurrencyFormat>
                                                            </td>
                                                        }
                                                        {item.DiscountPercentSpecial !== undefined && item.DiscountPercentSpecial != 0 &&
                                                            <td scope="col">{item.DiscountPercentSpecial} %</td>
                                                        } */}
                                                        {/* <td style={{color: "#666666"}}>
                                                            <CurrencyFormat
                                                                value={item.Price || 0}
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                prefix={currency.MainCurncy !== item.Currency  ? '' : currency.DocCurrCod}
                                                                suffix = {' ' + item.currency}>
                                                            </CurrencyFormat>
                                                        </td> */}
                                                        <td style={{color: "#666666"}}>
                                                            <CurrencyFormat
                                                                value={item.Price || 0}
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                suffix = {' ' + config.general.currency}>
                                                            </CurrencyFormat>
                                                        </td>
                                                        <td style={{color: "#0076B8"}}>
                                                            <CurrencyFormat
                                                                value={item.Price * item.quantity}
                                                                displayType={'text'}
                                                                thousandSeparator={true}
                                                                fixedDecimalScale={true}
                                                                decimalScale={2}
                                                                suffix = {' ' + config.general.currency}>
                                                            </CurrencyFormat>
                                                        </td>
                                                        {/* {   
                                                            item.U_FMB_Handel_PNTA == "1" &&
                                                            businessPartnerInfo.U_FMB_Handel_CardAct == "1" &&
                                                            businessPartnerInfo.U_FMB_Handel_RedCard != null && 
                                                            config.modules.points &&
                                                            <td>
                                                                <CurrencyFormat
                                                                    value={ Number((item.Price * item.quantity)*10).toFixed(0)}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    fixedDecimalScale={true}>
                                                                </CurrencyFormat>
                                                                {` puntos`}
                                                            </td>
                                                        } */}
                                                        <td>
                                                            {item.quantity &&
                                                                <i className="fa fa-trash" style={{ cursor: 'pointer', color: 'red' }} onClick={() => this.deleteItemLocal({ item, deleteAll: false })} />}
                                                            {this.validateStock(item)}
                                                        </td>
                                                        <td></td>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-md-12" >
                                                {itemsRegalos.map((elemenet)=>{return this.renderBonificaciones(elemenet,item.ItemCode)})}
                                                {itemsRegalosSelect.map((elemenet)=>{return this.renderBonificaciones(elemenet,item.ItemCode)})}
                                            </div>

                                            <div className="col-md-12" >
                                                {boxes.map((itemPromo, index) => { return this.renderBoxesProm(itemPromo,item.ItemCode)})}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                </div>

                {/* <div className="card-body" style={{overflow:'auto'}}>
                    <table className="table scroll" style={{borderCollapse: 'collapse', borderSpacing: 0, width : '100%', border: '0 solid #ddd'}}>                       
                        <thead >
                            <tr className="text-light bg-primary" >
                            <th scope="col" style={{fontSize: 12, textAlign:'left', padding: '0px',border: '2px solid #000',borderRadius: '10px'}}></th> 
                            <th scope="col" style={{fontSize: 12, textAlign:'left', padding: '0px',border: '2px solid #000',borderRadius: '10px'}}></th> 
                            <th scope="col" style={{fontSize: 12, textAlign:'left', padding: '0px',border: '2px solid #000',borderRadius: '10px'}}>Clave</th> 
                            <th scope="col" style={{fontSize: 12, textAlign:'left', padding: '0px'}}>Nombre</th>
                            <th scope="col" style={{fontSize: 12, textAlign:'left', padding: '0px'}}>Cantidad</th>
                            <th scope="col" style={{fontSize: 12, textAlign:'left', padding: '0px'}}>Cant. Disponible</th>
                            <th scope="col" style={{fontSize: 12, textAlign:'left', padding: '0px'}}>Precio</th> 
                            <th scope="col" style={{fontSize: 12, textAlign:'left', padding: '0px'}}>Precio con IVA</th>
                            <th scope="col" style={{fontSize: 12, textAlign:'left', padding: '0px'}}>Total</th> 
                            <th scope="col" style={{fontSize: 12, textAlign:'left', padding: '0px'}}></th>                                       
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => {
                                return (
                                <tr key={index}>
                                    <td scope="col" style={{fontSize: 12, padding: '0px',border: '2px solid #000',borderRadius: '10px'}}>{index+1}</td>
                                    <td scope="col" style={{fontSize: 12, padding: '0px',border: '2px solid #000',borderRadius: '10px'}}>
                                        <img className="img-fluid " style={{backgroundColor: 'white',maxHeight: 70}} src={item.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + item.PicturName) : require('../images/noImage.png')}/>
                                        {itemsNotificacion.map((element)=>{
                                            return (
                                                <div className="input-group details">
                                                    {item.ItemCode === element.idProducto &&
                                                    <Promociones
                                                    itemsNotificacion = {element.notificacion}/>}
                                                </div>
                                            )})
                                        }
                                    </td>
                                    <td scope="col" style={{fontSize: 12, padding: '0px',border: '2px solid #000',borderRadius: '10px'}}>{item.ItemCode}</td>
                                    <td scope="col" style={{fontSize: 12, padding: '0px'}}>{item.ItemName}</td>
                                    <td scope="col" style={{fontSize: 12, padding: '0px'}}>
                                        <input
                                            disabled={!(!!item.OnHandPrincipal)}
                                            id={'input-quantity-' + item.ItemCode + index}
                                            type="number"
                                            min="1"
                                            value={item.quantity ? Number(item.quantity) : ''}
                                            className="form-control mb-1"
                                            name={'quantity' + item.ItemCode}
                                            placeholder="1"
                                            style={{ textAlign: 'center', borderRadius: 20, borderColor: "#666666", color: "#666666",width: '60%'}}
                                            onChange={(event) => {  this.changeQuantity(index, item, event) }}
                                            onBlur={(e) => this.validateQuantity(index, item, e)} />
                                    </td>
                                    {seller ? seller.U_FMB_Handel_Perfil != '0' ? 
                                        <td scope="col" style={{fontSize: 12, padding: '0px'}}>
                                            {item.OnHandPrincipal}
                                        </td>
                                        : "" : ""
                                    }
                                    <td scope="col" style={{fontSize: 12, padding: '0px'}}>
                                        <CurrencyFormat
                                            value={item.Price}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            prefix={'$'}>
                                        </CurrencyFormat>
                                    </td>
                                    <td scope="col" style={{fontSize: 12, padding: '0px'}}>
                                        <CurrencyFormat
                                            value={item.priceTax}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            prefix={'$'}>
                                        </CurrencyFormat>
                                    </td>
                                    <td scope="col" style={{fontSize: 12, padding: '0px'}}>
                                        <CurrencyFormat
                                            value={item.priceTax * item.quantity}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                            fixedDecimalScale={true}
                                            decimalScale={2}
                                            prefix={'$'}>
                                        </CurrencyFormat>
                                    </td>
                                    <td scope="col" style={{padding: '0px'}}>
                                        {item.quantity &&
                                            <i className="fa fa-trash" style={{ cursor: 'pointer', color: '#DF7654' }} onClick={() => this.deleteItemLocal({ item, deleteAll: false })} />}
                                        {this.validateStock(item)}
                                    </td>
                                </tr>)
                            })}
                        </tbody>
                    </table>
                </div> */}
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
        setShoppingCart: value => dispatch({ type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value }),
        
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShoppingCartList);