import React, {Component} from 'react';
import {config, DISPATCH_ID} from '../libs/utils/Const';
import {connect} from "react-redux";
import { Modal } from './index';
import './NavBarContentSearchItems.css';
import { Link } from 'react-router-dom';
import { ApiClient } from '../libs/apiClient/ApiClient';

let modal = new Modal();
const apiClient = ApiClient.getInstance();
class NavBarContentSearchItems extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
          suggestions: [],
          text: ''
        }
      }

    search = async () => {
        const {itemsReducer,setIdCategory,setLocation} = this.props;
        setIdCategory(null);
        setLocation('navBar');
        await itemsReducer.searchByKey(0,'',true);
    };

    searchModal = () => {
        const { marcas, aparatos, refacciones, fabricantes } = this.state;

        return (
            <div className="modal fade" id="searchModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ border: "none", textAlign: "center" }}>
                <div className="modal-dialog" role="document" style={{ margin: "1.75rem auto" }}>
                    <div className="modal-content">
                        <div className="modal-header" style={{ background: config.navBar.iconColor }}>
                            <h5 className="modal-title " style={{ color: config.navBar.textColor2 }}>Búsqueda</h5>
                            <button type="button" style={{ color: config.navBar.textColor2 }} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body bg3">
                            <form onSubmit={this.handelSubmit} method="post" encType="text/plain" className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4>Marca</h4>
                                        <select name="marca" placeholder="Selecciona una marca" className="form-control text-left" onChange={this.handelChange} style={{ textAlign: "center", height: 30, padding: 0 }}>
                                            <option value="">Selecciona una marca</option>
                                            {marcas &&
                                                marcas.map(marca => {
                                                    return <option value={marca.Code} key={marca.Code}>{marca.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <h4>Aparato</h4>
                                        <select name="aparato" placeholder="Selecciona un aparato" className="form-control text-left" onChange={this.handelChange} style={{ textAlign: "center", height: 30, padding: 0 }}>
                                            <option value="">Selecciona un aparato</option>
                                            {aparatos &&
                                                aparatos.map(aparato => {
                                                    return <option value={aparato.Code} key={aparato.Code}>{aparato.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <h4>Refacción</h4>
                                        <select name="refaccion" placeholder="Selecciona una refaccion" className="form-control text-left" onChange={this.handelChange} style={{ textAlign: "center", height: 30, padding: 0 }}>
                                            <option value="">Selecciona una refacción</option>
                                            {refacciones &&
                                                refacciones.map(refaccion => {
                                                    return <option value={refaccion.Code} key={refaccion.Code}>{refaccion.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <h4>Fabricante</h4>
                                        <select name="fabricante" placeholder="Selecciona un fabricante" className="form-control text-left" onChange={this.handelChange} style={{ textAlign: "center", height: 30, padding: 0 }}>
                                            <option value="">Selecciona un fabricante</option>
                                            {fabricantes &&
                                                fabricantes.map(fabricante => {
                                                    return <option value={fabricante.Code} key={fabricante.Code}>{fabricante.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <h4>Nombre</h4>
                                        <input type="text" className="form-control" placeholder="Nombre(s)" name="nombre" onChange={this.handelChange} />
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-12">
                                            <input type="submit" value="Buscar" className="btn btn-primary mb-2 btn-block "
                                                style={{ fontsize: 25, background: config.navBar.iconColor, color: "white", marginTop: 15 }} />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    onTextChanged = async (e) => {
        const {setSearch} = this.props;
        let value = e.target.value;
        //{item.ItemCode +' - '+ item.ItemName +' - '+ item.Marca || '' +' - '+ item.Modelo || '';
        let arrayValue = value.split("-");
        if(arrayValue.length === 3){
            value = value.replace(/ - /g, ", " );
        }
        // let suggestions = [];
        // if(value.length > 1) {
        //     let apiResponse = await apiClient.AutoComplete(value);
        // //   const regex = new RegExp(`^${value}`, 'i');
        //   suggestions = apiResponse.data.data //apiResponse.data.data.sort().filter(v => regex.test(v));
        // }
        setSearch(value)
        this.setState(() => ({ text: value}));
        if(arrayValue.length === 3){
            setTimeout(()=>{
                this.search();
            }, 150)
        }
    }

    render() {
        const {itemsReducer, setSearch, icon, iconColor, textColor, iconColor3 ,fillDataSearch} = this.props;
        const { text } = this.state;
        let suggestions =  itemsReducer.itemsAutoComplete;
        return (
            <div className="input-group inputSearchItems pr-4">
                {/* <input
                    type="text"
                    className="form-control text-left"
                    name="password"
                    placeholder="Hola. ¿Cuál es el modelo, No. de refaccion o marca que buscas?"
                    autoComplete={'off'}
                    style={{borderRight: "transparent", height: "68.566px"}}
                    onKeyDown={event => event.keyCode === 13 && this.search()}
                    value={itemsReducer.search}
                    onChange={(event) => setSearch(event.target.value)}

                /> */}
                <input
                    id = "dropdownAutoComplete"
                    className = "form-control text-left"
                    name = "password"
                    placeholder = "Hola, ¿cuál es el modelo, número de parte o marca que buscabas?"
                    autoComplete = {'off'}
                    style = {{borderRight: "transparent", height: "68.566px"}}
                    onKeyDown = {event => event.keyCode === 13 && this.search()}
                    value = {itemsReducer.search} 
                    onChange = {this.onTextChanged} type="text" 
                    list = {itemsReducer.search.length > 1 && "suggestionList"}/>
                <datalist id="suggestionList">
                    {suggestions && suggestions.length > 0 && suggestions.map((item) => {
                        return (<option data-value={item.ItemCode+'| '+ item.ItemName +'| '+ item.Marca || '' +'| '+ item.Modelo || ''}>
                                {/* {item.ItemCode +' - '+ item.ItemName +' - '+ item.Marca || '' +' - '+ item.Modelo || '' } */}
                                {item.ItemName || '' }
                            </option>)
                        }
                    )}
                </datalist>
                <span className="divisior"></span>
                
                <button className="btn buttonIconSearch" type="button" style={{borderColor: "#ced4da",color: "#666666", borderLeft: "transparent", color: "#ced4da"}} onClick={this.search}>                        
                    <i
                        className={icon}
                        style={{cursor: 'pointer', padding: 0, margin: 0, color: "#ced4da"}}
                        aria-hidden="true"
                    />
                </button>
                
                {/* <button className="btn buttonAvanzada" type="button" style={{borderColor: "#ced4da",color: "#666666", borderLeft: "transparent", color: "#ced4da"}} onClick={fillDataSearch}>                        
                    Avanzada
                </button> */}
                {/* <div className="input-group-prepend" onClick={this.search} style={{marginLeft: 5}}>
                    <span
                        className="input-group-text"
                        style={{
                            textAlign: 'center',
                            height: 30,
                            cursor: 'pointer',
                            border: 'none',
                            background: 'none',
                            paddingLeft: 1,
                            paddingRight: 1
                        }}>
                        <i
                            className={icon}
                            style={{marginLeft: 5, cursor: 'pointer', color: iconColor}}
                            aria-hidden="true"
                        />
                    </span>
                </div> */}
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SET_SEARCH, value}),
        setIdCategory: value => dispatch({type: DISPATCH_ID.ITEMS_SET_IDCATEGORY, value}),
        setNextPage:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value}),
        setLocation:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_LOCATION, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavBarContentSearchItems)
