import React, {Component} from 'react';
import {config, SERVICE_API, VIEW_NAME, DISPATCH_ID} from '../libs/utils/Const';
import {connect} from "react-redux";
import $ from 'jquery';
import CurrencyFormat from 'react-currency-format';
import {BreadCrumb} from './index'
import "./ItemSlider.css";
import { Redirect } from 'react-router';

class SideBarItems extends Component {

    seeAllCategories = ()=>{
        $('#filterCategoriesModal').modal('show');
    }

    uniqueFilter = async (property, value, value2) => {
        const {itemsReducer, setItemsFilters, viewOne } = this.props;
        let data = {property : property, value : value, value2, value2}
        // Nueva variable de redux para guardar filtro
        setItemsFilters(data);
        setTimeout(async() => {
            // Ejecutar searchByKey
            if(viewOne && viewOne !== ''){
                console.log();
                await itemsReducer.searchByKey(0, viewOne);
            } else {
                await itemsReducer.searchByKey();
            }       
            
        }, 350);    
        $('#filterCategoriesModal').modal('hide');
    };

    render() {
        const { totalRows, dataCategories, itemsReducer:{ uniqueFilter }, itemsReducer} = this.props;
        // Variable que limita no. de caracteres de nombre de categorías
        let categoryStringLimiter = 28;
        // Limitante de categorias mostradas en SideBar
        let limitNumber = 10;
        // Modal de categorias
        let twoColumns = dataCategories ? dataCategories.itemsCategories ? dataCategories.itemsCategories.length >= 10 ? Number(((dataCategories.itemsCategories.length + 9) / 2).toFixed(0)) : dataCategories.itemsCategories.length : 0 : 0;
        if(!dataCategories || !dataCategories.itemsCategories){
            return <Redirect to="/"/>;
        } else {
            return (
                <div>
                    <div className="breadcrumb" style={{fontSize: "20px"}} aria-label="breadcrumb">
                        <BreadCrumb
                        data ={dataCategories.itemsCategories}
                        itemsReducer ={itemsReducer}
                        // data={dataCategories.itemsCategories }
                        />
                        </div>
                    <div>{totalRows > 1 ? totalRows+' resultados': totalRows+' resultado'}</div>
                    {/* Categorías */}
                    {(dataCategories && dataCategories.itemsCategories.length > 0) && 
                        <h5 className="mt-md-5 font-weight-bold TituloCategoria" >Categorías</h5>
                    }
                    {(dataCategories && dataCategories.itemsCategories.length > 0) && 
                        <ul className = "mt-2 SubCategorias" style={{marginLeft:"-45px",}}>
                            <dl>
                                {dataCategories.itemsCategories.map((category, index) => {
                                    if(index < limitNumber){
                                        if(category.category.includes(',')){
                                            let categoryNameArray = category.categoryName.split(',');
                                            let length = categoryNameArray.length;
                                            let containerReturn = [];
                                            for (let i = 0; i < categoryNameArray.length; i++) {
                                                let dinamicCategory = categoryNameArray[i].substring(0, categoryStringLimiter);
                                                containerReturn.push(<dd >{(i == 0) && dinamicCategory}
                                                    {((i > 0 && i < (length - 1))) && <ol> {dinamicCategory} </ol>}
                                                    {(i == (length - 1)) && <ol className = 'linkFilter' className = 'linkFilter' onClick = {() => this.uniqueFilter('categorias', category.category, '')}>
                                                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>{dinamicCategory} ({category.times})
                                                    </ol>}
                                                </dd>);
                                            }
                                            return containerReturn;
                                        } else {
                                            return <dd className = 'linkFilter' onClick = {() => this.uniqueFilter('categorias', category.category, '')}>
                                                    {category.categoryName} ({category.times})
                                                </dd>
                                        }
                                    }
                                })}
                                {dataCategories.itemsCategories.length >= 10 && 
                                <dd className = 'linkFilter font-weight-bold' onClick={()=> this.seeAllCategories()}> Ver todas las categorías </dd>}
                            </dl>
                        </ul>
                    }
                    {/* Marcas */}
                    {(dataCategories && dataCategories.itemsBrands.length > 0) && 
                        <h5 className="mt-md-4 font-weight-bold TituloCategoria">Marcas</h5>
                    }
                    {(dataCategories && dataCategories.itemsBrands.length > 0) && 
                        <ul className = "mt-2 SubCategorias">
                            <dl>
                                {dataCategories.itemsBrands.map(brand => {
                                    return  <dd className = 'linkFilter' onClick = {() => this.uniqueFilter('marca', brand.brand, '')}>
                                                {brand.brand} ({brand.times})
                                            </dd>
                                })}
                            </dl>
                        </ul>
                    }
                    {/* Fábricas
                    {(dataCategories && dataCategories.itemsFacilities.length > 0) && 
                        <h5 className="mt-md-4 font-weight-bold TituloCategoria">Fábricas</h5>
                    }
                    {(dataCategories && dataCategories.itemsFacilities.length > 0) && 
                        <ul className = "mt-2 SubCategorias">
                            <dl>
                                {dataCategories.itemsFacilities.map(facility => {
                                    return  <dd className = 'linkFilter' onClick = {() => this.uniqueFilter('fabrica', facility.facility, '')}>
                                                {facility.facility} ({facility.times})
                                            </dd>
                                })}
                            </dl>
                        </ul>
                    } */}
                    {/* Aparatos */}
                    {(dataCategories && dataCategories.itemsDevices.length > 0) && 
                        <h5 className="mt-md-4 font-weight-bold TituloCategoria">Aparatos</h5>
                    }
                    {(dataCategories && dataCategories.itemsDevices.length > 0) && 
                        <ul className = "mt-2 SubCategorias">
                            <dl>
                                {dataCategories.itemsDevices.map(device => {
                                    return  <dd className = 'linkFilter' onClick = {() => this.uniqueFilter('aparato', device.device, '')}>
                                                {device.device} ({device.times})
                                            </dd>
                                })}
                            </dl>
                        </ul>
                    }
                    {/* Refacciones */}
                    {(dataCategories && dataCategories.itemsSpareParts.length > 0) && 
                        <h5 className="mt-md-4 font-weight-bold TituloCategoria">Refacciones</h5>
                    }
                    {(dataCategories && dataCategories.itemsSpareParts.length > 0) && 
                        <ul className = "mt-2 SubCategorias">
                            <dl>
                                {dataCategories.itemsSpareParts.map(part => {
                                    return  <dd className = 'linkFilter' onClick = {() => this.uniqueFilter('refaccion', part.part, '')}>
                                                {part.part} ({part.times})
                                            </dd>
                                })}
                            </dl>
                        </ul>
                    }
                    {/* Precios */}
                    {(dataCategories && dataCategories.pricesSideBar) && 
                        <h5 className="mt-md-4 font-weight-bold TituloCategoria">Precios</h5>
                    }
                    {(dataCategories && dataCategories.pricesSideBar) && 
                        <ul className = "mt-2 SubCategorias">
                            <dl>
                                <dd className = 'linkFilter' onClick = {() => this.uniqueFilter('precio', dataCategories.pricesSideBar.max, '')}>
                                    Hasta ${(dataCategories.pricesSideBar.min).toFixed(2)}
                                </dd>
                                {(dataCategories.pricesSideBar.min != dataCategories.pricesSideBar.max) && 
                                    <dd className = 'linkFilter' onClick = {() => this.uniqueFilter('precio', dataCategories.pricesSideBar.min, dataCategories.pricesSideBar.max)}>
                                        Entre ${(dataCategories.pricesSideBar.min).toFixed(2)} - ${(dataCategories.pricesSideBar.max).toFixed(2)}
                                    </dd>
                                }
                                <dd className = 'linkFilter' onClick = {() => this.uniqueFilter('precio', '', dataCategories.pricesSideBar.min)}>
                                    Desde ${(dataCategories.pricesSideBar.max).toFixed(2)}
                                </dd>
                                
                            </dl>
                        </ul>
                    }
                    {/* Stock */}
                    {/* {(dataCategories && dataCategories.stock) && 
                        <h5 className="mt-md-4 font-weight-bold TituloCategoria">Existencias</h5>
                    } */}
                    {/* {(dataCategories && dataCategories.stock) && 
                        <ul className = "mt-2 SubCategorias">
                            <dl>
                                {(dataCategories.stock.available !== 0) &&
                                    <dd className = 'linkFilter' onClick = {() => this.uniqueFilter('stock', 'A', '')}>
                                        Disponibles {(dataCategories.stock.available)}
                                    </dd>
                                }
                                {(dataCategories.stock.missing !== 0) &&
                                    <dd className = 'linkFilter' onClick = {() => this.uniqueFilter('stock', 'B', '')}>
                                        Agotados {(dataCategories.stock.missing)}
                                    </dd>
                                }
                            </dl>
                        </ul>
                    } */}
                    <div className="modal fade" id="filterCategoriesModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{border: "none", textAlign: 'center'}}>
                        <div className="modal-dialog modal-lg" role="document" style={{margin: '1.75rem auto'}}>
                            <div className="modal-content">
                                <div className="modal-header" style={{background: config.navBar.backgroundColor}}>
                                    <h5 className="modal-title" style={{color: config.navBar.textColor}}>Otras categorías</h5>
                                    <button type="button" style={{color: config.navBar.textColor}} className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body bg3">
                                    <div className = 'row justity-content-center'>
                                    <div className = 'col-md-6'>
                                        {dataCategories && dataCategories.itemsCategories.length > 0 && dataCategories.itemsCategories.map((category, index) => {
                                            if(index >= limitNumber && index <= twoColumns){
                                                if(category.category.includes(',')){
                                                    let categoryNameArray = category.categoryName.split(',');
                                                    let length = categoryNameArray.length;
                                                    let containerReturn = [];
                                                    for (let i = 0; i < categoryNameArray.length; i++) {
                                                        let dinamicCategory = categoryNameArray[i].substring(0, categoryStringLimiter);
                                                        containerReturn.push(<span>
                                                            {(i == 0) && dinamicCategory}
                                                            {((i > 0 && i < (length - 1))) && ' / ' + dinamicCategory}
                                                            {(i == (length - 1)) && <span className='linkFilter mr-md-2' onClick = {() => this.uniqueFilter('categorias', category.category, '')}> <i class="fa fa-angle-double-right" aria-hidden="true"></i>{dinamicCategory}</span>}
                                                            {(i == (length - 1)) && <span className='linkFilter mr-md-2 ' onClick = {() => this.uniqueFilter('categorias', category.category, '')}>({category.times})</span>}
                                                            </span>);
                                                    }
                                                    containerReturn.push(<br/>);
                                                    containerReturn.push(<br/>);
                                                    return containerReturn;
                                                } else {
                                                    let containerReturn = [];
                                                    containerReturn.push(<span className = 'linkFilter mr-md-2' onClick = {() => this.uniqueFilter('categorias', category.category, '')}>
                                                            {category.categoryName} ({category.times})
                                                        </span>);
                                                    containerReturn.push(<br/>);
                                                    containerReturn.push(<br/>);
                                                    return containerReturn;
                                                }
                                            } 
                                        })}
                                    </div>
                                    <div className = 'col-md-6'>
                                        {dataCategories && dataCategories.itemsCategories.length > 0 && dataCategories.itemsCategories.map((category, index) => {
                                            if(index >= limitNumber && index > twoColumns){
                                                if(category.category.includes(',')){
                                                    let categoryNameArray = category.categoryName.split(',');
                                                    let length = categoryNameArray.length;
                                                    let containerReturn = [];
                                                    for (let i = 0; i < categoryNameArray.length; i++) {
                                                        let dinamicCategory = categoryNameArray[i].substring(0, categoryStringLimiter);
                                                        containerReturn.push(<span>
                                                            {(i == 0) && dinamicCategory}
                                                            {((i > 0 && i < (length - 1))) && ' / ' + dinamicCategory}
                                                            {(i == (length - 1)) && <span className='linkFilter mr-md-2' onClick = {() => this.uniqueFilter('categorias', category.category, '')}>/{dinamicCategory}</span>}
                                                            {(i == (length - 1)) && <span className='linkFilter mr-md-2' onClick = {() => this.uniqueFilter('categorias', category.category, '')}>({category.times})</span>}
                                                            </span>);
                                                    }
                                                    containerReturn.push(<br/>);
                                                    containerReturn.push(<br/>);
                                                    return containerReturn;
                                                } else {
                                                    let containerReturn = [];
                                                    containerReturn.push(<span className = 'linkFilter mr-md-2' onClick = {() => this.uniqueFilter('categorias', category.category, '')}>
                                                            {category.categoryName} ({category.times})
                                                        </span>);
                                                    containerReturn.push(<br/>);
                                                    containerReturn.push(<br/>);
                                                    return containerReturn;
                                                }
                                            } 
                                        })}
                                    </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
            
                </div>
            );
        }
    }
}

const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        itemsReducer: store.ItemsReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setShoppingCart: value => dispatch ({type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value}),
        setItemsFilterSearch: value => dispatch ({type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value}),
        setNextPage:  value => dispatch ({type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value}),
        setItemsFilters: value => dispatch({type: DISPATCH_ID.ITEMS_SET_UNIQUE_FILTER, value}), 

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SideBarItems);