// import React, {Component} from 'react';
// import {config, SERVICE_API, VIEW_NAME, DISPATCH_ID} from '../libs/utils/Const';
// import {connect} from "react-redux";


// class Pagination extends Component {

//     constructor(props) {
//         super(props);

//         this.state = {
//             totalRows: 0,
//             pageNumber: 0,
//             pageNum: 1,
//             locations:'',
//         };
//     };
//     shouldComponentUpdate(nextProps, nextState, nextContext) {
//         const {totalRows,pageNumber,pageNum} = this.state;
//         const {totalRowsRefresh} = this.props;
//         let flang = false;
//         if(totalRowsRefresh != nextProps.totalRowsRefresh || totalRows != nextProps.totalRowsRefresh ){
//             flang= true;
//         }else if( totalRows != nextState.totalRows){
//             flang= true;
//         }else if( pageNumber != nextState.pageNumber){
//             flang= true;
//         }else if( pageNum != nextState.pageNum){
//             flang= true;
//         }
//         return   flang;
//     }
//     componentDidUpdate(){
//         const {totalRows,locations} = this.state;
//         const {totalRowsRefresh,itemsReducer:{location}} = this.props;

//         if (totalRows != totalRowsRefresh) {
//             this.setState({
//                 totalRows: totalRowsRefresh,
//                 locations:location,
//             });
//         }
//         if(locations != location){
//             this.setState({
//                 pageNum: 1,
//                 pageNumber: 0,
//             });
//         }
//     }
   
//     handleChangeFirstPage = ()=>{
//         const {totalRows,pageNumber} = this.state;
//         const {refresh} = this.props;

//         this.setState({
//             pageNumber : 0,
//             pageNum: 1,
//         });
//         setTimeout(async ()=> {
//             refresh(0);
//         }, 10);
//     }

//     handleChangePreviusPage = ()=>{
//         const {totalRows,pageNumber,pageNum} = this.state;
//         const {refresh} = this.props;
//         let page = pageNumber - 10 ;
//         let num = pageNum - 1;
//         if(page >= 0){
//             this.setState({
//                 pageNumber : page,
//                 pageNum: num,
//             });
//             setTimeout(async ()=> {
//                 refresh(page);
//             }, 10);
//         }
//     }

//     handleChangeNextPage = ()=>{
//         const {totalRows,pageNumber,pageNum} = this.state;
//         const {refresh} = this.props;
//         let page = pageNumber + 10 ;
//         let last = Math.ceil(totalRows / 10);
//         let num = pageNum + 1;
//         if(page <= totalRows && num <= last){
            
//             this.setState({
//                 pageNumber : page,
//                 pageNum: num,
//             });
//             setTimeout(async ()=> {
               
//                 refresh(page);
//             }, 300);
//         }
//     }
    
//     handleChangeLastPage = ()=>{
//         const {totalRows} = this.state;
//         const {refresh} = this.props;
//         let last =  Math.ceil(totalRows / 10);
//         let page = (last -1) * 10 ;
        
//         this.setState({
//             pageNumber : page,
//             pageNum: last,
//         });
//         setTimeout(async ()=> {
//             refresh(page);
//         }, 10);
//     }
//     handleChangesSelectedPage = (selectedPage)=>{
//         const {totalRows} = this.state;
//         const {refresh} = this.props;
//         let page = selectedPage * 10;
//         this.setState({
//             pageNumber : page,
//             pageNum: selectedPage + 1,
//         });
//         setTimeout(async ()=> {
//             refresh(page);
//         }, 10);
//     }
//     render(){
//         const {totalRows,pageNum,pageNumber} =  this.state;
//         var x = window.matchMedia("(max-width: 600px)")
//         let page = Math.ceil(totalRows/10);
//         let array = [];
//          for (let index = 0; index < page; index++) {
//             array.push(index);
//          }
//         if(parseInt(totalRows) <= 0){
//             return (
//                 <div className="row justify-content-center"></div>
//             );
//         }
//         // console.log('con>x.matches ',x.matches );
//         return (
//             <div className="row justify-content-center pag"> 
//                 {/* <label className="paginacion"><strong>{'Página ' + pageNum  + ' de ' + Math.ceil(totalRows/10)}</strong></label> */}
//                 {/* <button type="button" className="buttonPagination btn btn-light" disabled = {totalRows > 10 ? false : true} onClick = {()=>this.handleChangeFirstPage()} data-toggle="tooltip" title="Primero"><i className="fa fa-step-backward" aria-hidden="true" ></i></button> */}
//                 <button type="button" className="buttonPagination btn" style={{color: "#808080"}} disabled = {totalRows > 10 ? false : true} onClick = {()=>this.handleChangePreviusPage()} data-toggle="tooltip" title="Anterior"><i className="fa fa-chevron-left" aria-hidden="true"></i> Anterior</button>
//                 {!x.matches && <button type="button" className="buttonPagination btn" style={{background: config.navBar.iconColorPaginacion, color: config.navBar.textColorPaginacion}} data-toggle="tooltip" title="Página">{Math.ceil(pageNum)}</button>}
//                 {x.matches && <div id="scroll-container">
//                     <div className="wrap-container" id="wrap-scroll">
//                         <ul id="ul-scroll">
//                             {
//                                 array.map((index)=>{
//                                     return(
//                                         <li> <span className="item " onClick = {()=>this.handleChangesSelectedPage(index)} > {index + 1} </span> </li>
//                                     )
//                                 })
//                             }
//                         </ul>
//                     </div>
//                 </div>}
//                 {/* {totalRows > 10 ? false : true} */}
//                 <button type="button" className="buttonPagination btn" style={{color: "#808080"}} disabled = {totalRows > 10 ? false : true} onClick = {()=>this.handleChangeNextPage()} data-toggle="tooltip" title="Siguiente">Siguiente <i className="fa fa-chevron-right" aria-hidden="true"></i></button>
//                 {/* <button type="button" className="buttonPagination btn btn-light" disabled = {totalRows > 10 ? false : true} onClick = {()=>this.handleChangeLastPage()} data-toggle="tooltip" title="Último"><i className="fa fa-step-forward" aria-hidden="true"></i></button> */}
                
//             </div>
//         );
//     }

// }
// const mapStateToProps = store => {
//     return {
//         notificationReducer: store.NotificationReducer,
//         shoppingCartReducer: store.ShoppingCartReducer,
//         itemsReducer: store.ItemsReducer,
//     };
// };
//  const mapDispatchToProps = dispatch => {
//     return {
//         setShoppingCart: value => dispatch({type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value}),
//         setItemsFilterSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value}),
//         setNextPage:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value}),
        
//     };
// };

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps,
// )(Pagination);


import React from 'react';
import PropTypes from 'prop-types';
import './Pagination.css'

const propTypes = {
    totalRowsRefresh: PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    initialPage: PropTypes.number,
    pageSize: PropTypes.number
}


const defaultProps = {
    initialPage: 1,
    pageSize: 50
}
class Pagination extends React.Component {
    constructor(props) {
        super(props);
        this.state = { pager: {} };
    }

    componentWillMount() {
        // set page if items array isn't empty
        if (this.props.totalRowsRefresh) {
            this.updatePage();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // reset page if items array has changed
        if (this.props.totalRowsRefresh !== prevProps.totalRowsRefresh) {
            this.updatePage();
        }
    }

    updatePage = ()=>{
        var { totalRowsRefresh, pageSize } = this.props;
        var pager = this.state.pager;
        
        // get new pager object for specified page
        pager = this.getPager(totalRowsRefresh, 0, pageSize);
        
        // update state
        this.setState({ pager });
    }
    setPage(page) {
        var { totalRowsRefresh, pageSize } = this.props;
        var pager = this.state.pager;
        
        if (page < 1 || page > pager.totalPages) {
            return;
        }
        // get new pager object for specified page
        pager = this.getPager(totalRowsRefresh, page, pageSize);
        
        // update state
        this.setState({ pager });

        // call change page function in parent component
        this.props.refresh(pager.currentPage);
    }

    getPager(totalItems, currentPage, pageSize) {
        // default to first page
        currentPage = currentPage || 1;

        // default page size is 10
        pageSize = pageSize || 50;

        // calculate total pages
        var totalPages = Math.ceil(totalItems / pageSize);

        var startPage, endPage;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }

        // create an array of pages to ng-repeat in the pager control
        var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);

       

        // return object with all pager properties required by the view
        return {
            currentPage: currentPage,
            totalPages: totalPages,
            pages: pages
        };
    }

    render() {
        var pager = this.state.pager;
        const {pageSize} = this.props;

        if (!pager.pages || pager.pages.length <= 1) {
            // don't display pager if there is only 1 page
            return null;
        }
        
        return (
            <ul className="pagination">
                <li className={pager.currentPage  === 1 ? 'disabled' : ''}>
                    <a href="#Ancla" onClick={() => this.setPage(1)}>Primero</a>
                </li>
                <li className={pager.currentPage  === 1 ? 'disabled' : ''}>
                    <a href="#Ancla" onClick={() => this.setPage(pager.currentPage - 1 )}>Anterior</a>
                </li>
                {pager.pages.map((page, index) =>{
                    return (<li key={index} className={pager.currentPage === page ? 'active' : ''}>
                        <a href="#Ancla" onClick={() => this.setPage(page)}>{page}</a>
                    </li>)}
                )}
                <li className={pager.currentPage === pager.totalPages ? 'disabled' : ''}>
                    <a href="#Ancla" onClick={() => this.setPage(pager.currentPage + 1)}>Siguiente</a>
                </li>
                <li className={pager.currentPage === pager.totalPages ? 'disabled' : ''}>
                    <a href="#Ancla" onClick={() => this.setPage(pager.totalPages)}>Ultimo</a>
                </li>
            </ul>
        );
    }
}

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;
export default Pagination;