import React, { Component } from 'react';
import { Session, NavBar, CarouselDashboard, Slider, Footer, MenuCategories, ItemsList, ItemSlider, ItemSliderResponsive, Suscription, TopNavBar, DejanosMensajeModal,ItemSlider1,ItemSlider2, } from '../../components';
import { config, VIEW_NAME, SERVICE_API, DISPATCH_ID, ROLES  } from '../../libs/utils/Const';
import { connect } from "react-redux";
import { Prueba } from '../../components/MenuCategories';
import { ApiClient } from "../../libs/apiClient/ApiClient";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './Categories.css';
import $ from "jquery";

const apiClient = ApiClient.getInstance();
const responsiveCategories = {
    desktop: {
      breakpoint: { max: 3500, min: 1920 },
      items: 6,
      partialVisibilityGutter: 10 // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1920, min: 1200 },
      items: 5,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    tablet2: {
        breakpoint: { max: 1200, min: 464 },
        items: 2,
        partialVisibilityGutter: 10 // this is needed to tell the amount of px that should be visible.
      },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 10 // this is needed to tell the amount of px that should be visible.
    }
  }

class DashboardView extends Component {
    //Crearla funcion ene le store procedure para trear el baner de enmedio
    //en el back, genearar la función par apoder consultarl, similar a lo de categorias ygen errar su ruta
    //front, generar la funcion en Apiclient, g¿enerar la variabla para la ruta de la funcion crada para poder consultar la imgane del banner de enmedio
    state = {
        itemsPromociones: [],//CODIGO DEL PRODUCTO EN UN ARREGLO DE DASH
        itemsNuevos: [],
        items: [],
        categories: [],
        banner: [],
    }
    async componentDidMount() {
        const { sessionReducer: {role}, enableSpinner, itemsReducer:{nextPage}, Spinner, setItemsSearch, configReducer: { history }, setItemsFilterSearch, searchByDash, searchByDashOption } = this.props;
        searchByDash(this.handelSubmit);
        enableSpinner(true);
        let whs = '';
        let user = {};

        try {
            user = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser'));
            whs = user.U_FMB_Handel_ALMA || '';
        } catch (error) {
            whs = '1'
        }
        let localShoppingCart = undefined;
        if (role === ROLES.PUBLIC) {
            localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');
            localShoppingCart = JSON.parse(localShoppingCart) || [];
        }
        // Categorias para mostrar al inicio del modelo
        await apiClient.getCategoriesHome('home').then(result => {
            this.setState({
                categories: result.data,
            });
        });

        // Busqueda de los banners para el inicio
        await apiClient.getBannerHome().then(result => {
            this.setState({
                banner: result.data,
            });
        });

      
        // Busqueda de nuevo producto

        // let busqueda2 = {
        //     contenido: {
        //         opcion: "nuevosproductos",
        //         limit: 20
        //     }
        // };
      
     
        // await apiClient.ProductsEspecial(busqueda2,localShoppingCart, whs, 0).then(result => {

        //     this.setState({
        //         itemsPromociones: result.data.responseBody,
        //         itemsNuevos: result.data.responseBody2,
        //     });
        // });
        enableSpinner(false);
    }

    //Arrows o bien con funiciones declaradas

    mostrarCategoria = (valor) => {
        //Estamos haciendo uso de props del sistema
        const {setCategory,setIdCategory, setLocation,itemsReducer: {searchByCategories},setNextPage, itemsReducer} = this.props;
        console.log("restar",itemsReducer);
        setIdCategory(valor);
        setNextPage(0);
        setLocation('menuCategories');
        searchByCategories(valor,0,'home');
    };


    changeQuantity = (index, item, newQuantity, addItem) => {
        const { itemsReducer: { addShoppingCart, deleteShoppingCart } } = this.props;
        if (addItem) {
            addShoppingCart({ item, quantity: (newQuantity || '1') })
        } else {
            deleteShoppingCart({ item, deleteAll: false });
        }
    };

    changeBackOrder = (item, addItem) => {
        const { itemsReducer: { deleteBackOrder, addBackOrder } } = this.props;
        if (addItem) {
            addBackOrder({ item, quantity: 1 })
        } else {
            deleteBackOrder({ item, deleteAll: false });
        }
    };

    handelSubmit = async (opcion,page = 0) => {
        const { sessionReducer: {role}, enableSpinner, itemsReducer:{nextPage}, setTotalRows, setItemsSearch, configReducer: { history }, setItemsFilterSearch, setLocation, searchByDashOption } = this.props;
        //console.log("Valor boton", opcion);

        let whs = '';
        let user = {};
        // console.log('con>', nextPage);
        try {
            user = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser'));
            whs = user.U_FMB_Handel_ALMA || '';
        } catch (error) {
            whs = '01'
        }
        let localShoppingCart = undefined;
        if (role === ROLES.PUBLIC) {
            localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');
            localShoppingCart = JSON.parse(localShoppingCart) || [];
        }
        //Hace la petición al back
        let busqueda = {
            contenido: {
                opcion: opcion
            }
        };
        setLocation('dash');
        searchByDashOption(opcion);

        enableSpinner(true);
        await apiClient.getProductsEspecial(busqueda,localShoppingCart, whs, page).then(result => {
            // console.log(result);
            enableSpinner(false);
            setItemsSearch(result.data.responseBody);
            setItemsFilterSearch(result.data.responseBody);
            setTotalRows(result.data.totalRows);
            history.goItems();
            return;
        });
        //Validamos la respuesta del Back
    };

    redirectMulos = async () => {
        const { configReducer: { history }} = this.props;
        const {itemsReducer,setIdCategory,setLocation} = this.props;
        setIdCategory(null);
        setLocation('Mulos');
        await itemsReducer.searchByKey(0,'Mulos');
    };

    redirectOFFSET = async () => {
        const { configReducer: { history }} = this.props;
        const {itemsReducer,setIdCategory,setLocation, itemsReducer: {searchByCategories},setNextPage} = this.props;
        setIdCategory('1');
        // setLocation('OFFSET');
        setLocation('menuCategories');
        // await itemsReducer.searchByKey(0,'OFFSET');
        searchByCategories('1',0,'Descubre');
    };

    redirectFLEXO = async () => {
        const { configReducer: { history }} = this.props;
        const {itemsReducer,setIdCategory,setLocation, itemsReducer: {searchByCategories},setNextPage} = this.props;
        setIdCategory('16');
        // setLocation('OFFSET');
        setLocation('menuCategories');
        // await itemsReducer.searchByKey(0,'OFFSET');
        searchByCategories('16',0,'Descubre');
    };

    redirectFORMATO = async () => {
        const { configReducer: { history }} = this.props;
        const {itemsReducer,setIdCategory,setLocation, itemsReducer: {searchByCategories},setNextPage} = this.props;
        setIdCategory('21');
        // setLocation('OFFSET');
        setLocation('menuCategories');
        // await itemsReducer.searchByKey(0,'OFFSET');
        searchByCategories('21',0,'Descubre');
    };

    redirectREACONDI = async () => {
        const { configReducer: { history }} = this.props;
        const {itemsReducer,setIdCategory,setLocation, itemsReducer: {searchByCategories},setNextPage} = this.props;
        setIdCategory('91');
        // setLocation('OFFSET');
        setLocation('menuCategories');
        // await itemsReducer.searchByKey(0,'OFFSET');
        searchByCategories('91',0,'Descubre');
    };
    
   
    redirectAboutRedZone = async () => {
        const { configReducer: { history } } = this.props;
        history.goAboutRedZone();
    };

    redirectgoHome = async () => {
        const { configReducer: { history } } = this.props;
        history.goHome();
    };

    redirectRedStock = async () => {
        return (
            <a href={`${config.BASE_URL}/${SERVICE_API.getPolitics}/${config.pdf.politicasEnviosGarantias}`} target="_blank" ></a>
        )
    };

    VerMas = (name)=>{
        let result = '';
        switch (name) {
            // case 'Nosotros':
            //     result = (this.redirectAboutUs);
            //     break;
            // case 'BlogNoticias':
            //     result = (this.redirectNewsBlog);
            //     break;
            // case 'Cotizacion':
            //     result = (this.redirectCotizacion);
            //     break;
            // case 'Mulos':
            //     result = (this.redirectMulos);
            //     break;
            // Utilizar la categoia de SAP para hacer la búsqueda a futuro
            case 'OFFSET':
                result = (this.redirectOFFSET);
                break;
            case 'FLEXO':
                result = (this.redirectFLEXO);
                break;
            case 'FORMATO':
                result = (this.redirectFORMATO);
                break;
            case 'REACONDICIONADOS':
                result = (this.redirectREACONDI);
                break;

            default:
                result = (this.redirectgoHome);
                break;
        }
        return result
    }

    //Render va pintar el codigo html que ingresemos en la funcion return 
    render() {
        const { history, itemsReducer: { updateFavorite, deleteShoppingCart, openItemDetails,items1,items2 },sessionReducer: { user }, sessionReducer } = this.props;
        const { categories, items, banner, itemsPromociones, itemsNuevos } = this.state;     
        return (

            <div className="container-fluid" style={{ marginTop: 150, backgroundColor: "#EFEFEF", paddingLeft:0, paddingRight:0 }}>
                <Session history={history} view={VIEW_NAME.DASHBOARD_VIEW} />
                <NavBar />
                <DejanosMensajeModal
                    Usuario={user}
                    datos = {sessionReducer.role}
                ></DejanosMensajeModal>
                <div className="overflow-auto">
                {/* Si es un video */}
                {config.dashboard === 'Video' && <Slider />}
                {/* Si es un video */}
                {config.dashboard === 'Carousel' && <CarouselDashboard />}
                <TopNavBar/>
                <div className="text-justify col-md-12">
                    <p><br></br></p>
                    <p className="titulodeSeccionPromoRed">Promociones</p>
                </div>
                <div style={{ padding: 15}}>
                    <div className="PromoRedSlider" style={{  margin: 0, justifyContent: "center", alignItems: "center" }}>
                    {items1 &&
                            items1.length !== 0 ? (
                                <ItemSlider1 changeQuantity={this.changeQuantity} dashboard={10} changeBackOrder={this.changeBackOrder}/>
                            ) : (
                                <div style={{ paddingTop: 100, margin: 0, textAlign: "center" }}>No hay artículos disponibles de momento:
                                    <br />
                                    <br />
                                    <i style={{ fontSize: 70 }} className={config.icons.search}></i>
                                </div>
                            )}
                    </div>
                </div>
                <div className="text-justify col-md-12">
                    <p><br></br></p>
                    <p className="titulodeSeccionDescubre">Descubre</p>
                </div>
                <div style={{ paddingTop: 30}}>
                    <div className="cardsDescubre col-lg-12 row" style={{ color: "white"}}>
                    {banner.length !== 0 &&
                            banner.map((ban,index)=>{
                                return (
                                    ban.active === "on" &&
                                        ban.Name === "Informacion" ?
                                        <div className="col-lg-6 col-md-6 pb-4" style={{display:"flex", justifyContent:((index / 2) == 0 || (index / 2) == 1? "right" : "left ")}}>
                                            <div className="col-lg-10 col-md-10 pb-2" key={index}>
                                                <div className="ConoceTarjeta card img-fluid" style={{border: 'none', background: "transparent"}}>
                                                    <img
                                                        className="card-img-top"
                                                        src={ban.image ? (config.BASE_URL + SERVICE_API.getImageCategories + '/' + ban.image) : require('../../images/noImage.png')}
                                                        alt="Card image"
                                                        style={{width:"100%"}}></img>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="col-lg-6 col-md-6 pb-4" style={{display:"flex", justifyContent:((index / 2) == 0 || (index / 2) == 1? "right" : "left ")}}>
                                            <div className="col-lg-10 col-md-10 pb-2" key={index}>
                                                <div className="ConoceTarjeta card img-fluid" style={{border: 'none', background: "transparent"}}>
                                                    <img 
                                                        className="card-img-top" 
                                                        src={ban.image ? (config.BASE_URL + SERVICE_API.getImageCategories + '/' + ban.image) : require('../../images/noImage.png')}
                                                        alt="Card image" 
                                                        style={{width:"100%"}}></img>
                                                    <div className="card-img-overlay">
                                                        <a onClick={this.VerMas(ban.Name)} className="btn text-white" 
                                                        style={{background: "rgb(0,94,173)",marginTop: '5%', marginLeft:'85%'}}>
                                                            Ver más
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className="text-justify col-md-12">
                    <p><br></br></p>
                    <p className="titulodeSeccionNuevosProductos">Nuevos productos</p>
                </div>
                <div style={{ padding: 15}}>
                    <div style={{ paddingTop: 30, margin: 0, justifyContent: "center", alignItems: "center" }}>
                    {items2 &&
                            items2.length !== 0 ? (
                                <ItemSlider2 changeQuantity={this.changeQuantity} dashboard={10} changeBackOrder={this.changeBackOrder}/>
                            ) : (
                                <div style={{ paddingTop: 100, margin: 0, textAlign: "center" }}>No hay artículos disponibles de momento:
                                    <br />
                                    <br />
                                    <i style={{ fontSize: 70 }} className={config.icons.search}></i>
                                </div>
                            )}
                    </div>
                </div>
                <div className="envios col-lg-12 row mt-4 text-center pt-4 pb-4">
                    <div className="pagosTarjeta col-lg-4 col-md-4">
                        <img className="img-fluid" src={config.trademarks.pagoTarjeta} style={{height:"6rem"}}></img>
                        <h5 className="mt-2 font-weight-bold">Paga con tarjeta de crédito o débito</h5>
                        <p >Realizar tus pagos de manera segura <br/> en nuestra plataforma.</p><br/>
                    </div>
                    <div className="enviosRepublica col-lg-4 col-md-4">
                        <img className="img-fluid" src={config.trademarks.enviosRepublica} style={{height:"6rem"}}></img>
                        <h5 className="mt-2 font-weight-bold">Envíos a toda la República Mexicana</h5>
                        <p >No importa en qué parte de México estés, <br/> nosotros te lo enviamos.</p><br/>
                    </div>
                    <div className="comprasSeguras col-lg-4 col-md-4">
                        <img className="img-fluid" src={config.trademarks.comprasSeguras} style={{height:"6rem"}}></img>
                        <h5 className="mt-2 font-weight-bold">Compras seguras</h5>
                        <p >Realizar tus pagos de manera segura <br/> en nuestra plataforma.</p><br/>
                    </div>
                </div>
                {/* <Suscription/> */}
                </div >
            </div >
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer
    };
};


const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setItemsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS, value }),
        setItemsFilterSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value }),
        setTotalRows : value => dispatch({type: DISPATCH_ID.ITEMS_SET_TOTALROWS, value}),
        setItemsCategories: value => dispatch({type: DISPATCH_ID.ITEMS_SET_CATEGORIES, value}), 
        setLocation:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_LOCATION, value}),
        setDashboard:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_DASH, value}),
        searchByDash:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_SEARCH_BY_DASH, value}),
        searchByDashOption: value => dispatch({type: DISPATCH_ID.ITEMS_SET_SEARCH_BY_DASH_OPTION, value}),
        setCategory: value => dispatch({type: DISPATCH_ID.ITEMS_SET_CATEGORY, value}),
        setIdCategory: value => dispatch({type: DISPATCH_ID.ITEMS_SET_IDCATEGORY, value}),
        setNextPage:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value}),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DashboardView);