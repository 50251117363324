import React, {Component} from 'react';
import {config, SERVICE_API,DISPATCH_ID} from "../libs/utils/Const";
import {ApiClient} from "../libs/apiClient/ApiClient";
import $ from 'jquery';
import {connect} from 'react-redux';
import CurrencyFormat from 'react-currency-format';

let apiClient = ApiClient.getInstance();

class SaveDetailModel extends Component {
    async addToShopingCart(){
        //Los PROPS los consigues de abajo
        const {order,notificationReducer: {showAlert},configReducer,enableSpinner} = this.props;
        //Lista de los productos del documento
        //console.log("List of products",order);
        let items = [];
        //Evadir el Articulo de envio
        order.body.map(item => {
            if(item.ItemCode !== "MANIOBRAS" && item.ItemCode !== "MANIOBRAS II"){
                items.push({ItemCode: item.ItemCode, quantity: parseInt(item.Quantity)});
            }
        });
        //Lista sin el Envio o Traslado
        //console.log("Lista para cargar",items);
        //Comineza a pasarse al carro
        enableSpinner(true);
        //agregar al carro directo con sql
        let responsesd = await apiClient.updateShoppingCartLocal(items);
        if(responsesd.status === 1){
            $('#saveModal').modal('hide');
            showAlert({type: 'success', message: ' Agregado a carrito correctamente ', timeOut: 0});
            configReducer.history.goShoppingCart();
        }
        enableSpinner(false);
    }

    render() {
        const {order,configReducer:{currency}} = this.props;
        
        return (
            <div className="modal fade" id="saveModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{border: "none", textAlign: 'center'}}>
                <div className="modal-dialog modal-xl" role="document" style={{margin: '1.75rem auto'}}>
                    <div className="modal-content">
                        <div className="modal-header" style={{background: config.navBar.iconColor}}>
                            <h5 className="modal-title" style={{color: config.navBar.textColor2}}>Detalle del carrito</h5>
                            <button type="button" style={{color: config.navBar.textColor2}} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body bg3">

                        <div>
                            {order.body.map(item => {
                                return (
                                    <div key={item.ItemCode} className=' text-left card ' style={{ border: 'none'}}>
                                        <div className='row' style={{ padding: 10, textAlign: 'center' }}>
                                            <div className='col-sm-2' style={{ margin: 0 }}>
                                                <img className="img-fluid"
                                                    style={{backgroundColor: 'white', maxHeight: 130}}
                                                    src={item.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + item.PicturName) : require('../images/noImage.png')}
                                                    alt=""
                                                />
                                            </div>
                                            <div className='col-sm-10'>
                                                <div className="container p-0">
                                                    <div className="row">
                                                        <div className="col-12" style={{ borderBottom: '1px solid rgb(124, 124, 125)' }}>
                                                            <div className='text-left' style={{color: "#808080"}}>
                                                                {item.ItemName}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row font-weight-bold">
                                                        <div className="col-md-12 table-responsive ">
                                                            <table className="table">
                                                                <thead>
                                                                    <tr style={{backgroundColor: config.navBar.iconColor, color: "white"}}>
                                                                        <th scope="col">Código</th>
                                                                        <th scope="col">Cantidad</th>
                                                                        <th scope="col">Precio</th>
                                                                        <th scope="col">Precio Total</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td> {item.ItemCode} </td>
                                                                        <td> {parseInt(item.Quantity)} </td> 
                                                                        <td>
                                                                            <CurrencyFormat 
                                                                                value={item.Price} 
                                                                                displayType={'text'} 
                                                                                thousandSeparator={true} 
                                                                                fixedDecimalScale={true} 
                                                                                decimalScale={2} 
                                                                                prefix={currency.MainCurncy !== item.Currency  ? '' : currency.DocCurrCod}
                                                                                suffix = {' ' + item.Currency}>
                                                                            </CurrencyFormat>   
                                                                        </td>
                                                                        <td>
                                                                            <CurrencyFormat 
                                                                                value={(item.Price * item.Rate) * parseInt(item.Quantity)} 
                                                                                displayType={'text'} 
                                                                                thousandSeparator={true} 
                                                                                fixedDecimalScale={true} 
                                                                                decimalScale={2} 
                                                                                prefix={currency.MainCurncy !== item.Currency  ? '' : currency.DocCurrCod}
                                                                                    suffix = {' ' + currency.MainCurncy}>
                                                                            </CurrencyFormat>   
                                                                        </td>                                                       
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                            {/* {order.body.map(item => {
                                return <div key={item.ItemCode} className="row">
                                    <div className="col-md-4">
                                        <img className="img-fluid" style={{ backgroundColor: 'white', maxHeight: 130 }}
                                            src={item.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + item.PicturName) : require('../images/noImage.png')}
                                            alt=""
                                        />
                                    </div>
                                    <div className="col-md-8">
                                        <div className="container p-0">
                                            <div className="row text-left">
                                                <div className="col-md-12"  style={{borderBottom: '1px solid rgb(124, 124, 125)'}}>
                                                    {item.ItemName}
                                                </div>
                                            </div>
                                            <div className="row font-weight-bold ">
                                                <div className="col-md-3">
                                                    Código
                                                </div>
                                                <div className="col-md-3">
                                                    Cantidad
                                                </div>
                                                <div className="col-md-3">
                                                    Precio
                                                </div>
                                                <div className="col-md-3">
                                                    Precio total
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    {item.ItemCode}
                                                </div>
                                                <div className="col-md-3">
                                                    {parseInt(item.Quantity)}
                                                </div>
                                                <div className="col-md-3">
                                                    <CurrencyFormat 
                                                        value={item.Price} 
                                                        displayType={'text'} 
                                                        thousandSeparator={true} 
                                                        fixedDecimalScale={true} 
                                                        decimalScale={2} 
                                                        prefix={config.general.currency}>
                                                    </CurrencyFormat>
                                                </div>
                                                <div className="col-md-3">
                                                    <CurrencyFormat 
                                                        value={item.Price * parseInt(item.Quantity)} 
                                                        displayType={'text'} 
                                                        thousandSeparator={true} 
                                                        fixedDecimalScale={true} 
                                                        decimalScale={2} 
                                                        prefix={config.general.currency}>
                                                    </CurrencyFormat>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })} */}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            <button type="button" className="btn text-white" style={{background: "#86C03F"}} onClick={()=> this.addToShopingCart()}>
                                <i className="fas fa-cart-plus"/>
                                &nbsp; Agregar al carrito
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer,
        shoppingCartReducer: store.ShoppingCartReducer
    };
}

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
        setItemDetailsSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SET_ITEM_DETAILS, value}),
        setItemsSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SET_ITEMS, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SaveDetailModel);
