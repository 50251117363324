import React, {Component} from 'react';
import {Footer, NavBar, Session, Suscription} from "../../components";
import {VIEW_NAME, config} from "../../libs/utils/Const";
import './AboutUsView.css';
import { animateScroll as scroll, scroller } from 'react-scroll'

class AboutUsView extends Component {
    constructor(props){
        super(props);
        this.scrollToBottom = this.scrollToBottom.bind(this);
    }

    scrollToBottom() {
        scroll.scrollToTop({
            duration: 1500,
            delay: 100,
            smooth: 'easeOutQuart',
            isDynamic: true
          })
    }

    componentDidMount(){
        this.scrollToBottom();
    }

    render() {
        const {history} = this.props;
        const {mission, images, description, vision, values} = config.aboutUs;
        return (
            <div className="content-fluid margenSuperiorMenuCategorias" style={{paddingRight:0, backgroundColor:"#fff" }}>
                <Session history={history} view={VIEW_NAME.ABOUT_US_VIEW}/>
                <NavBar/>
                {/* <div className="bannerRedCompensas margenS" style={{backgroundColor:"#fff"}}>
                    <img className="img-fluid"
                        src={require('../../images/gbtl/bannerPaginas/BANNERS-POR-SECCIONES-NOSOTROS.png')}
                        alt="Segundo NavBar"
                    />
                </div> */}
                <br/>
                <div className="container">
                    <div style={{background: "#e9ecef", padding:"20px"}}>
                        <div className="title" >
                            <div className="jumbotron">
                                <h1 className="display-4 text-center">¿Quiénes somos?</h1>
                                <hr className="col-auto"/>
                            </div>
                        </div>   
                        <div className="description">
                            <p className="text-justify ">{description}</p>
                        </div><br/>
                        
                        {/* <div className="description2">
                            <p><span>Visión:</span> {vision}</p>
                            <p><span>Misión:</span> {mission}</p>
                        </div> */}
                        <div className="bannerRedCompensas margenS">
                            <img className="img-fluid"
                                src={require('../../images/cig/quienes-somos.jpg')}
                                alt="Segundo NavBar"
                                    />
                        </div>
                    </div>
                    <br/>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="list-group" id="list-tab" role="tablist" >
                                {!!mission && <a className="list-group-item list-group-item-action-1 active"
                                id="list-home-list" data-toggle="list" href="#list-home"
                                role="tab" aria-controls="home" style={{color:'#fff'}}>
                                    Misión
                                </a>}
                                { !!vision && <a className="list-group-item list-group-item-action-1"
                                id="list-profile-list" data-toggle="list"
                                href="#list-profile" role="tab"
                                aria-controls="profile">
                                    Visión
                                </a>}
                                { !!values.length && <a className="list-group-item list-group-item-action-1"
                                id="list-messages-list" data-toggle="list"
                                href="#list-messages" role="tab"
                                aria-controls="messages">
                                Valores
                                </a>}
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <div className="tab-content" id="todo-rojo" >
                                { !!mission && <div className="tab-pane fade show active text-justify" id="list-home" role="tabpanel" aria-labelledby="list-home-list" >
                                    {mission}
                                </div>}
                                { !!vision && <div className="tab-pane fade text-justify" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list">
                                    {vision}
                                </div>}
                                { !!values.length && <div className="tab-pane fade" id="list-messages" role="tabpanel" aria-labelledby="list-messages-list">
                                    <ul className="list-group list-group-flush">
                                        {values.map( value => {
                                            return  <li key={value.title} className="list-group-item">{value.title}</li>
                                        } )}
                                    </ul>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div><br/><br/>
                {/* <Suscription/> */}
            </div>
        );
    }
}

export default AboutUsView;