let local = "GBTL";

export default {
    general: {
        titleWindowsExplorer: 'GBTL',
        iconWindowsExplorer: '',
        loaderImage: 'Cargando...', //require('./images/loader/831.gif'),
        loaderBackground: 'rgba(255,255,255,0.1)',
        loaderColor: 'text-info',
        business: 1,
        imagesPath: 'gbtl',
        currency:'MXN',
        localStorageNamed: local + 'E-commerce',
    },

    icons: {
        add : "fa fa-plus",
        //menu: 'fas fa-bars fa-2x',
        user: 'fas fa-user',
        password: 'fa fa-unlock-alt',
        search: 'fas fa-search fa-2x',
        shoppingCart: 'fas fa-shopping-cart fa-7x',
        signature: 'fa fa-signature',
        building: 'fa fa-building',
        phone: 'fa fa-phone',
        pin: 'fa fa-map-pin',
        road: 'fa fa-road',
        map: 'fa fa-map',
        city: 'fa fa-city',
        envelope: 'fa fa-envelope-open-text',
        globalAmericas: 'fa fa-globe-americas',
        shield: 'fa fa-shield',
        favorite: "fas fa-heart",
        trash: "fa fa-trash",
        backOrderTrue: 'fa fa-plus',
        backOrderFalse: 'fa fa-minus',
        arrowLeft: 'fas fa-caret-left',
        arrowRight: 'fas fa-caret-right',
        datasheet: 'far fa-file-pdf fa-2x',
        word: 'fas fa-file-word fa-2x',
        cfdi: 'fas fa-file-invoice-dollar',
        report: 'fas fa-file-pdf',
        xml: 'fas fa-file-alt',
        detail: 'fas fa-info-circle',   
        suscription: 'fas fa-chevron-circle-right',
        select : 'fas fa-check',
    },

    trademarks:{
        marcasRegistradas: require('../../images/gbtl/trademarks.png'),
        pagoTarjeta: require('../../images/cig/icono_metodos_de_pago.svg'),
        enviosRepublica: require('../../images/cig/icono_Envios.svg'),
        comprasSeguras: require('../../images/cig/icono_compra_segura.svg'),
        // prmoRed: require('../../images/gbtl/promored.png'),
        // buscados: require('../../images/gbtl/+buscados.png'),
        disponible: require('../../images/gbtl/nuevamente-disponibles.png'),
        nuestraTarjeta: require('../../images/gbtl/Tarjeta-Zona-Redcompensas.png'),
        // refrigeracionIndustrial: require('../../images/gbtl/Descubre2.png'),
    },

    navBar: {
        icon: require('../../images/cig/Logo_GBTL.svg'),
        icono2Login: require('../../images/cig/icono_compra segura_login.svg'),
        searchAdvanced: require('../../images/gbtl/searchAdvanced.png'),
        menu: require('../../images/cig/navBar/menu.png'),
        shoppingCart: require('../../images/cig/navBar/carrito.png'),
        backgroundColor: 'white',
        iconColor: 'rgb(0,94,173)',
        iconColor2: '#FFFFFF',
        iconColor3: '#666666',
        iconColorPaginacion: "#E3E3E3",
        textColorPaginacion: "#666666",
        textColor: 'white',
        textColor2: 'white',
        menuCategoriesBackground: 'rgb(0,94,173)',
        textColorCategorie: 'white',
        textColorCategorie2: 'rgb(0,94,173)',
        menuCategoriesBackgroundHover: 'white',
        textColorCategorieHover: 'black',
        iconModal: 'white',
        iconBackground: '#09488F',
        iconSearch: require('../../images/gbtl/busAvanzada.png'),
        carrito: require('../../images/cig/navBar/carrito-01.png'),
        avatar: require('../../images/cig/icono_avatar_principal.svg')
    },

    shoppingCartIcons: {
        camionAgotado: require('../../images/gbtl/iconosCarrito/carrito-01.png'),
        camionVerde: require('../../images/gbtl/iconosCarrito/carrito-02.png'),
        camionAmarillo: require('../../images/gbtl/iconosCarrito/carrito-03.png'),
        carritoVerde: require('../../images/gbtl/iconosCarrito/carrito-04.png'),
        carritoAgotado: require('../../images/gbtl/iconosCarrito/carrito-05.png'),
        carritoAmarillo: require('../../images/gbtl/iconosCarrito/carrito-06.png')
        
    },

    NavarIconos: {
        iconOne: require('../../images/gbtl/marcas/nav_1.png'),
        iconTwo: require('../../images/gbtl/marcas/nav_2.png'),
        iconThree: require('../../images/gbtl/marcas/nav_3.png'),
        iconFour: require('../../images/gbtl/marcas/nav_4.png'),
        iconFive: require('../../images/gbtl/marcas/nav_5.png'),
    },

    marcasScroll: {
        One: require('../../images/gbtl/marcasCarousel/Brands_1.png'),
        Two: require('../../images/gbtl/marcasCarousel/Brands_2.png'),
        Three: require('../../images/gbtl/marcasCarousel/Brands_3.png'),
        Four: require('../../images/gbtl/marcasCarousel/Brands_4.png'),
        Five: require('../../images/gbtl/marcasCarousel/Brands_5.png'),
        Six: require('../../images/gbtl/marcasCarousel/Brands_6.png'),
        Seven: require('../../images/gbtl/marcasCarousel/Brands_7.png'),
        Eight: require('../../images/gbtl/marcasCarousel/Brands_8.png'),
        // Nine: require('../../images/gbtl/marcasCarousel/Brands_9.png'),
        // Ten: require('../../images/gbtl/marcasCarousel/Brands_10.png'),
        // Eleven: require('../../images/gbtl/marcasCarousel/Brands_11.png'),
        // Twelve: require('../../images/gbtl/marcasCarousel/Brands_12.png'),
        // Thirteen: require('../../images/gbtl/marcasCarousel/Brands_13.png'),
        // Fourteen: require('../../images/gbtl/marcasCarousel/Brands_14.png'),
        // Fifteen: require('../../images/gbtl/marcasCarousel/Brands_15.png'),
        // Sixteen: require('../../images/gbtl/marcasCarousel/Brands_16.png'),
    },

    openPay:{
        credit: require('../../views/validateOrder/cards1.png'),
        debit: require('../../views/validateOrder/cards2.png'),
        logo: require('../../views/validateOrder/openpay.png'),
        security: require('../../views/validateOrder/security.png'),

    },
    // <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7189.30914847664!2d-100.33297598314306!3d25.715858498446593!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xa82a5aab49e3b149!2sDistribuidora%20Industrial%20De%20Abrasivos%20Sa%20De%20Cv!5e0!3m2!1ses!2smx!4v1625429196137!5m2!1ses!2smx" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>

    dataSucursal: [
        {
            imagen: require('../../images/gbtl/sucursales/sucursales-icono.png'),
            name: 'CULIACÁN',
            address: 'Carretera a El Dorado, Km 8.6, El Quemadito.',
            zipcode: '80300',
            block: 'Culiacán, Sinaloa. México.',
            phones: ['800 700 4772']
        },
        {
            imagen: require('../../images/gbtl/sucursales/sucursales-icono.png'),
            name: 'GUADALAJARA',
            address: 'Av. San Miguel No. 399 N° int. 46 Fracc. San Juan de Ocotán',
            zipcode: '45019',
            block: 'Zapopan, Jalisco. México.',
            phones: ['(333) 620 2520']
        },
        {
            imagen: require('../../images/gbtl/sucursales/sucursales-icono.png'),
            name: 'URUAPAN',
            address: 'Calle Atenas #13 esquina con Manuel Pérez Coronado Col. Jardines del Cupatitzio.',
            zipcode: '60080',
            block: 'Uruapan, Michoacán. México.',
            phones: ['(452) 688 2033']
        },
        {
            imagen: require('../../images/gbtl/sucursales/sucursales-icono.png'),
            name: 'SAN QUINTÍN',
            address: 'Carretera Transpeninsular Km 167.5, M 36, L7 S/N Ejido Emiliano Zapata',
            zipcode: '22920',
            block: 'Vicente Guerrero, B.C. México.',
            phones: ['(616) 165 2538']
        }, 
        {
            imagen: require('../../images/gbtl/sucursales/representanteVentas.png'),
            name: 'ENSENADA',
            address: 'Manuel Romero',
            zipcode: '',
            block: 'mromero@industriasrochin.com',
            phones: ['(646) 210 1459']
        }, 
        {
            imagen: require('../../images/gbtl/sucursales/representanteVentas.png'),
            name: 'TORREÓN',
            address: 'Fernando Torres',
            zipcode: '',
            block: 'ftorres@ircomx.com',
            phones: ['(811) 660 2500']
        },    
        {
            imagen: require('../../images/gbtl/sucursales/representanteVentas.png'),
            name: 'CHIHUAHUA',
            address: 'David Molinar',
            zipcode: '',
            block: 'dmolinar@industriasrochin.com',
            phones: ['(614) 176 5128']
        },    
        {
            imagen: require('../../images/gbtl/sucursales/representanteVentas.png'),
            name: 'LOS MOCHIS',
            address: 'Sergio Caballet',
            zipcode: '',
            block: 'sacaballetv@ircomx.com',
            phones: ['(668) 125 0657']
        }, 
    ],

    Back:{
        backgroundColor:"#FFFFFF"
    },
    BarraTelefono:{
        Whats: require('../../images/gbtl/barraTelefono/whatsapp-verde.svg'),
        IconUbicacion: require('../../images/gbtl/barraTelefono/Sucursales.svg'),
        IconCel: require('../../images/gbtl/barraTelefono/Telefono.svg'),
    },

    dashboard: 'Carousel',
    slider: {
    },
    carousel: {
        images: []
    },

    aboutUs: {
        active: true,
        description: `Somos una empresa 100% mexicana con más de 25 años de experiencia en el medio de las Artes Gráficas que busca brindar un servicio de excelencia. 
        Brindamos soluciones de alta tecnología para pre-prensa, prensa y post-prensa a compañías dedicadas a la impresión de gran formato offset y flexografía. 
        Ofrecemos a nuestros clientes tecnología de punta de las marcas más prestigiadas del mundo; tenemos la gama más amplia de equipos e insumos apoyados con servicios de calidad mundial. 
        GBTL comparte la visión de establecer una relación de largo plazo con sus clientes satisfaciendo sus necesidades y fomentando la introducción de nuevas tecnologías.`,
        mission: 'Ofrecer a nuestros clientes soluciones de alta tecnología con el mejor valor agregado para lograr excelentes relaciones comerciales de largo plazo.',
        vision: 'Ser una empresa líder en la integración de sistemas de alta tecnología y servicios de calidad internacional para las artes gráficas.',
        values: [
            {title: 'Conocimiento y superación constante'},
            {title: 'Responsabilidad'},
            {title: 'Actitud de servicio'},
            {title: 'Disciplina'},
            {title: 'Respeto'},

            ],
        images: require('../../images/cig/about/quienes-somos.jpg'),
    },

    contactUs: {
        active: true,
        locations: [
            {
                name: 'México',
                street: 'Circuito Ingenieros 62 Col. Ciudad Satélite.',
                country: 'Naucalpan Edo. De Mex. C.P.53100.',
                phone: 'Teléfono: (55)5362-6529',
                email: 'ventas@gbtl.com.mx',
                maps: 'https://goo.gl/maps/UpX955GCtv91SRAk9',
            }
            // ,
            // {
            //     name: 'Monterrey',
            //     street: 'José María Arteaga No. 117, Col. Centro de Guadalupe',
            //     country: 'Monterrey, Nuevo León, C.P. 67100',
            //     phone: 'Teléfono: +52 (81)8381-9024',
            //     email: 'monterrey@tsgmexico.com.mx',
            //     maps: 'https://www.google.com.br/maps/place/Jos%C3%A9+Mar%C3%ADa+Arteaga+117,+Centro+de+Guadalupe,+67100+Guadalupe,+N.L.',
            // },
            // {
            //     name: 'Guadalajara',
            //     street: 'Pavo No. 591 Col. Mexicaltzingo Secc. Reforma',
            //     country: 'Guadalajara, Jalisco, C.P. 44180',
            //     phone: 'Teléfono: (33) 3647 6033',
            //     email: 'guadalajara@tsgmexico.com.mx',
            //     maps: 'http://maps.google.com/?q=Calle+Pavo+No.+591+Col.+Mexicaltzingo+Sec.+Reforma+Guadalajara+44180,+Jal.',
            // },
            // {
            //     name: 'Cancún',
            //     street: 'Av. Lopez Portillo MZ 66 Lote 3 Local A6 Región 98',
            //     country: 'Cancún, Quintana Roo, C.P. 77537',
            //     phone: 'Teléfono: (998) 843 1920',
            //     email: 'cancun@tsgmexico.com.mx',
            //     maps: 'http://maps.google.com/?q=Av.+Lopez+Portillo+MZ+Región+98+Cancún,+Q.+Roo+CP.+77537',
            // },
            // {
            //     name: 'Culiacán',
            //     street: 'Jesús Kumate No. 3680 Sur Local 5, Fracc. Rincón del Valle',
            //     country: 'Culiacán, Sinaloa, C.P. 80155',
            //     phone: 'Teléfono: (667) 721-5341',
            //     email: 'culiacan@tsgmexico.com.mx',
            //     maps: 'https://www.google.com/maps/place/BOSCH+CAR+SERVICE/@24.7710839,-107.4464989,201m/data=!3m1!1e3!4m5!3m4!1s0x86bcd04e4fe78dc7:0x11f6fa5f2551978c!8m2!3d24.7709486!4d-107.4462039',
            // },
            // {
            //     name: 'León',
            //     street: 'Justo Sierra No. 627, Col. Leon de los Aldama Centro',
            //     country: 'León, Guanajuato, C.P. 37000',
            //     phone: 'Teléfono: (477) 195 66 42',
            //     email: 'leon@tsgmexico.com.mx',
            //     maps: 'http://maps.google.com/?q=Calle+Justo+Sierra+No.+627+Col.+Leon+de+los+Aldama+Centro+C.P.+37000',
            // },
            // {
            //     name: 'Puebla',
            //     street: 'Calle 29 Oriente 215, Col. El Carmen Huexotitla',
            //     country: 'Puebla, México, CP 72534',
            //     phone: 'Teléfono: (222) 2111453',
            //     email: 'puebla@tsgmexico.com.mx',
            //     maps: 'http://maps.google.com/?q=Calle+29+Oriente+215,+Col.+El+Carmen+Huexotitla,+Puebla,+México,+CP+72534.',
            // },
            // {
            //     name: 'Querétaro',
            //     street: 'Av. Universidad 155 Oriente Col. Los Cedros',
            //     country: 'Querétaro, Querétaro, CP 76165',
            //     phone: 'Teléfono: (442) 224 11 91',
            //     email: ' queretaro@tsgmexico.com.mx',
            //     maps: 'https://www.google.com/maps/place/Avenida+Universidad+155,+Los+Cedros,+76165+Santiago+de+Quer%C3%A9taro,+Qro./@20.5998759,-100.3904722,17z/data=!3m1!4b1!4m5!3m4!1s0x85d35b2f4144278d:0x50cd7cbbb725276f!8m2!3d20.5998759!4d-100.3882835',
            // }
        ],
    },

    claim: {
        active: false,
    },

    termPage: {
        active: false,
    },

    questions: {
        active: true,
        bannerImage: require('../../images/gbtl/preguntasFrecuentes/banner-preugntas.png'),
    },

    itemsList: {
        backgroundColor: 'rgba(255,255,255, 0.5)',
        textColor: 'rgba(0,0,0, 0.8)',
        iconColor: 'rgb(0,94,173)',
        textPrice: 'rgb(0,94,173)'
    },

    shoppingList: {
        productList: '#efefef',
        textProductList: 'rbg (0,0,0)',
        summaryList: 'rgb(0,94,173)',
        textsummaryList: 'rbg(255,255,255)'
    },

    pdf: {
        VERterminosCondiciones: true,
        terminosCondiciones: 'terminosCondiciones.pdf',
        VERpoliticasVentas: false,
        politicasVentas: 'politicasVentas.pdf',
        VERpoliticasEnviosGarantias: false,
        politicasEnviosGarantias: 'politicasEnviosGarantias.pdf',
        VERpoliticasGarantia: false,
        politicasGarantia: 'politicasGarantia.pdf',
        VERpoliticasUsoAvisoPrivacidad : true,
        politicasUsoAvisoPrivacidad: 'politicasUsoAvisoPrivacidad.pdf',
        politicasRecompensas: 'politicasRecompensa.pdf',
        politicasFooter: 'politicasFooter.pdf',
        nosotros: 'nosotros.pdf',
    },

    footer: {
        icon: require('../../images/cig/Logo_GBTL.svg'),
        power: require('../../images/Powered_by_FMB_20.svg'),
        backgroundColor: '#efefef',
        iconColor: 'rgb(0,94,173)',
        textColor: 'rgb(40,68,90)',

        info: {
            Icon30:  require('../../images/gbtl/footer/logofmb.png'),
            hoursIcon: require('../../images/cig/icon_hora.svg'),
            hours: 'Lunes a Viernes 9:00 hrs. a 18 hrs.',//Sábado 9:00 am a 2:00 pm
            calendarIcon: require ('../../images/cig/icon_Calendario.svg'),
            calendar: "Lunes a Viernes",
            emailIcon: require('../../images/cig/icon_correo.svg'),
            email: 'ventas@gbtl.com.mx',
            phoneIcon: require('../../images/cig/icon_Telefono.svg'),
            phone: '(55)5362-6529',
            phoneWhats: '',
            linkPhoneWhats: "",
            addressIcon: 'fas fa-2x fa-location-arrow',
            address: 'Circuito Ingenieros 62 Col. Cd. Satelite Naucalpan Edo. De Mex. 53100.',
        },

        socialMedia2: [
            {
                visibility: true,
                icon: require('../../images/cig/youtube.svg'),
                linkref: '',
                // linkref: 'https://www.youtube.com/channel/UCJ81aLs383rT1kEOIW_QJtg',
            },
            {
                visibility: true,
                icon: require('../../images/cig/instagram.svg'),
                linkref: '',
                // linkref: 'https://www.instagram.com/cig.oficial/?igshid=p42vcqtyvwqo',
            },
            {
                visibility: true,
                icon: require('../../images/cig/facebook.svg'),
                linkref: '',
                // linkref: 'https://www.facebook.com/cig.oficial/',
            },
            {
                visibility: false,
                icon: 'fab fa-4x fa-twitter',
                linkref: 'https://twitter.com/gruporedhogar',
            },
            {
                visibility: false,
                icon: require('../../images/gbtl/footer/whatsap.png'),
                linkref: 'https://api.whatsapp.com/send?phone=523313207614&text=Hola%2C%20Gracias%20por%20contactar%20a%20Grupo%20Redhogar%2C%0A%C2%BFEn%20qu%C3%A9%20podemos%20apoyarlo%3F',
            },
            {
                visibility: false,
                icon: require("../../images/gbtl/footer/linkedin.svg"),
                linkref: 'https://www.linkedin.com/company/ircocommercial',
            },
        ]
    },

    paymentMethod: {
        paypal: {
            enable: false,
            clienId: 'AcgfSWjvFifar4P_3alUSPkhf0Z5g21gs1ieaFCF0HoFojz5GnYxYFE5L-uIH4tZEqWffcTzN_jnqf6l',
            //clienId: 'Aa2jAA2wV-jR9wXcDcaBnAatmI5nbANCx0TaQks_UWDAEKWAkuRwEvCap3krt7my-8FciPFo73rbOCJH',
        },
        transbank: {
            enable: false,
        },
        mercadoPago:{
            enable:true,
        },
        openPay:{
            enable:false,
        }
    },

    privacy:{
        banner: require('../../images/gbtl/avisoPrivacidad/avisoprivacidad.png'),
    },

    recompensas:{
        banner : require('../../images/gbtl/AboutRecompensas/banner.png'),
        card : require('../../images/gbtl/card.png'),
        vitrina1 : require('../../images/gbtl/vitrinaRedCompensas/icon-10.png'),
        vitrina2 : require('../../images/gbtl/vitrinaRedCompensas/icon-11.png'),
        vitrina3 : require('../../images/gbtl/vitrinaRedCompensas/icon-12.png'),
    },
    Avatar:{
        bronze1 : require('../../images/gbtl/Avatar/bronze1.png'),
        silver1 : require('../../images/gbtl/Avatar/silver1.png'),
        gold1 : require('../../images/gbtl/Avatar/gold1.png'),
        platinum1 : require('../../images/gbtl/Avatar/platinum1.png'),
        diamond1 : require('../../images/gbtl/Avatar/diamond1.png'),
        mercado1 : require('../../images/gbtl/Avatar/mercado1.png'),
        red1 : require('../../images/cig/icono_avatar_principal.svg'),

        bronze2 : require('../../images/gbtl/Avatar/bronze2.png'),
        silver2 : require('../../images/gbtl/Avatar/silver2.png'),
        gold : require('../../images/gbtl/Avatar/gold2.png'),
        platinum2 : require('../../images/gbtl/Avatar/platinum2.png'),
        diamond2 : require('../../images/gbtl/Avatar/diamond2.png'),
        mercado2 : require('../../images/gbtl/Avatar/mercado2.png'),
        red2 : require('../../images/cig/icono_Avatar_perfil.svg'),
    },

    gif:{
        gif: require('../../images/gbtl/GIF-prueba.gif'),
    },
    modules:{
        createOrder : true ,
        points : false,
        pasarelaCarrito : false,
        redZone : false,
        redAliado : false,
        subirArchivoExcel : false,
        BolsaTrabajo : true,
        cupon : false,
        Comments : false,
        Devoluciones : false
    },

    catalogo: [
        {
            nombre: `Catálogo Uno`,
            imagen: require('../../images/gbtl/catalogos/1.jpg'),
            pdf: '1.pdf',
        },
        {
            nombre: `Catálogo Dos`,
            imagen: require('../../images/gbtl/catalogos/2.jpg'),
            pdf: '2.pdf',
        },
        {
            nombre: `Catálogo Tres`,
            imagen: require('../../images/gbtl/catalogos/3.jpg'),
            pdf: '3.pdf',
        }
    ],
    
    // BASE_URL: 'http://192.168.0.124:4052/api',
    BASE_URL: 'http://189.240.232.250:4040/api',
    
    
    // ASSETS: 'http://192.168.0.124:4052/api/uploads/',
    ASSETS: 'http://189.240.232.250:4040/api/uploads/',
   
    localStorageNamed: local + 'E-commerce',
};