import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import "./BreadCrumb.css";

class BreadCrumb extends Component {
    // constructor(props){
    //     super(props);
        // this.state = {
        //     categoriesData : [],
        //     itemsReducer : [],
            
        // };
    // }
    
    
    orderByTimes = ()=>{
        const {data}=this.props;
        let array = data || [];
        let piv = {};
        for (let k = 1; k < array.length; k++) {
            for (let i = 0; i < (array.length - k); i++) {
                if (array[i] > array[i + 1]) {
                    piv = array[i];
                    array[i] = array[i + 1];
                    array[i + 1] = piv;
                }
            }
        } 
        array.sort(((a, b) => b.times - a.times))
        
        return array;
        
    }
    render() {
        const {itemsReducer} = this.props;
        let categoriesData = this.orderByTimes();
        let split = categoriesData[0] ? categoriesData[0].categoryName.split(',') : [];
        console.log("con(AAAh)",split[1] == null  ||  split[1] == undefined);
        if(split[1] == null  ||  split[1] == undefined ){$('subItemBread').hide();}//si no llegan subitems oculta los elemento
        if(itemsReducer){
            if((itemsReducer.location == 'navBar' && itemsReducer.uniqueFilter.property == undefined)){
                return (
                    <span>
                        <span>
                            <Link to="/items" className="breadcrumb-item text-dark"><strong className="BreakWord">PRODUCTOS</strong></Link>             
                        </span>
                    </span>
                );
            }
            if((itemsReducer.location == 'navBar' && itemsReducer.uniqueFilter.property != "categorias")){
                return (
                    <span>
                        <span>
                            <Link to="/items" className="breadcrumb-item text-dark"><strong className="BreakWord">PRODUCTOS POR {(itemsReducer.uniqueFilter.property).toUpperCase()}</strong></Link>             
                        </span>
                    </span>
                );
            }
        }
        return (
            <span>
                <span>
                    <Link to="/items" className="breadcrumb-item text-dark"><strong className="BreakWord">{split[0] || ''}</strong></Link>
                    <Link to={'#'} className= "breadcrumb-item text-muted BreakWord" aria-current="page">{split[1] ? split[1]+"/" : ""}</Link>                  
                </span>
            </span>
        );
    }
}

export default BreadCrumb;