import React, {Component} from 'react';
import { config, ROLES, DISPATCH_ID,SERVICE_API } from '../libs/utils/Const';
import { connect } from "react-redux";
import packageJson from '../../package.json';
global.appVersion = packageJson.version;

class Footer extends Component {
    getYear = () => {
        let today = new Date();
        return today.getFullYear() || '';
    };

    render() {
        const { configReducer: { history }, sessionReducer: { user }, sessionReducer } = this.props;
        return (
            <footer className="page-footer font-small pt-5" style={{backgroundColor: config.footer.backgroundColor}}>
                <div className="text-center text-md-left" style={{marginRight: 20}}>
                    <div className="row">
                        {/* Logo de FMB */}
                        <div className="col-md-2 text-center p-0">
                            <a href="http://fmbsolutions.mx/" target="_blank">
                                <img src={config.footer.power} className="img-fluid" width='190px' />
                            </a>
                        </div>
                        {/* Información de la empresa */}
                        <div className="col-md-2 p-0">
                            <h5 className="font-weight-bold text-uppercase mb-4" style={{color: config.footer.textColor}}>Empresa</h5>
                             {/* <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goAboutUs()}> ¿Quienes somos? </span>
                                </a>
                            </p>
                              <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goQuestions()}> Preguntas frecuentes</span>
                                </a>
                            </p>  
                            <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goContactUs()}> Contacto </span>
                                </a>
                            </p>  */}
                            <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goNewsBlog()}> Blog de noticias </span>
                                </a>
                            </p>
                            <p>
                                <a>
                                    <span style={{ color: config.footer.textColor }} onClick={() => history.goJob()}> Bolsa de trabajo </span>
                                </a>
                            </p>
                        </div>
                        {/* Logo de la empresa */}
                        <div className="col-md-4 text-center p-0" style = {{marginTop: '3%'}}>
                            <a href="#" target="_blank">
                                <img src={config.footer.icon} className="img-fluid" width='200px' />
                            </a>
                            <p>
                                { config.footer.socialMedia2.map( (red, index) => {
                                    return(red.visibility &&
                                        <a class="btn btn-floating m-1" /*href={red.linkref}*/ target="_black" key={index}>
                                            <img className="img-fluid" width='30px' src={red.icon}/>
                                        </a>
                                    )
                                })}
                            </p>
                        </div>
                        {/* <hr className="clearfix w-100 d-md-none" />
                        {/* Información legal */}
                        <div className="col-md-2 p-0">
                            <h5 className="font-weight-bold text-uppercase mb-4" style={{color: config.footer.textColor}}>Legales</h5>
                            {config.pdf.VERterminosCondiciones &&
                                <p>
                                    <a href={`${config.BASE_URL}/${SERVICE_API.getPolitics}/${config.pdf.terminosCondiciones}`} target="_blank" >
                                        <span style={{ color: config.footer.textColor }}>Términos y condiciones </span>
                                    </a>
                                </p>
                            }
                            {config.pdf.VERpoliticasUsoAvisoPrivacidad &&
                                <p>
                                    <a href={`${config.BASE_URL}/${SERVICE_API.getPolitics}/${config.pdf.politicasUsoAvisoPrivacidad}`} target="_blank" >
                                        <span style={{ color: config.footer.textColor }} > Políticas de uso y aviso de privacidad </span>
                                    </a>
                                </p>
                            }
                        </div> 
                        {/* Ubicaccion de la empresa */}
                        <div className="col-md-2 p-0">
                            <h5 className="font-weight-bold text-uppercase mb-4" style={{color: config.footer.textColor}}>Contacto</h5>
                            <p>
                                <a>
                                    <img className="img-fluid" width='30px'  src={config.footer.info.hoursIcon} style={{ color: config.footer.iconColor, padding: 1 }}></img>&nbsp;
                                    <span style={{ color: config.footer.textColor }}>{config.footer.info.hours}</span>
                                </a>
                            </p>
                            <p>
                                <a>
                                    <img className="img-fluid" width='30px'  src={config.footer.info.phoneIcon} style={{ color: config.footer.iconColor, padding: 1 }}></img>&nbsp;
                                    <span style={{ color: config.footer.textColor }}>{config.footer.info.phone}</span>
                                </a>
                            </p>
                            <p>
                                <a /*href={"mailto:" + config.footer.info.email}*/>
                                    <img className="img-fluid" width='30px'  src={config.footer.info.emailIcon} style={{ color: config.footer.iconColor, padding: 1 }}></img>&nbsp;
                                    <span style={{ color: config.footer.textColor }}>{config.footer.info.email}</span>
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                {/* Inofrmacion de la version */}
                <div className="footer-copyright text-center py-4">
                    <a href="http://fmbsolutions.mx/" target="_blank">
                        <span style={{ color: config.footer.textColor }}>Todos los derechos reservados © {this.getYear()} &middot; Versión &middot; {global.appVersion}</span>
                    </a>
                </div>
            </footer>
        );
    }
}
const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setItemsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS, value }),
        setItemsFilterSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Footer);