import React, {Component} from 'react';
import {Footer, NavBar, Session, Suscription} from "../../components";
import {VIEW_NAME, SERVICE_RESPONSE, config} from "../../libs/utils/Const";
import {ApiClient} from "../../libs/apiClient/ApiClient";
import { connect } from "react-redux";

let apiClient = ApiClient.getInstance();

const EMAIL_FORMAT_REGEX = /^([A-Za-z0-9_\-\.+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

class PrivacyView extends Component {
    render() {
        const {history} = this.props;
        // console.log('Valor del state', this.state);
        return (
            <div className="content-fluid" style={{marginTop: 150,backgroundColor:"#EFEFEF"}}>
                <Session history={history} view={VIEW_NAME.PRIVACY_VIEW}/>
                <NavBar/>
                <div className="bannerRedCompensas margenS" style={{backgroundColor:"#EFEFEF"}}>
                    <img className="img-fluid"
                        src={config.privacy.banner}
                        alt="Segundo NavBar"
                    />
                </div>
                <div className="container" style={{paddingTop: 60, paddingBottom: 20}}>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-justify">
                                El contenido de este mensaje de datos es confidencial y se entiende dirigido y para uso exclusivo del
                                destinatario, por lo que no podrá distribuirse y/o difundirse por ningún medio sin la previa autorización
                                del emisor original.<br/><br/>
                                Se prohíbe su utilización total o parcial para cualquier fin. En cumplimiento con lo establecido por la Ley
                                Federal de Protección de Datos Personales en Posesión de Particulares le informamos nuestra política de
                                privacidad y manejo de datos personales y hacemos el siguiente compromiso:<br/><br/>
                                <ol>
                                    <li>Distribuidora Industrial de Abrasivos, S.A. de C.V., con domicilio en  Av Alfonso Reyes N° 2020, Fracc Bernardo Reyes, Monterrey, Nuevo León, es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección.</li>
                                    <li>Los datos que le solicitamos únicamente serán utilizados como referencia para fijar una postura con respecto a la viabilidad del otorgamiento de un posible crédito y para localización.</li>
                                    <li>Los datos que ingrese en el formulario de contacto no serán difundidos, distribuidos o comercializados.</li>
                                    <li>En caso de que desee ser removido de nuestra base de datos, se podrá, en cualquier momento, solicitar la baja de sus datos mediante correo electrónico a pagos@diasa.net o por escrito dirigido a Distribuidora Industrial de Abrasivos, S.A. de C.V. ,con domicilio en  Av. Alfonso Reyes N° 2020, Fracc. Bernardo Reyes, Monterrey, Nuevo León, Teléfono 1253-3080.</li>
                                    <li>Sus datos personales podrán ser proporcionados a terceros de acuerdo con lo estrictamente señalado en la Ley Federal de Protección de Datos Personales en Posesión de Particulares.</li>
                                    <li>Cualquier modificación a este aviso de privacidad podrá consultarlo en la presente página de Internet, diasa.net</li>
                                </ol>
                                <p>Cualquier información adicional por favor escriba a pagos@diasa.net</p>
                            </div>
                        </div>    
                    </div>
                </div>
                {/* <Suscription/> */}
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer
    };
};

export default connect(
    mapStateToProps
)(PrivacyView);