const questions = [
    {
        question: `¿QUE HACER SI MI ENVÍO NO LLEGA? `,
        answer: `No, puede registrarse y navegar en nuestra tienda, sin la necesidad de comprar. Si usted quiere comprar, sí es necesario registrarse.`,
    },
    {
        question: `¿COMO RASTREO MI ENVÍO?`,
        answer: `Le enviaremos vía e-mail un correo automático para informarle de su registro y de la orden de compra que confirmó usted. En los pedidos realizados por teléfono, en forma inmediata le notificaremos el número de su orden. Así mismo se puede apoyar con nuestros asesores de ventas.`,
    },
];

export {
    questions as default,
}