import React, {Component} from 'react';
import {Footer, NavBar, Session, Suscription} from "../../components";
import {DISPATCH_ID, SERVICE_RESPONSE, config, ROLES,VIEW_NAME,SERVICE_API} from '../../libs/utils/Const';
import {connect} from 'react-redux';
import {ApiClient} from "../../libs/apiClient/ApiClient";
import { animateScroll as scroll, scroller } from 'react-scroll'
import './RedCompensasZoneView.css';

let apiClient = ApiClient.getInstance();

class AboutRedCompensasZoneView extends Component {
    constructor(props){
        super(props);
        this.scrollToBottom = this.scrollToBottom.bind(this);
    }

    scrollToBottom() {
        scroll.scrollToTop({
            duration: 1500,
            delay: 100,
            smooth: 'easeOutQuart',
            isDynamic: true
          })
    }

    componentDidMount(){
        this.scrollToBottom();
    }

    requestCard = async () => {
        const {sessionReducer, enableSpinner, notificationReducer:{showAlert}, configReducer: { history }} = this.props;
        if(sessionReducer.user.CardCode){
            let cardCode = sessionReducer.user.CardCode;

            enableSpinner(true);
            let response = await apiClient.getBusinessPartnerInfo(cardCode);
            enableSpinner(false);

            let businessPartnerInfoResponse = response.data.resultData;
            
            if(businessPartnerInfoResponse.Phone1 == ''){
                showAlert({type: 'warning', message: ' Debes actualizar en tu perfil el número telefónico', timeOut: 3500});  
                return;
            }
            if(businessPartnerInfoResponse.Country == ''){
                showAlert({type: 'warning', message: ' Debes actualizar en tu dirección de envío el campo de País', timeOut: 3500});  
                return;
            }
            if(businessPartnerInfoResponse.State == ''){
                showAlert({type: 'warning', message: ' Debes actualizar en tu dirección de envío el campo del Estado ', timeOut: 3500});  
                return;
            }
            if(businessPartnerInfoResponse.City == ''){
                showAlert({type: 'warning', message: ' Debes actualizar en tu dirección de envío el campo de la ciudad ', timeOut: 3500});  
                return;
            }
            
            let data = {
                CardCode : businessPartnerInfoResponse.CardCode,
                CardName : businessPartnerInfoResponse.CardName, 
                email : businessPartnerInfoResponse.U_FMB_Handel_Email, 
                phone : businessPartnerInfoResponse.Phone1, 
                country : businessPartnerInfoResponse.Country, 
                state : businessPartnerInfoResponse.State, 
                city : businessPartnerInfoResponse.City,
            }

            let response2 = await apiClient.sendRequestCardMail(data);
            if (response2.status === SERVICE_RESPONSE.SUCCESS) {
                if(response2.data.response == "10minutes"){
                    showAlert({type: 'warning', message: response2.message || ' Se envió una solicitud hace menos de 10 minutos ', timeOut: 3250});  
                } else {
                    showAlert({type: 'success', message: ' Solicitud enviada de forma correcta ', timeOut: 3250});  
                }
                
            } else {
                showAlert({type: 'error', message: response2.message || ' Ocurrió un error al enviar tu solicitud :( ', timeOut: 3250});  
            }

        } else {
            showAlert({type: 'warning', message: ' Debes registrarte para poder solicitar una tarjeta de Recompensas ', timeOut: 3500}); 
            history.goLogin();
        }
    }

    render() {
        const {history} = this.props;
        return (
            <div className="content-fluid margenSuperiorMenuCategorias" style={{paddingRight:0, backgroundColor:"#EFEFEF" }}>
                <Session history={history} view={VIEW_NAME.aboutRedView}/>
                <NavBar/>
                <div className="bannerRedCompensas margenS" style={{backgroundColor:"#EFEFEF"}}>
                    <img className="img-fluid"
                        src={config.recompensas.banner}
                        alt="Segundo NavBar"
                    />
                </div>

                <div className="container">
                    <div className="row titleRedZone">
                        <span className="col-auto text-left">¿Qué es Zona de Recompensas?</span>
                        <hr className="col-auto"/>
                    </div>

                    <div className="descriptionRedZone">
                        <p className="text-justify ">
                            Es un sistema en el que por cada compra de productos participantes que realices obtendrás una cantidad de puntos 
                            con los cuales, podrás canjear productos participantes dentro de la empresa.<br/><br/>
                            Podrás utilizar tu tarjeta de <strong>Zona de Recompensas</strong> en todas las sucursales de la empresa.<br/>
                            Además disfrutarás de beneficios como descuentos y promociones especiales.<br/><br/>
                            Los puntos acumulados en tu tarjeta tienen fecha de caducidad de un año, estos vencen el día <strong>01 de noviembre</strong> de cada año
                            y podrás utilizarlos en el momento que lo requieras.<br/>
                            Visítanos regularmente y no dejes de utilizar tu tarjeta de <strong>Zona de Recompensas</strong> para acumular más puntos. 
                        </p>
                    </div>

                    <div className="seccionPedirTarjeta justify-content-left">
                        <div className="row">
                            <div className="col-lg-auto description2RedZone">
                                <span> Con la <strong>Zona de Recompensas</strong> queremos premiar tu lealtad.</span>
                            </div>
                            <div className="col-lg-auto SolicitarTarjeta" style={{marginTop:'auto', marginBottom: 'auto'}}>
                                <button className="btn btn-lg" type="button" onClick={this.requestCard}>
                                    Solicitar Tarjeta
                                </button>
                            </div>                            
                        </div>
                    </div><br/>

                    <div className="infoTarjeta pb-4 pt-4">
                        <div className="row">
                            <div className="col-lg-6" style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                <div className="mb-2">
                                <span style={{marginTop: 'auto', marginBottom: 'auto'}}>CON TU TARJETA <img className="img-fluid" src={require('../../images/gbtl/AboutRecompensas/Zona.png')} alt="Zona Redcompensas"/> PUEDES:</span>
                                </div>
                                <div className="descripcionTarjetaRedZone" style={{textAlign: 'justify'}}>
                                    <ul className=" ">
                                        <li className="li-azul  pb-2">
                                            ACUMULAR PUNTOS CON TUS COMPRAS. 
                                        </li>
                                        <li className="li-rojo pb-2">
                                            SER EL PRIMERO EN CONOCER SOBRE NUESTROS DESCUENTOS.
                                        </li>
                                        <li className="li-amarillo pb-2">
                                            OBTENER REGALOS SORPRESAS.
                                        </li>
                                        <li className="li-rojo pb-2">
                                            CONTAR CON DESCUENTOS Y PROMOCIONES ESPECIALES.
                                        </li>
                                        <li className="li-amarillo pb-2">
                                            ADEMÁS DE DISFRUTAR DE FABULOSAS EXPERIENCIAS QUE PREPARAREMOS PARA TI.
                                        </li>
                                        {/* <li className="li-azul pb-2">
                                            
                                        </li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className=" col-lg-6 bannerRedCompensas" style={{marginTop: 'auto', marginBottom: 'auto'}}>
                                <img className="img-fluid"
                                    src={require('../../images/gbtl/AboutRecompensas/tarjeta-02.png')}
                                    alt="Tarjeta RedZone"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Suscription/> */}
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
        itemsReducer: store.ItemsReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setItemsSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SET_ITEMS, value}),
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
        setItemsFilterSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value}),
        setLocation:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_LOCATION, value}),
        setTotalRows : value => dispatch({type: DISPATCH_ID.ITEMS_SET_TOTALROWS, value}),
        setIdCategory: value => dispatch({type: DISPATCH_ID.ITEMS_SET_IDCATEGORY, value}),
        setNextPage:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AboutRedCompensasZoneView);