import React, { Component } from 'react';
import {DISPATCH_ID, SERVICE_RESPONSE, config, ROLES,VIEW_NAME} from '../libs/utils/Const';
import {connect} from 'react-redux';
import {ApiClient} from "../libs/apiClient/ApiClient";
import $ from 'jquery';
import moment from 'moment';
import './Suscription.css';

let apiClient = ApiClient.getInstance();

class Suscription extends Component {

    constructor(props){
        super(props);
        this.state = {
            emailSubscription : '',
            subscriptionStatus : ''
        };
    }

    async componentDidMount() {
        const {sessionReducer} = this.props;
        if(Object.keys(sessionReducer.user).length !== 0){
            setTimeout(async () => {
                await this.verifySubscription();
            }, 250);
        }
    }

    subscribeUnsubscribe = async (option) => {
        const {sessionReducer, enableSpinner, notificationReducer:{showAlert}} = this.props;
        const { emailSubscription } = this.state;

        if(emailSubscription && emailSubscription !== '' && emailSubscription.includes("@")){
            if(option === 'S'){
                let responseVerify = await this.verifySubscription();
                if(responseVerify === 'NO'){
                    return;
                }
            }           

            let user = {
                Email_SAP : emailSubscription
            }

            // enableSpinner(true);
            let response = await apiClient.subscribeUnsubscribe(user, option);
            // enableSpinner(false);

            if (response.status === SERVICE_RESPONSE.SUCCESS) {
                if(option === 'S'){
                    showAlert({type: 'success', message: ' Suscripción existosa ', timeOut: 2500});
                    if(Object.keys(sessionReducer.user).length !== 0){
                        this.setState({
                            subscriptionStatus : 'S'
                        });
                    } else {
                        this.setState({
                            subscriptionStatus : 'S',
                            emailSubscription : ''
                        });
                    }
                    
                } else {
                    showAlert({type: 'success', message: ' Has sido eliminado de nuestro boletín de noticias :( ', timeOut: 3000});
                    if(Object.keys(sessionReducer.user).length !== 0){
                        this.setState({
                            subscriptionStatus : 'U'
                        });
                    } else {
                        this.setState({
                            subscriptionStatus : 'U',
                            emailSubscription : ''
                        });
                    }
                }
            } else {
                showAlert({type: 'error', message: response.message || ' Ocurrió un error con su suscripción', timeOut: 2000});
            }

        } else {
            showAlert({type: 'warning', message: ' Teclee un correo electrónico válido ', timeOut: 2500});
        }
    }

    verifySubscription = async () => {
        const {sessionReducer, enableSpinner, notificationReducer:{showAlert}} = this.props;
        const { emailSubscription } = this.state;
        let response = null;

        // Verificar para usuarios CON SESIÓN
        if(Object.keys(sessionReducer.user).length !== 0){
            // enableSpinner(true);
            response = await apiClient.verifySubscription(sessionReducer.user.U_FMB_Handel_Email);
            // enableSpinner(false);
        } else {
            if(emailSubscription && emailSubscription !== '' && emailSubscription.includes("@")){
                // enableSpinner(true);
                response = await apiClient.verifySubscription(emailSubscription);
                // enableSpinner(false);
            } else {
                showAlert({type: 'warning', message: ' Teclee un correo electrónico válido ', timeOut: 2500});
                return;
            }
        }

        if (response.status === SERVICE_RESPONSE.SUCCESS) {
            if(response.data.status === 'S'){
                this.setState({
                    subscriptionStatus : 'S',
                    emailSubscription : sessionReducer.user.U_FMB_Handel_Email
                });
                showAlert({type: 'success', message: ' Su correo se encuentra suscrito al boletín de noticias ', timeOut: 3000});
                return 'NO';
            } else {
                this.setState({
                    subscriptionStatus : 'U',
                    emailSubscription : sessionReducer.user.U_FMB_Handel_Email
                });
            }
        } 
    }

    changeSubscriptionEmail = (event) => {
        let newEmail =  event.nativeEvent.target.value;
        this.setState({
            emailSubscription : newEmail
        });
    }

    render() {
        const { emailSubscription, subscriptionStatus } = this.state;
        const { sessionReducer } = this.props;

        let nameButtonSubscription = Object.keys(sessionReducer.user).length !== 0 ? subscriptionStatus === 'S' ? 'Cancelar suscripción' : 'Suscribirse' : 'Suscribirse';
        let parameterSubscribeUnsuscribe = nameButtonSubscription === 'Suscribirse' ? 'S' : 'U';

        return (
            <div className="fondo" style={{display: "flex",justifyContent: "center", alignItems: "center"}}>
                <div className="SeccionBoletin row">
                    <span className="font-weight-bold text-white">Suscríbete a nuestro boletín </span>
                    <div className="Suscripcion input-group" >
                        <input type="text" className="form-control text-dark bg-white" placeholder="Ejemplo: correo@dominio.com" maxLength = '70' size = '70'
                            value = {emailSubscription || ''} 
                            onChange={(event) => {this.changeSubscriptionEmail(event)}}
                            // Activo para usuarios SIN SESIÓN
                            disabled = {Object.keys(sessionReducer.user).length !== 0 ? true : false}
                            />
                        <div className="input-group-append">
                            <button className="btn" type="button" onClick={() => this.subscribeUnsubscribe(parameterSubscribeUnsuscribe)} >{nameButtonSubscription} &nbsp;<i className={config.icons.suscription}/></button>
                        </div>
                    </div>
                </div>

                {/* <form style={{ color: "white"}}>
                    <div className="form-group row" >
                        <label for="suscrip" className="col-lg-2 col-md-6 col-sm-2 form-label">Suscríbete a Nuestro Boletín</label>
                        <div className="row">
                                <input type="text" className="form-control" id="suscrip" placeholder="Ejemplo: correo@dominio.com"/>
                                <button id="" className="btn text-white" type="button" style={{display: "block"}}> 
                                    Suscribirme &nbsp;<i className={config.icons.suscription}/>
                                </button>
                        </div>

                    </div>
                </form> */}
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Suscription);