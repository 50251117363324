import React, {Component} from 'react';
import {Footer, NavBar, Session, TopNavBar} from "../../components";
import {DISPATCH_ID, SERVICE_RESPONSE, config, ROLES,VIEW_NAME,SERVICE_API} from '../../libs/utils/Const';
import {ApiClient} from "../../libs/apiClient/ApiClient";
import {connect} from "react-redux";
import $ from 'jquery';
import moment from "moment";
import { Redirect } from 'react-router';
import { animateScroll as scroll, scroller } from 'react-scroll'
import '../dashboard/Categories.css';
import './ProfileView.css';

let apiClient = ApiClient.getInstance();

class ProfileView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editModePersonalInfoZone: false,
            editModeAccountInfoZone: false,
            typeVisibility : true,
            typeVisibilitynew : true,
            typeVisibilityrepeat : true,
            typeVisibilityCard: true,
            name: '',
            email: '',
            RFC: '',
            phone1: '',
            phone2: '',
            user: '',
            oldPass: '',
            newPass1: '',
            newPass2: '',
            card: '',
            cardStatus: '',
            password: ''
        };
        this.scrollToBottom = this.scrollToBottom.bind(this);
    };

    scrollToBottom() {
        scroll.scrollToTop({
            duration: 1500,
            delay: 100,
            smooth: 'easeOutQuart',
            isDynamic: true
          })
    }

    async componentDidMount(){
        await this.accountBusinessPartnerInfo();
        this.scrollToBottom();
    };

    accountBusinessPartnerInfo = async () => {
        const {sessionReducer, enableSpinner, notificationReducer:{showAlert}} = this.props;

        if(sessionReducer.user.CardCode){
            let cardCode = sessionReducer.user.CardCode;
            enableSpinner(true);
            let response = await apiClient.getBusinessPartnerInfo(cardCode);
            enableSpinner(false);
            this.setState({
                user: response.data.resultData.U_FMB_Handel_Email,
                password: response.data.resultData.U_FMB_Handel_Pass,
                name : response.data.resultData.CardName,                    
                email: response.data.resultData.U_FMB_Handel_Email,
                RFC: response.data.resultData.LicTradNum,
                phone1: response.data.resultData.Phone1,
                phone2: response.data.resultData.Phone2,
                cardStatus: response.data.resultData.U_FMB_Handel_CardAct,
                card: response.data.resultData.U_FMB_Handel_RedCard,
                oldPass: response.data.resultData.U_FMB_Handel_Pass,
                newPass1: '',
                newPass2: '',
                editModePersonalInfoZone: false,
                editModeAccountInfoZone: false,
            });
        }
    }

    updatePartnerInfo = async response => {
        const {enableSpinner, notificationReducer: {showAlert}, sessionReducer} = this.props;
        const {oldPass, newPass1, newPass2, name, email, RFC, phone1, phone2, user, editModePersonalInfoZone, editModeAccountInfoZone} = this.state; 

        if(editModePersonalInfoZone === true){
            if(name == '' || email == '' || RFC == '' || phone1 == ''){
                showAlert({ type: 'warning', message: 'Por favor, rellene todos los datos de la sección "Datos personales"', timeOut: 3000 });
                return;
            }
            if(RFC.length < 12 || RFC.length > 14){
                showAlert({ type: 'warning', message: 'El RFC debe contener 12/13 caracteres ', timeOut: 3000 });
                return;
            }
        } else {
            // if(oldPass == ''){
            //     showAlert({ type: 'warning', message: 'Por favor, escriba su contraseña actual', timeOut: 2500 });
            //     return;
            // }
            if (newPass1 == '') {
                showAlert({ type: 'warning', message: 'Por favor, escriba su nueva contraseña', timeOut: 2500 });
                return;
            } 
            if (newPass2 == '') {
                showAlert({ type: 'warning', message: 'Por favor, confirme su nueva contraseña', timeOut: 2500 });
                return;
            } 
            if (newPass1 != newPass2) {
                showAlert({ type: 'warning', message: 'La contraseña y su confirmación no coinciden', timeOut: 2500 });
                return;
            }
        }        

        let data = {
            name: name,
            email: email,
            RFC: RFC,
            phone1: phone1,
            phone2: phone2,
            user: sessionReducer.user.CardCode,
            oldPass: oldPass,
            newPass1: newPass1,
        }

        enableSpinner(true);
        let apiResponse = await apiClient.updatePartner(data);
        enableSpinner(false);

        if (apiResponse.status === SERVICE_RESPONSE.SUCCESS) {
            showAlert({ type: 'success', message: 'Tus datos se actualizaron de forma exitosa', timeOut: 3000 });
            await this.accountBusinessPartnerInfo();
            this.scrollToBottom();
        } else {
            showAlert({ type: 'error', message: apiResponse.message || 'Ocurrió un error al actualizar sus datos', timeOut: 2500 });
        }
    }

    eyeVisibility = (type) => {
        const { typeVisibility, typeVisibilitynew, typeVisibilityrepeat, typeVisibilityCard } = this.state;
        switch(type){
            case 'typeVisibility':
                this.setState({
                    typeVisibility : !typeVisibility
                })
                break;
            case 'typeVisibilitynew':
                this.setState({
                    typeVisibilitynew : !typeVisibilitynew
                })
                break;
            case 'typeVisibilityrepeat':
                this.setState({
                    typeVisibilityrepeat : !typeVisibilityrepeat
                })
                break;
            case 'typeVisibilityCard':
                this.setState({
                    typeVisibilityCard : !typeVisibilityCard
                })
                break;
            default:
                break;
        }        
    }

    onChangeZone = async (value) => {
        if(value === 'editModePersonalInfoZone'){
            await this.accountBusinessPartnerInfo();
            this.setState({
                editModePersonalInfoZone : true,
                editModeAccountInfoZone: false,
            })    
        } else {
            await this.accountBusinessPartnerInfo();
            this.setState({
                editModePersonalInfoZone : false,
                editModeAccountInfoZone: true,
            })    
        }         
    }

    onCancelZone = async () => {
        await this.accountBusinessPartnerInfo();
        this.setState({
            editModePersonalInfoZone : false,
            editModeAccountInfoZone: false,
        })  
    }

    onChangeName = (value) => {
        this.setState({
            name : value
        })     
    }

    onChangeEMail = (value) => {
        this.setState({
            email : value
        })     
    }

    onChangeRFC = (value) => {
        this.setState({
            RFC : value
        })     
    }

    onChangePhone1 = (value) => {
        this.setState({
            phone1 : value
        })     
    }

    onChangePhone2 = (value) => {
        this.setState({
            phone2 : value
        })     
    }

    onChangeUser = (value) => {
        this.setState({
            user : value
        })     
    }

    onChangeOldPass = (value) => {
        this.setState({
            oldPass : value
        })     
    }

    onChangeNewPass1 = (value) => {
        this.setState({
            newPass1 : value
        })     
    }

    onChangeNewPass2 = (value) => {
        this.setState({
            newPass2 : value
        })     
    }

    onChangeCard = (value) => {
        this.setState({
            card : value
        })     
    }

    iconUser = (priceList)=>{
        let result = '';
        switch (priceList) {
            // case 4:
            //     result = config.Avatar.bronze2;
            //     break;
            // case 5:
            //     result = config.Avatar.silver2;
            //     break;
            // case 6:
            //     result = config.Avatar.gold2;
            //     break;
            // case 7:
            //     result = config.Avatar.platinum2;
            //     break;
            // case 9:
            //     result = config.Avatar.diamond2;
            //     break;
            // case 13:
            //     result = config.Avatar.mercado2;
            //     break;
            default:
                result = config.Avatar.red2;
                break;
        }
        return result
    }
    
    render() {
        const { configReducer: { history }, sessionReducer } = this.props;
        const {typeVisibility, typeVisibilitynew, typeVisibilityrepeat, typeVisibilityCard, name, email, RFC, phone1, phone2, user, 
            oldPass, newPass1, newPass2, card, editModePersonalInfoZone, editModeAccountInfoZone, password} = this.state;
        if(!sessionReducer || !history){
            return <Redirect to="/"/>;
        } else {
            return (
                <div className="content-fluid none-scroll" style={{ marginTop: 150, backgroundColor:"#EFEFEF"}}>
                    {/* <Session history={history} view={VIEW_NAME.PROFILE_VIEW}/> */}
                    <NavBar/>
                    <div className="margenSuperiorViewProfile">
                        {/* <TopNavBar/> */}
                    </div>
                    <div className="mb-md-4 mt-md-5 justify-content-center">
                        <div className="text-center pt-4">
                            <h1 className = "font-weight-bold">MIS DATOS</h1>
                            <img className="img-fluid" src={this.iconUser(sessionReducer.user.listNum)} style={{ color: config.navBar.iconColor2, fontSize: 25, height:"7rem" }}/>
                        </div>
                    </div>
                    <div className="row mb-md-3 mt-md-3 justify-content-center">
                        <div className="col-md-8">
                            <div className="row justify-content-start">
                                <div className="col-md-4">
                                    <h3 className = "font-weight-bold">Datos Personales</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="borderRadius">                        
                                <div className="card">
                                    <ul className="list-group list-group-flush">
                                        <li className="liTransition bg-white  list-group-item" >
                                            <label htmlFor="name" className="form__labelProfile font-weight-bold">Nombre de usuario</label>                                 
                                            <input
                                                type="text"
                                                className="form-control form-control-lg profileForm form__inputProfile"
                                                data-placement="right" 
                                                title="Nombre de usuario"
                                                name="name"
                                                placeholder="Nombre de usuario"
                                                autoComplete={'name'}
                                                value={name || ''}
                                                id="name"
                                                disabled = {editModePersonalInfoZone === false ? true : false}
                                                onChange={(event) => this.onChangeName(event.target.value)}
                                            />  
                                            {/* <label htmlFor="name" className="form__labelProfile">Nombre de usuario</label>   */}
                                        </li>
                                        {/* <li className="liTransition bg-white list-group-item" style={{maxHeight: "70px"}}>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg profileForm form__inputProfile"
                                                name="email"
                                                placeholder="E-mail"
                                                autoComplete={'e-mail'}
                                                value={email || ''}
                                                id="email"
                                                onChange={(event) => this.onChangeEMail(event.target.value)}
                                            />
                                        </li> */}
                                        <li className="liTransition bg-white list-group-item">
                                            <label htmlFor="rfc" className="form__labelProfile font-weight-bold">RFC</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg profileForm form__inputProfile"
                                                name="RFC"
                                                placeholder="RFC"
                                                autoComplete={'RFC'}
                                                value={RFC || ''}
                                                id="RFC"
                                                disabled = {editModePersonalInfoZone === false ? true : false}
                                                onChange={(event) => this.onChangeRFC(event.target.value)}
                                            />
                                            {/* <label htmlFor="rfc" className="form__labelProfile">RFC</label> */}
                                        </li>
                                        <li className="liTransition bg-white list-group-item">
                                            <label htmlFor="phone1" className="form__labelProfile font-weight-bold">Teléfono</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg profileForm form__inputProfile"
                                                name="phone1"
                                                placeholder="Teléfono"
                                                autoComplete={'phone1'}
                                                value={phone1 || ''}
                                                id="phone1"
                                                disabled = {editModePersonalInfoZone === false ? true : false}
                                                onChange={(event) => this.onChangePhone1(event.target.value)}
                                            />
                                            {/* <label htmlFor="phone1" className="form__labelProfile font-weight-bold">Teléfono</label> */}
                                        </li>
                                        <li className="liTransition bg-white list-group-item">
                                            <label htmlFor="phone2" className="form__labelProfile font-weight-bold">Teléfono alternativo</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg profileForm form__inputProfile"
                                                name="phone2"
                                                placeholder="Teléfono alternativo"
                                                autoComplete={'phone2'}
                                                value={phone2 || ''}
                                                id="phone1"
                                                disabled = {editModePersonalInfoZone === false ? true : false}
                                                onChange={(event) => this.onChangePhone2(event.target.value)}
                                            />
                                            {/* <label htmlFor="phone2" className="form__labelProfile font-weight-bold">Teléfono alternativo</label> */}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="row justify-content-end">
                                <div className="col-md-2 text-right">
                                    <button
                                        onClick={editModePersonalInfoZone === false ? () => this.onChangeZone('editModePersonalInfoZone') : this.updatePartnerInfo}
                                        className="btn btn-lg mt-md-2 font-weight-bold"
                                        style={{
                                            backgroundColor: config.navBar.backgroundColor,
                                            color: config.navBar.textColor,
                                        }}>
                                        {editModePersonalInfoZone === false ? 'Editar' : 'Actualizar'}
                                    </button>
                                </div>
                                {editModePersonalInfoZone === true && 
                                    <div className="col-md-2 text-right">
                                        <button
                                            onClick={this.onCancelZone}
                                            className="btn btn-lg mt-md-2 font-weight-bold"
                                            style={{
                                                backgroundColor: config.navBar.backgroundColor,
                                                color: config.navBar.textColor,
                                            }}>
                                            Cancelar
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row mb-md-3 mt-md-3 justify-content-center">
                        <div className="col-md-8">
                            <div className="row justify-content-start">
                                <div className="col-md-4">
                                    <h3 className = "font-weight-bold">Datos de cuenta</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="borderRadius">
                                <div className="card">
                                    <ul className="list-group list-group-flush">
                                        <li className="liTransition bg-white list-group-item">
                                            <label htmlFor="user" className="form__labelProfile font-weight-bold">Cuenta de usuario</label>
                                            <input
                                                type="text"
                                                className="form-control form-control-lg profileForm form__inputProfile"
                                                name="user"
                                                placeholder="Cuenta de usuario"
                                                autoComplete={'user'}
                                                value={user || ''}
                                                id="user"
                                                disabled={true}
                                                onChange={(event) => this.onChangeUser(event.target.value)}
                                            />
                                            {/* <label htmlFor="user" className="form__labelProfile font-weight-bold">Cuenta de usuario</label> */}
                                        </li>
                                        <li className="liTransitionIcon bg-white list-group-item">
                                            <label htmlFor="currentPassword" className="form__labelProfile font-weight-bold">Contraseña actual</label>
                                            <div className="input-group" >   
                                                <div className="input-group-prepend bg-white mr-md-3">
                                                    <span className="input-group-text"
                                                    onClick = {() => this.eyeVisibility('typeVisibility')}><i aria-hidden="true" className={typeVisibility === false ? 'fa fa-eye' : 'fa fa-eye-slash'}/></span>
                                                </div>                                     
                                                <input
                                                    type={ typeVisibility === false ? 'text' : 'password' }
                                                    className="form-control form-control-lg profileForm form__inputProfile"
                                                    name="currentPassword"
                                                    placeholder="Contraseña actual"
                                                    autoComplete={'off'}
                                                    value={typeVisibility === true ? '*****' : (password || oldPass || '')}
                                                    id="currentPassword"
                                                    disabled = {editModeAccountInfoZone === false ? true : false}
                                                    onChange={(event) => this.onChangeOldPass(event.target.value)}
                                                />
                                                {/* <label htmlFor="currentPassword" className="form__labelProfile ">Contraseña actual</label> */}
                                            </div>
                                        </li>
                                        {editModeAccountInfoZone === true && 
                                        <>
                                        <li className="liTransitionIcon bg-white list-group-item">
                                            <label htmlFor="newPassword1" className="form__labelProfile font-weight-bold">Nueva contraseña</label>
                                            <div className="input-group" >    
                                                <div className="input-group-prepend bg-white mr-md-3">
                                                    <span className="input-group-text"
                                                    onClick = {() => this.eyeVisibility('typeVisibilitynew')}><i aria-hidden="true" className={typeVisibilitynew === false ? 'fa fa-eye' : 'fa fa-eye-slash'}/></span>
                                                </div>
                                                <input
                                                    type={ typeVisibilitynew === false ? 'text' : 'password' }
                                                    className="form-control form-control-lg profileForm form__inputProfile"
                                                    name="newPassword1"
                                                    placeholder="Nueva contraseña"
                                                    autoComplete={'off'}
                                                    value={newPass1 || ''}
                                                    id="newPassword1"
                                                    disabled = {editModeAccountInfoZone === false ? true : false}
                                                    onChange={(event) => this.onChangeNewPass1(event.target.value)}
                                                />
                                                {/* <label htmlFor="newPassword1" className="form__labelProfile font-weight-bold">Nueva contraseña</label> */}
                                            </div>
                                        </li>
                                        <li className="liTransitionIcon bg-white list-group-item">
                                            <label htmlFor="newPassword2" className="form__labelProfile font-weight-bold">Confirmación de contraseña</label>
                                            <div className="input-group" >               
                                                <div className="input-group-prepend bg-white mr-md-3">
                                                    <span className="input-group-text"
                                                    onClick = {() => this.eyeVisibility('typeVisibilityrepeat')}><i aria-hidden="true" className={typeVisibilityrepeat === false ? 'fa fa-eye' : 'fa fa-eye-slash'}/></span>
                                                </div>
                                                <input
                                                    type={ typeVisibilityrepeat === false ? 'text' : 'password' }
                                                    className="form-control form-control-lg profileForm form__inputProfile"
                                                    name="newPassword2"
                                                    placeholder="Vuelva a confirmar su contraseña"
                                                    autoComplete={'off'}
                                                    value={newPass2 || ''}
                                                    id="newPassword2"
                                                    disabled = {editModeAccountInfoZone === false ? true : false}
                                                    onChange={(event) => this.onChangeNewPass2(event.target.value)}
                                                />
                                                {/* <label htmlFor="newPassword2" className="form__labelProfile font-weight-bold">Confirmación de contraseña</label> */}
                                            </div>
                                        </li>
                                        </>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="row justify-content-end">
                                <div className="col-md-2 text-right">
                                    <button
                                        onClick={editModeAccountInfoZone === false ? () => this.onChangeZone('editModeAccountInfoZone') : this.updatePartnerInfo}
                                        className="btn btn-lg mt-md-2 font-weight-bold"
                                        style={{
                                            backgroundColor: config.navBar.backgroundColor,
                                            color: config.navBar.textColor,
                                        }}>
                                        {editModeAccountInfoZone === false ? 'Editar' : 'Actualizar'}
                                    </button>
                                </div>
                                {editModeAccountInfoZone === true && 
                                    <div className="col-md-2 text-right">
                                        <button
                                            onClick={this.onCancelZone}
                                            className="btn btn-lg mt-md-2 font-weight-bold"
                                            style={{
                                                backgroundColor: config.navBar.backgroundColor,
                                                color: config.navBar.textColor,
                                            }}>
                                            Cancelar
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {/* Tarjeta de recompensas */}
                    {/* <div className="row mb-md-3 mt-md-4 justify-content-center">
                        <div className="col-md-8">
                            <div className="row justify-content-start">
                                <div className="col-md-4">
                                    <h3 className = "font-weight-bold">Tarjeta Recompensas</h3>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="row justify-content-center">
                        <div className="col-md-8">
                            <div className="borderRadius">
                                <div className="card">
                                    <ul className="list-group list-group-flush">
                                        <li className="liTransitionIcon bg-white list-group-item" >
                                            <label htmlFor="card" className="form__labelProfile font-weight-bold">No. de tarjeta de Recompensas </label>
                                            {card !== '' ?                                            
                                                <div className="input-group" >                                                    
                                                    <div className="input-group-prepend bg-white mr-md-3">
                                                        <span className="input-group-text"
                                                        onClick = {() => this.eyeVisibility('typeVisibilityCard')}><i aria-hidden="true" className={typeVisibilityCard === false ? 'fa fa-eye' : 'fa fa-eye-slash'}/></span>
                                                    </div>                                   
                                                    <input
                                                        type={ typeVisibilityCard === false ? 'text' : 'password' }
                                                        className="form-control form-control-lg profileForm form__inputProfile"
                                                        name="card"
                                                        placeholder="Tarjeta de Recompensas"
                                                        autoComplete={'off'}
                                                        value={card || ''}
                                                        disabled = {true}
                                                        id="card"
                                                    />
                                                </div> :
                                                ' No posee una tarjeta de Recompensas'
                                            }  
                                        </li>
                                        {card !== '' && 
                                            <li className="liTransition bg-white list-group-item" > 
                                                <label htmlFor="cardStatus" className="form__labelProfile font-weight-bold">Status de tarjeta</label>                                      
                                                <input
                                                    type= 'text'
                                                    className="form-control form-control-lg profileForm form__inputProfile"
                                                    name="cardStatus"
                                                    placeholder="Estatus de tarjeta"
                                                    autoComplete={'off'}
                                                    disabled = {true}
                                                    value={card === 0 ? 'Inactiva' : 'Activa'}
                                                    id="cardStatus"
                                                />
                                            </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="row justify-content-center py-4">
                        <div className="col-md-8">
                            <div className="row justify-content-start">
                                <div className="col-md-4">
                                    <button 
                                        className="btn btn-lg text-white" 
                                        type="button" 
                                        style={{background: config.navBar.iconColor, borderRadius: "20px", padding: 10}} 
                                        onClick={() => history.goEditAddress()}>
                                            Apartado de direcciones
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            );
        }
    }
}
const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
        itemsReducer: store.ItemsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfileView);