import React, {Component} from 'react';
import {Footer, NavBar, Session} from "../../components";
import {VIEW_NAME, config} from "../../libs/utils/Const";
import './ContactUsView.css';

class ContactUsView extends Component {
    render() {
        const {history} = this.props;
        const { locations } = config.contactUs;
        return (
            <div className="content-fluid" style={{marginTop: 200}}>
                <Session history={history} view={VIEW_NAME.CONTACT_US_VIEW}/>
                <NavBar/>
                <div className="container mb-4" style={{paddingTop: 60}}>
                    <div className="row">
                        <div className="col">
                            <div className="jumbotron">
                                <h1 className="display-4 text-center">Contáctanos</h1>
                            </div>
                        </div>    
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="list-group" id="list-tab" role="tablist">
                                {locations.map( (location, index) => {
                                    return  <a key={location.name} className={"list-group-item list-group-item-action " + (!index ? 'active' : '')} id={"list" + location.name} data-toggle="list" href={"#location" + location.name}>
                                        { location.name }
                                    </a>
                                })}
                            </div>
                        </div>
                        <div className="col-sm-8">
                            <div className="tab-content" id="nav-tabContent">
                                {locations.map( (location, index) => {
                                    return  <div key={location.name} className={"tab-pane fade" + (!index ? ' show active' : '')} id={"location" + location.name} role="tabpanel" arial-labelledby={"list" + location.name}>
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item">
                                                <i className={ config.icons.road } style={{color: config.footer.iconColor, margin: 5}}> </i>
                                                { location.street }
                                            </li>
                                            <li className="list-group-item">
                                                <i className={ config.icons.city } style={{color: config.footer.iconColor, margin: 5}}> </i>
                                                { location.country }
                                            </li>
                                            <li className="list-group-item">
                                                <i className={ config.icons.phone } style={{color: config.footer.iconColor, margin: 5}}> </i>
                                                { location.phone }
                                            </li>
                                            <li className="list-group-item">
                                                <a target="_blank" href={"mailto:" + location.email}>
                                                    <i className={ config.icons.envelope } style={{color: config.footer.iconColor, margin: 5}}> </i>
                                                    { location.email }
                                                </a>
                                            </li>
                                            <li className="list-group-item">
                                                <i className={ config.icons.pin } style={{color: config.footer.iconColor, margin: 5}}> </i>
                                                <a target="_blank" href={ location.maps }>
                                                    Ir a Google Maps
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactUsView;