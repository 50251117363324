import React, {Component} from 'react';
import {Footer, NavBar, Session, TopNavBar} from "../../components";
import {DISPATCH_ID, SERVICE_RESPONSE, config, ROLES,VIEW_NAME,SERVICE_API} from '../../libs/utils/Const';
import {ApiClient} from "../../libs/apiClient/ApiClient";
import {connect} from "react-redux";
import $ from 'jquery';
import moment from "moment";

let apiClient = ApiClient.getInstance();

class SucursalView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Sucursal : 0
        };
    };

    localidad = (event, option) => {
        if (option == 1) {
            this.setState({
                groupCodeValue: event.nativeEvent.target.value,
            })
        }

        setTimeout(() => {
            // this.searchDocuments()
        }, 100)
    };

    sucursal = (event, option) => {
        if (option == 1) {
            this.setState({
                Sucursal: parseInt(event.nativeEvent.target.value),
            })
        }

        setTimeout(() => {
            // this.searchDocuments()
        }, 100)
    };

    direccion = (dir)=>{
        let result = '';
        switch (dir) {
            case 0:
                result = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1523.9974342560001!2d-107.44552454096211!3d24.70293425378988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86bcd23a8f1888fb%3A0x94bc48aad041a437!2sINDUSTRIAS%20ROCHIN%20S.A%20DE%20C.V!5e0!3m2!1ses-419!2smx!4v1626726859923!5m2!1ses-419!2smx';
                break;
            case 1:
                result = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.217778035869!2d-103.45991618507287!3d20.701379386176665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428a92bd2265f77%3A0xec9807d517a6b389!2sAv.%20San%20Miguel%20399-N%C2%B0%20int.%2046%2C%20San%20Juan%20de%20Ocot%C3%A1n%2C%2045019%20Zapopan%2C%20Jal.!5e0!3m2!1ses-419!2smx!4v1626731137508!5m2!1ses-419!2smx';
                break;
            case 2:
                result = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3763.349851589053!2d-102.05988608509386!3d19.397284286904352!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x842de288d5d6f2c9%3A0x1db0dbb6d7aad7d0!2sCalle%20Atenas%2013%2C%20Jardines%20del%20Cupatitzio%2C%2060080%20Uruapan%2C%20Mich.!5e0!3m2!1ses-419!2smx!4v1626731313791!5m2!1ses-419!2smx';
                break;
            case 3:
                result = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3428.4545570078826!2d-116.01031918486783!3d30.76181928162703!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x81263f3a902193fb%3A0xe619b36c1f1d672f!2sIndustrias%20Rochin%20Suc.%20San%20Quint%C3%ADn!5e0!3m2!1ses-419!2smx!4v1626731354074!5m2!1ses-419!2smx';
                break;
            // case 4:
            //     result = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3624.6823820225027!2d-107.44741518500027!3d24.703442884128325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86bcd23a8f1888fb%3A0x94bc48aad041a437!2sINDUSTRIAS%20ROCHIN%20S.A%20DE%20C.V!5e0!3m2!1ses-419!2smx!4v1626731410675!5m2!1ses-419!2smx';
            //     break;
            // case 5:
            //     result = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3624.6823820225027!2d-107.44741518500027!3d24.703442884128325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86bcd23a8f1888fb%3A0x94bc48aad041a437!2sINDUSTRIAS%20ROCHIN%20S.A%20DE%20C.V!5e0!3m2!1ses-419!2smx!4v1626731410675!5m2!1ses-419!2smx';
            //     break;
            default:
                result = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1523.9974342560001!2d-107.44552454096211!3d24.70293425378988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86bcd23a8f1888fb%3A0x94bc48aad041a437!2sINDUSTRIAS%20ROCHIN%20S.A%20DE%20C.V!5e0!3m2!1ses-419!2smx!4v1626726859923!5m2!1ses-419!2smx';
                break;
        }
        return result
    }
    
    render() {
        const {history, sessionReducer } = this.props;
        const { Sucursal } = this.state;
        
        return (
            <div className="content-fluid none-scroll" style={{marginTop: 150,backgroundColor:"#EFEFEF"}}>
                <Session history={history} view={VIEW_NAME.SUCURSALES_VIEW}/>
                <NavBar/>
                <div className="bannerRedCompensas margenS" style={{backgroundColor:"#EFEFEF"}}>
                    <img id = "scrollDownPlease" className="img-fluid"
                        src={require('../../images/gbtl/bannerPaginas/banner-sucursal.png')}
                        alt="Segundo NavBar"
                    />
                </div> 
                <div className="row justify-content-center mt-3">
                    <div className="col-md-10">
                        <div className="borderRadius">                        
                            <div className="card">
                                <ul className="list-group list-group-flush">
                                    <li className="liTransition bg-white  list-group-item" >                                 
                                        {/* <select name="select" className="btn-outline-secondary" id="selectDocTypeDocumentSearch"
                                            style={{color: "#000", borderRadius: 4, backgroundColor: "transparent", height: 40}}
                                            onChange={(event) => this.localidad(event, 1)}>
                                            <option value="---">Seleccione su localidad</option>
                                            <option value="1">Hey</option>
                                            {groupCodes.map(group => {
                                                return (<option value={group.GroupCode}>{group.GroupName}</option>)
                                            })}
                                        </select>
                                        &nbsp;&nbsp; */}
                                        <select name="select" className="btn-outline-secondary" id="selectDocTypeDocumentSearch"
                                            style={{color: "#000", borderRadius: 4, backgroundColor: "transparent", height: 40}}
                                            onChange={(event) => this.sucursal(event, 1)}>
                                            <option value="0">CULIACÁN</option>
                                            <option value="1">GUADALAJARA</option>
                                            <option value="2">URUAPAN</option>
                                            <option value="3">SAN QUINTÍN</option>
                                            {/* <option value="4">ENSENADA</option>
                                            <option value="5">CHIHUAHUA</option> */}
                                        </select>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10">
                        {/* <img id = "scrollDownPlease" className="img-fluid" style={{display:Sucursal === 0 ? 'block' : 'none' }}
                            src={require('../../images/gbtl/sucursales/sucursales-02.png')} alt="Segundo NavBar"/> */}
                            {/* style={{display:Sucursal === 0 ? 'none' : 'block' }} */}
                        <div >
                        <iframe src={this.direccion(Sucursal)} width="100%" height="400" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
                        </div>
                    </div>
                    <div className="col-md-10 mt-3">
                        <div className="row">
                            {config.dataSucursal.map( (sucur, index) =>{
                                return(
                                    <div className="col-sm-4" key={index}>
                                        <div className="card mb-3" style={{background: 'transparent', border: 'none'}}>
                                            <div className="row no-gutters">
                                                <div className="col-md-4 text-center">
                                                    <img id = "scrollDownPlease" className="img-fluid" src={sucur.imagen} alt="Segundo NavBar"/>
                                                </div>
                                                <div className="col-md-8 align-self-end">
                                                    <div className="card-body text-center" style={{fontFamily: 'Helvetica'}}>
                                                        <h5 className="card-title "><strong>{sucur.name}</strong></h5>
                                                        <span className="card-text">{sucur.address}</span><br/>
                                                        <span className="card-text">{sucur.zipcode !== '' ? 'C.P.' : '' }{sucur.zipcode}</span><br/>
                                                        <span className="card-text">{sucur.block}</span><br/>
                                                        {sucur.phones.map((phone,index) => {
                                                            return (
                                                                <span className="card-text">Tel. <strong>{phone}</strong><br/></span>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
        itemsReducer: store.ItemsReducer,
    };
};
const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SucursalView);