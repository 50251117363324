import React, {Component} from 'react';
import { VIEW_NAME, ROLES, OBJ_TYPE, DISPATCH_ID, config, SERVICE_API,SERVICE_RESPONSE } from "../../libs/utils/Const";
import {Footer, ItemDetailsModal, ItemsList, NavBar, Session, ShoppingCartList, CommentsModal, ItemsPromoModal} from "../../components";
import {connect} from "react-redux";
import {ApiClient} from "../../libs/apiClient/ApiClient";
import CurrencyFormat from 'react-currency-format';
import {Modal} from '../../components/index';
import CreditLimitModal from '../../components/CreditLimitModal';
import DejanosMensajeModal from '../../components/DejanosMensajeModal';
import $ from 'jquery';
import ValidateOrderView from '../validateOrder/ValidateOrderView';
import { parseJSON } from 'jquery';

let apiClient = ApiClient.getInstance();
// Definicion de un arreglo para el producto Flete
let responseFlete = {ItemCode:'',ItemName:'',Price:'0',PriceList:'0',PurchaseLimit:''};
let modal = new Modal();
let discPrcnt = 0;

class ShoppingCartView extends Component {

    constructor(props) {
        super(props);
        

        this.state = {
            itemsBonificacion :[],
            selectorType : null,
            itemDisparador: null,
            itemsRegalos : [],
            itemsRegalosSelect: [],
            EitemsRegalos : [],
            boxes:[],
            itemsNotificacion:[],
            businessPartnerInfo: {},
            pointsInfo: {},
            activePoints : '',
            coupon: '',
            infoCoupon: {},
            discPrcnt: 0,
            resurtido : 'NO',
            numOrden : '',
            file: '',
            responseFlete : '',
        };
    };

    redirectLogin = async () => {
        const { shoppingCartReducer: { items }, notificationReducer: { showAlert }, configReducer: { history } } = this.props;
        history.goLogin();
    };

    redirectHome = async () => {
        const { shoppingCartReducer: { items }, notificationReducer: { showAlert }, configReducer: { history } } = this.props;
        history.goHome();
    };

    refeshState = (array,Notificacion) =>{
        //  console.log('con>Notificacion',Notificacion);
        const {history, shoppingCartReducer: {items}, itemsReducer: {addShoppingCart, deleteShoppingCart}} = this.props;
        const {itemsRegalosSelect} = this.state;

        // console.log('con>array',array);
        let itemsPromo = [];
        for (let index = 0; index < items.length; index++) {
            const item = items[index];
            for (let index = 0; index < array.length; index++) {
                const element = array[index];
                // console.log('con>element',element);
                if (element.itemDisparador ===  item.ItemCode && element.tipoSeleccion === 1) {
                    if(element.tipoDisparador === 2){
                        let result = itemsPromo.find(itemDisp => (itemDisp.itemDisparador !== item.ItemCode) );
                        if(!result){
                            element.bonificacion.cantidad*= element.homeItemQuantity;
                            itemsPromo.push(element);
                        }
                    }else if(element.tipoDisparador === 1){
                        element.bonificacion.cantidad*= element.homeItemQuantity;
                        itemsPromo.push(element);
                    }
                    
                }
            }
        }

        // let itesmSelect = [];
        // for (let index = 0; index < items.length; index++) {
        //     const item = items[index];
        //     for (let index = 0; index < itemsRegalosSelect.length; index++) {
        //         const gift = itemsRegalosSelect[index];
        //         if(item.ItemCode === gift.itemDisparador){
        //             if(parseInt(item.quantity) === gift.itemQuantity){
        //                 itesmSelect.push(gift);
        //             }
        //         }
        //     }
        // }
        
        if(itemsPromo.length > 0){
            let boxes = [];
            for (let index = 0; index < array.length; index++) {
                const element = array[index];
                if(element.tipoSeleccion === 2){
                    let result = boxes.find(item => (item.itemDisparador === element.itemDisparador ));
                    if(!result){
                        boxes.push(element);
                    }
                }
            }
            for (let index = 0; index < itemsRegalosSelect.length; index++) {
                const element = itemsRegalosSelect[index];
                for (let index = 0; index < boxes.length; index++) {
                    const box = boxes[index];
                    if(element.itemDisparador === box.itemDisparador && element.idDisparador === box.idDisparador ){
                        boxes.splice(index,1);
                    }
                }
            }
            /////////////////////////////////////////////////////---------------Quitar Bonificacion sin Stock---------------/////////////////////////////////////////////////////

            // let deleteItems : any = [];
            // console.log('con<<<<', itemsPromo);

            // for (let a = 0; a < items.length; a++) {
            //     const data = items[a];
            //     for (let i = 0; i < itemsPromo.length; i++) {
            //         const stock = itemsPromo[i];

            //         console.log('con< itemsRegalos',data.ItemCode,data.quantity,data.OnHand, 'Bonificacion:', stock.bonificacion.idProducto, stock.bonificacion.Stock, stock.bonificacion.cantidad, parseInt(stock.bonificacion.Stock));
            //         console.log('con<<<<<<<<<<<<<<<<<<<<<',parseInt(stock.bonificacion.Stock), '<<<<',stock.bonificacion.cantidad);
            //         if(data.ItemCode == stock.bonificacion.idProducto && parseInt(data.OnHand - data.quantity - stock.bonificacion.cantidad) < 0){
            //             if(data.OnHand - data.quantity != 0){
            //                 stock.bonificacion.stockMissing = (data.OnHand - data.quantity - stock.bonificacion.cantidad) * -1;
            //                 stock.bonificacion.comment = 'Has regalado ' + (data.OnHand - data.quantity) + '/' + stock.bonificacion.cantidad;

            //                 // let inicio = stock.bonificacion.comment.indexOf('regalado ');
            //                 // let final = stock.bonificacion.comment.indexOf('/');
            //                 // let cantidadAntesDeOrden =  stock.bonificacion.comment.slice(inicio, final);
            //             } 
            //         } 
            //         else if (stock.bonificacion.cantidad > stock.bonificacion.Stock){
            //             console.log('con<<< entre', i, stock.bonificacion.idProducto);
            //             stock.bonificacion.stockMissing =  stock.bonificacion.cantidad - stock.bonificacion.Stock;
            //             stock.bonificacion.comment = 'Has regalado ' + (stock.bonificacion.cantidad - stock.bonificacion.stockMissing) + '/' + stock.bonificacion.cantidad;
            //         }
            //     }
            // }

            // console.log('con<<<<<',itemsPromo);
            /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////            
            
            this.setState({
                itemsRegalos: itemsPromo,
                itemPromociones: array,    
                selectorType: 1,
                boxes: boxes,
                itemsRegalosSelect: [],
                itemsNotificacion: Notificacion
                
            });
            
        }else{
            let boxes = [];
            for (let index = 0; index < array.length; index++) {
                const element = array[index];
                if(element.tipoSeleccion === 2){
                    let result = boxes.find(item => (item.itemDisparador === element.itemDisparador ));
                    if(!result){
                        boxes.push(element);
                    }
                }
            }
            for (let index = 0; index < itemsRegalosSelect.length; index++) {
                const element = itemsRegalosSelect[index];
                for (let index = 0; index < boxes.length; index++) {
                    const box = boxes[index];
                    if(element.itemDisparador === box.itemDisparador && element.idDisparador === box.idDisparador ){
                        boxes.splice(index,1);
                    }
                }
            }

            this.setState({
                itemsRegalos: itemsPromo,
                itemPromociones: array,
                boxes: boxes,
                itemsRegalosSelect: [],
                itemsNotificacion: Notificacion,

            });
        }



    }
   
    selectItemsPromo = (itemsPromo,ItemCode) =>{
        const {itemPromociones,itemsRegalos} = this.state;

        if(itemsPromo.selectorType === 1){
            let arrayPromo = itemPromociones;
            for (let index = 0; index < arrayPromo.length; index++) {
                const promo = arrayPromo[index];
                for (let index = 0; index < promo.bonificacion.length; index++) {
                    const element = promo.bonificacion[index];
                    for (let index = 0; index < itemsPromo.length; index++) {
                        const items = itemsPromo[index];
                        if(element.bonificacion === items.idDetallesPromo){
                            promo.use = true;
                        }
                    }
                }
            }

            this.setState({
                itemsRegalos: [itemsPromo],
                itemPromociones: arrayPromo,    
                selectorType: itemsPromo.selectorType
            });
        }else{
            let boxes = [];
            for (let index = 0; index < itemPromociones.length; index++) {
                const element = itemPromociones[index];
                if(element.tipoSeleccion === 2){
                    if(element.idDisparador === itemsPromo.idDisparador && element.itemDisparador === ItemCode){
                        boxes.push(element);
                    }
                }
            }

            let items = [];
            let registrados = [];
            for (let index = 0; index < boxes.length; index++) {
                const element = boxes[index];
                // console.log('con>element',element.bonificacion.indexPack);
                let include = registrados.includes(element.bonificacion.indexPack);
                if(!include){

                let arrayTemp = boxes.filter(item => (item.bonificacion.indexPack === element.bonificacion.indexPack));
                registrados.push(element.bonificacion.indexPack);
                items.push(arrayTemp);

                }
            }
            //  console.log('con>items',items);

            this.setState({
                itemsBonificacion: items,
                selectorType: itemsPromo.selectorType,
                itemDisparador: itemsPromo.itemDisparador
            });
            $('#boniModal').modal('show');
        }

    }

    addItemsPromo = (item) =>{
        const {itemPromociones,itemsRegalos,itemDisparador,itemsBonificacion,boxes,itemsRegalosSelect} = this.state;
    // console.log('con>item',item);
    //    let newBoxes = [];
    //     for (let index = 0; index < boxes.length; index++) {
    //         const promo = boxes[index];
    //         if(promo.itemDisparador !== item.itemDisparador){
    //             newBoxes.push(promo);
    //         }
    //     }
    for (let index = 0; index < item.length; index++) {
        const element = item[index];
        element.bonificacion.cantidad*= element.homeItemQuantity;
        itemsRegalosSelect.push(element);
    }
    // console.log('con< itemsRegalosSelect', itemsRegalosSelect);


       if(item[0].tipoDisparador === 1){
            this.setState( prevState => ({
                itemsRegalosSelect,
                boxes: prevState.boxes.filter(box => (box.itemDisparador !== item[0].itemDisparador )),
            }));
       }else if(item[0].tipoDisparador === 2){
            this.setState( prevState => ({
                itemsRegalosSelect,
                boxes: prevState.boxes.filter(box => (box.idDisparador !== item[0].idDisparador )),
            }));
       }
        $('#boniModal').modal('hide');

    }

    deleteItemPromo = (element) =>{
        this.setState( (prevState) => ({
            boxes: [...prevState.boxes,element],
            itemsRegalosSelect: prevState.itemsRegalosSelect.filter(item => (item.itemDisparador !== element.itemDisparador )),
        }));
        // const {itemsRegalosSelect} = this.state;
        // let arrayPromo = itemPromociones;
        // let Regalos = itemsRegalosSelect;

        // let nRegalos = [];
        // for (let index = 0; index < itemsRegalosSelect.length; index++) {
        //     const item = itemsRegalosSelect[index];
        //     if(item.idDisparador === element.idDisparador && item.itemDisparador == element.itemDisparador){
        //     }else{
        //         nRegalos.push(item);
        //     }
        // }
        // // let result = ;
        // // if(result){
        // //     nRegalos= result;
        // // }
        // let box = [];
        // for (let index = 0; index < arrayPromo.length; index++) {
        //     const itemPromo = arrayPromo[index];
        //     if(element.idDisparador === itemPromo.idDisparador ){
        //         box.push(itemPromo);
        //     }
        // }
        // //let boxe = boxes || [];
        // let boxe = boxes.slice() || [];
        // for (let index = 0; index < box.length; index++) {
        //     const element = box[index];
        //     if(element.tipoSeleccion === 2){
        //         let result = boxe.find(item => (item.itemDisparador === element.itemDisparador ));
        //         if(!result){
        //             boxe.push(element);
        //         }
        //     }
        // }
        //items.filter(item => (item.itemDisparador !== element.itemDisparador)),  
    }

    async componentDidMount(){
        //Asiganacion del producto que viene de SAP al front
        let responseApi = await this.getRegisterPack();
        //Tomando valores que vienen desde SAP
        let item_code = responseApi.data[0].ItemCode;
        let item_name = responseApi.data[0].ItemName;
        let price = responseApi.data[0].Price;
        let price_list = responseApi.data[0].PriceList;
        let purchase_limit = responseApi.data[0].PurchaseLimit;

        //Asigancion de los valores al arreglo del front}
        responseFlete.ItemCode = item_code;
        responseFlete.ItemName = item_name;
        responseFlete.Price = price;
        responseFlete.PriceList = price_list;
        responseFlete.PurchaseLimit = purchase_limit;
        // console.log("Hola desde view",responseFlete);
        
        this.setState({
            responseFlete
        })


        this.handleInputChange = this.handleInputChange.bind(this)
        this.cargarDatos();
        await this.accountBusinessPartnerInfo();
    }

    // Peticion del producto flete
    getRegisterPack = async () => {
        return await apiClient.getFlete();
    };

    accountBusinessPartnerInfo = async () => {
        const {sessionReducer, enableSpinner, notificationReducer:{showAlert}} = this.props;

        if(sessionReducer.user.CardCode){
            let cardCode = sessionReducer.user.CardCode;
            enableSpinner(true);
            let response = await apiClient.getBusinessPartnerInfo(cardCode);
            enableSpinner(false);
            if (response.status === SERVICE_RESPONSE.SUCCESS) {
                this.setState({
                    businessPartnerInfo : response.data.resultData,
                    pointsInfo : response.data.resultInfoPoints,
                    activePoints : response.data.resultInfoPoints.totalPoints
                });
            } else {
                this.setState({
                    businessPartnerInfo : {permissions:'NO'},
                });
                showAlert({type: 'warning', message: response.message || ' Ocurrió un error al traer su info.', timeOut: 3250});                
            }
        }
    }

    createDocument = async () => {
        const {shoppingCartReducer: {items}, notificationReducer: {showAlert}, configReducer: {history},setItemsGift} = this.props;
      //const {itemPromociones,Limit, Codigo, CardName, itemsRegalos, itemsBonificacion,selectorType,itemsRegalosSelect,,itemsNotificacion} = this.state;
        const {itemsRegalos,itemsRegalosSelect, infoCoupon, activePoints,boxes, resurtido, numOrden, file} = this.state;

        if(boxes.length > 0){
            boxes.map(articulosSeleccionados =>{
                showAlert({type: 'warning', message: 'No has seleccionado la bonificación del artículo: ' + articulosSeleccionados.itemDisparador, timeOut: 5000});
            })
            return;
        }        
        let data = {
            resurtido : resurtido, 
            numOrden : numOrden, 
            file : file
        }
        localStorage.setItem(config.general.localStorageNamed + 'DatosOrden', JSON.stringify(data));

        setItemsGift(itemsRegalos.concat(itemsRegalosSelect));
        //Se va el arregle de regalos al local
        localStorage.setItem(config.general.localStorageNamed + 'Gift', JSON.stringify(itemsRegalos));
        let errors = items.filter((item) => {            
            return (!(!!item.OnHandPrincipal) || (item.quantity > item.OnHandPrincipal))
        });

        if (errors.length) return showAlert({
            message: 'Existen articulos agotados o en cantidad mayor en existencia. Para continuar elimine del carrito o paselos a su lista de deseos',
            type: 'warning',
            timeOut: 10000
        })

        // Descuento por porcentaje
        if( infoCoupon.U_TIPO_DSCT === "1" || infoCoupon.U_TIPO_DSCT === "2" ){
            localStorage.setItem(config.general.localStorageNamed + 'Discount',JSON.stringify(infoCoupon));
        }else{
            localStorage.setItem(config.general.localStorageNamed + 'Discount',JSON.stringify(null));
        }
        // Descuento por puntos
        if (activePoints != 0){
            localStorage.setItem(config.general.localStorageNamed + 'DiscountPoints',activePoints);
        }else{
            localStorage.setItem(config.general.localStorageNamed + 'DiscountPoints',0);
        }

        history.goSelectAddress();
        //let response = await apiClient.createDocument({objType: OBJ_TYPE.ORDER, currency: 'MXP'});
    };

    changeActivePoints = (event) => {
        const { pointsInfo, activePoints } = this.state;
        const { notificationReducer:{showAlert} } = this.props;
        let newActivePoints =  event.nativeEvent.target.value;
        // console.log('######newActivePoints', newActivePoints);
        if(parseInt(newActivePoints) > parseInt(pointsInfo.totalPoints)){
            showAlert({type: 'warning', message: ' No puedes exceder el número de puntos: ' + pointsInfo.totalPoints, timeOut: 2500});
            this.setState({
                activePoints : pointsInfo.totalPoints
            });
            return;
        }else{
            this.setState({
                activePoints : newActivePoints
            });
        }
        // console.log('######activePoints', activePoints);
        
    }

    changeDiscount = (event) => {
        let newCoupon =  event.nativeEvent.target.value;
        this.setState({
            coupon : newCoupon
        });
        
    }

    renderPointsData = () => {
        const { pointsInfo, activePoints } = this.state;
        //console.log("Foca",pointsInfo.pointsMoney);
        let valueEquals = parseFloat(pointsInfo.pointsMoney);
        let valuePoints = parseFloat(pointsInfo.totalPoints * valueEquals);

        return (
            <div className="container" style={{ fontSize: 18}}>
                <div className="row">
                    <div className="col-6" style={{ padding: 0 }}>
                        <span className="font-weight-bold">Total de puntos: </span>
                    </div>
                    <div className="col-6 text-right" style={{ padding: 0 }}>
                        <span className="text-right">
                            <CurrencyFormat
                                value={pointsInfo.totalPoints}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}>
                            </CurrencyFormat>
                            {` puntos`}
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6" style={{ padding: 0 }}>
                        <span className="font-weight-bold">Valor del punto: </span>
                    </div>
                    <div className="col-6 text-right" style={{ padding: 0 }}>
                        <span className="text-right">
                            <CurrencyFormat
                                value={valueEquals || 0}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                prefix={'$ '}>
                            </CurrencyFormat>
                            {` ${config.general.currency}`}
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6" style={{ padding: 0 }}>
                        <span className="font-weight-bold">Valor en pesos: </span>
                    </div>
                    <div className="col-6 text-right" style={{ padding: 0 }}>
                        <span className="text-right">
                            <CurrencyFormat
                                value={valuePoints || 0}
                                displayType={'text'}
                                thousandSeparator={true}
                                fixedDecimalScale={true}
                                decimalScale={2}
                                prefix={'$ '}>
                            </CurrencyFormat>
                            {` ${config.general.currency}`}
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6" style={{ padding: 0 }}>
                        <span className="font-weight-bold">Puntos a descontar: </span>
                    </div>
                    <div className="col-6 text-right" style={{ padding: 0 }}>
                        <input type="text" className="form-control" maxLength = '70' size = '70'
                            value = {activePoints || 0} style={{borderRadius: 10}}
                            onChange={(event) => {this.changeActivePoints(event)}}
                            // Activo para usuarios SIN SESIÓN
                            disabled = {pointsInfo.totalPoints == 0 ? true : false}
                            />
                    </div>
                </div>
            </div>
        )
    };

    mostrar = async () => {
        const { notificationReducer: {showAlert}, sessionReducer} = this.props;
        const { Balance, CreditLine, boxes, resurtido, numOrden, file} = this.state;
        
        if(boxes.length > 0){
            boxes.map(articulosSeleccionados =>{
                showAlert({type: 'warning', message: 'No has seleccionado la bonificación del artículo: ' + articulosSeleccionados.itemDisparador, timeOut: 5000});
            })
            return;
        }

        // let limit = Balance- CreditLine;
        // if(limit > 0){
        //     $('#creditLimitModal').modal('show');
        // }
        // else{
            this.createDocument();
        // }   
    };

    checkDiscount = async () => {
        // Recibir el cupon ingresado
        const { coupon } = this.state
        const { notificationReducer: { showAlert } } = this.props;
        // Buscar si el cupón existe
        if ( coupon != '') {
            let responseInfoCoupon = await apiClient.getInfoCoupon(coupon);
            if( responseInfoCoupon.status === 1 ){
                showAlert({type: 'success', message: "Cupón encontrado", timeOut: 2500});
                // Asiganar cupon
                this.setState({
                    infoCoupon: responseInfoCoupon.data,
                });
            }else{
                showAlert({type: 'warning', message: responseInfoCoupon.message, timeOut: 2500});
                // Asiganar cupon
                this.setState({
                    infoCoupon: [],
                });
            }
        }else{
            showAlert({type: 'warning', message: "No has colocadó un cupón", timeOut: 2500});
        }
        
        

    }

    changeQuantity = async (index, item, newQuantity, addItem) => {
        const {enableSpinner, setShoppingCart, itemsReducer: {addShoppingCart, deleteShoppingCart}, shoppingCartReducer: {items}} = this.props;
        
        items.map(itemFilter => {
            if (item.ItemCode === itemFilter.ItemCode) {
                itemFilter.quantity = newQuantity;
            }
        });

        setShoppingCart(items);

        if (!newQuantity) return;

        if (addItem) {
            addShoppingCart({item, quantity: (newQuantity)})
        } else {
            //deleteShoppingCart({item, deleteAll: false});
        }

        setTimeout(async () => {
            let shoppingCart = [];
            enableSpinner(true);        
            let response = await apiClient.getShoppingCart(items);
            enableSpinner(false);

            if (response.status === SERVICE_RESPONSE.SUCCESS) {                    
                shoppingCart = response.data.shoppingCart;                    
            } else {
                return;
                //showAlert({type: 'error', message: response.message, timeOut: 0});
            }    
            setShoppingCart(shoppingCart);
        }, 150); 
    };


    renderShoppingCartTotal = () => {
        const {history, shoppingCartReducer: {items}, itemsReducer: {addShoppingCart, deleteShoppingCart},configReducer:{currency}} = this.props;
        const { infoCoupon, pointsInfo, activePoints, responseFlete } = this.state;

        let subTotal = 0;
        let taxTotal = 0;
        let total = 0;
        let tax = 0;
        let localLanguage = '';
        // let currency = '';
        let valueDiscount = 0;
        let valueEquals = parseFloat(pointsInfo.pointsMoney);
        //Variables para validacion del Flete
        let transport = 0;
        let taxTransport = 0;
        let limit = 0;
        let peso=0;

        let activePointsNew = activePoints;
        items.map(item => {
            let totalPrice = Number((item.Price) * parseInt(item.quantity));
            // estimamos el peso
            if(item.SWeight1){
                peso = peso + Number(item.quantity) * item.SWeight1;
            }else{
                peso = 1;
            }
            localStorage.setItem("Peso",peso)

            subTotal += totalPrice;
            tax = item.taxRate;
            taxTotal = parseFloat(subTotal * (tax * 0.01));
            localLanguage = item.localLanguage;
            // currency = item.currency;
        });
        //Asignacion de valores para el flete
        limit = parseInt(responseFlete.PurchaseLimit);
        transport = parseFloat(responseFlete.Price);

        // 117
        taxTransport = parseFloat(transport*(tax*0.01));
        //Validacion del flete
        if(subTotal < limit){
            taxTotal = taxTotal + taxTransport;
            total = subTotal + transport + taxTotal;
            total = total.toFixed(2);
        }else{
            transport = 0;
            total = subTotal + transport + taxTotal;
            total = total.toFixed(2);
        }
        // Total sin envio
        // total = subTotal + taxTotal;
        

        // Validar cupon
        // console.log("InfoCopupon", infoCoupon);
        if( infoCoupon.U_TIPO_DSCT === "1" && pointsInfo && activePoints){
            // Descuento por porcentaje
            let valueAux = parseFloat(infoCoupon.U_VALOR_DSCT);
            valueDiscount = valueAux;
            // valueDiscount = (total / 100) * valueAux;
            // total = total - valueDiscount;
        }
        if( infoCoupon.U_TIPO_DSCT === "2" && pointsInfo && activePoints){
            // Descuento en efectivo
            valueDiscount = parseFloat(infoCoupon.U_VALOR_DSCT);
            // discPrcnt = (valueDiscount * 100) / total;
            // total = total - valueDiscount;
        }
        // Mandar el total de la orden para que viaje a Mercado Pago
        localStorage.setItem(config.general.localStorageNamed + 'OrderTotal', total);

        return (
            <div className="container" style={{fontSize: 18}}>
                <div className="row">
                    <div className="col-6" style={{padding: 0}}>
                        <span className="font-weight-bold">Subtotal: </span>
                    </div>
                    <div className="col-6 text-right" style={{padding: 0}}>
                        <span className="text-right">
                            <CurrencyFormat 
                                value={subTotal} 
                                displayType={'text'} 
                                thousandSeparator={true} 
                                fixedDecimalScale={true} 
                                decimalScale={2} 
                                prefix={currency.DocCurrCod}
                                suffix = {' '+config.general.currency}>
                            </CurrencyFormat>
                            {/* {` ` +currency} */}
                        </span>
                    </div>
                </div>
                {/* Descomentar en caso de envio */}
                <div className="row border-bottom">
                    <div className="col-6" style={{ padding: 0 }}>
                        <span className="font-weight-bold">Envío:</span>
                    </div>
                    <div className="col-6 text-right" style={{ padding: 0 }}>
                        <span className="text-right">
                            {(subTotal) === 0 ?
                                <CurrencyFormat
                                    value={0}
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    fixedDecimalScale={true} 
                                    decimalScale={2} 
                                    prefix={currency.DocCurrCod}
                                    //suffix ={' '+currency.MainCurncy}
                                    suffix = {' '+config.general.currency}
                                    >
                                </CurrencyFormat> 
                            :
                                <CurrencyFormat
                                    value={transport}

                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    fixedDecimalScale={true} 
                                    decimalScale={2} 
                                    prefix={currency.DocCurrCod}
                                    suffix = {' '+config.general.currency}
                                    //suffix ={' '+currency.MainCurncy}
                                    >
                                    
                                </CurrencyFormat>
                            }
                        </span>
                    </div>
                </div>
                <div className="row border-bottom">
                    <div className="col-6" style={{padding: 0}}>
                        <span className="font-weight-bold">
                            I.V.A. ({parseFloat(tax).toFixed(2)}%): 
                        </span>
                    </div>
                    <div className="col-6 text-right" style={{padding: 0}}>
                        <span className="text-right">
                            <CurrencyFormat 
                                value={taxTotal} 
                                displayType={'text'} 
                                thousandSeparator={true} 
                                fixedDecimalScale={true} 
                                decimalScale={2} 
                                prefix={currency.DocCurrCod}
                                suffix = {' '+config.general.currency}
                                //suffix ={' '+currency.MainCurncy}
                                
                                >
                            </CurrencyFormat>
                            {/* {` ` +currency} */}
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6" style={{ padding: 0 }}>
                        <span className="font-weight-bold">Total: </span>
                    </div>
                    <div className="col-6 text-right" style={{ padding: 0 }}>
                        <span className="text-right font-weight-bold" style={{color: "#86C03F"}}>
                            {subTotal === 0 ?
                                <CurrencyFormat
                                    value={0}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    prefix={currency.DocCurrCod}
                                    suffix = {' '+config.general.currency}
                                    //suffix ={' '+currency.MainCurncy}
                                    
                                    >
                                </CurrencyFormat> 
                                :
                                <CurrencyFormat
                                    value={total}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    prefix={currency.DocCurrCod}
                                    suffix = {' '+config.general.currency}
                                    //suffix ={' '+currency.MainCurncy}
                                    >
                                </CurrencyFormat>
                            }
                            {/* {` ` +currency} */}
                        </span>
                    </div>
                </div>
                {
                    infoCoupon.U_TIPO_DSCT &&
                    <div className="row">
                        <div className="col-6" style={{ padding: 0 }}>
                            <span className="font-weight-bold">
                                Descuento:
                            </span>
                        </div>
                        <div className="col-6 text-right" style={{ padding: 0 }}>
                            <span className="text-right" style={{color: "#86C03F"}}>
                                <CurrencyFormat
                                    value={valueDiscount}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    fixedDecimalScale={true}
                                    decimalScale={2}
                                    prefix={infoCoupon.U_TIPO_DSCT == "1" ? '- ' : `- ${currency.DocCurrCod}`}
                                    suffix ={infoCoupon.U_TIPO_DSCT == "1" ? '' :' '+currency.MainCurncy}>
                                </CurrencyFormat>
                                {infoCoupon.U_TIPO_DSCT == "1" ? ' %' : ''}
                            </span>
                        </div>
                    </div>
                }
            </div>
        )
    };

    sendToBackOrder = item => {
        const {itemsReducer: {addBackOrder, deleteShoppingCart}} = this.props;
        deleteShoppingCart({item, deleteAll: false});
        addBackOrder({item, quantity: (item.quantity || '1')})
    };

    cargarDatos = async () => {
        let user = localStorage.getItem(config.general.localStorageNamed + 'CurrentUser');
        user = JSON.parse(user) || {};
        let limit = user.Balance- user.CreditLine;
        this.setState({
            Codigo: user.CardCode,
            CardName : user.CardName,    
            Balance : user.Balance ,
            CreditLine : user.CreditLine,
            Limit : limit,
        });
    };

    renderShoppingCartDetail = () => {
        const {history, shoppingCartReducer: {items}, itemsReducer: {addShoppingCart, deleteShoppingCart}} = this.props;
        let totalbruto = 0;
        let totalneto = 0;
        let unidades = 0;
        let articulos = items.length;
        items.map(item => {        
            let bruto = Number(item.weight1 * item.quantity);
            totalbruto += bruto;
            let neto = Number(item.weight * item.quantity);
            totalneto += neto; 
            let cantidadArti = Number(item.quantity);
            unidades += cantidadArti
        });

        return (
            <div className="container" style={{fontSize: 18}}>
                {/* <div className="row">
                    <div className="col-6" style={{padding: 0}}>
                        <span className="font-weight-bold">Total peso neto: </span>
                    </div>
                    <div className="col-6 text-right" style={{padding: 0}}>
                        <span className="text-right">
                             {parseFloat(totalneto).toFixed(2)} KG
                        </span>
                    </div>
                </div>
                <div className="row border-bottom">
                    <div className="col-6" style={{padding: 0}}>
                        <span className="font-weight-bold">Total peso bruto:</span>
                    </div>
                    <div className="col-6 text-right" style={{padding: 0}}>
                        <span className="text-right">
                            {parseFloat(totalbruto).toFixed(2)} KG
                        </span>
                    </div>
                </div> */}
                <div className="row border-bottom">
                    <div className="col-6" style={{padding: 0}}>
                        <span className="font-weight-bold">Total de articulos:</span>
                    </div>
                    <div className="col-6 text-right" style={{padding: 0}}>
                        <span className="text-right">
                            {parseInt(articulos)}
                        </span>
                    </div>
                </div>
                <div className="row border-bottom">
                    <div className="col-6" style={{padding: 0}}>
                        <span className="font-weight-bold">Total de unidades:</span>
                    </div>
                    <div className="col-6 text-right" style={{padding: 0}}>
                        <span className="text-right">
                            {parseInt(unidades)}
                        </span>
                    </div>
                </div>
            </div>
        )
    };

    async handleInputChange (event) {
        const target = event.target
        const value = target.value
        const name = target.name
        this.setState({
            [name]: value.split('\\').pop()
        })
    };

    handelChange = ({ target }) => {
        const { name, value } = target;
        this.setState({
            [name]: value
        });
    };

    dejanosMensaje = async () => {        
        // showAlert({type: 'warning', message: 'No has seleccionado la bonificación del artículo: ' , timeOut: 5000});
        $('#dejanosMensajeModal').modal('show');
    };

    render() {
        const {sessionReducer: {role, user}} = this.props;
        const {history, shoppingCartReducer: { items }, itemsReducer: {addShoppingCart, deleteShoppingCart}} = this.props;
        const {itemPromociones,Limit, Codigo, CardName, itemsRegalos, itemsBonificacion,selectorType,itemsRegalosSelect,boxes,itemsNotificacion,businessPartnerInfo, pointsInfo, activePoints} = this.state;

        let flagPoints = false;
        if(items){
            items.map(item => {
                if(item.U_FMB_Handel_PNTA == 1){
                    flagPoints = true;
                    return;
                }
            });
        }
        return (
            <div className="content-fluid" style={{ marginTop: 150, backgroundColor: config.Back.backgroundColor }}>
                <Session history={history} view={VIEW_NAME.SHOPPING_CART_VIEW}></Session>
                <NavBar/>
                <ItemsPromoModal
                    items = {itemsBonificacion}
                    selectorType = {selectorType}
                    addItemsPromo = {this.addItemsPromo}
                />
                {/* <CreditLimitModal
                    Limit={Limit}
                    Codigo={Codigo}
                    CardName={CardName}
                    createDocument={this.createDocument}
                ></CreditLimitModal> */}
                <DejanosMensajeModal
                    Usuario={user}
                    datos = {role}
                ></DejanosMensajeModal>
                <div className="row" style={{paddingTop: 120, margin: 0, minHeight: '70vh' , paddingBottom: 20}}>
                    <div className="col-lg-8 pt-3">
                        <div className="card style-articles-cart" style={{
                            borderRadius: 20, border: '1px solid #ADADAD'
                        }}>
                            <div className="card-header" style={{background: config.footer.backgroundColor, borderTopLeftRadius: 20, borderTopRightRadius: 20, borderBottom: "none"}}>
                                <div className='row'>
                                    <p className="card-title col-12 col-sm-10 row" style={{ color: config.shoppingList.textProductList, justifyContent: "left" }}>Artículos en tu carrito&nbsp;&nbsp; <span className="small" style={{ color: config.footer.iconColor ,marginTop: "auto", marginBottom: "auto" }} > (precios sin I.V.A.):</span></p>
                                    <div className='text-right col-12 col-sm-2' style={{ width: '100%' }}>
                                        {!!items.length &&
                                            <div onClick={() => deleteShoppingCart({ item: {}, deleteAll: true })}
                                                className='text-left text-sm-right'
                                                style={{ cursor: 'pointer', color: '#000' }}>
                                                <i className="fa fa-trash" style={{color: "red"}}/> Eliminar todo
                                        </div>}
                                    </div>
                                </div>
                            </div>
                            {/* ,border: '2px solid #000',borderRadius: '10px' */}
                            <div className="card-body" style={{height: items.length >= 6 ? '600px' : '',overflowY: items.length >= 6 ? 'auto' : 'hidden'}}>
                                {!!items.length ?
                                    <ShoppingCartList
                                        view={VIEW_NAME.SHOPPING_CART_VIEW}
                                        data={items}
                                        deleteItem={deleteShoppingCart}
                                        changeQuantity={this.changeQuantity}
                                        add={addShoppingCart}
                                        sendTo={this.sendToBackOrder}
                                        businessPartnerInfo={businessPartnerInfo}

                                        refeshState = {this.refeshState}
                                        itemPromociones = {itemPromociones}
                                        selectItemsPromo = {this.selectItemsPromo}
                                        itemsRegalos = {itemsRegalos}
                                        itemsRegalosSelect = {itemsRegalosSelect}
                                        deleteItemPromo = {this.deleteItemPromo}
                                        boxes = {boxes}
                                        itemsNotificacion ={itemsNotificacion}
                                    /> :
                                    <div className='text-center' style={{fontSize: 30}}>
                                        Carrito vacío
                                    </div>}
                            </div>
                            {/* <div className="card-body" style={{overflow:'auto'}}>
                                <table className="table scroll"> */}
                                    {/* table-responsive w-100 d-block d-md-table */}                                    
                                    {/* <thead > */}
                                        {/* style={{width:'30%'}} */}
                                        {/* <tr className="text-light bg-primary" >
                                        <th scope="col" style={{}}></th> 
                                        <th scope="col" style={{textAlign:'left'}}>Producto</th> 
                                        <th scope="col" style={{}}>Nombre</th>
                                        <th scope="col" style={{}}>Cantidad</th>
                                        <th scope="col" style={{}}></th>
                                        <th scope="col" style={{}}>Producto</th> 
                                        <th scope="col" style={{}}>Nombre</th>                                        
                                        </tr>
                                    </thead>
                                    <tbody> */}
                                        {/* {items.map((item, index) => {
                                            return (
                                            <tr key={index}>
                                                <td style={{fontSize: 12}}>{index+1}</td>
                                                <td style={{fontSize: 12, textAlign:'left'}}>{item.ItemCode}</td>
                                                <td style={{fontSize: 12, textAlign:'left'}}>{item.ItemName}</td>
                                                <td style={{fontSize: 12, textAlign:'left'}}>{item.quantity}</td>
                                                <td style={{textAlign:'left'}}>
                                                    <button
                                                        className="btn btn-sm"
                                                        type="button"
                                                        style={{ backgroundColor: config.navBar.iconBackground, color: config.navBar.iconModal }}
                                                        // onClick={() => selectItems(item)}
                                                        > 
                                                        <i className="fas fa-plus-circle"></i> 
                                                    </button>
                                                </td>
                                                <td style={{fontSize: 12, textAlign:'left'}}>{item.ItemCode}</td>
                                                <td style={{fontSize: 12, textAlign:'left'}}>{item.ItemName}</td>
                                            </tr>)
                                        })} */}
                                    {/* </tbody>
                                </table>
                            </div> */}
                        </div>
                    </div>

                    <div className="col-lg-4 pt-3 mb-3">
                        { config.modules.points && <div className="card" style={{
                            borderRadius: 20,
                            backgroundColor: config.navBar.backgroundColor,
                            border: '1px solid #ADADAD'
                        }}>
                            {
                                businessPartnerInfo.U_FMB_Handel_CardAct == "1" &&
                                businessPartnerInfo.U_FMB_Handel_RedCard != null &&
                                flagPoints === true &&
                                <>
                                <div className="card-header text-white"
                                    style={{  background: "#ADADAD", borderTopLeftRadius: 20, borderTopRightRadius: 20, borderBottom: "none" }}>
                                    <div className="card-title">
                                        <h3 style={{ color: config.shoppingList.textsummaryList }}>Puntos</h3>
                                    </div>
                                </div>
                             
                                <div className="card-body">
                                    {pointsInfo && this.renderPointsData()}
                                </div>
                                </>
                            }
                        </div>
                        }
                        {config.modules.points && <br/> }
                        <div className="card mb-4" style={{
                            borderColor: 'white',  borderRadius: 20,
                            backgroundColor: config.navBar.backgroundColor,
                            border: '1px solid #ADADAD'
                        }}>
                            <div className="card-header text-white"
                                style={{background: config.shoppingList.summaryList, borderTopLeftRadius: 20, borderTopRightRadius: 20, borderBottom: "none"}}>
                                <div className="card-title">
                                    <h3 style={{color: config.shoppingList.textsummaryList}}>Resumen:</h3>
                                </div>
                            </div>

                            <div className="card-body">
                                {this.renderShoppingCartTotal()}

                                {/* <div>
                                    <input type="file" name="file" id="file" onChange={this.handleInputChange}  placeholder="Archivo orden de compra" className="form-control-file" ></input>
                                </div> */}

                                {items.length !== 0 &&
                                    role === ROLES.CLIENT 
                                    ? <div className="col-12 text-right p-0 mt-3">
                                        <button type='button' onClick={this.mostrar} className="btn btn-block text-white" style={{ fontWeight: "bold", borderRadius:10, background: "#86C03F" }}>Continuar compra</button>
                                    </div> 
                                    : role === ROLES.PUBLIC && items.length >= 1 
                                        ? <div className="col-12 text-right p-0 mt-3">
                                            <button type='button' onClick={this.redirectLogin } className="btn btn-block text-white" style={{ fontWeight: "bold", borderRadius:10, background: "#86C03F" }}>Continuar compra</button>
                                        </div>
                                        : <div className="col-12 text-right p-0 mt-3">
                                            <button type='button' onClick={this.redirectHome } className="btn btn-block text-white" style={{ fontWeight: "bold", borderRadius:10, background: "#86C03F" }}>Continuar compra</button>
                                        </div>
                                }
                            {config.modules.pasarelaCarrito && <div className="mt-3 row col-12 formasPagoImages text-center">
                                    <div className="col-lg-auto col-md-3 col-sm-auto col-3" style={{marginTop: "auto", marginBottom: "auto"}}>
                                        <img className="img-fluid"
                                            src={require('../../images/gbtl/carrito/icon-02.png')}
                                            alt="Segundo NavBar"
                                        />
                                    </div>
                                    <div className="col-lg-auto col-md-3 col-sm-auto col-3" style={{marginTop: "auto", marginBottom: "auto"}}>
                                        <img className="img-fluid"
                                            src={require('../../images/gbtl/carrito/icon-03.png')}
                                            alt="Segundo NavBar"
                                        />
                                    </div>
                                    {/* <div className="col-lg-auto col-md-3 col-sm-auto col-3" style={{marginTop: "auto", marginBottom: "auto"}}>
                                        <img className="img-fluid"
                                            src={require('../../images/gbtl/carrito/icon-04.png')}
                                            alt="Segundo NavBar"
                                        />
                                    </div>
                                    <div className="col-lg-auto col-md-3 col-sm-auto col-3" style={{marginTop: "auto", marginBottom: "auto"}}>
                                        <img className="img-fluid"
                                            src={require('../../images/gbtl/carrito/icon-05.png')}
                                            alt="Segundo NavBar"
                                        />
                                    </div>
                                    <div className="col-lg-auto col-md-3 col-sm-auto col-3" style={{marginTop: "auto", marginBottom: "auto"}}>
                                        <img className="img-fluid"
                                            src={require('../../images/gbtl/carrito/icon-06.png')}
                                            alt="Segundo NavBar"
                                        />
                                    </div>
                                    <div className="col-lg-auto col-md-3 col-sm-auto col-3" style={{marginTop: "auto", marginBottom: "auto"}}>
                                        <img className="img-fluid"
                                            src={require('../../images/gbtl/carrito/icon-07.png')}
                                            alt="Segundo NavBar"
                                        />
                                    </div>
                                    <div className="col-lg-auto col-md-3 col-sm-auto col-3" style={{marginTop: "auto", marginBottom: "auto"}}>
                                        <img className="img-fluid"
                                            src={require('../../images/gbtl/carrito/icon-08.png')}
                                            alt="Segundo NavBar"
                                        />
                                    </div> */}
                                </div> 
                            }
                            {config.modules.cupon &&
                                <div className="text-center mb-4 seccionCupon">
                                    <span style={{color: "#808080", fontFamily: "Helvetica", fontSize: 18}}> ¿Cuentas con algún cupón?</span>
                                    <div className="mt-2">
                                        <div className="input-group mb-3"  style={{display: "flex",justifyContent: "center", alignItems: "center", marginLeft: "auto", marginRight: "auto"}}>
                                            <input type="text" 
                                                className="form-control form-control-lg" 
                                                placeholder=""
                                                onChange={(event) => {this.changeDiscount(event)}}/>
                                            <div className="input-group-append">
                                                <button className="btn text-white btn-lg" 
                                                    type="submit" 
                                                    style={{background: "#0076B8"}}
                                                    onClick={this.checkDiscount}
                                                >
                                                    Aplicar
                                                </button>
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            }
                                <div className="mt-4" style={{width: "60%", marginLeft: "auto", marginRight: "auto"}}>
                                    <img className="img-fluid"
                                        src={config.navBar.icono2Login}
                                        alt="Segundo NavBar"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className="card mb-4" style={{
                            borderColor: 'white',  borderRadius: 20,
                            backgroundColor: config.navBar.backgroundColor,
                            border: '1px solid #ADADAD'
                             }}>
                            <div className="card-header text-white"
                                 style={{background: config.shoppingList.summaryList, borderTopLeftRadius: 20, borderTopRightRadius: 20, borderBottom: "none"}}>
                                <div className="card-title">
                                    <h3 style={{color: config.shoppingList.textsummaryList}}>Detalles:</h3>
                                </div>
                            </div>

                            <div className="card-body">
                                {this.renderShoppingCartDetail()}
                            </div>
                        </div> */}
                        <h6>* El pedido incluye la entrega si es por un mínimo de $1,500 más I.V.A. 
                            en zona metropolitana de CDMX, Monterrey, Guadalajara, Puebla, Querétaro, 
                            León, Cancún y Culiacán.</h6>
                        <h6>* Los pedidos fuera de estas zonas tendrán un costo extra por concepto 
                            de envío favor de contactar a Atención a Clientes de la sucursal más cercana.</h6>
                        <h6>* El tiempo de entrega será en un plazo no mayor a 48 hrs.</h6>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state,props) => {
    return {
        itemsReducer: state.ItemsReducer,
        sessionReducer: state.SessionReducer,
        configReducer: state.ConfigReducer,
        notificationReducer: state.NotificationReducer,
        shoppingCartReducer: state.ShoppingCartReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
        setGetShoppingCartReference: value => dispatch({type: DISPATCH_ID.SHOPPING_CART_GET_SHOPPING_CART_REFERENCE, value}),
        setShoppingCart: value => dispatch({type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value}),
        setItemsGift: value => dispatch(
            {
                type: DISPATCH_ID.SHOPPING_CART_ITEMS_GIFT, 
                value
            }
            ),
    };
};



export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ShoppingCartView);