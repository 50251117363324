import React, { Component } from 'react';
import { SERVICE_RESPONSE, DISPATCH_ID, config, ROLES} from "../libs/utils/Const";
import $, { isNumeric } from 'jquery';
import {ApiClient} from "../libs/apiClient/ApiClient";
import {connect} from "react-redux";
let apiClient = ApiClient.getInstance();

class DejanosMensajeModal extends Component{

    constructor(props) {
        super(props);

        this.state = {
            articulo : '',
            cantidad : '',
            message : '',
            code : '',
            nombre : '',
            apellido : '',
            empresa : '',
            email : '',
            cp: '',
            estado : '',
            ciudad : '',
            telefono : '',
        };
    };

    componentDidMount() {
        this.closeAction();
    }

    closeConfirm = async () => {
        $('#dejanosMensajeModal').modal('hide');  
    }

    closeAction = () => {
        $('#dejanosMensajeModal').on('hidden.bs.modal', function () {
        });
    };

    handelChange = ({ target }) => {
        const { name, value } = target;
        this.setState({
            [name]: value
        });
    };

    getDataCP = async () => {
        const {notificationReducer: {showAlert}} = this.props;
        const { cp } = this.state;

        let estado1 = '';
        let ciudad1 = '';
        if(cp.length === 5){
            
            await apiClient.getInfoCP(cp).then( infoCP => {
                if(infoCP.status === SERVICE_RESPONSE.SUCCESS){
                    estado1 = infoCP.data.edo;
                    ciudad1 = infoCP.data.cd;
                }else{
                    estado1 = '';
                    ciudad1 = '';
                    showAlert({type: 'warning', message: "El código postal no es válido (2)"});
                }
            });
            this.setState({
                estado: estado1,
                ciudad: ciudad1,
            });
        }else{
            if(cp.length > 5){
                showAlert({type: 'warning', message: "El código postal no es válido (1)"});
            }
            
        }
    };

    SendMessage = async response => {
        const {enableSpinner, notificationReducer: {showAlert}, Usuario, datos} = this.props;
        const {articulo,cantidad,message,nombre,apellido,empresa,email,cp,estado,ciudad,telefono} = this.state;

        if (articulo.length <= 0) return showAlert({
            type: 'warning',
            message: 'El campo del artículo no puede ir vacío',
            timeOut: 0
        });
        if(!isNumeric(cantidad))return showAlert({
            type: 'warning',
            message: 'El campo de cantidad solo permite números',
            timeOut: 0
        });
        if (cantidad.length <= 0) return showAlert({
            type: 'warning',
            message: 'El campo de cantidad no puede ir vacío',
            timeOut: 0
        });
        if (message.length <= 0) return showAlert({
            type: 'warning',
            message: 'El campo de las especificaciones no puede ir vacío',
            timeOut: 0
        });
        if (nombre.length <= 0) return showAlert({
            type: 'warning',
            message: 'El campo del nombre no puede ir vacío',
            timeOut: 0
        });
        if (apellido.length <= 0) return showAlert({
            type: 'warning',
            message: 'El campo del apellido no puede ir vacío',
            timeOut: 0
        });
        if (empresa.length <= 0) return showAlert({
            type: 'warning',
            message: 'El campo de la empresa no puede ir vacío',
            timeOut: 0
        });
        if (email.length <= 0) return showAlert({
            type: 'warning',
            message: 'El campo del correo no puede ir vacío',
            timeOut: 0
        });
        if(!isNumeric(cp))return showAlert({
            type: 'warning',
            message: 'El campo del código postal solo permite números',
            timeOut: 0
        });
        if (cp.length <= 0) return showAlert({
            type: 'warning',
            message: 'El campo del código postal no puede ir vacío',
            timeOut: 0
        });
        if (estado.length <= 0) return showAlert({
            type: 'warning',
            message: 'El campo del estado no puede ir vacío',
            timeOut: 0
        });
        if (ciudad.length <= 0) return showAlert({
            type: 'warning',
            message: 'El campo de la ciudad no puede ir vacío',
            timeOut: 0
        });
        if(!isNumeric(telefono))return showAlert({
            type: 'warning',
            message: 'El campo del teléfono solo permite números',
            timeOut: 0
        });
        if (telefono.length <= 0) return showAlert({
            type: 'warning',
            message: 'El campo del teléfono no puede ir vacío',
            timeOut: 0
        });
        enableSpinner(true);

        let data = {
            articulo : articulo,
            cantidad : cantidad,
            message : message,
            nombre : nombre,
            apellido : apellido,
            empresa : empresa,
            email : email,
            cp : cp,
            estado : estado,
            ciudad : ciudad,
            telefono : telefono,      
        };
        let apiResponse = await apiClient.sendMessage(data);
        if (apiResponse.status === SERVICE_RESPONSE.SUCCESS) {
            enableSpinner(false);
            showAlert({type: 'success', message: apiResponse.message});
            this.setState({
                articulo: '',
                cantidad: '',
                message: '',
                nombre: '',
                apellido: '',
                empresa: '',
                email: '',
                telefono: '',
                estado: '',
                ciudad: '',
            })
            $('#dejanosMensajeModal').modal('hide');
            return;
        }

        showAlert({type: 'error', message: "Aviso: "+apiResponse.message});
        enableSpinner(false)
    };

    render() {
        const {Usuario} = this.props;
        const {articulo,cantidad,message,nombre,apellido,empresa,email,telefono,estado,ciudad,cp} = this.state;
        return (
                <div className="modal fade bd-example-modal-lg" tabIndex="-1" role="dialog" id="dejanosMensajeModal" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header" style={{background: config.navBar.iconColor, color: '#FFFFFF'}}>
                                <h5 className="modal-title">COTIZADOR</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div className="form-group">
                                        <label style={{fontWeight: "bold"}}>Información del artículo</label>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="articulo">ARTICULO</label>
                                            <input type="text" className="form-control" name="articulo" id="articulo" onChange={this.handelChange} autoComplete="off" value={articulo}/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="cantidad">CANTIDAD DE PIEZAS</label>
                                            <input type="text" className="form-control" name="cantidad" id="cantidad" onChange={this.handelChange} autoComplete="off" value={cantidad}/>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="message">ESPECIFICACIONES</label>
                                        <textarea className="form-control" name="message" id="message" rows="5" onChange={this.handelChange} autoComplete="off" value={message}></textarea>
                                    </div>
                                    <div className="form-group">
                                        <label style={{fontWeight: "bold"}}>Información de contacto</label>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="nombre">NOMBRE</label>
                                            <input type="text" className="form-control" name="nombre" id="nombre" onChange={this.handelChange} autoComplete="off" value={nombre}/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="apellido">APELLIDO</label>
                                            <input type="text" className="form-control" name="apellido" id="apellido" onChange={this.handelChange} autoComplete="off" value={apellido}/>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-6">
                                            <label htmlFor="empresa">NOMBRE DE EMPRESA O NEGOCIO PROPIO</label>
                                            <input type="text" className="form-control" name="empresa" id="empresa" onChange={this.handelChange} autoComplete="off" value={empresa}/>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label htmlFor="email">CORREO ELECTRÓNICO</label>
                                            <input type="email" className="form-control" name="email" id="email" onChange={this.handelChange} autoComplete="off" value={email}/>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col-md-3">
                                            <label htmlFor="cp">CÓDIGO POSTAL</label>
                                            <input type="text" className="form-control" name="cp" id="cp" 
                                                onChange={(event) => {
                                                    this.handelChange(event);
                                                    setTimeout(() => {
                                                        this.getDataCP()
                                                    }, 250);
                                                }} autoComplete="off" value={cp}/>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="estado">ESTADO</label>
                                            <input type="text" className="form-control" name="estado" id="estado" onChange={this.handelChange} autoComplete="off" value={estado}/>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="ciudad">CIUDAD</label>
                                            <input type="text" className="form-control" name="ciudad" id="ciudad" onChange={this.handelChange} autoComplete="off" value={ciudad}/>
                                        </div>
                                        <div className="form-group col-md-3">
                                            <label htmlFor="telefono">TELÉFONO</label>
                                            <input type="text" className="form-control" name="telefono" id="telefono" onChange={this.handelChange} autoComplete="off" value={telefono} />
                                        </div>
                                    </div>
                                    {/* <input type="text" className="form-control" name="code" id="code" onChange={this.handelChange} autoComplete="off" value={Usuario.CardCode ? Usuario.CardCode : code} disabled={Usuario.CardCode ? true : false}/> */}
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn" style={{ fontWeight: "bold", background: config.navBar.iconColor, color: '#FFFFFF'}} onClick={this.SendMessage}> Enviar </button>
                            </div>
                        </div>
                    </div>
                </div>
        );  
    }
}

const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(DejanosMensajeModal);