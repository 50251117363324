import React, {Component} from 'react';
import {Footer, NavBar, Session, ProfieldModel, DocumentModel} from "../../components";
import {DISPATCH_ID, SERVICE_API, SERVICE_RESPONSE, VIEW_NAME, config} from "../../libs/utils/Const";
import {ApiClient} from "../../libs/apiClient/ApiClient";
import {connect} from "react-redux";
import moment from 'moment';
import $ from 'jquery';
import OrdersView from '../orders/OrdersView';
import BillView from '../bill/billingViwes';
import AbstractView from '../abstract/AbstractView';
import Quatiton from '../quotation/quotationViews';
import Delivery from '../delivery/deliveryViews';
import Saveds from '../saved/savedViews';
import Preliminary from '../preliminary/preliminaryViews';
import Collection from '../colletion/collectionViews';
import Overdue from '../overdue/overdueViews';
import {CSVLink, CSVDownload} from "react-csv";
import * as XLSX from 'xlsx';
import './reports.css';

let apiClient = ApiClient.getInstance();

class reportsView extends Component {
    csvLink = React.createRef();
    constructor(props) {
        super(props);
        const f = new Date;
        const newDate = new Date(f.setMonth(f.getMonth() + +(-3)))
        this.state = {
            orders: [],
            order: {
                header: {},
                body: []
            },
            hoja: "",
            dataCsv : [],
            hojas:[],
            file: false,
            items: {
                header: {},
                body: []
            },
            usuario: '',
            data: [],
            fechaInicio: '',
            fechaFinal: '',
            overdueOne: [],
            opcion: 0,
            reportsData : [],
            fechamin: moment(newDate).format('YYYY-MM-DD'),
            fechamax: moment(new Date()).format('YYYY-MM-DD'),
            isLoaded : false,
        };
    }
    
    async componentDidMount() {
        const {enableSpinner} = this.props;
        enableSpinner(true);
        let response = await apiClient.getDataProfiel();
        try {
            let creatorUser = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser'));
            this.setState({
                usuario: creatorUser
            });
        } catch (error) {
            
        }
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.SUCCESS) {
            this.setState({
                order: response.data,
            })
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        let fechaInicio = moment(new Date()).format('YYYY-MM-DD');
        let fechaFinal = moment(new Date()).format('YYYY-MM-DD');
        this.setState({
            fechaInicio,
            fechaFinal
        });
    };

    async handleInputChange (event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        const this2 = this
        this.setState({
            [name]: value
        })
        let hojas = []
        if (name === 'file') {
            let reader = new FileReader()
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = (e) => {
                var data = new Uint8Array(e.target.result);
                var workbook = XLSX.read(data, {type: 'array'});
                workbook.SheetNames.forEach(function(sheetName) {
                    // Here is your object
                    var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                    hojas.push({
                        data: XL_row_object,
                        sheetName
                    })
                });
                this2.setState({
                    selectedFileDocument: target.files[0],
                    hojas
                });
                if(hojas[0]){
                    this.openDetail(hojas[0]);
                }
            }
        }
    };

    openDetail = async docEntry => {
        const {enableSpinner, notificationReducer: {showAlert}} = this.props;
        enableSpinner(true);
        //console.log("las hojas",docEntry);
        let response = await apiClient.getDataDocuemt(docEntry);
        enableSpinner(false);

        if (response.status === SERVICE_RESPONSE.SUCCESS) {
            this.setState({
                items: response.data,
            });
            $('#docuementModal').modal('show');
            $("#file").val(null);
            return;
        }

        showAlert({type: 'error', message: response.message})
    };

    openOrder = () => {
        const {enableSpinner, notificationReducer: {showAlert}} = this.props;
        enableSpinner(true);
        $('#dataProfiel').modal('show');
        enableSpinner(false);
        return;
    };

    onClickGetData = async (opcion)=>{
        let fechaInicio = moment(new Date()).format('YYYY-MM-DD')
        let fechaFinal = moment(new Date()).format('YYYY-MM-DD')
        this.setState({
            fechaInicio,
            fechaFinal,
            data:[],
            isLoaded : false
        });
        setTimeout(async  () => {
            await this.getData(opcion);
        },200);
    };

    getData = async (opcion) =>{
        const {enableSpinner,notificationReducer: {showAlert}} = this.props;
        const {fechaInicio,fechaFinal, fechamin} = this.state;
        let response =[];
        enableSpinner(true);
        if(opcion === 1){
            response = await apiClient.getSaveds(!this.state.isLoaded ? fechamin : fechaInicio,fechaFinal);
        }else if(opcion === 2){
            response = await apiClient.getPreliminarys(!this.state.isLoaded ? fechamin : fechaInicio,fechaFinal);
        }
        // if(opcion === 1){
        //     response = await apiClient.getOrders(fechaInicio,fechaFinal);
        // }else if(opcion === 2){
        //     response = await apiClient.getPreliminarys(fechaInicio,fechaFinal);
        // }
        else if(opcion === 3){
            response = await apiClient.getBills(!this.state.isLoaded ? fechamin : fechaInicio,fechaFinal);
        } else if(opcion === 4){
            response = await apiClient.getOverdueOne();
        } else if(opcion === 5){
            response = await apiClient.getCollections(!this.state.isLoaded ? fechamin : fechaInicio,fechaFinal);
        }  else if(opcion === 6){
            response = await apiClient.getDelivery(!this.state.isLoaded ? fechamin : fechaInicio,fechaFinal);
            //response = await apiClient.getCollections(fechaInicio,fechaFinal);
            // let creatorUser = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser'));
            // let data = {
            //     slpCode: creatorUser.salesPrson, 
            //     fechauno: moment(fechaInicio).format('YYYYMMDD') ,
            //     fechados: moment(fechaFinal).format('YYYYMMDD') ,
            // }
            // response = await apiClient.getDataResumen(data);
        }else if(opcion === 7){
            response = await apiClient.getQuotation(!this.state.isLoaded ? fechamin : fechaInicio,fechaFinal);
            
        }
        enableSpinner(false);

        if (response.status != SERVICE_RESPONSE.SUCCESS) {
            showAlert({type: 'error', message: 'Ocurrió un error al consultar la Información'});
            return;
        }

        if (!Array.isArray(response.data)) {
            showAlert({type: 'error', message: 'Ocurrió un error al consultar la Información'});

        }

        if (response.data.length === 0) {
            showAlert({type: 'info', message: 'No hay Información por mostrar'});
            return;
        }
        // console.log("Lalito>", response);
        setTimeout(async  () => {
            this.setState({
                data : response.data || [],
                opcion,
            });
        },500);

    }

    handleInputDateInicio = event =>{
        let fechaInicio = event.nativeEvent.target.value;
        this.setState({
            isLoaded : true,
            fechaInicio
        });
    }

    handleInputDateFinal = event =>{
        let fechaFinal = event.nativeEvent.target.value;
        this.setState({
            fechaFinal
        });
    }

    generateCSV = async () => {
        let datacsv = [["","DESCRIPCIÓN","UNIDADES SOLICITADAS","CODIGO EAN","PTV-RINTI","desde"],
        ["","DESCRIPCIÓN","UNIDADES SOLICITADAS","CODIGO EAN","PTV-RINTI","desde"]]
        
        this.setState({dataCsv : datacsv})

    };

    render() {
        const {history} = this.props;
        const {order,items,dataCsv, usuario, data,fechaInicio,fechaFinal, fechamin, fechamax, isLoaded} = this.state;
        return (
            <div className="content-fluid reports" style={{marginTop: 150, paddingBottom: 20,paddingRight:0, backgroundColor: config.Back.backgroundColor}}>
                <Session history={history} view={VIEW_NAME.ABOUT_US_VIEW}/>
                <NavBar/>
                <ProfieldModel order={order}/>
                <DocumentModel order={items}/>

                <CSVLink 
                data={dataCsv}
                filename={"plantilla.csv"}
                className="hidden"
                ref={this.csvLink}
                target="_blank"/>
                
                <div className="content-fluid mx-3" >
                    <div className="row">
                        <div className="col-md-12 MargenSuperior">
                            <h2>Reportes</h2>
                        </div>
                        {/* <div className="col-sm-3" style={{cursor: 'pointer', color: 'blue'}} onClick={() => this.openOrder("147")}>
                            <i className={config.icons.detail} style={{color: config.shoppingList.summaryList, paddingRight: 6}}></i>
                            Información de la cuenta
                        </div> */}
                    </div>
                    {/* <div className="row">
                        <div className="col-sm-6">
                            <label>Subir desde un excel</label>
                            <input type="file" name="file" id="file" onChange={this.handleInputChange}  placeholder="Archivo de excel" className="form-control-file" ></input>
                        </div>
                        <div className="col-sm-6">

                        </div>
                    </div> */}
                    <div className="row p-2">
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                {/* <li className="nav-item">
                                    <a className="nav-link" id="quatations-tab" data-toggle="tab" href="#quatations" role="tab" aria-controls="quatations" onClick={() => this.onClickGetData(7)} aria-selected="false">Oferta de Venta</a>
                                </li> */}
                                <li className="nav-item">
                                    {/* <a className="nav-link active" id="pedido-tab" data-toggle="tab" href="#pedido" role="tab" aria-controls="pedido" onClick={() => this.onClickGetData(1)} aria-selected="true">Pedidos</a> */}
                                    <a className="nav-link active" id="pedido-tab" data-toggle="tab" href="#pedido" role="tab" aria-controls="pedido" aria-selected="true">Pedidos</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" id="preliminar-tab" data-toggle="tab" href="#preliminar" role="tab" aria-controls="preliminar" onClick={() => this.onClickGetData(2)} aria-selected="false">Preliminares</a>
                                </li> */}
                                <li className="nav-item">
                                    <a className="nav-link" id="entrega-tab" data-toggle="tab" href="#entrega" role="tab" aria-controls="entrega" onClick={() => this.onClickGetData(6)} aria-selected="false">Entregas</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" id="guardados-tab" data-toggle="tab" href="#guardados" role="tab" aria-controls="guardados" onClick={() => this.onClickGetData(1)} aria-selected="false">Guardados</a>
                                </li> */}
                                <li className="nav-item">
                                    <a className="nav-link" id="factura-tab" data-toggle="tab" href="#factura" role="tab" aria-controls="factura" onClick={() => this.onClickGetData(3)} aria-selected="false">Facturas</a>
                                </li>
                                {/* <li className="nav-item">
                                    <a className="nav-link" id="vencidas-tab" data-toggle="tab" href="#vencidas" role="tab" aria-controls="vencidas" onClick={() => this.onClickGetData(4)} aria-selected="false">Facturas vencidas</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="cobranza-tab" data-toggle="tab" href="#cobranza" role="tab" aria-controls="cobranza" onClick={() => this.onClickGetData(5)} aria-selected="false">Cobranza</a>
                                </li> */}
                                {/* {
                                    usuario.U_FMB_Handel_Perfil === "2" && */}
                                    {/* <li className="nav-item">
                                        <a className="nav-link" id="resumen-tab" data-toggle="tab" href="#resumen" role="tab" aria-controls="resumen" aria-selected="false">Resumen</a>
                                    </li> */}
                                {/* } */}
                                
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="pedido" role="tabpanel" aria-labelledby="pedido-tab">
                                    {/* <OrdersView 
                                    orders={data} 
                                    getData = {this.getData} 
                                    handleInputDateInicio = {this.handleInputDateInicio} 
                                    handleInputDateFinal = {this.handleInputDateFinal} 
                                    fechaInicio = {fechaInicio} 
                                    fechaFinal = {fechaFinal}/> */}
                                    <OrdersView />

                                </div> 
                                <div className="tab-pane fade" id="quatations" role="tabpanel" aria-labelledby="quatations-tab">
                                    <Quatiton
                                        orders={data} 
                                        getData = {this.getData} 
                                        handleInputDateInicio = {this.handleInputDateInicio} 
                                        handleInputDateFinal = {this.handleInputDateFinal} 
                                        fechaInicio = {fechaInicio} 
                                        fechaFinal = {fechaFinal}
                                        fechamin = {fechamin}
                                        fechamax = {fechamax}
                                        isLoaded = {isLoaded}/>
                                </div>
                                <div className="tab-pane fade" id="factura" role="tabpanel" aria-labelledby="factura-tab">
                                    <BillView
                                        orders={data} 
                                        getData = {this.getData} 
                                        handleInputDateInicio = {this.handleInputDateInicio} 
                                        handleInputDateFinal = {this.handleInputDateFinal} 
                                        fechaInicio = {fechaInicio} 
                                        fechaFinal = {fechaFinal}
                                        fechamin = {fechamin}
                                        fechamax = {fechamax}
                                        isLoaded = {isLoaded}/>
                                </div>
                                {/* <div className="tab-pane fade" id="cotizacion" role="tabpanel" aria-labelledby="cotizacion-tab">
                                    <Quatiton/>
                                </div> */}
                                <div className="tab-pane fade" id="entrega" role="tabpanel" aria-labelledby="entrega-tab">
                                    <Delivery
                                        orders={data}
                                        getData = {this.getData}
                                        handleInputDateInicio = {this.handleInputDateInicio}
                                        handleInputDateFinal = {this.handleInputDateFinal}
                                        fechaInicio = {fechaInicio}
                                        fechaFinal = {fechaFinal}
                                        fechamin = {fechamin}
                                        fechamax = {fechamax}
                                        isLoaded = {isLoaded}/>
                                </div>
                                {/* <div className="tab-pane fade" id="guardados" role="tabpanel" aria-labelledby="guardados-tab">
                                    <Saveds
                                    orders={data}
                                    getData = {this.getData} 
                                    handleInputDateInicio = {this.handleInputDateInicio} 
                                    handleInputDateFinal = {this.handleInputDateFinal} 
                                    fechaInicio = {fechaInicio} 
                                    fechaFinal = {fechaFinal}
                                    fechamin = {fechamin}
                                    fechamax = {fechamax}
                                    isLoaded = {isLoaded}/>
                                </div> */}
                                <div className="tab-pane fade" id="preliminar" role="tabpanel" aria-labelledby="preliminar-tab">
                                    <Preliminary
                                        orders={data} 
                                        getData = {this.getData} 
                                        handleInputDateInicio = {this.handleInputDateInicio} 
                                        handleInputDateFinal = {this.handleInputDateFinal} 
                                        fechaInicio = {fechaInicio} 
                                        fechaFinal = {fechaFinal}
                                        fechamin = {fechamin}
                                        fechamax = {fechamax}
                                        isLoaded = {isLoaded}/>
                                </div>
                                {/* <div className="tab-pane fade" id="preliminar" role="tabpanel" aria-labelledby="preliminar-tab">
                                    <Preliminary/>
                                </div> */}
                                <div className="tab-pane fade" id="cobranza" role="tabpanel" aria-labelledby="cobranza-tab">
                                    <Collection
                                     orders={data} 
                                     getData = {this.getData} 
                                     handleInputDateInicio = {this.handleInputDateInicio} 
                                     handleInputDateFinal = {this.handleInputDateFinal} 
                                     fechaInicio = {fechaInicio} 
                                     fechaFinal = {fechaFinal}
                                     fechamin = {fechamin}
                                     fechamax = {fechamax}
                                     isLoaded = {isLoaded}/>
                                </div>
                                <div className="tab-pane fade" id="vencidas" role="tabpanel" aria-labelledby="vencidas-tab">
                                    <Overdue  overdueOne={data} getData = {this.getData} />
                                </div>
                                {/* {
                                    usuario.U_FMB_Handel_Perfil === "2" && */}
                                    <div className="tab-pane fade" id="resumen" role="tabpanel" aria-labelledby="resumen-tab">
                                        <AbstractView
                                            handleInputDateInicio = {this.handleInputDateInicio} 
                                            handleInputDateFinal = {this.handleInputDateFinal} 
                                            fechaInicio = {fechaInicio} 
                                            fechaFinal = {fechaFinal}/>
                                    </div>
                                {/* } */}
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(reportsView);