import React, {Component} from 'react';
import {Footer, NavBar, Session} from "../../components";
import {config, DISPATCH_ID, ROLES, VIEW_NAME, SERVICE_RESPONSE} from "../../libs/utils/Const";
import {connect} from "react-redux";
import {ApiClient} from "../../libs/apiClient/ApiClient";
import { SessionReducer } from '../../redux/reducers/SessionReducer';

const apiClient = ApiClient.getInstance();

class SelectAddressView extends Component {

    state = {
        showAddress: false,
        showBill: false,
        addressType: '',
        address: {
            codeOrigin: '',
            address: '',
            name: '',
            email: '',
            phone: '',
            street: '',
            suburb: '',
            city: '',
            cp: '',
            country: '',
            countryName: '',
            state: '',
            stateName: '',
            typeOfAddress: '',
        },
        bill: {
            codeOrigin: '',
            address: '',
            name: '',
            email: '',
            phone: '',
            street: '',
            suburb: '',
            city: '',
            cp: '',
            country: '',
            countryName: '',
            state: '',
            stateName: '',
            taxID: '',
            cfdi:'',
            typeOfAddress: '',
        },
        addressKeySelect: '',
        addressKeyEnabled: true,
        billKeySelect: '',
        billKeyEnabled: true,
        paymentMethodSelect: '',
        paymentMethodEnabled: true,
        countries: [],
        cfdis: [],
        states: [],
    };

    async componentDidMount() {
        const {enableSpinner, notificationReducer: {showAlert}} = this.props;

        enableSpinner(true);
        let response = await apiClient.getCountries();
        let responseCFDI = await apiClient.getCFDI();
        enableSpinner(false);

        this.setState({
            countries: response.data || [],
            cfdis: responseCFDI.data || [],
        });
    }

    getStates = async () => {
        const {enableSpinner, notificationReducer: {showAlert}} = this.props;
        const {address: {country}} = this.state;
        enableSpinner(true);
        let response = await apiClient.getStates(country);

        this.setState({
            states: response.data || [],
        });

        setTimeout(() => {
            enableSpinner(false);
        }, 100)
    };
    getStatesBill = async () => {
        const {enableSpinner, notificationReducer: {showAlert}} = this.props;
        const {bill: {country}} = this.state;
        enableSpinner(true);
        let response = await apiClient.getStates(country);

        this.setState({
            states: response.data || [],
        });

        setTimeout(() => {
            enableSpinner(false);
        }, 100)
    };

    setAddressKey = (key, value, value2 = '') => {
        let {address, countries, states} = this.state;
        address[key] = value;

        if (key === 'country') {
            countries.forEach(country => {
                if (country.Code === value) {
                    address.countryName = country.Name;
                }
            });
        }

        if (key === 'state') {
            states.forEach(state => {
                if (state.Code === value) {
                    address.stateName = state.Name;
                }
            });
        }

        this.setState({
            address
        })
    };

    setBillKey = (key, value, value2 = '') => {
        let {bill, countries, states, cfdis} = this.state;
        bill[key] = value;

        if (key === 'country') {
            countries.forEach(country => {
                if (country.Code === value) {
                    bill.countryName = country.Name;
                }
            });
        }

        if (key === 'state') {
            states.forEach(state => {
                if (state.Code === value) {
                    bill.stateName = state.Name;
                }
            });
        }

        if (key === 'cfdi') {
            cfdis.forEach(cfdi => {
                if (cfdi.CfdiCode === value) {
                    bill.cfdi = cfdi.CfdiCode;
                }
            });
        }

        this.setState({
            bill
        })
    };

    saveAddress = async () => {
        const {enableSpinner, setAddresses, sessionReducer: {addresses,user}, notificationReducer: {showAlert}} = this.props;
        const {address} = this.state;

        let newAddresses = [];

        let addressFilter = {};

        addressFilter.Address = address.address;
        addressFilter.Street = address.street;
        addressFilter.City = address.city;
        addressFilter.Country = address.country;
        addressFilter.CountryName = address.countryName;
        addressFilter.ZipCode = address.cp;
        addressFilter.State = address.state;
        addressFilter.StateName = address.stateName;
        addressFilter.Block = address.suburb;
        addressFilter.AdresType = address.typeOfAddress;

        for (let i = 0; i < addresses.length; i++) {
            if (addresses[i].Address !== address.codeOrigin) {
                if(addresses[i].AdresType === "B"){
                    addresses[i].TaxID = user.rfc; 
                }
                newAddresses.push(addresses[i]);
            }
        }

        newAddresses.push(addressFilter);
        //console.log("Send data of edit",newAddresses);

        enableSpinner(true);
        let response = await apiClient.updateAddress(newAddresses);
        enableSpinner(false);

        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({type: 'error', message: response.message, timeOut: 5000});
            return;
        }

        setAddresses(newAddresses);
        this.cancelAddress();

    };

    saveBill = async () => {
        const {enableSpinner, setAddresses, sessionReducer: {addresses, user}, notificationReducer: {showAlert}} = this.props;
        const {bill} = this.state;

        let newAddresses = [];

        let addressFilter = {};

        addressFilter.Address = bill.address;
        addressFilter.Street = bill.street;
        addressFilter.City = bill.city;
        addressFilter.Country = bill.country;
        addressFilter.CountryName = bill.countryName;
        addressFilter.ZipCode = bill.cp;
        addressFilter.State = bill.state;
        addressFilter.StateName = bill.stateName;
        addressFilter.Block = bill.suburb;
        if (bill.taxID === ""){
            addressFilter.TaxID = user.rfc;
        }else{
            addressFilter.TaxID = bill.taxID;
        }

        if (bill.cfdi === ""){
            addressFilter.cfdi = user.cfdi;
        }else{
            addressFilter.cfdi = bill.cfdi;
        }

        addressFilter.AdresType = bill.typeOfAddress;

        for (let i = 0; i < addresses.length; i++) {
            if (addresses[i].Address !== bill.codeOrigin) {
                newAddresses.push(addresses[i]);
            }
        }

        newAddresses.push(addressFilter);
        //console.log("Send data of edit",newAddresses);

        enableSpinner(true);
        let response = await apiClient.updateAddress(newAddresses);
        enableSpinner(false);

        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({type: 'error', message: response.message, timeOut: 5000});
            return;
        }

        setAddresses(newAddresses);
        this.cancelBill();

    };

    createAddress = async () => {
        const {enableSpinner, setAddresses, sessionReducer: {addresses,user}, notificationReducer: {showAlert}} = this.props;
        const {address} = this.state;

        let newAddresses = [];

        let addressFilter = {};

        addressFilter.Address = address.address;
        addressFilter.Street = address.street;
        addressFilter.City = address.city;
        addressFilter.Country = address.country;
        addressFilter.CountryName = address.countryName;
        addressFilter.ZipCode = address.cp;
        addressFilter.State = address.state;
        addressFilter.StateName = address.stateName;
        addressFilter.Block = address.suburb;
        addressFilter.AdresType = "S";

        for (let i = 0; i < addresses.length; i++) {
            if (addresses[i].Address !== address.codeOrigin) {
                if(addresses[i].AdresType === "B"){
                    addresses[i].TaxID = user.rfc; 
                }
                newAddresses.push(addresses[i]);
            }
        }

        newAddresses.push(addressFilter);

        enableSpinner(true);
        let response = await apiClient.updateAddress(newAddresses);
        enableSpinner(false);

        //console.log(response);

        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({type: 'error', message: response.message, timeOut: 5000});
            return;
        }

        setAddresses(newAddresses);
        this.cancelAddress();
        this.cancelBill();
    };
    createBill = async () => {
        const {enableSpinner, setAddresses, sessionReducer: {addresses,user}, notificationReducer: {showAlert}} = this.props;
        const {bill} = this.state;

        let newAddresses = [];

        let addressFilter = {};

        addressFilter.Address = bill.address;
        addressFilter.Street = bill.street;
        addressFilter.City = bill.city;
        addressFilter.Country = bill.country;
        addressFilter.CountryName = bill.countryName;
        addressFilter.ZipCode = bill.cp;
        addressFilter.State = bill.state;
        addressFilter.StateName = bill.stateName;
        addressFilter.Block = bill.suburb;
        if (bill.taxID === ""){
            addressFilter.TaxID = user.rfc;
        }else{
            addressFilter.TaxID = bill.taxID;
        }
        if (bill.cfdi === ""){
            addressFilter.cfdi = user.cfdi;
        }else{
            addressFilter.cfdi = bill.cfdi;
        }
        addressFilter.AdresType = "B";

        for (let i = 0; i < addresses.length; i++) {
            if (addresses[i].Address !== bill.codeOrigin) {
                newAddresses.push(addresses[i]);
            }
        }

        newAddresses.push(addressFilter);

        enableSpinner(true);
        let response = await apiClient.updateAddress(newAddresses);
        enableSpinner(false);

        //console.log(response);

        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({type: 'error', message: response.message, timeOut: 5000});
            return;
        }

        setAddresses(newAddresses);
        this.cancelBill();
    };

    cancelAddress = () => {
        const {address} = this.state;
        address.codeOrigin = '';
        address.address = '';
        address.street = '';
        address.city = '';
        address.country = '';
        address.countryName = '';
        address.cp = '';
        address.state = '';
        address.stateName = '';
        address.suburb = '';

        this.setState({
            address,
            showAddress: false,
            addressType: '',
            states: [],
        })
    };
    cancelBill = () => {
        const {bill} = this.state;
        bill.codeOrigin = '';
        bill.address = '';
        bill.street = '';
        bill.city = '';
        bill.country = '';
        bill.countryName = '';
        bill.cp = '';
        bill.state = '';
        bill.stateName = '';
        bill.suburb = '';

        this.setState({
            bill,
            showBill: false,
            addressType: '',
            states: [],
        })
    };
    renderFormNewAddress = () => {
        const {sessionReducer: {role}} = this.props;
        const {address, countries, states, addressType} = this.state;

        let title = '';
        let action = '';

        if (addressType === 'save') {
            title = 'Editar';
            action = this.saveAddress;
        }

        if (addressType === 'new') {
            title = 'Nueva';
            action = this.createAddress;
        }

        return <div className='row' style={{margin: '0px auto', marginTop: 50}}>
            <div className="col-md-12">
                <div className="col-md-12">
                    <h2>{title + ' '}dirección de envío</h2>
                </div>
                {role === ROLES.CLIENT && <div className="input-group">
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.pin} style={{color: config.navBar.iconColor}}/></span>
                    </div>
                    {addressType === "new" ?
                        <input
                            type="text"
                            className="form-control text-left"
                            name="handel_calle_register"
                            placeholder="Nombre de la dirección"
                            autoComplete={'new-id'}
                            style={{textAlign: 'center', height: 30}}
                            value={address.address}
                            onChange={(event) => this.setAddressKey('address', event.target.value)}
                        />:
                        <input
                            type="text"
                            className="form-control text-left"
                            name="handel_calle_register"
                            placeholder="Nombre de la dirección"
                            autoComplete={'new-id'}
                            style={{textAlign: 'center', height: 30}}
                            value={address.address}
                            onChange={(event) => this.setAddressKey('address', event.target.value)}
                            disabled
                        />
                    }
                </div>}
                {role === ROLES.PUBLIC && <div className="input-group">
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.user} style={{color: config.navBar.iconColor}}/></span>
                    </div>
                    <input
                        type="text"
                        className="form-control text-left"
                        name="handel_calle_register"
                        placeholder="Nombre del comprador"
                        autoComplete={'new-id'}
                        style={{textAlign: 'center', height: 30}}
                        value={address.name}
                        onChange={(event) => this.setAddressKey('name', event.target.value)}
                    />
                </div>}
                {role === ROLES.PUBLIC && <div className="input-group" style={{marginTop: 10}}>
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.envelope} style={{color: config.navBar.iconColor}}/></span>
                    </div>
                    <input
                        type="text"
                        className="form-control text-left"
                        name="handel_calle_register"
                        placeholder="Correo del comprador"
                        autoComplete={'new-id'}
                        style={{textAlign: 'center', height: 30}}
                        value={address.email}
                        onChange={(event) => this.setAddressKey('email', event.target.value)}
                    />
                </div>}
                {role === ROLES.PUBLIC && <div className="input-group" style={{marginTop: 10}}>
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.phone} style={{color: config.navBar.iconColor}}/></span>
                    </div>
                    <input
                        type="text"
                        className="form-control text-left"
                        name="handel_calle_register"
                        placeholder="Teléfono del comprador"
                        autoComplete={'new-id'}
                        style={{textAlign: 'center', height: 30}}
                        value={address.phone}
                        onChange={(event) => this.setAddressKey('phone', event.target.value)}
                    />
                </div>}
                <div className="input-group" style={{marginTop: 10}}>
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.road} style={{color: config.navBar.iconColor}}/></span>
                    </div>
                    <input
                        type="text"
                        className="form-control text-left"
                        name="handel_street_register"
                        placeholder="Calle"
                        style={{textAlign: 'center', height: 30}}
                        autoComplete={'new-street'}
                        value={address.street}
                        onChange={(event) => this.setAddressKey('street', event.target.value)}
                    />
                </div>
                <div className="input-group" style={{marginTop: 10}}>
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.map} style={{color: config.navBar.iconColor}}/></span>
                    </div>
                    <input
                        type="text"
                        className="form-control text-left"
                        name="handel_suburb_register"
                        placeholder="Colonia"
                        autoComplete={'new-suburb'}
                        style={{textAlign: 'center', height: 30}}
                        value={address.suburb}
                        onChange={(event) => this.setAddressKey('suburb', event.target.value)}
                    />
                </div>
                <div className="input-group" style={{marginTop: 10}}>
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.city} style={{color: config.navBar.iconColor}}/></span>
                    </div>
                    <input
                        type="text"
                        className="form-control text-left"
                        name="handel_city_register"
                        placeholder="Ciudad"
                        autoComplete={'new-register'}
                        style={{textAlign: 'center', height: 30}}
                        value={address.city}
                        onChange={(event) => this.setAddressKey('city', event.target.value)}
                    />
                </div>
                <div className="input-group" style={{marginTop: 10}}>
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.envelope} style={{color: config.navBar.iconColor}}/></span>
                    </div>
                    <input
                        type="text"
                        className="form-control text-left"
                        name="handel_cp_register"
                        placeholder="Código postal"
                        autoComplete={'new-cp_register'}
                        style={{textAlign: 'center', height: 30}}
                        value={address.cp}
                        onChange={(event) => this.setAddressKey('cp', event.target.value)}
                    />
                </div>
                <div className="input-group" style={{marginTop: 10}}>
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.globalAmericas} style={{color: config.navBar.iconColor}}/></span>
                    </div>
                    <select id="cars" name="handel_country_register" autoComplete={'new_country_register'}
                            placeholder="País" value={address.country}
                            className="form-control text-left"
                            onChange={(event) => {
                                this.setAddressKey('country', event.target.value);
                                setTimeout(async () => {
                                    await this.getStates()
                                }, 100);
                            }}
                            style={{textAlign: 'center', height: 30, padding: 0}}>
                        <option value=''>Selecciona un país</option>
                        {countries.map(country => {
                            return <option value={country.Code}>{country.Name}</option>
                        })}
                    </select>

                </div>
                <div className="input-group" style={{marginTop: 10}}>
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.shield} style={{color: config.navBar.iconColor}}/></span>
                    </div>
                    <select id="cars" name="handel_state_register" autoComplete={'state_register'}
                            placeholder="País" value={address.state} className="form-control text-left"
                            onChange={(event) => this.setAddressKey('state', event.target.value)}
                            style={{textAlign: 'center', height: 30, padding: 0}}>
                        <option value=''>Selecciona un estado</option>
                        {states.map(state => {
                            return <option value={state.Code}>{state.Name}</option>
                        })}
                    </select>
                </div>
                <div className="row">
                    <div className="col-12">
                        {role === ROLES.CLIENT && <div className="input-group col-xs-4" style={{marginTop: 10}}>
                            <button
                                onClick={this.cancelAddress}
                                className="btn btn-block"
                                style={{
                                    backgroundColor: config.navBar.backgroundColor,
                                    color: config.navBar.textColor,
                                }}>
                                Cancelar
                            </button>
                        </div>}
                    </div>
                    <div className="col-12">
                        {role === ROLES.CLIENT && <div className="input-group col-xs-4" style={{marginTop: 10}}>
                            <button
                                onClick={action}
                                className="btn btn-primary btn-block"
                                style={{
                                    backgroundColor: '#005ead',
                                    color: config.navBar.textColor,
                                    fontWeight: "bold",
                                }}>
                                Guardar
                            </button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    };
    renderFormNewBill = () => {
        const {sessionReducer: {role, user}} = this.props;
        const {bill, countries, states, cfdis, addressType} = this.state;

        let title = '';
        let action = '';

        if (addressType === 'save') {
            title = 'Editar';
            action = this.saveBill;
        }

        if (addressType === 'new') {
            title = 'Nueva';
            action = this.createBill;
        }

        return <div className='row' style={{margin: '0px auto', marginTop: 50}} id='selectBillViewForm'>
            <div className="col-md-12">
                <div className="col-md-12">
                    <h2>{title + ' '}dirección de facturación</h2>
                </div>
                {role === ROLES.CLIENT && <div className="input-group">
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.pin} style={{color: config.navBar.iconColor}}/></span>
                    </div>
                    {addressType === "new" ?
                        <input
                            type="text"
                            className="form-control text-left"
                            name="handel_calle_register"
                            placeholder="Nombre de la dirección"
                            autoComplete={'new-id'}
                            style={{textAlign: 'center', height: 30}}
                            value={bill.address}
                            onChange={(event) => this.setBillKey('address', event.target.value)}
                        />:
                        <input
                            type="text"
                            className="form-control text-left"
                            name="handel_calle_register"
                            placeholder="Nombre de la dirección"
                            autoComplete={'new-id'}
                            style={{textAlign: 'center', height: 30}}
                            value={bill.address}
                            onChange={(event) => this.setBillKey('address', event.target.value)}
                            disabled
                        />
                    }
                </div>}
                {role === ROLES.PUBLIC && <div className="input-group">
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.user} style={{color: config.navBar.iconColor}}/></span>
                    </div>
                    <input
                        type="text"
                        className="form-control text-left"
                        name="handel_calle_register"
                        placeholder="Nombre del comprador"
                        autoComplete={'new-id'}
                        style={{textAlign: 'center', height: 30}}
                        value={bill.name}
                        onChange={(event) => this.setBillKey('name', event.target.value)}
                    />
                </div>}
                {role === ROLES.PUBLIC && <div className="input-group" style={{marginTop: 10}}>
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.envelope} style={{color: config.navBar.iconColor}}/></span>
                    </div>
                    <input
                        type="text"
                        className="form-control text-left"
                        name="handel_calle_register"
                        placeholder="Correo del comprador"
                        autoComplete={'new-id'}
                        style={{textAlign: 'center', height: 30}}
                        value={bill.email}
                        onChange={(event) => this.setBillKey('email', event.target.value)}
                    />
                </div>}
                {role === ROLES.PUBLIC && <div className="input-group" style={{marginTop: 10}}>
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.phone} style={{color: config.navBar.iconColor}}/>
                        </span>
                    </div>
                    <input
                        type="text"
                        className="form-control text-left"
                        name="handel_calle_register"
                        placeholder="Teléfono del comprador"
                        autoComplete={'new-id'}
                        style={{textAlign: 'center', height: 30}}
                        value={bill.phone}
                        onChange={(event) => this.setBillKey('phone', event.target.value)}
                    />
                </div>}
                <div className="input-group" style={{marginTop: 10}}>
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.user} style={{color: config.navBar.iconColor}}/>
                        </span>
                    </div>
                    <input
                        type="text"
                        className="form-control text-left"
                        name="handel_taxid_register"
                        placeholder="RFC"
                        autoComplete={'new-id'}
                        style={{textAlign: 'center', height: 30}}
                        value={bill.taxID || user.rfc}
                        onChange={(event) => this.setBillKey('taxID', event.target.value)}
                    />
                </div>
                <div className="input-group" style={{marginTop: 10}}>
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}>
                            <i className={config.icons.cfdi} style={{color: config.navBar.iconColor}}/>
                        </span>
                    </div>
                    <select id="cars" name="handel_country_register" autoComplete={'new_country_register'}
                            placeholder="País" value={bill.cfdi || user.cfdi}
                            className="form-control text-left"
                            onChange={(event) => {
                                this.setBillKey('cfdi', event.target.value);
                            }}
                            style={{textAlign: 'center', height: 30, padding: 0}}>
                        <option value=''>Selecciona el uso de su CFDI</option>
                        {cfdis.map(cdfi => {
                            return <option value={cdfi.CfdiCode} key={cdfi.CfdiCode}>{cdfi.CfdiCode +" - "+ cdfi.CfdiName}</option>
                        })}
                    </select>
                </div>
                <div className="input-group" style={{marginTop: 10}}>
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.road} style={{color: config.navBar.iconColor}}/></span>
                    </div>
                    <input
                        type="text"
                        className="form-control text-left"
                        name="handel_street_register"
                        placeholder="Calle"
                        style={{textAlign: 'center', height: 30}}
                        autoComplete={'new-street'}
                        value={bill.street}
                        onChange={(event) => this.setBillKey('street', event.target.value)}
                    />
                </div>
                <div className="input-group" style={{marginTop: 10}}>
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.map} style={{color: config.navBar.iconColor}}/></span>
                    </div>
                    <input
                        type="text"
                        className="form-control text-left"
                        name="handel_suburb_register"
                        placeholder="Colonia"
                        autoComplete={'new-suburb'}
                        style={{textAlign: 'center', height: 30}}
                        value={bill.suburb}
                        onChange={(event) => this.setBillKey('suburb', event.target.value)}
                    />
                </div>
                <div className="input-group" style={{marginTop: 10}}>
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.city} style={{color: config.navBar.iconColor}}/></span>
                    </div>
                    <input
                        type="text"
                        className="form-control text-left"
                        name="handel_city_register"
                        placeholder="Ciudad"
                        autoComplete={'new-register'}
                        style={{textAlign: 'center', height: 30}}
                        value={bill.city}
                        onChange={(event) => this.setBillKey('city', event.target.value)}
                    />
                </div>
                <div className="input-group" style={{marginTop: 10}}>
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.envelope} style={{color: config.navBar.iconColor}}/></span>
                    </div>
                    <input
                        type="text"
                        className="form-control text-left"
                        name="handel_cp_register"
                        placeholder="Código postal"
                        autoComplete={'new-cp_register'}
                        style={{textAlign: 'center', height: 30}}
                        value={bill.cp}
                        onChange={(event) => this.setBillKey('cp', event.target.value)}
                    />
                </div>
                <div className="input-group" style={{marginTop: 10}}>
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.globalAmericas} style={{color: config.navBar.iconColor}}/></span>
                    </div>
                    <select id="cars" name="handel_country_register" autoComplete={'new_country_register'}
                            placeholder="País" value={bill.country}
                            className="form-control text-left"
                            onChange={(event) => {
                                this.setBillKey('country', event.target.value);
                                setTimeout(async () => {
                                    await this.getStatesBill()
                                }, 100);
                            }}
                            style={{textAlign: 'center', height: 30, padding: 0}}>
                        <option value=''>Selecciona un país</option>
                        {countries.map(country => {
                            return <option key={country.Code} value={country.Code}>{country.Name}</option>
                        })}
                    </select>

                </div>
                <div className="input-group" style={{marginTop: 10}}>
                    <div className="input-group-prepend" style={{width: 40}}>
                        <span className="input-group-text" style={{background: config.navBar.backgroundColor}}><i
                            className={config.icons.shield} style={{color: config.navBar.iconColor}}/></span>
                    </div>
                    <select id="cars" name="handel_state_register" autoComplete={'state_register'}
                            placeholder="País" value={bill.state} className="form-control text-left"
                            onChange={(event) => this.setBillKey('state', event.target.value)}
                            style={{textAlign: 'center', height: 30, padding: 0}}>
                        <option value=''>Selecciona un estado</option>
                        {states.map(state => {
                            return <option key={state.Code} value={state.Code}>{state.Name}</option>
                        })}
                    </select>
                </div>
                <div className="row">
                    <div className="col-12">
                        {role === ROLES.CLIENT && <div className="input-group col-xs-4" style={{marginTop: 10}}>
                            <button
                                onClick={this.cancelBill}
                                className="btn btn-block"
                                style={{
                                    backgroundColor: config.navBar.backgroundColor,
                                    color: config.navBar.textColor,
                                }}>
                                Cancelar
                            </button>
                        </div>}
                    </div>
                    <div className="col-12">
                        {role === ROLES.CLIENT && <div className="input-group col-xs-4" style={{marginTop: 10}}>
                            <button
                                onClick={action}
                                className="btn btn-primary btn-block"
                                style={{
                                    backgroundColor:'#005ead',
                                    color: config.navBar.textColor,
                                    fontWeight: "bold",
                                }}>
                                Guardar
                            </button>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    };
    editAddress = addressValue => {
        const {address} = this.state;
        //console.log("dddd", addressValue);
        address.codeOrigin = addressValue.Address || '';
        address.address = addressValue.Address || '';
        address.street = addressValue.Street || '';
        address.city = addressValue.City || '';
        address.country = addressValue.Country || '';
        address.countryName = addressValue.CountryName || '';
        address.cp = addressValue.ZipCode || '';
        address.state = addressValue.State || '';
        address.stateName = addressValue.StateName || '';
        address.suburb = addressValue.Block || '';
        address.typeOfAddress = addressValue.AdresType || '';

        this.setState({
            address,
            showAddress: true,
            showBill: false,
            addressType: 'save',
        })

        setTimeout(async () => {
            await this.getStates();
        }, 50)
    };
    editBill = addressValue => {
        const {bill} = this.state;
        //console.log("dddd", addressValue);
        bill.codeOrigin = addressValue.Address || '';
        bill.address = addressValue.Address || '';
        bill.street = addressValue.Street || '';
        bill.city = addressValue.City || '';
        bill.country = addressValue.Country || '';
        bill.countryName = addressValue.CountryName || '';
        bill.cp = addressValue.ZipCode || '';
        bill.state = addressValue.State || '';
        bill.stateName = addressValue.StateName || '';
        bill.suburb = addressValue.Block || '';
        bill.typeOfAddress = addressValue.AdresType || '';

        this.setState({
            bill,
            showBill: true,
            showAddress: false,
            addressType: 'save',
        })

        setTimeout(async () => {
            await this.getStatesBill();
        }, 50)
    };
    newAddress = () => {
        const {address} = this.state;
        address.codeOrigin = '';
        address.address = '';
        address.street = '';
        address.city = '';
        address.country = '';
        address.countryName = '';
        address.cp = '';
        address.state = '';
        address.stateName = '';
        address.suburb = '';
        address.typeOfAddress = 'S';

        this.setState({
            address,
            showAddress: true,
            showBill: false,
            addressType: 'new',
            states: [],
        })
    };
    newBill = () => {
        const {bill} = this.state;
        bill.codeOrigin = '';
        bill.address = '';
        bill.street = '';
        bill.city = '';
        bill.country = '';
        bill.countryName = '';
        bill.cp = '';
        bill.state = '';
        bill.stateName = '';
        bill.suburb = '';
        bill.typeOfAddress = 'B';

        this.setState({
            bill,
            showBill: true,
            showAddress: false,
            addressType: 'new',
            states: [],
        })
    };

    renderClientAddress = () => {
        const {sessionReducer: {addresses}} = this.props;
        
        return <div className='container-fluid row' style={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div className="col-sm-12">
                <p  onClick={this.newAddress} style={{cursor: 'pointer', color: config.navBar.backgroundColor}}>
                    <i style={{marginRight: 5,  color:'#005ead'}} className={ config.icons.backOrderTrue}></i>
                    <span style={{fontSize: 16, color:'#005ead'}}>Agregar una nueva dirección de envío</span>
                </p>
            </div>
            {addresses.map(address => {
                if(address.AdresType === "S"){
                    return <div className="col-sm-12" key={address.Address}>
                        <div className="card text-center">
                            <div className="card-body">
                                <h5 className="card-title">
                                    <input type="radio" id={address.Address} name="address" value={address.Address} onClick={this.selectAddress} style={{marginRight: 5}}/>
                                    {address.Address}
                                </h5>
                                <p className="card-text">{address.Street + ' ' + address.Block + ' ' + address.City + ' ' + address.StateName + ' ' + address.CountryName + ' ' + address.ZipCode}</p>
                                <button className="btn btn-primary" style={{backgroundColor: config.navBar.iconColor,color: config.navBar.textColor,fontWeight: "bold"}} onClick={() => this.editAddress(address)}>
                                    Editar dirección
                                </button>
                            </div>
                        </div>
                    </div>
                }
            })}
        </div>
    };
    renderClientABill = () => {
        const {sessionReducer: {addresses, user}} = this.props;
        //console.log("Address",addresses,"user",user);
        return <div className='container-fluid row' style={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <div className="col-sm-12">
                <p  onClick={this.newBill} style={{cursor: 'pointer', color: config.navBar.backgroundColor}}>
                    <i style={{marginRight: 5, color:'#005ead'}} className={ config.icons.backOrderTrue}></i>
                    <span style={{fontSize: 16 ,color:'#005ead'}}>Agregar una nueva dirección de facturación</span>
                </p>
            </div>
            <div className="col-sm-12">
                <div className="card text-center">
                    <div className="card-body">
                        <h5 className="card-title">
                            <input type="radio" id="same" name="bill" value="same" onClick={this.selectBill} style={{marginRight: 5}}/>
                            Misma dirección
                        </h5>
                        <p className="card-text">
                            Usar la misma dirección de envío como dirección de facturacción
                        </p>
                    </div>
                </div>
            </div>

            {addresses.map(address => {
                if(address.AdresType === "B"){
                    return <div className="col-sm-12" key={address.Address}>
                        <div className="card text-center">
                            <div className="card-body">
                                <h5 className="card-title">
                                    <input type="radio" id={address.Address} name="bill" value={address.Address} onClick={this.selectBill} style={{marginRight: 5}}/>
                                    {address.Address}
                                </h5>
                                <p className="card-text">{address.Street + ' ' + address.Block + ' ' + address.City + ' ' + address.StateName + ' ' + address.CountryName + ' ' + address.ZipCode}</p>
                                <button className="btn btn-primary" style={{backgroundColor: config.navBar.iconColor,color: config.navBar.textColor,fontWeight: "bold"}} onClick={() => this.editBill(address)}>
                                    Editar dirección
                                </button>
                            </div>
                        </div>
                    </div>
                }
            })}
        </div>
    };

    selectAddress = event => {
        this.setState({
            showAddress: false,
            addressKeyEnabled: true,
            addressKeySelect: event.nativeEvent.target.value || '',
        })
    };
    selectBill = event => {
        this.setState({
            showBill: false,
            billKeyEnabled: true,
            billKeySelect: event.nativeEvent.target.value || '',
        })
    };

    validateForm = () => {
        const {notificationReducer: {showAlert}} = this.props;
        const {address, addressKeySelect} = this.state;

        if (!address.name) {
            showAlert({type: 'warning', message: 'Introduzca un nombre de comprador'});
            return false;
        }

        if (!address.email) {
            showAlert({type: 'warning', message: 'Introduzca un correo de comprador'});
            return false;
        }

        if (!address.phone) {
            showAlert({type: 'warning', message: 'Introduzca un teléfono de comprador'});
            return false;
        }

        if (!address.name) {
            showAlert({type: 'warning', message: 'Introduzca un nombre de comprador'});
            return false;
        }

        if (!address.street) {
            showAlert({type: 'warning', message: 'Introduzca una calle'});
            return false;
        }

        if (!address.suburb) {
            showAlert({type: 'warning', message: 'Introduzca una colonia'});
            return false;
        }

        if (!address.city) {
            showAlert({type: 'warning', message: 'Introduzca una ciudad'});
            return false;
        }

        if (!address.cp) {
            showAlert({type: 'warning', message: 'Introduzca un código postal'});
            return false;
        }

        if (!address.country) {
            showAlert({type: 'warning', message: 'Introduzca un país'});
            return false;
        }

        if (!address.state) {
            showAlert({type: 'warning', message: 'Introduzca un estado'});
            return false;
        }

        return true;
    };

    continue = () => {
        const {sessionReducer: {role, addresses}, notificationReducer: {showAlert}, configReducer: {history}} = this.props;
        const {address, addressKeySelect,billKeySelect , paymentMethodSelect} = this.state;
        let newAddress;
        let newBill;
        let costototal = 0;
        if (role === ROLES.CLIENT) {
            if (!addressKeySelect) return showAlert({type: 'warning', message: 'Selecciona una dirección de envío'})

            addresses.map(addressFilter => {
                if(addressFilter.Address === addressKeySelect){
                    newAddress = addressFilter
                }
            })
        }
        if (role === ROLES.CLIENT) {
            if (!billKeySelect) return showAlert({type: 'warning', message: 'Selecciona una dirección de facturación'})
            if(billKeySelect === "same"){
                addresses.map(addressFilter => {
                    if(addressFilter.Address === addressKeySelect){
                        newBill = addressFilter
                    }
                })
            }else{
                addresses.map(addressFilter => {
                    if(addressFilter.Address === billKeySelect){
                        newBill = addressFilter
                    }
                })
            }
        }

        if (role === ROLES.PUBLIC) {
            if (!this.validateForm()) return;
            newAddress = address;
        }

        if (!paymentMethodSelect) return showAlert({type: 'warning', message: 'Selecciona un método de pago'});

        let data = {
            address: newAddress,
            bill: newBill,
            paymentMethod: paymentMethodSelect,
            date: new Date(),
        };

        let totalOrder = localStorage.getItem(config.general.localStorageNamed + 'OrderTotal');
        if (paymentMethodSelect === 'mercadoPago') {
            this.getURLPayMercado(Math.ceil(totalOrder));
        }

        sessionStorage.setItem('validateOrder', JSON.stringify(data));

        history.goValidateOrder();
    };

    getURLPayMercado = async (totalCTax = null) => {
        localStorage.setItem(config.general.localStorageNamed + 'goToMP', '');
        await apiClient.getPreferenceMP(totalCTax).then(response => {
            // URL = response.data.sandbox_init_point; //Paguna de testeo
            URL = response.data.init_point;
            localStorage.setItem(config.general.localStorageNamed + 'goToMP', URL);
            // return response.data.sandbox_init_point; //Paguna de testeo
            return response.data.init_point;
        });
        //console.log("PRUEBA MP URL => ",URL)
        localStorage.setItem(config.general.localStorageNamed + 'goToMP', URL);
        this.setState({
            url_mp: URL
        });
    }

    selectPaymentMethod = event => {
        this.setState({
            paymentMethodEnabled: true,
            paymentMethodSelect: event.nativeEvent.target.value || '',
        })
    };

    render() {
        const {history, sessionReducer: {role}} = this.props;
        //console.log("SessionReducer",SessionReducer);
        const {showAddress, showBill, addressKeyEnabled, paymentMethodEnabled} = this.state;
        return (
            <div className="content-fluid" style={{marginTop: 260,marginBottom: 30}}> 
                <Session history={history} view={VIEW_NAME.SELECT_ADDRESS_VIEW}></Session>
                <NavBar/>
                    <div className="row"  style={{marginTop: 100, marginLeft: 40, marginRight: 40,  minHeight: '70vh'}}>
                        <div className="col-sm-4">
                            <div className="card style-articles-cart" style={{ borderColor: 'white', border: '1px solid  rgba(124, 124, 125, 0.3)'}}>
                                <div className="card-header" style={{background: config.shoppingList.productList}}>
                                    <h5 className="card-title" style={{color: config.shoppingList.textProductList}}>
                                        Seleccione una dirección de envío
                                    </h5>
                                </div>
                                <div className="card-body">
                                    {role !== ROLES.PUBLIC && this.renderClientAddress()}
                                    <div style={{textAlign: 'center'}}>
                                        {(showAddress || role == ROLES.PUBLIC) && this.renderFormNewAddress()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="card style-articles-cart" style={{ borderColor: 'white', border: '1px solid  rgba(124, 124, 125, 0.3)'}}>
                                <div className="card-header" style={{background: config.shoppingList.productList}}>
                                    <h5 className="card-title" style={{color: config.shoppingList.textProductList}}>
                                        Seleccione una dirección de facturación
                                    </h5>
                                </div>
                                <div className="card-body">
                                    {role !== ROLES.PUBLIC && this.renderClientABill()}
                                    <div style={{textAlign: 'center'}}>
                                        {(showBill || role == ROLES.PUBLIC) && this.renderFormNewBill()}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="card" style={{ borderColor: 'white', backgroundColor: 'transparent', border: '1px solid  rgba(124, 124, 125, 0.3)'}}>
                                <div className="card-header text-white" style={{background: config.shoppingList.summaryList}}>
                                    <div className="card-title">
                                        <h5 style={{color: config.shoppingList.textsummaryList}}>Método de pago</h5>
                                    </div>
                                    <p className="small">Seleccione un método</p>
                                </div>
                                <div className="card-body">
                                    {config.paymentMethod.paypal.enable && <div>
                                        <input type="radio" id="paypal" name="paymentMethod" value="paypal" onClick={this.selectPaymentMethod} style={{margin: 10}}/>
                                        <span style={{ fontSize: 18}} htmlFor="paypal" >Paypal</span>
                                    </div>}
                                    {config.paymentMethod.mercadoPago.enable && <div className="imagePaymentMethod">
                                        <input type="radio" id="mercadoPago" name="paymentMethod" value="mercadoPago" onClick={this.selectPaymentMethod} style={{ margin: 10 }} />
                                        <span style={{ fontSize: 18 }} htmlFor="mercadoPago" >MercadoPago</span>
                                    </div>}
                                    {config.paymentMethod.transbank.enable && <div onClick={this.selectPaymentMethod}>
                                        <input type="radio" id="transbank" name="paymentMethod" value="transbank" style={{margin: 10}}/>
                                        <span style={{ fontSize: 18}} htmlFor="transbank" >TransBank</span>
                                    </div>}
                                    {(addressKeyEnabled || role === ROLES.PUBLIC) && paymentMethodEnabled && <div style={{textAlign: 'center'}}>
                                        <button
                                            onClick={this.continue}
                                            className="btn btn-success btn-block"
                                            style={{
                                                marginTop:10,
                                                fontWeight: "bold",
                                            }}>
                                            Continuar
                                        </button>
                                    </div>}

                                </div>
                            </div>
                        </div>
                    </div>
            </div>

        );
    }
}


const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
        setAddresses: value => dispatch({type: DISPATCH_ID.SESSION_SET_ADDRESSES, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectAddressView);
