import React, {Component} from 'react';
import {NavBar, Session, History, ItemsList, ItemDetailsModal, Footer} from "../../components";
import {config, DISPATCH_ID, VIEW_NAME, SERVICE_RESPONSE} from "../../libs/utils/Const";
import {connect} from "react-redux";
import { ItemSlider, ItemSliderResponsive } from '../../components';
import {BreadCrumb} from '../../components'
import CurrencyFormat from 'react-currency-format';
import { Redirect } from 'react-router';
import ReactImageMagnify from 'react-image-magnify';
import "./ItemsView.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ReactStars from "react-rating-stars-component";
import { ApiClient } from '../../libs/apiClient/ApiClient'; 
import  OpenOpinionsModal from '../../components/OpenOpinionsModal';
import { animateScroll as scroll, scroller } from 'react-scroll'
import $ from 'jquery';

import moment from 'moment';

let apiClient = ApiClient.getInstance();

class ItemsDetailsView extends Component {

    constructor(props){
        super(props);
        this.state = {
            images: [],
            rating : 0,
            comentario : '',
            Comentarios : [],
            titulo : '',
            valueStart : 0,
            comentar : false
        };
        // this.scrollToBottom = this.scrollToBottom.bind(this);
        this.mouseOverAddCart = this.mouseOverAddCart.bind(this);
	    this.mouseOutAddCart = this.mouseOutAddCart.bind(this);
        this.iconMouse = [];  
        this.iconMouseOver = [];
    }

    scrollToBottom() {
        scroll.scrollToTop({
            duration: 1500,
            delay: 100,
            smooth: 'easeOutQuart',
            isDynamic: true
        })
    }

    async componentDidMount(){
        const {enableSpinner} = this.props;
        this.scrollToBottom();
        enableSpinner(true);
        // setTimeout(() => {
        //     this.cargarDatos(); 
        // }, 2000);
        this.cargarDatos();
        enableSpinner(false);
        // this.scrollToBottom();
    };

    // Se vacia la tabla
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.state.valueStart != nextState.valueStart) {
            this.valueStart = 1;
        }
        return true;
    }
    // se llena la tabla
    componentDidUpdate() {
        if (this.valueStart == 0) {
            this.cargarDatos();
        }
    }

    async cargarDatos (){
        const {itemsReducer: { itemDetails }, enableSpinner} = this.props;
        enableSpinner(true);
        let user = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'CurrentUser'));
        let newdatas = await apiClient.getRaiting(itemDetails.ItemCode, user ? user.CardCode : '');
        if(newdatas.status != 0 ){
            this.setState({   
                Comentarios: newdatas.data.data,
                Valores : newdatas.data.promedio,
                All : newdatas.data.All,
                Opinion : newdatas.data.All.length > 0 ? true : false,
                comentar : newdatas.data.comentar
            });
        }
        
        enableSpinner(false);
    };

    renderCarouselImage = (imagePath, index) => {
        const {itemCode} = this.props;
        return (
            <div className={"carousel-item" + (index === 0 ? ' active' : '')} style={{backgroundColor: "rgba(248,249,250,0.6)"}} id={index + 'Image' + itemCode}>
                <img className="img-fluid" src={imagePath} alt="First slide"/>
            </div>
        );
    };

      imageZoom = (imagePath, index)  => {
          const {itemCode} = this.props;
          let modal = document.getElementById("myModal");
          let img = document.getElementById(index + 'Image' + itemCode);
          let modalImg = document.getElementById("img01");
            modal.style.display = "block";
            modalImg.src = imagePath;
          let span = document.getElementsByClassName("close")[0];
    };

    cerrarModalImage = () =>{
        let modal = document.getElementById("myModal");
        modal.style.display = "none";
    }

    renderCarouselImageMP = (imagePath, index) => {
        const {itemCode} = this.props;
        return (
            <a onClick={()=>this.imageZoom(imagePath, index)} key={index} className="contenidoCarousel"  style={{justifyContent: "center", alignItems: "center"}}>
                <img className="img-fluid" id={index + 'Image' + itemCode} src={imagePath} alt="Carousel 2" style={{cursor: 'pointer'}}/>
            </a>
        );
    };
    
    changeQuantityDetails = (item, event) => {
        const {itemsReducer : {deleteShoppingCart, addShoppingCart}, notificationReducer: {showAlert}} = this.props;
        
        let newQuantity =  event.nativeEvent.target.value;
        let onHand = item.OnHandPrincipal;
        if(Number(newQuantity) > Number(onHand)) {
            if (Number(onHand) !== 0 ) {
                showAlert({type: 'warning', message: "Se excede el número de ARTÍCULOS disponibles de este producto", timeOut: 2500});
            }
            newQuantity = onHand;
        }
        if(!newQuantity){
            deleteShoppingCart({item, deleteAll: false});
        }else{
            addShoppingCart({item, quantity: (newQuantity || '1')})
        }
    };

    changeQuantity = (index, item, newQuantity, addItem) => {
        const { itemsReducer: { addShoppingCart, deleteShoppingCart } } = this.props;
        if (addItem) {
            addShoppingCart({ item, quantity: (newQuantity || '1') })
        } else {
            deleteShoppingCart({ item, deleteAll: false });
        }
    };

    setSepecialPrice = (itemParameter) =>{
        let item = itemParameter;
        const {itemsReducer: {specialPrice}} = this.props;
        
        let CardCode = specialPrice.CardCode;
        let PriceList = specialPrice.ListNum && specialPrice.ListNum !== '' ? specialPrice.ListNum : specialPrice.priceList;
        
        // Special Prices Validation
        //########################################################################################################################
        let priority2 = "*" + PriceList;
        let formattedDocDate = moment().format('YYYY/MM/DD');

        let flagSN = true; // Socio de negocio
        let flagPriceList = true; // Lista de precios
        let flagPricePriority = true;
        let maxQuantity = 0; // Cantidad maxima a alegir
        let priceItem = -1;
        let discount = -1;
        let priceBeforeDisc= -1;

        item.QuantitySpecial = item.quantity;
        item.PriceTest = -1;

        // Socio de negocios
        if(specialPrice.specialPrices.length > 0){
            specialPrice.specialPrices.map((itemPrice) => {
                if (item.ItemCode == itemPrice.ItemCode && itemPrice.CardCode == CardCode) {
                    if (itemPrice.children.length > 0) {
                        itemPrice.children.map((child) => {
                            let inicial = new Date(child.FromDate);
                            let final = new Date(child.ToDate || formattedDocDate);
                            let docDate = new Date(formattedDocDate);
                            
                            inicial.setMinutes(inicial.getMinutes() + inicial.getTimezoneOffset());
                            final.setMinutes(final.getMinutes() + final.getTimezoneOffset());

                            inicial.setHours(0, 0, 0, 0);
                            final.setHours(0, 0, 0, 0);
                            docDate.setHours(0, 0, 0, 0);

                            if (docDate >= inicial && docDate <= final) {
                                if (itemPrice.childrenCantidades.length > 0) {
                                    // Cantidades
                                    itemPrice.childrenCantidades.map((child2) => {
                                        let inicialCantidad = new Date(child2.FromDate);
                                        let finalCantidad = new Date(child2.ToDate || formattedDocDate);

                                        if(docDate >= inicialCantidad && docDate <= finalCantidad){
                                            if ((item.QuantitySpecial || 1) >= child2.Amount) {
                                                if(child2.Amount > maxQuantity) {
                                                    maxQuantity = child2.Amount;
                                                    item.DiscountPercentSpecial = child2.Discount;
                                                    flagPricePriority = false;
                                                    priceItem = parseFloat(child2.Price);
                                                    discount = parseFloat(child2.Discount);
                                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                                }
                                            }
                                        }
                                    });
                                    if(maxQuantity != 0){
                                        flagSN = false;
                                    }
                                    if(flagSN){
                                        // Fechas
                                        item.DiscountPercentSpecial = child.Discount;
                                        flagPricePriority = false;
                                        priceItem = parseFloat(child.Price);
                                        discount = parseFloat(child.Discount);
                                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                        flagSN = false;
                                    }
                                } else {
                                    // Fechas
                                    item.DiscountPercentSpecial = child.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(child.Price);
                                    discount = parseFloat(child.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                    flagSN = false;
                                }
                            } else {
                                // General
                                if(flagSN){
                                    item.DiscountPercentSpecial = itemPrice.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(itemPrice.Price);
                                    discount = parseFloat(itemPrice.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                }
                            } 
                        })
                    } else {                           
                        //General
                        item.DiscountPercentSpecial = itemPrice.Discount;
                        flagPricePriority = false;
                        priceItem = parseFloat(itemPrice.Price);
                        discount = parseFloat(itemPrice.Discount);
                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                    }
                }
            });
        }
        // Lista de inventario
        if(specialPrice.specialPricesList.length > 0 && flagPricePriority){
            specialPrice.specialPricesList.map((itemPrice2)  => {
                if(itemPrice2.ItemCode == item.ItemCode && itemPrice2.CardCode == priority2){
                    if (itemPrice2.children.length > 0) {
                        itemPrice2.children.map((child)=> {

                            let inicial = new Date(child.FromDate);
                            let final = new Date(child.ToDate || formattedDocDate);
                            let docDate = new Date(formattedDocDate);
                            
                            inicial.setMinutes(inicial.getMinutes() + inicial.getTimezoneOffset());
                            final.setMinutes(final.getMinutes() + final.getTimezoneOffset());

                            inicial.setHours(0, 0, 0, 0);
                            final.setHours(0, 0, 0, 0);
                            docDate.setHours(0, 0, 0, 0);
                            if (docDate >= inicial && docDate <= final) {
                                if (itemPrice2.childrenCantidades.length > 0) {
                                    // Cantidades
                                    itemPrice2.childrenCantidades.map((child2) => {
                                        let inicialCantidad = new Date(child2.FromDate);
                                        let finalCantidad = new Date(child2.ToDate || formattedDocDate);

                                        if(docDate >= inicialCantidad && docDate <= finalCantidad){
                                            if ((item.QuantitySpecial || 1) >= child2.Amount) {
                                                if(child2.Amount > maxQuantity) {
                                                    maxQuantity = child2.Amount;
                                                    item.DiscountPercentSpecial = child2.Discount;
                                                    flagPricePriority = false;
                                                    priceItem = parseFloat(child2.Price);
                                                    discount = parseFloat(child2.Discount);
                                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                                }
                                            }
                                        }
                                    });
                                    if(maxQuantity != 0){
                                        flagPriceList = false;
                                    }
                                    if(flagPriceList){
                                        // Fechas
                                        item.DiscountPercentSpecial = child.Discount;
                                        flagPricePriority = false;
                                        priceItem = parseFloat(child.Price);
                                        discount = parseFloat(child.Discount);
                                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                        flagPriceList = false;
                                    }
                                } else {
                                    // Fechas
                                    item.DiscountPercentSpecial = child.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(child.Price);
                                    discount = parseFloat(child.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                    flagPriceList = false;
                                }
                            } else {
                                // General
                                if(flagPriceList){
                                    item.DiscountPercentSpecial = itemPrice2.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(itemPrice2.Price);
                                    discount = parseFloat(itemPrice2.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                }
                            } 
                        })
                    } else {
                        // General
                        item.DiscountPercentSpecial = itemPrice2.Discount;
                        flagPricePriority = false;
                        priceItem = parseFloat(itemPrice2.Price);
                        discount = parseFloat(itemPrice2.Discount);
                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                    }
                }
            });
        } 
        //No tiene precio especial
        if(flagPricePriority){
            item.DiscountPercentSpecial = 0;
        }

        // Precios por descuentos especiales
        if(priceBeforeDisc != -1){
            item.Price = Number(priceItem);
            item.PriceTest = Number(priceItem);            
            item.PriceBeforeDiscount = Number(priceBeforeDisc);
        }
        
        let price = item.PriceTest !== -1 ? Number(item.Price).toFixed(2) : -1;
        return price;
    }
    
    changLocalQuantity = (item, event)=>{
        let newQuantity =  event.nativeEvent.target.value;
        item.quantity = newQuantity;
        let itemFilter = {
            ItemCode : item.ItemCode,
            quantity : item.quantity,
        }
        let specialPrice = this.setSepecialPrice(itemFilter);
        if(specialPrice !== -1){
            item.Price = specialPrice;
        }
        this.applyFilters(item);
    }
        
    applyFilters = data => {
        const {setItemDetailsSearch} = this.props;
        setItemDetailsSearch(data);
    };    

    changeBackOrder = (item, addItem) => {
        const { itemsReducer: { deleteBackOrder, addBackOrder } } = this.props;
        if (addItem) {
            addBackOrder({ item, quantity: 1 })
        } else {
            deleteBackOrder({ item, deleteAll: false });
        }
    };

    removeDuplicates(duplicated){
        let returnArray = new Array();
        for (let a = duplicated.length - 1; a >= 0; a--) {
            for (let b = duplicated.length - 1; b >= 0; b--) {
                if(duplicated[a] && duplicated[b]){
                    if(duplicated[a].ItemCode == duplicated[b].ItemCode && a != b){
                        delete duplicated[b];
                    }
                }         
            };
            if(duplicated[a] != undefined)
                returnArray.push(duplicated[a]);
        };
        return returnArray;
    }

    mouseOverAddCart = (index, flag, event)=>{
        if(this.iconMouse && this.iconMouse.length > 0){
            this.iconMouse[index].style.display = "none";
            this.iconMouseOver[index].style.display = "block";
        }
    }

    mouseOutAddCart = (index, flag, event)=>{
        if(flag == 'green'){
            event.currentTarget.style.backgroundColor = "#89c33f";
            event.currentTarget.className = "btn btn-block botonAgregar"
        } else if(flag == 'yellow'){
            event.currentTarget.style.backgroundColor = "#efc964";
            event.currentTarget.className = "btn btn-block botonAgregarYellow"
        } else {
            event.currentTarget.style.backgroundColor = "#e27753";
            event.currentTarget.className = "btn btn-block botonAgregarAgotado"
        }
        // Íconos
        if(this.iconMouse && this.iconMouse.length > 0){
            this.iconMouse[index].style.display = "block";
            this.iconMouseOver[index].style.display = "none";
        }
    }

    ratingChanged = (newRating)=>{
        this.setState ({
            rating : newRating
        })
    }

    handelChange = ({ target }) => {
        const { name, value } = target;
        this.setState({
            [name]: value
        });
    };

    sendComment = async (item = null) => { 
        const { enableSpinner,notificationReducer: {showAlert} } = this.props;
        const { titulo, comentario, rating} = this.state;
        let user = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'CurrentUser'));

        let data = {
            itemcode : item.ItemCode,
            cardcode : user.CardCode,
            titulo,
            comentario,
            rating
        };

        if(rating == '0'){
            showAlert({type: 'warning', message: 'Por favor califique el producto.', timeOut: 10000})
            return;
        }
        if(titulo == ''){
            showAlert({type: 'warning', message: 'Por favor ponga un título.', timeOut: 10000})
            return;
        }
        if(comentario == ''){
            showAlert({type: 'warning', message: 'Escribe un comentario acerca del producto.', timeOut: 10000})
            return;
        }

        enableSpinner(true);
        let apiResponse = await apiClient.sendRaiting(data);

        //console.log(apiResponse);

        if (apiResponse.status === 1) { 
            enableSpinner(false);
            this.setState({
                titulo : '', 
                comentario : '', 
                rating : 0
            })
            this.cargarDatos();
            return;
        }
        showAlert({ type: 'error', message: apiResponse.message });
        enableSpinner(false)
    };

    OpenOpinions = async() =>{ 
        $('#OpinionsModal').modal('show');  
    }

    render() {
        const { configReducer : {history,currency}, itemsReducer: { itemDetails, deleteShoppingCart, items, updateFavorite, openItemDetails, itemsCategories: {itemsCategories,}, }, view, images, itemCode } = this.props;
        const { titulo, Comentarios, comentario, rating , Valores, All, Opinion, comentar} = this.state;
        // console.log("EGO 32", itemDetails);
        itemDetails.files = itemDetails.files || [];
        let sliderDetailsArrayNoDuplicated = [];
        if(items && items.length > 0){
            let sliderDetailsArray = [];
            let itemsCopy = items.slice();


            if(itemDetails.categoSearch){
                //let itemDetailsCopy = itemDetails.U_Handel_Tags.split(/[|,]/);
                let itemDetailsCopy = itemDetails.categoSearch;

                itemsCopy.map(item => {
                    // itemDetailsCopy.map(itemDetail => {
                    //     if((itemDetail && itemDetail != '') && (item.U_Handel_Tags && item.U_Handel_Tags != '')){
                    //         if(item.U_Handel_Tags.includes(itemDetail) && item.ItemCode !== itemDetails.ItemCode){
                    //             sliderDetailsArray.push(item);
                    //             return;
                    //         }
                    //     }
                    // });
                    if(item.categoSearch.includes(itemDetailsCopy) && item.ItemCode !== itemDetails.ItemCode){
                        sliderDetailsArray.push(item);
                        return;
                    }
                });
                
                if(sliderDetailsArray.length > 0){
                    sliderDetailsArrayNoDuplicated = this.removeDuplicates(sliderDetailsArray);
                }
            } else {
                sliderDetailsArrayNoDuplicated = [];
            }
        }
        // Descuentos espec. por cantidad
        let quantityPromo = itemDetails.quantityPromoSN ? itemDetails.quantityPromoSN : itemDetails.quantityPromoPriceList ? itemDetails.quantityPromoPriceList : [];
        if(!history || itemDetails.length == 0 ){
            return <Redirect to="/"/>;
        } else {
            return (
                <div id = "zoom" className="content-fluid none-scroll" style={{marginTop: 150,backgroundColor:"#ffffff !important"}}>
                    <NavBar/>
                    <OpenOpinionsModal
                        All = {All}
                    />
                    <div className="pt-4 text-left">
                        <div className = "row">
                            <div className="col-md-12 mt-md-4 botonRegresar">
                                <h5 className="font-weight-bold TituloCategoria linkFilter" onClick={() => history.goItems()} style={{whiteSpace:"nowrap"}}>
                                    {/* <span style={{color: "#000000"}}> */}
                                        {'< Regresar     |       '}
                                        <span style={{color: "#212529 !important"}}>
                                            <BreadCrumb data={itemsCategories}/>
                                        </span>
                                    {/* </span> */}

                                    <span style={{color: "#005ead"}}>
                                        {'  '+itemDetails.ItemName}
                                    </span>
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center borderB-details">
                        <div className="col-md-6 mt-md-2"  style={{height: "auto"}}>
                            <div className="text-center">
                                <div className="geeks" style={{ margin: 'auto', padding: 0 }}>
                                    {/* <img  src="https://bytelesen.com/wp-content/uploads/logitech-g-703-lightspeed-kabellose-gaming-maus-1.jpg"></img> */}
                                    {/* <Carousel images={itemDetails.images || []} itemCode={itemDetails.ItemCode} >  */}
                                    {/* Ya funciona con las imagenes que trae */}
                                    <Carousel autoPlay infiniteLoop showArrows={true}> 
                                        {itemDetails.images.map((imagePath, index) => {
                                            if (imagePath.path) return this.renderCarouselImageMP(imagePath.path, index)
                                        })}
                                    </Carousel>                                   
                                </div>
                            </div>
                        </div>
                        <div className="Caracteristicas col-md-6 col-md-6-detail text-left mt-md-2 none-scroll" style={{ color: "000000", marginTop: 20 }}>
                            <div className="space-line">
                                <div className="justify-content-center margin-line">
                                    <div>
                                        <p className="nombreArticulo">
                                            <a className="font-weight-bold" style={{color:"#AFAFAF", fontSize: 21}}>
                                                {itemDetails.ItemName}
                                            </a>
                                        </p>
                                        <p style={{fontSize: 20}}>
                                            <span style={{color:"#AFAFAF", fontSize: 21}}>Clave: </span><span style={{color: "#000000"}}>{itemDetails.ItemCode}</span>
                                        </p>
                                        {itemDetails.PriceTaxBeforeDiscount !== undefined && itemDetails.PriceTaxBeforeDiscount != 0 && 
                                        itemDetails.U_web !== 0 && itemDetails.U_web !== null
                                        ?
                                            <>
                                            <p className=" text-left" style={{marginLeft:0, marginRight: 0}}>
                                                <p className="card-subtitle  font-weight-bold" style={{fontSize: 36, color: "#666666"}}>
                                                    <CurrencyFormat
                                                        value={ itemDetails.Price}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        prefix={currency.MainCurncy != itemDetails.Currency ? '' : currency.DocCurrCod}
                                                        suffix = {' ' + itemDetails.Currency +'más IVA'}>
                                                    </CurrencyFormat>
                                                </p>
                                            </p>
                                            { quantityPromo.length > 0 && 
                                                <div className = "row justify-content-start">
                                                    <div className = "col-md-4 font-weight-bold text-left text-dark" style={{fontSize: 24}}>Precio de Volúmen </div>
                                                    <div className = "col-md-2 font-weight-bold text-center text-dark" style={{fontSize: 24}}>Descuento </div>
                                                    <div className = "col-md-3 font-weight-bold text-center text-dark" style={{fontSize: 24}}>Precio </div>
                                                </div>
                                            }
                                            { quantityPromo.length > 0 && quantityPromo.map((promo, index) => {
                                                return  <div className = "row justify-content-start">
                                                            <div className = "col-md-4 font-weight-bold text-left text-dark" style={{fontSize: 24}}> {promo.Amount} pz {index === quantityPromo.length - 1 ? '' : 'a'} {promo.NextAmount} {index === quantityPromo.length - 1 ? '' : 'pz'}</div>
                                                            <div className = "col-md-2 font-weight-bold text-center text-danger" style={{fontSize: 24}}> {promo.DiscountPercent}% </div>
                                                            <div className = "col-md-3 font-weight-bold text-center text-danger" style={{fontSize: 24}}> 
                                                                <CurrencyFormat 
                                                                    className = "ml-md-3"
                                                                    value={promo.Price}
                                                                    style={{weight: "normal"}}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    fixedDecimalScale={true}
                                                                    decimalScale={2}
                                                                    prefix={currency.MainCurncy != itemDetails.Currency ? '' : currency.DocCurrCod}
                                                                    suffix = {' ' + itemDetails.Currency}>
                                                                </CurrencyFormat>
                                                                </div>
                                                        </div>;                                              
                                                })
                                            }
                                            </>
                                        :
                                            <p className=" text-left" style={{marginLeft:0, marginRight: 0, fontSize: 30, color: "rgb(0,94,173)"}}>
                                                <span className="card-subtitle  font-weight-bold" /*style={{fontSize: 40, color: "rgb(0,94,173)"}}*/>
                                                    {itemDetails.U_web === 0 || itemDetails.U_web === null
                                                    ? "Solicite su cotización"
                                                    : <CurrencyFormat
                                                        value={itemDetails.Price}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        prefix={'$ '}>
                                                    </CurrencyFormat>
                                                    }
                                                </span>
                                                <span className="card-subtitle  font-weight-bold"> más IVA</span>
                                            </p>
                                        }
                                        {itemDetails.U_NomSud &&
                                            <div>
                                                <a style={{fontSize: 20}}>
                                                    <span className="nombreCaracteristica"> NOM SUD:  </span><b><span className="caracteristica">{itemDetails.U_NomSud}</span></b>
                                                </a>
                                            </div>
                                        }
                                        
                                    </div>
                                    {view !== VIEW_NAME.BACK_ORDER_VIEW && <div className = "row justify-content-start text-left mt-md-4" style={{ textAlign: "left", marginLeft: 'auto', marginRight: 'auto' }}>
                                        {itemDetails.U_web === 0 || itemDetails.U_web === null
                                            ? <div className="col-md-12 text-left" style={{ padding: 0 }}>
                                                <label style={{ textAlign: "left", marginTop: 12, fontSize: 24, color:"#AFAFAF"  }}>Llámenos o envíe un correo para cotización</label>
                                            </div>
                                            : <div className="col-auto cantidadDetalles" style={{padding:0}}>
                                                <input
                                                    disabled={!(!!itemDetails.OnHandPrincipal)}
                                                    id={'input-quantity-details-' + itemDetails.ItemCode}
                                                    type="number"
                                                    min="1"
                                                    className="form-control"
                                                    name={'quantity' + itemDetails.ItemCode}
                                                    placeholder="1"
                                                    value={itemDetails.quantity ? Number(itemDetails.quantity) : ''}
                                                    style={{ textAlign: "left"}}
                                                    onChange={(event) => {this.changLocalQuantity(itemDetails, event)}}
                                                />
                                            </div>
                                        }
                                        {itemDetails.U_web === 0 || itemDetails.U_web === null ?
                                            <div><label style={{textAlign: "left", fontSize: 24, color:"#AFAFAF"}}>Para cotización</label></div>:
                                            <div className="col-auto botonAgregarDetalles" >
                                                <button style={{textAlign: "center", height: 44.5}} 
                                                    type="button" 
                                                    value={(itemDetails.quantity ? Number(itemDetails.quantity): '1')} 
                                                    onClick={(event)=>{this.changeQuantityDetails(itemDetails, event)}}
                                                    readOnly = {itemDetails.OnHandPrincipal === 0 || itemDetails.OnHandPrincipal === '' ? true : false} 
                                                    className= {itemDetails.OnHandPrincipal === 0 ? "btn btn-block botonAgregarAgotado" : itemDetails.flag === 'green' ? "btn btn-block botonAgregar": "btn btn-block botonAgregarYellow"}  
                                                    onMouseOver={(event)=>{this.mouseOverAddCart(0, itemDetails.flag, event)}} 
                                                    onMouseOut={(event)=>{this.mouseOutAddCart(0, itemDetails.flag, event)}}
                                                >
                                                </button>
                                            </div>
                                        }
                                        {((itemDetails.U_web !== 0 && itemDetails.U_web !== null) && (itemDetails.OnHandPrincipal == 0 || itemDetails.OnHandPrincipal == '')) &&
                                            <div className="icoCarrito col-auto text-center align-middle" style={{fontSize: 33,padding:0, textAlign: "right"}}>
                                                <img ref={iconMouse => this.iconMouse[0] = iconMouse} src={config.shoppingCartIcons.carritoAgotado} style={{display: "block", color: "red", cursor: "pointer"}}/>
                                                <img ref={iconMouseOver => this.iconMouseOver[0] = iconMouseOver} src={config.shoppingCartIcons.camionAgotado} style={{display: "none", color: "red", cursor: "pointer"}}/>
                                            </div>
                                        }
                                        {((itemDetails.U_web !== 0 && itemDetails.U_web !== null) && itemDetails.OnHandPrincipal > 0 && (itemDetails.flag === 'green' || itemDetails.flag === 'yellow')) &&
                                            <div className="icoCarrito col-auto text-center align-middle" style={{fontSize: 33,padding:0, textAlign: "right"}}>
                                                <img ref={iconMouse => this.iconMouse[0] = iconMouse} src={itemDetails.flag === 'green' ? config.shoppingCartIcons.carritoVerde : config.shoppingCartIcons.carritoAmarillo} style={{display: "block", cursor: "pointer"}}/>
                                                <img ref={iconMouseOver => this.iconMouseOver[0] = iconMouseOver} src={itemDetails.flag === 'green' ? config.shoppingCartIcons.camionVerde : config.shoppingCartIcons.camionAmarillo} style={{display: "none", cursor: "pointer"}}/>
                                            </div>
                                        }
                                        {itemDetails.U_web === 1 &&
                                            <div className="iconosDetalle col-auto text-center" style={{color: "red", fontSize: 33,padding:0, textAlign: "right"}}>
                                                {itemDetails.quantity && <i className="fa fa-trash ml-md-3" style={{ cursor: "pointer" }} onClick={() => deleteShoppingCart({ item: itemDetails, deleteAll: false })} />}
                                            </div>
                                        }
                                        {/* {itemDetails.U_web === 0 || itemDetails.U_web === null ?
                                            <div><label style={{textAlign: "center", fontSize: 16}}>Para cotización</label></div>:
                                            <div className="col-6 botonAgregar">
                                                    <button style={{textAlign: "center", height: 44.5, width: 123.26}} type="button" className="btn btn-block" value={(itemDetails.quantity ? Number(itemDetails.quantity): '1')} onClick={(event)=>{this.changeQuantityDetails(itemDetails, event)}}>
                                                        AGREGAR
                                                    </button>
                                            </div>
                                        } */}
                                        {itemDetails.U_web === 1 &&
                                            itemDetails.wishlist === 1 &&
                                            <div className="row text-left" style={{ margin: "auto", padding: 0, width: "80%" }}>
                                                <div className="col-8">
                                                    <label style={{ textAlign: "center", marginTop: 12 }}>Lista de deseos</label>
                                                </div>
                                                <div className="col-2" style={{ color: "rgb(13, 98, 168)", fontSize: 30, padding: 0 }}>
                                                    {itemDetails.backOrder ?
                                                        <i className="fa fa-minus" style={{ cursor: "pointer", marginLeft: 7 }} onClick={() => this.changeBackOrder(itemDetails, false)} /> :
                                                        <i className="fa fa-plus" style={{ cursor: "pointer", marginLeft: 7 }} onClick={() => this.changeBackOrder(itemDetails, true)} />
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>}
                                    <br/>
                                    <div>
                                        <div className="Details none-scroll details-content" id="Layer1" style={{marginBottom: 25, marginTop:"10px", border: "none", height: "auto", overflow: "auto", fontSize: 20, maxHeight: "130px", minHeight:"110px" /*marginBottom: 15, border: "none", height: "300px", overflow: "scroll", fontSize: 18*/}}>
                                            {/* {itemDetails.U_NoParte &&
                                                <div>
                                                    <a className="">
                                                        <span className="nombreCaracteristica"> NÚMERO DE PARTE:  </span><b><span className="caracteristica">{itemDetails.U_NoParte}</span></b>
                                                        {/* <b>  NO PARTE : </b> {itemDetails.U_NoParte} /}
                                                    </a>
                                                </div>
                                            }
                                            {itemDetails.U_Nombre_Ecommerce &&
                                                <div>
                                                    <a className="">
                                                        <span className="nombreCaracteristica"> NOMBRE ECOMMERCE: </span><b><span className="caracteristica">{itemDetails.U_Nombre_Ecommerce}</span></b>
                                                    </a>
                                                </div>
                                            }
                                            {itemDetails.U_SKU &&
                                                <div>
                                                    <a className="">
                                                        <span className="nombreCaracteristica"> SKU: </span><b><span className="caracteristica">{itemDetails.U_SKU}</span></b>
                                                    </a>
                                                </div>
                                            }
                                            {itemDetails.U_NomSud &&
                                                <div>
                                                    <a className="">
                                                        <span className="nombreCaracteristica"> NOMBRE DE SUDAMÉRICA: </span><b><span className="caracteristica">{itemDetails.U_NomSud}</span></b>
                                                    </a>
                                                </div>
                                            } */}
                                             {itemDetails.U_ALG_Marca &&
                                                <div>
                                                    <a className="">
                                                        <span className="nombreCaracteristica" style={{fontSize: 21}}> Marca: </span><b><span className="caracteristica">{itemDetails.U_ALG_Marca}</span></b>
                                                    </a>
                                                </div>
                                            }
                                            {itemDetails.U_FMB_Handel_Slogan &&
                                                <div>
                                                    <a className="">
                                                        <span className="nombreCaracteristica"> E-COMMERCE SLOGAN: </span><b><span className="caracteristica">{itemDetails.U_FMB_Handel_Slogan}</span></b>
                                                    </a>
                                                </div>
                                            }
                                            {/* {itemDetails.U_FMB_Handel_Tags &&
                                                <div>
                                                    <a className="">
                                                        <span className="nombreCaracteristica"> E-COMMERCE TAGS: </span><b><span className="caracteristica">{itemDetails.U_FMB_Handel_Tags}</span></b>
                                                    </a>
                                                </div>
                                            } */}
                                            {/* {itemDetails.U_FMB_Handel_Marca &&
                                                <div>
                                                    <a className="">
                                                        <span className="nombreCaracteristica"> MARCA: </span><b><span className="caracteristica">{itemDetails.U_FMB_Handel_Marca}</span></b>
                                                    </a>
                                                </div>
                                            }
                                            {itemDetails.U_FMB_Handel_Refa &&
                                                <div>
                                                    <a className="">
                                                    <span className="nombreCaracteristica"> TIPO DE REFACCIÓN:  </span><b><span className="caracteristica">{itemDetails.U_FMB_Handel_Refa}</span></b>
                                                        {/* <b>HANDEL REFA: </b> {itemDetails.U_FMB_Handel_Refa} /}
                                                    </a>
                                                </div>
                                            }
                                            {itemDetails.U_FMB_Handel_Apara &&
                                                <div>
                                                    <a className="">
                                                    <span className="nombreCaracteristica"> TIPO DE APARATO:  </span><b><span className="caracteristica">{itemDetails.U_FMB_Handel_Apara}</span></b>
                                                        {/* <b>HANDEL APARA : </b> {itemDetails.U_FMB_Handel_Apara} /}
                                                    </a>
                                                </div>
                                            }
                                            
                                            {itemDetails.U_FMB_Handel_Fabrica &&
                                                <div>
                                                    <a className="">
                                                    <span className="nombreCaracteristica"> FABRICANTE:  </span><b><span className="caracteristica">{itemDetails.U_FMB_Handel_Fabrica}</span></b>
                                                        {/* <b>HANDEL FABRICA : </b> {itemDetails.U_FMB_Handel_Fabrica} /}
                                                    </a>
                                                </div>
                                            }
                                            {itemDetails.U_version &&
                                                <div>
                                                    <a>
                                                        <span className="nombreCaracteristica"> VERSIÓN: </span>
                                                        <b>
                                                            <span className="caracteristica">
                                                                {itemDetails.U_version === "O" ? "Original" : itemDetails.U_version === "G" ? "Generico" : itemDetails.U_version === "OEM" ? "Fabrricante del equipo original" : "" }
                                                            </span>
                                                        </b>
                                                    </a>
                                                </div>
                                            }
                                            {itemDetails.U_material &&
                                                <div>
                                                    <a className="">
                                                        <span className="nombreCaracteristica"> MATERIAL:  </span><b><span className="caracteristica">{itemDetails.U_material}</span></b>
                                                        {/* <b>  VOLUMEN : </b> {itemDetails.SVolume} /}
                                                    </a>
                                                </div>
                                            }
                                            {itemDetails.U_video &&
                                                <div>
                                                    <a href={itemDetails.U_video} target="_blank">
                                                        <span className="nombreCaracteristica"> VIDEO:  </span>
                                                        <b>
                                                            <span className="caracteristica"> You Tube</span>
                                                        </b>
                                                        
                                                    </a>
                                                </div>
                                            }
                                            {itemDetails.U_dificultad &&
                                                <div>
                                                    <a className="">
                                                        <span className="nombreCaracteristica"> DIFICULTAD: </span><b><span className="caracteristica">{itemDetails.U_dificultad}</span></b>
                                                    </a>
                                                </div>
                                            }
                                            {itemDetails.U_FMB_Sustitutos &&
                                                <div>
                                                    <a className="">
                                                        <span className="nombreCaracteristica"> ARTÍCULOS SUSTITUTOS: </span><b><span className="caracteristica">{itemDetails.U_FMB_Sustitutos}</span></b>
                                                    </a>
                                                </div>
                                            }
                                            {itemDetails.U_alterno_act &&
                                                <div>
                                                    <a className="">
                                                        <span className="nombreCaracteristica"> ARTÍCULOS ALTERNOS:  </span><b><span className="caracteristica">{itemDetails.U_alterno_act}</span></b>
                                                        {/* <b>   ALTERNOS act : </b>  {itemDetails.U_alterno_Act} /}
                                                    </a>
                                                </div>
                                            }
                                            {itemDetails.U_Relacionados &&
                                                <div>
                                                    <a className="">
                                                        <span className="nombreCaracteristica"> ARTÍCULOS RELACIONADOS:  </span><b><span className="caracteristica">{itemDetails.U_Relacionados}</span></b>
                                                        {/* <b>   ALTERNOS act : </b>  {itemDetails.U_alterno_Act} /}
                                                    </a>
                                                </div>
                                            }
                                            {itemDetails.U_modelos &&
                                                <div>
                                                    <a className="">
                                                        <span className="nombreCaracteristica"> MODELO DE APARATO:  </span><b><span className="caracteristica">{itemDetails.U_modelos}</span></b>
                                                        {/* <b>   ALTERNOS act : </b>  {itemDetails.U_alterno_Act} /}
                                                    </a>
                                                </div>
                                            } */}
                                        </div>
                                        <div className="text-left textLeft-padd" style={{ padding: 0, marginBottom: 10 }}>
                                            {itemDetails.files.map(file => {
                                                return <a key={file} href={file.path} target="_blank" >
                                                    <i className={config.icons.datasheet} style={{ color: "red", paddingRight: 5 }}></i>
                                                    <span style={{ color: "#0078C0", fontWeight: "bold", fontSize: 18 }}>Ficha técnica</span>
                                                </a>
                                            })}
                                        </div>
                                    </div>                                

                                    {/* {view !== VIEW_NAME.BACK_ORDER_VIEW && <div className = "row justify-content-start text-left" style={{ textAlign: "left" }}>
                                            {itemDetails.U_web === 0 || itemDetails.U_web === null
                                                ? <div className="col-md-12 text-center" style={{ padding: 0 }}>
                                                    <label style={{ textAlign: "center", marginTop: 12, fontSize: 16 }}>Llámenos o envíe un correo para cotización</label>
                                                </div>
                                                : <div className="col-md-6">
                                                    <input
                                                        disabled={!(!!itemDetails.OnHand)}
                                                        id={'input-quantity-details-' + itemDetails.ItemCode}
                                                        type="number"
                                                        min="1"
                                                        className="form-control"
                                                        name={'quantity' + itemDetails.ItemCode}
                                                        placeholder="Cantidad"
                                                        value={itemDetails.quantity ? Number(itemDetails.quantity) : ''}
                                                        style={{ textAlign: "center", marginTop: 8 }}
                                                        onChange={(event) => {this.changLocalQuantity(itemDetails, event)}}
                                                    />
                                                </div>
                                            }
                                            {itemDetails.U_web === 1 &&
                                                <div className="col-md-1" style={{ color: "red", fontSize: 30, padding: 0 }}>
                                                    {itemDetails.quantity && <i className="fa fa-trash" style={{ cursor: "pointer" }} onClick={() => deleteShoppingCart({ item: itemDetails, deleteAll: false })} />}
                                                </div>
                                            }
                                            <br/>
                                        {itemDetails.U_web === 0 || itemDetails.U_web === null ?
                                            <div><br/></div>:
                                            <div className="col-md-7 text-center">
                                                    <button type="button" className="btn btn-success btn-block" value={(itemDetails.quantity ? Number(itemDetails.quantity): '1')} onClick={(event)=>{this.changeQuantityDetails(itemDetails, event)}}>
                                                        <i className={config.icons.add}/>&nbsp; Agregar al carrito
                                                    </button>
                                            </div>
                                        }
                                        {itemDetails.U_web === 1 &&
                                            itemDetails.wishlist === 1 &&
                                            <div className="row text-left" style={{ margin: "auto", padding: 0, width: "80%" }}>
                                                <div className="col-8">
                                                    <label style={{ textAlign: "center", marginTop: 12 }}>Lista de deseos</label>
                                                </div>
                                                <div className="col-2" style={{ color: "rgb(13, 98, 168)", fontSize: 30, padding: 0 }}>
                                                    {itemDetails.backOrder ?
                                                        <i className="fa fa-minus" style={{ cursor: "pointer", marginLeft: 7 }} onClick={() => this.changeBackOrder(itemDetails, false)} /> :
                                                        <i className="fa fa-plus" style={{ cursor: "pointer", marginLeft: 7 }} onClick={() => this.changeBackOrder(itemDetails, true)} />
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>} */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* AQUI COMIENZA LA DESCRIPCIÓN Y CARACTERÍSTICAS DEL ARTÍCULO */}
                    <div className="pl-1 pr-1 justify-content-left">
                        <div className="Descripciones mb-md-3">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link active font-weight-bold" data-mdb-toggle="tab" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home-tab" aria-selected="false">Descripción</a>
                                </li>
                                {/* <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Características</a>
                                </li> */}
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active mt-md-3 text-justify" id="home" role="tabpanel" aria-labelledby="home-tab" style={{marginBottom: "1rem", marginRight:"1rem", marginLeft: "1rem"}}>
                                    <div style={{maxHeight: "332px", overflow: "auto"}}>{itemDetails.UserText}</div>
                                </div>
                                <div className="tab-pane fade mt-md-3" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                    {itemDetails.U_Color &&
                                        <div>
                                            <a className="">
                                                <span className="nombreCaracteristica"> COLOR:  </span><b><span className="caracteristica">{itemDetails.U_Color}</span></b>
                                                {/* <b>   ALTERNOS act : </b>  {itemDetails.U_alterno_Act} */}
                                            </a>
                                        </div>
                                    }
                                    {itemDetails.U_Gas_Refri &&
                                        <div>
                                            <a className="">
                                                <span className="nombreCaracteristica"> GAS:  </span><b><span className="caracteristica">{itemDetails.U_Gas_Refri}</span></b>
                                                {/* <b>   ALTERNOS act : </b>  {itemDetails.U_alterno_Act} */}
                                            </a>
                                        </div>
                                    }
                                    {itemDetails.U_Hp &&
                                        <div>
                                            <a className="">
                                                <span className="nombreCaracteristica"> HP:  </span><b><span className="caracteristica">{itemDetails.U_Hp}</span></b>
                                                {/* <b>   ALTERNOS act : </b>  {itemDetails.U_alterno_Act} */}
                                            </a>
                                        </div>
                                    }
                                    {itemDetails.U_Watts &&
                                        <div>
                                            <a className="">
                                                <span className="nombreCaracteristica"> WATTS:  </span><b><span className="caracteristica">{itemDetails.U_Watts}</span></b>
                                                {/* <b>   ALTERNOS act : </b>  {itemDetails.U_alterno_Act} */}
                                            </a>
                                        </div>
                                    }
                                    {itemDetails.U_Voltaje &&
                                        <div>
                                            <a className="">
                                                <span className="nombreCaracteristica"> VOLTAJE:  </span><b><span className="caracteristica">{itemDetails.U_Voltaje}</span></b>
                                                {/* <b>   ALTERNOS act : </b>  {itemDetails.U_alterno_Act} */}
                                            </a>
                                        </div>
                                    }
                                    {itemDetails.U_Fases &&
                                        <div>
                                            <a className="">
                                                <span className="nombreCaracteristica"> FASES:  </span><b><span className="caracteristica">{itemDetails.U_Fases}</span></b>
                                                {/* <b>   ALTERNOS act : </b>  {itemDetails.U_alterno_Act} */}
                                            </a>
                                        </div>
                                    }
                                    {itemDetails.U_Amperaje &&
                                        <div>
                                            <a className="">
                                                <span className="nombreCaracteristica"> AMPERAJE:  </span><b><span className="caracteristica">{itemDetails.U_Amperaje}</span></b>
                                                {/* <b>   ALTERNOS act : </b>  {itemDetails.U_alterno_Act} */}
                                            </a>
                                        </div>
                                    }
                                    {itemDetails.U_Hertz &&
                                        <div>
                                            <a className="">
                                                <span className="nombreCaracteristica"> HERTZ:  </span><b><span className="caracteristica">{itemDetails.U_Hertz}</span></b>
                                                {/* <b>   ALTERNOS act : </b>  {itemDetails.U_alterno_Act} */}
                                            </a>
                                        </div>
                                    }
                                    {itemDetails.U_Rpm &&
                                        <div>
                                            <a className="">
                                                <span className="nombreCaracteristica"> RPM:  </span><b><span className="caracteristica">{itemDetails.U_Rpm}</span></b>
                                                {/* <b>   ALTERNOS act : </b>  {itemDetails.U_alterno_Act} */}
                                            </a>
                                        </div>
                                    }
                                    {itemDetails.U_Tipo_Corriente &&
                                        <div>
                                            <a className="">
                                                <span className="nombreCaracteristica"> TIPO DE CORRIENTE:  </span><b><span className="caracteristica">{itemDetails.U_Tipo_Corriente}</span></b>
                                                {/* <b>   ALTERNOS act : </b>  {itemDetails.U_alterno_Act} */}
                                            </a>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Comentarios y calificación del producto */}
                    {/* 
                    SQL=>
                        itemCode||CardCode||puntuación||Comentario
                    Insertes => todos los campos
                    Consultes => AVG (puntuación ) where itemcode = articulo secccionado
                            => comentarios where itemcode = articulo secccionado
                     */}

                    {/**comentarios  */}
                    {config.modules.Comments && 
                    <div className="container-fluid commentProduct">                        
                        <div className="col-md-11 container-fluid">
                            <div className="nombreArticulo font-weight-bold" style={{textAlign: 'start'}}>
                                <p>Opiniones sobre el producto</p>
                            </div>
                            <div className="text-center" style={{display:Opinion === false ? 'block' : 'none', color: '#959595'}}>
                                <h1>¡Nada por aquí!</h1>
                                <br></br>
                                <h2>Este producto aún no tiene opiniones</h2>
                            </div>
                            
                            <div className=" justify-content-left pt-4" style={{display:Opinion === true ? 'block' : 'none' }}>
                                <div className="">
                                    <div className="col">
                                        {!!Valores && Valores.map((prom, index) =>{
                                            return(
                                                <div key = {index}>
                                                    <span className="califProducto">{parseFloat(prom.Promedio || 0).toFixed(1)}</span >
                                                    <ReactStars
                                                        count={5}
                                                        value= {parseInt(prom.Promedio || 0)}
                                                        edit= {false}
                                                        size={60}
                                                        isHalf={true}
                                                        emptyIcon={<i className="far fa-star"></i>}
                                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                        fullIcon={<i className="fa fa-star"></i>}
                                                        activeColor="#ffd700"
                                                    />                                                    
                                                    <span className="promedioProducto">Promedio entre {parseInt(prom.NumCommnt)} opiniones</span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div><hr/>
                            <div  style={{display:Opinion === true ? 'block' : 'none' }}>
                                <div className="row">
                                    <div className="col">
                                        {!!Comentarios && Comentarios.map((raiting, index ) => {
                                            return(
                                                <div>
                                                    <div style={{paddingBottom:0}}>
                                                        <ReactStars
                                                            count={5}
                                                            onChange={(event) => {this.ratingChanged(event)}}
                                                            value= {raiting.calificacion}
                                                            edit= {false}
                                                            size={30}
                                                            isHalf={true}
                                                            emptyIcon={<i className="far fa-star"></i>}
                                                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                            fullIcon={<i className="fa fa-star"></i>}
                                                            activeColor="#ffd700"
                                                        />
                                                    </div>
                                                    {/* <br></br> */}
                                                    <label><b>{raiting.titulo}</b></label>
                                                    <div className="text-justify" id="comment" role="tabpanel" aria-labelledby="home-tab" style={{paddingBottom:20}}>
                                                        <div style={{maxHeight: "332px", overflow: "scroll"}}>{raiting.comentario}</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        }
                                    </div>
                                </div>
                                        <u onClick={() => this.OpenOpinions()}>Ver todas las opiniones</u>
                            </div>
                            <div style={{display:comentar === true ? 'block' : 'none' }}>
                            <div className="row" style={{justifyContent: "center", alignItems: "center"}}>
                                <div className="col-lg-3">
                                    <ReactStars
                                        count={5}
                                        onChange={(event) => {this.ratingChanged(event)}}
                                        value= {rating}
                                        size={60}
                                        isHalf={true}
                                        emptyIcon={<i className="far fa-star"></i>}
                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                        fullIcon={<i className="fa fa-star"></i>}
                                        activeColor="#ffd700"
                                    />
                                </div>
                                <div className="col-lg-3 nuevoComentario">
                                    <label>Escribe un comentario.</label>
                                    <input type="text" className="form-control" name="titulo"  placeholder="Pon aqui el título" autoComplete="off" onChange={this.handelChange} value={titulo ? titulo : ''}/>
                                    <textarea placeholder="Coloque aquí su comentario" name="comentario" value={comentario ? comentario : ''} className="form-control mt-2" onChange={this.handelChange}/>
                                    <div className="buttonEnviar mt-2">
                                        <button type="button" className="btn" onClick={(event)=>{this.sendComment(itemDetails)}}>
                                            Enviar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                        </div>
                    </div>
                    }
                    
                    {/**funcion y validacion para pintatr los productos */}
                    <div className="justify-content-center">
                        <div className="col-md-12">
                            <div style={{ paddingTop: 15, paddingBottom: 15}}>
                                <div style={{ paddingTop: 30, paddingBottom: 100, margin: 0, justifyContent: "center", alignItems: "center" }}>
                                    {sliderDetailsArrayNoDuplicated &&
                                        sliderDetailsArrayNoDuplicated.length !== 0 ? (
                                            <>
                                                <p className="titulodeSeccionPromoRed">Más productos</p>
                                                <ItemSlider
                                                    items={sliderDetailsArrayNoDuplicated}
                                                    updateFavorite={updateFavorite}
                                                    openDetails={openItemDetails}
                                                    changeQuantity={this.changeQuantity}
                                                    deleteShoppingCart={deleteShoppingCart}
                                                    changeBackOrder={this.changeBackOrder}
                                                    dashboard={30}
                                                />
                                            </>
                                        ) : (
                                            <div style={{ paddingTop: 100, paddingBottom: 100, margin: 0, textAlign: "center" }}>No existen más ARTÍCULOS relacionados con esta categoría:
                                                <br />
                                                <br />
                                                <i style={{ fontSize: 70 }} className={config.icons.search}></i>
                                            </div>
                                        )}
                                </div>
                            </div>
                            {/* : <div style={{ paddingTop: 15, paddingBottom: 15}}>
                                <div  style={{ paddingTop: 30, paddingBottom: 100, margin: 0, justifyContent: "center", alignItems: "center" }}>
                                    {sliderDetailsArrayNoDuplicated &&
                                        sliderDetailsArrayNoDuplicated.length !== 0 ? (
                                            <ItemSliderResponsive
                                                items={sliderDetailsArrayNoDuplicated}
                                                updateFavorite={updateFavorite}
                                                openDetails={openItemDetails}
                                                changeQuantity={this.changeQuantity}
                                                deleteShoppingCart={deleteShoppingCart}
                                                changeBackOrder={this.changeBackOrder}
                                            />
                                        ) : (
                                            <div style={{ paddingTop: 100, paddingBottom: 100, margin: 0, textAlign: "center" }}>No existen más ARTÍCULOS relacionados con esta categoría:
                                                <br />
                                                <br />
                                                <i style={{ fontSize: 70 }} className={config.icons.search}></i>
                                            </div>
                                        )}
                                </div>
                            </div>} */}
                        </div>
                    </div>
                    <div className="modalImagen">
                        <div id="myModal" className="modal">   
                            <div className="modal-content"> 
                                <img  id="img01"/>
                                <span className="close" onClick={this.cerrarModalImage}>&times;</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTags: value => dispatch({type: DISPATCH_ID.ITEMS_SAVE_TAGS, value}),
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
        setItemDetailsSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SET_ITEM_DETAILS, value}),
        setItemsSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SET_ITEMS, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ItemsDetailsView);