import React, {Component} from 'react';
import {Footer, NavBar, Session} from "../../components";
import {config, DISPATCH_ID, SERVICE_API, VIEW_NAME, ROLES, SERVICE_RESPONSE,licence} from "../../libs/utils/Const";
import {connect} from "react-redux";
import {PayPalButton} from "react-paypal-button";
import {ApiClient} from "../../libs/apiClient/ApiClient";
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';

const apiClient = ApiClient.getInstance();
// Definicion de un arreglo para el producto Flete
let responseFlete = {ItemCode:'',ItemName:'',Price:'0',PriceList:'0',PurchaseLimit:''};

let paypalOptions = {
    clientId: config.paymentMethod.paypal.clienId,
    intent: 'capture',
    commit: true
};

const buttonStyles = {
    layout: 'vertical',
    shape: 'pill',
    color: 'silver'
};

class ValidateOrderView extends Component {
    state = {
        address: {},
        bill: {},
        paymentMethod: '',
        totalOrder: 0,
    };

    async componentDidMount() {
        const {history, sessionReducer: {role}} = this.props;
        //Mandar traer el flete
        let responseApi = await this.getRegisterPack();

        let item_code = responseApi.data[0].ItemCode;
        let item_name = responseApi.data[0].ItemName;
        let price = responseApi.data[0].Price;
        let price_list = responseApi.data[0].PriceList;
        let purchase_limit = responseApi.data[0].PurchaseLimit;
        //Asigancion de los valores al arreglo del front
        responseFlete.ItemCode = item_code;
        responseFlete.ItemName = item_name;
        responseFlete.Price = price;
        responseFlete.PriceList = price_list;
        responseFlete.PurchaseLimit = purchase_limit;
        //console.log("Hola",responseFlete);
        let data = sessionStorage.getItem('validateOrder');
        data = JSON.parse(data || '{}');
        const {date, paymentMethod, address, bill} = data;

        let newDate = new Date();
        let diferent = newDate.getTime() - (date ? new Date(date).getTime() : 0);

        if (diferent > 30000) {
            history.push('/shoppingCart');
        }
        let newAddress = {};
        let newBill = {};
        if (role === ROLES.PUBLIC) {
            newAddress.name = address.name || '';
            newAddress.email = address.email || '';
            newAddress.phone = address.phone || '';
            newAddress.street = address.street || '';
            newAddress.block = address.suburb || '';
            newAddress.city = address.city || '';
            newAddress.cp = address.cp || '';
            newAddress.state = address.stateName || '';
            newAddress.country = address.countryName || '';
        }

        if (role === ROLES.CLIENT) {
            newAddress.address = address.Address || '';
            newAddress.street = address.Street || '';
            newAddress.block = address.Block || '';
            newAddress.city = address.City || '';
            newAddress.cp = address.ZipCode || '';
            newAddress.state = address.StateName || '';
            newAddress.country = address.CountryName || '';
        }
        if (role === ROLES.CLIENT) {
            newBill.address = bill.Address || '';
            newBill.street = bill.Street || '';
            newBill.block = bill.Block || '';
            newBill.city = bill.City || '';
            newBill.cp = bill.ZipCode || '';
            newBill.state = bill.StateName || '';
            newBill.country = bill.CountryName || '';
        }

        this.setState({
            address: newAddress,
            bill: newBill,
            paymentMethod: paymentMethod || '',
        })
    }
    getRegisterPack = async () => {
        //this.state(await apiClient.getFlete());
        return await apiClient.getFlete();
    }

    getRegisterPack = async () => {
        //this.state(await apiClient.getFlete());
        return await apiClient.getFlete();
    }

    renderItem = (item,index) => {

        return <div key={index} className='row' style={{padding: 10, textAlign: 'center'}}>
            {!(!!item.OnHand) && <div className='col-12' style={{
                marginBottom: 5,
                padding: 5,
                backgroundColor: config.navBar.backgroundColor, color: config.navBar.textColor
            }}>
                Agotado
            </div>}
            <div className='col-sm-4' style={{margin: 0}}>
                <img
                    className="img-fluid "
                    style={{
                        backgroundColor: 'white',
                        maxHeight: 130
                    }}
                    src={item.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + item.PicturName) : require('../../images/noImage.png')}
                    alt=""
                />
            </div>


            <div className='col-sm-8'>
                <div className="container p-0">
                    <div className="row">
                        <div className="col-12" style={{borderBottom: '1px solid rgb(124, 124, 125)'}}>
                            <div className='text-left'>
                                {item.ItemName}
                            </div>
                        </div>
                    </div>
                    <div className="row font-weight-bold">
                        <div className="col-2 p-0">
                            Clave
                        </div>
                        <div className="col-3 p-0">
                            Cantidad
                        </div>
                        <div className="col-3 p-0">
                            Precio unitario
                        </div>
                        <div className="col-3 p-0">
                            Precio total
                        </div>
                        <div className="col-1 p-0">
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-2 p-0 pt-2 font-italic">
                            {item.ItemCode}
                        </div>
                        <div className="col-3 p-0 pt-2">
                            {item.quantity ? Number(item.quantity) : ''}
                        </div>
                        <div className="col-3 p-0 pt-2">
                            <CurrencyFormat 
                                value={item.Price} 
                                displayType={'text'} 
                                thousandSeparator={true} 
                                fixedDecimalScale={true} 
                                decimalScale={2} 
                                prefix={'$'}>
                            </CurrencyFormat>
                            {item.currency}
                        </div>
                        <div className="col-3 p-0 pt-2">
                            <CurrencyFormat 
                                value={item.Price * item.quantity} 
                                displayType={'text'} 
                                thousandSeparator={true} 
                                fixedDecimalScale={true} 
                                decimalScale={2} 
                                prefix={'$'}>
                            </CurrencyFormat>
                            {item.currency}
                        </div>
                        <div className="col-1 p-0 pt-1" style={{color: 'red', fontSize: 20, paddingTop: 5}}>
                        </div>
                    </div>
                </div>
                {!(!!item.OnHand) && 
                <div className='row text-center' style={{margin: 0, padding: 0}}>
                    <div className='col-2' style={{
                        color: item.OnHand ? 'rgb(13, 98, 168)' : 'rgb(124, 124, 125)',
                        fontSize: 30,
                        padding: 0
                    }}>
                    </div>
                </div>}

            </div>

        </div>
    };

    renderAddress = () => {
        const {address} = this.state;

        let addressComplete = (address.street || '') + ' ' + (address.block || '') + ' ' + (address.city || '') + ' C.P. ' + (address.cp || '') + ', ' + (address.state || '') + ', ' + (address.country || '') + '.';
        let name = address.name;
        let email = address.email;
        let phone = address.phone;

        return <div>
            {name && <div className='col-12'>Nombre de comprador: {name}</div>}
            {email && <div className='col-12'>Email de comprador: {email}</div>}
            {phone && <div className='col-12'>Teléfono de comprador: {phone}</div>}

            <div>
                {addressComplete}
                <br/>
            </div>
        </div>
    };


    sendOrder = async response => {
        const {enableSpinner, sessionReducer: {role}, notificationReducer: {showAlert}, itemsReducer: {deleteShoppingCart}, configReducer: {history}} = this.props;
        const {address, bill} = this.state;
        let shoppingCart = [];
        let today = moment().format('YYYY-MM-DD');

        if(today > licence){
            showAlert({type: 'error', message: 'Su licencia ha expirado, porfavor contacte a FMB Solutions'});
            return;
        }

        enableSpinner(true);

        if (role === ROLES.PUBLIC) {
            let localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');

            localShoppingCart = JSON.parse(localShoppingCart);

            if (!localShoppingCart) {
                localStorage.setItem(config.general.localStorageNamed + 'shoppingCart', JSON.stringify([]));
            }
            //console.log(localShoppingCart)
            shoppingCart = localShoppingCart || []
        }/*else{
            let localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');
            localShoppingCart = JSON.parse(localShoppingCart);
            if (!localShoppingCart) {
                localStorage.setItem(config.general.localStorageNamed + 'shoppingCart', JSON.stringify([]));
            }
            //console.log("Almacenado en el Local Storage cobro", localShoppingCart);
            
            shoppingCart = localShoppingCart || []
        }*/

        let data = {
            shoppingCart,
            address,
            bill,
            objType: '17',
            responseFlete,
        };

        //console.log("se va para crear",data);

        let apiResponse = await apiClient.createDocument(data);

        //console.log(apiResponse);

        if (apiResponse.status === SERVICE_RESPONSE.SUCCESS) {
            deleteShoppingCart({item: {}, deleteAll: true});
            enableSpinner(false);
            localStorage.setItem(config.general.localStorageNamed + 'createOrder', apiResponse.data.docNum);
            history.goCreateOrder();
            return;
        }

        showAlert({type: 'error', message: apiResponse.message});
        enableSpinner(false)

    };

    renderPaymentMethod = (total = null, currency = null) => {
        const { paymentMethod, totalOrder, url_mp, address, bill,discPrcnt, discPnt } = this.state;
        const {enableSpinner} = this.props;
        let shoppingCart = [];
        if (paymentMethod === 'paypal') {
            paypalOptions.currency = currency;
            if (total && currency) return <div>
                <PayPalButton
                    paypalOptions={paypalOptions}
                    buttonStyles={buttonStyles}
                    onPaymentStart={() => {
                        //console.log("start payment paypal");
                        enableSpinner(true)
                    }}
                    onPaymentCancel={() => {
                        //console.log("cancel payment paypal");
                        enableSpinner(false)
                    }}
                    onPaymentError={(msg) => {
                        //console.log("error payment paypal", msg);
                        enableSpinner(false)
                    }}
                    onPaymentSuccess={this.sendOrder}
                    amount={total}
                />
            </div>
        }
        if (paymentMethod === 'mercadoPago') {
            let url_mp = localStorage.getItem(config.general.localStorageNamed + 'goToMP');
            let data = {
                shoppingCart,
                address,
                bill,
                objType: '23',
                responseFlete,
                pagoCon: paymentMethod,
            };

            localStorage.setItem(config.general.localStorageNamed + "Envio", JSON.stringify(data));
            // console.log(url_mp);

            return <div>
                {/* <input type="button" className="btn btn-success" value="Pagar con MercadoPago" onClick={() => { modal.mercadoPagoModal('show') }} /> */}
                <a href={url_mp} >
                    <input type="button" className="btn btn-success btn-block" value="Mercado Pago" />
                </a>
            </div>
        }
    };

    totalOrder = () => {
        const {shoppingCartReducer: {items}} = this.props;
        let subTotal = 0;
        let taxTotal = 0;
        let total = 0;
        let tax = 0;
        let localLanguage = '';
        let currency = '';
        //Variables para validacion del Flete
        let transport = 0;
        let taxTransport = 0;
        let limit = 0;
        items.map(item => {
            let totalPrice = Number(item.Price * item.quantity);
            subTotal += totalPrice;
            tax = item.taxRate;
            taxTotal += parseFloat(item.taxSum * item.quantity);
            localLanguage = item.localLanguage;
            // currency = item.currency;
        });
        //Asignacion de valores para el flete
        limit = parseInt(responseFlete.PurchaseLimit);
        transport = parseFloat(responseFlete.Price);
        taxTransport = parseFloat(transport*(tax*0.01));
        //Validacion del flete
        if(subTotal < limit){
            taxTotal = taxTotal + taxTransport;
            total = subTotal + transport + taxTotal;
        }else{
            transport = 0;
            total = subTotal + transport + taxTotal;
        }

        //total = subTotal + taxTotal;
        return {total: total.toFixed(2), currency: currency === 'MXP' ? 'MXN' : currency};
    };

    render() {
        const {history, sessionReducer: {role}, shoppingCartReducer: {items}} = this.props;

        let currency = this.totalOrder().currency;
        let total = this.totalOrder().total;
        //console.log(total);

        return (
            <div className="content-fluid" style={{marginTop: 260}}>
                <Session history={history} view={VIEW_NAME.VALIDATE_ORDER_VIEW}/>
                <NavBar/>

                <div className="row"  style={{marginTop: 100, marginLeft: 40, marginRight: 40,  minHeight: '70vh'}}>
                    <div className="col-sm-8">
                        <div className="card style-articles-cart" style={{ borderColor: 'white', border: '1px solid  rgba(124, 124, 125, 0.3)'}}>
                            <div className="card-header" style={{background: config.shoppingList.productList}}>
                                <h4 className="card-title" style={{color: config.shoppingList.textProductList}}>
                                    Últimos detalles <spam className="small"></spam>
                                </h4>
                            </div>
                            <div className="card-body">
                                {items.map((item,index) => {
                                        return this.renderItem(item,index);
                                    })}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="card" style={{ borderColor: 'white', backgroundColor: 'transparent', border: '1px solid  rgba(124, 124, 125, 0.3)'}}>
                            <div className="card-header text-white" style={{background: config.shoppingList.summaryList}}>
                                <div className="card-title">
                                    <h5 style={{color: config.shoppingList.textsummaryList}}>Detalles del envío</h5>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12">
                                        <span className="font-weight-bold" style={{fontSize: 17}}>Dirección de envío</span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {this.renderAddress()}
                                    </div>
                                </div>
                                <div className="row bg-light">
                                    <div className="col-5">
                                        <span className="font-weight-bold" style={{fontSize: 17}}>Total a pagar</span>
                                    </div>
                                    <div className="col-7 text-right">
                                        <span className="text-right" style={{fontSize: 18, color: config.itemsList.textPrice}}>
                                            <CurrencyFormat 
                                                value={total} 
                                                displayType={'text'} 
                                                thousandSeparator={true} 
                                                fixedDecimalScale={true} 
                                                decimalScale={2} 
                                                prefix={'$'}>
                                            </CurrencyFormat>
                                            {currency}
                                        </span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <span className="font-weight-bold" style={{fontSize: 17}}>Método de pago</span>
                                    </div>
                                </div>
                                {this.renderPaymentMethod(total, currency)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
        shoppingCartReducer: store.ShoppingCartReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ValidateOrderView);