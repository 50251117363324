import React, {Component} from 'react';
import {Footer, NavBar, Session,BonificacionesModal, NotificationsModal } from "../../components";
import {VIEW_NAME, config, SERVICE_API, DISPATCH_ID, SERVICE_RESPONSE} from "../../libs/utils/Const";
import {connect} from 'react-redux';
import {ApiClient} from "../../libs/apiClient/ApiClient";
import $ from 'jquery';
import CurrencyFormat from 'react-currency-format';
import moment from 'moment';
import {CSVLink, CSVDownload} from "react-csv";

let apiClient = ApiClient.getInstance();

class AnalyticsView extends Component {

    constructor(props) {
        super(props);

        this.state = {
            lists : [],
            order: {
                header: {},
                body: []
            },
            csvData : [],
            fechaInicio: '',
            fechaFinal: '',
            fechaActual : '',
            tipo: '0',
        };
        this.tableUno = null;
        this.tableDos = null;
        this.tableTres = null;
        this.tableCuatro = null;
    };

    async componentDidMount(){
        let fechaInicio = moment(new Date()).format('YYYY-MM-DD');
        let fechaFinal = moment(new Date()).format('YYYY-MM-DD');
        this.setState({
            fechaInicio,
            fechaFinal
        });
        setTimeout(() => {
            this.cargarDatos();
        }, 2000);


        // this.table.destroy();
    };

    async cargarDatos (){
        const {enableSpinner} = this.props;
        const {fechaInicio, fechaFinal, tipo} = this.state;
        enableSpinner(true);
        let seller = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser'));
        let user = localStorage.getItem(config.general.localStorageNamed + 'CurrentUser');
        user = JSON.parse(user) || {}; 

        let data ={
            fechaInicio : fechaInicio,
            fechaFinal : fechaFinal,
            tipo: tipo
        }
        // if(tipo === '0'){
            let newdatas = await apiClient.getAnalytics(data);
            if (newdatas.status === SERVICE_RESPONSE.SUCCESS) {
            
                this.setState({
                    lists : newdatas.data.list,
                });

                enableSpinner(false);
                return;
            }
        // }

        this.setState({
            CardName : user.CardName,
            fechaActual : moment(new Date()).format('YYYY-MM-DD')
        });
        this.tableUno = $('#tableUno').DataTable({
            "paging": false,
            "info": false,
            "searching": false
        });
        this.tableDos = $('#tableDos').DataTable({
            "paging": false,
            "info": false,
            "searching": false
        });

        enableSpinner(false);
    };

    Details = async (valor) => {
        const {notificationReducer: { showAlert }, enableSpinner} = this.props;

        enableSpinner(true);
        let responses = await apiClient.getDetailsOrderStatus(valor);
        if (responses.data.list.length > 0 && responses.status === SERVICE_RESPONSE.SUCCESS) {
            this.setState({
                order: responses.data.list,
            });

            $('#detailsOrders').modal('show');
            enableSpinner(false);
            return;
        }
        else{
            this.setState({
                order: [],
            });
            showAlert({ type: 'error', message: 'Los artículos guardados en este carrito ya no son visibles para este cliente',timeOut: 5000 });
            enableSpinner(false);
            return;
        }
    }

    handleInputDateInicio = event =>{
        let fechaInicio = event.nativeEvent.target.value;
        this.setState({
            fechaInicio
        });
    }

    handleInputDateFinal = event =>{
        let fechaFinal = event.nativeEvent.target.value;
        this.setState({
            fechaFinal
        });
    }

    getData = async () =>{        
        this.tableUno.destroy();
        this.tableDos.destroy();
        // if (this.state.orders != nextState.orders) {
        //     //console.log("hay nuevos datos");
        //     this.table.destroy();
        //     this.table = null;
        // }
        this.cargarDatos();
    }

    download = () => {
        const { enableSpinner, notificationReducer: { showAlert }} = this.props;
        const { lists } = this.state;

        // showAlert({ type: 'warning', message: 'Los artículos de bonificacion no se guardan' });

        enableSpinner(true);
        let csvData = [];
        if(lists.length > 0){
            lists.map((data)=>{
                let row ={
                    '':'',
                    'FECHA EMISIÓN': moment(data.DocDate).format('DD-MM-YYYY'),
                    'PEDIDO' : data.DocNum,
                    'CÓDIGO CLIENTE' : data.CardCode,
                    'CLIENTE' : data.CardName, 
                    'DIRECCIÓN DE ENTREGA' : data.Address2,
                    'ZONA' : data.Zona, 
                    'PESO NETO' : data.Neto + ' KG',
                    'VALOR' : 'S/ '+parseFloat(data.DocTotal).toFixed(2),
                    'FACTURA': data.NumAtCard,
                    'CONDICIÓN': data.PymntGroup,
                    'VALIDACION DE PAGO': data.U_SYP_RICO_HABENTR === 'Y' ? 'Aprobado' : 'Pendiente',
                    // 'CLIENTE': '',
                    // 'NOMBRE': '',
                }
                csvData.push(row);
            });
            // csvData[0].CLIENTE = 'user.CardCode';
            // csvData[0].NOMBRE = 'user.CardName';

            this.setState({
                csvData,
                action : 1
            });
        }
        enableSpinner(false)

    };

    tipoAnalytics = (event, option) => {
        if (option == 1) {
            let valor = event.nativeEvent.target.value;
            this.setState({
                tipo : valor,
            })

            if(valor === '0'){
                this.tableUno.destroy();
                // this.tableUno = null;
            }
            if(valor === '1'){
                this.tableDos.destroy();
                // this.tableDos = null;
            }
        }
        setTimeout(() => {
            // this.table.destroy();
            this.cargarDatos();
        }, 200)
    };

    render() {
        const {history} = this.props;
        const {lists, order, fechaInicio, fechaFinal, csvData, fechaActual, tipo} = this.state; 

        return (
            <div className="content-fluid" style={{marginTop: 200, paddingBottom: 20,paddingRight:0, backgroundColor: config.Back.backgroundColor}}>
                <Session history={history} view={VIEW_NAME.PROMO_VIEW}/>
                <NavBar/>

                <div className="container mb-4" style={{paddingTop: 60}}>
                    <div className="row">
                        <div className="col">
                            <div className="jumbotron" style={{paddingTop:'10px', paddingBottom:'10px'}}>
                                <h1 className="display-6 text-center">IRCO Analytics</h1>
                            </div>
                        </div>    
                    </div>
                </div> 
                <div className="col-md-11 col-sm-12" style={{marginRight:'auto', marginLeft:'auto'}}>
                    <div className="row text-center" style={{marginBottom: 16, marginTop: 16}}>
                        <div className=" row col-md-4">
                            <h4 className="pr-2">Desde:</h4>
                            <input 
                                id="fechaInicio"
                                type="date" 
                                className="form-control col-md-6" 
                                name="fechauno" 
                                max={fechaActual}
                                value = {fechaInicio}
                                onChange = {(event) => this.handleInputDateInicio(event)}/>
                        </div>
                        <div className="row col-md-4 pb-3">
                            <h4 className="pr-2">Hasta:</h4>
                            <input 
                                id="FechaFin"
                                type="date" 
                                className="form-control col-md-6" 
                                name="fechados" 
                                max={fechaActual}
                                value = {fechaFinal}
                                onChange = {(event) => this.handleInputDateFinal(event)}/>
                        </div>
                        <div className="col-md-2 pb-2">
                            <select name="select" className="btn-outline-secondary" id="selectDocTypeDocumentSearch"
                                style={{color: "#000", borderRadius: 4, backgroundColor: "transparent", height: 40, width:'100%'}}
                                onChange={(event) => this.tipoAnalytics(event, 1)}
                                >
                                <option value="0">Accesos</option>
                                <option value="1">Busquedas</option>
                                <option value="2">Detalle</option>
                                <option value="3">Atendidos</option>
                            </select>
                        </div>
                        <div className="col-md-2 pb-2">
                            <button
                                onClick={()=>this.getData()}
                                className="btn botonResumen" 
                                style={{
                                    backgroundColor: config.navBar.menuCategoriesBackgroundHover,
                                    color: 'white',
                                    fontWeight: "bold",
                                }}>
                                Ver pedidos
                            </button>
                        </div>
                    </div>  
                
                    <div className="table-responsive" style={{marginBottom: 0, height: 400, maxHeight: 400, display:tipo === '0' ? 'block' : 'none' }}> 
                        <table id="tableUno" className="table table-hover scrolltable" style={{marginRight: 0, marginLeft: 0}}>
                            <thead >
                                <tr className="text-light text-center" style={{background: '#2d75bd', borderRadius: '0', width:'100%'}} >
                                <th scope="col">#</th>
                                <th scope="col">Cód. Cliente</th>
                                <th scope="col">Nombre</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Hora</th>
                                <th scope="col">Tipo de usuario</th>
                                <th scope="col">Correo</th>
                                <th scope="col">IP</th>
                                <th scope="col">Estatus</th>
                                {/* <th scope="col" style={{paddingRight: 0, paddingLeft: 0, width: '90px'}}></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {tipo === '0' && !!lists && lists.map((cart, index ) => {
                                    return(
                                        <tr className="text-center">
                                            <th scope="row">{index+1}</th>
                                            <td style={{padding: 0}}>{cart.CardCode}</td>
                                            <td style={{padding: 0}}>{cart.CardName}</td>
                                            <td style={{padding: 0}}>{moment(cart.Date).format('DD-MM-YYYY')}</td>
                                            <td style={{padding: 0}}>{cart.Time}</td>
                                            <td style={{padding: 0}}>{cart.TypeUser}</td>
                                            <td style={{padding: 0}}>{cart.Email}</td>
                                            <td style={{padding: 0}}>{cart.IP ? cart.IP.substring(7,22) : ''}</td>
                                            <td style={{padding: 0}}>{cart.Session === 1 ? 'Entro' : 'Salio'}</td>
                                            {/* <td style={{padding: 0}}>
                                                <button
                                                    className="btn btn-sm"
                                                    type="button"
                                                    style={{ backgroundColor: config.navBar.iconBackground, color: config.navBar.iconModal }}
                                                    onClick={() => this.Details(cart.DocEntry)}> 
                                                   Visualizar
                                                </button>
                                            </td> */}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div> 
                    <div className="table-responsive" style={{marginBottom: 0, height: 400, maxHeight: 400, display:tipo === '1' ? 'block' : 'none' }}> 
                        <table id="tableDos" className="table table-hover scrolltable" style={{marginRight: 0, marginLeft: 0}}>
                            <thead >
                                {/* [],[] ,[] ,[] ,[],[] */}
                                <tr className="text-light text-center" style={{background: '#2d75bd', borderRadius: '0', width:'100%'}} >
                                <th scope="col">#</th>
                                <th scope="col">Cód. Cliente</th>
                                <th scope="col">¿Qué busco?</th>
                                <th scope="col">Fecha</th>
                                <th scope="col">Hora</th>
                                <th scope="col">Origen de busqueda</th>
                                <th scope="col">Núm. Resultados</th>
                                {/* <th scope="col" style={{paddingRight: 0, paddingLeft: 0, width: '90px'}}></th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {!!lists && lists.map((cart, index ) => {
                                    return(
                                        <tr className="text-center">
                                            <th scope="row">{index+1}</th>
                                            <td style={{padding: 0}}>{cart.cve_user}</td>
                                            <td style={{padding: 0}}>{cart.search}</td>
                                            <td style={{padding: 0}}>{moment(cart.date).format('DD-MM-YYYY')}</td>
                                            <td style={{padding: 0}}>{cart.time}</td>
                                            <td style={{padding: 0}}>{cart.origin}</td>
                                            <td style={{padding: 0}}>{cart.num_results}</td>
                                            {/* <td style={{padding: 0}}>
                                                <button
                                                    className="btn btn-sm"
                                                    type="button"
                                                    style={{ backgroundColor: config.navBar.iconBackground, color: config.navBar.iconModal }}
                                                    onClick={() => this.Details(cart.DocEntry)}> 
                                                   Visualizar
                                                </button>
                                            </td> */}
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div> 
                    <br></br>

                    <CSVLink
                        className="btn btn-success float-left"
                        onClick={this.download}
                        style={{
                            color: config.navBar.textColorCategorieHover,
                            fontWeight: "bold",
                        }}
                        data={csvData} filename={"EstadoDePedidos.csv"}>
                            Descargar Excel <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                    </CSVLink>
                    <br></br>
                    <br></br>
                </div>

                <div class="modal fade bd-example-modal-xl" id="detailsOrders" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className="modal-header text-light" style={{background: '#2d75bd', borderRadius: '0' }}>
                                <h4 className="modal-title" id="modal-basic-title ">Detalle del pedido</h4>

                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span className="text-white" aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div className="modal-body bg3">
                                {order && order.length > 0 && order.map(item => {
                                    return <div key={item.ItemCode} className="row">
                                        <div className="col-md-3 text-center">
                                            <img className="img-fluid" style={{ backgroundColor: 'white', maxHeight: 130 }}
                                                src={item.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + item.PicturName) : require('../../images/noImage.png')}
                                                alt=""
                                            />
                                        </div>
                                        <div className="col-md-8 table-responsive">
                                            <div className="container p-0">
                                                <div className="row text-left">
                                                    <div className="col-md-12"  style={{borderBottom: '1px solid rgb(124, 124, 125)'}}>
                                                        {item.ItemName}
                                                    </div>
                                                </div>
                                                <div className="row font-weight-bold ">
                                                    <div className="col-md-3 text-center">
                                                        Código
                                                    </div>
                                                    <div className="col-md-3 text-center">
                                                        Cantidad
                                                    </div>
                                                    <div className="col-md-3 text-center">
                                                        Precio unitario
                                                    </div>
                                                    <div className="col-md-3 text-center">
                                                        Precio total
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-3 text-center">
                                                        {item.ItemCode}
                                                    </div>
                                                    <div className="col-md-3 text-center">
                                                        {parseInt(item.Quantity)}
                                                    </div>
                                                    <div className="col-md-3 text-center">
                                                        <CurrencyFormat
                                                            value={item.Price}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            fixedDecimalScale={true}
                                                            decimalScale={2}
                                                            prefix={config.general.currency}>
                                                        </CurrencyFormat>
                                                    </div>
                                                    <div className="col-md-3 text-center">
                                                        <CurrencyFormat
                                                            value={item.LineTotal}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            fixedDecimalScale={true}
                                                            decimalScale={2}
                                                            prefix={config.general.currency}>
                                                        </CurrencyFormat>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })}

                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        );
    }
}

const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AnalyticsView);