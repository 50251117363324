import React, {Component} from 'react';
import {Session, OrderDetailsModal} from "../../components";
import {DISPATCH_ID, SERVICE_API, SERVICE_RESPONSE, VIEW_NAME, config} from "../../libs/utils/Const";
import {ApiClient} from "../../libs/apiClient/ApiClient";
import {connect} from "react-redux";
import moment from 'moment';
import $ from 'jquery';
import CurrencyFormat from 'react-currency-format';

require('datatables.net-bs4');

const apiClient = ApiClient.getInstance();

class OrdersView extends Component {
    constructor(props) {
        super(props);
        const f = new Date;
        const newDate = new Date(f.setMonth(f.getMonth() + +(-3)))
        this.state = {
            orders: [],
            order: {
                header: {},
                body: []
            },
            fechaInicio: '',
            fechaFinal: '',
            fechamin: moment(newDate).format('YYYY-MM-DD'),
            fechamax: moment(new Date()).format('YYYY-MM-DD'),
            isLoaded : false,
            guia: []
        };
        this.table = null;
    }

    // Se Manda a traer la funcion de definicion de tabla
    componentDidMount() {
        let fechaInicio = moment(new Date()).format('YYYY-MM-DD')
        let fechaFinal = moment(new Date()).format('YYYY-MM-DD')
        this.setState({
            fechaInicio,
            fechaFinal
        });
        setTimeout(async  () => {
            await this.getData();
        },300);
        this.fillDataOrders();
    }
    // Se llena la tabla con la configuración
    fillDataOrders = () => {
        this.table =  $('#tablaOrder').DataTable({
            "paging": true,
            "info": false,
            "searching": false,
            //"bDestroy": true,	
            "order": [[ 0, 'desc' ]],
            "language": {
                "lengthMenu": "Mostrar _MENU_ registros por página",
                "zeroRecords": "No se encontraron registros",
                "info": "Mostrando página _PAGE_ de _PAGES_",
                "infoEmpty": "No existen registros",
                "infoFiltered": "(filtrado de _MAX_ entradas)",
                "loadingRecords": "Buscando...",
                "processing": "Procesando...",
                "search": "Buscar:",
                "paginate": {
                    "first": "Primero",
                    "last": "Último",
                    "next": "Siguiente",
                    "previous": "Anterior"
                }
            }
        });
    }
    // Se vacia la tabla
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.state.orders != nextState.orders) {
            //console.log("hay nuevos datos");
            this.table.destroy();
            this.table = null;
        }
        return true;
    }
    // se llena la tabla
    componentDidUpdate() {
        if (this.table == null) {
            this.fillDataOrders();
        }
    }

    getData = async ()=>{
        const { fechaInicio,fechaFinal, fechamin } = this.state;
        const {enableSpinner, notificationReducer: {showAlert}} = this.props;
        enableSpinner(true);
        let response = await apiClient.getOrders(!this.state.isLoaded ? fechamin : fechaInicio,fechaFinal);
        enableSpinner(false);
        if (!Array.isArray(response.data)) {
            showAlert({type: 'error', message: 'Ocurrió un error al consultar la Información'});
            return;
        }

        if (response.data.length === 0) {
            showAlert({type: 'info', message: 'No hay Información por mostrar'});
            return;
        }

        this.setState({
            orders: response.data,
        });
    }

    //Evento para capturar los valores de los campos
    handelChange = ({ target }) => {
        const { name, value } = target;
        this.setState({
            [name]: value
        });
    };

    openOrder = async docEntry => {
        const { enableSpinner, notificationReducer: { showAlert } } = this.props;
        enableSpinner(true);
        let response = await apiClient.getOrder(docEntry);
        enableSpinner(false);

        if (response.status === SERVICE_RESPONSE.SUCCESS) {
            this.setState({
                order: response.data,
                guia: response.data.statusGuia || [],
            });

            $('#orderDetailsModal').modal('show');
            return;
        }

        showAlert({ type: 'error', message: response.message })
    };

    handleInputDateInicio = event =>{
        let fechaInicio = event.nativeEvent.target.value;
        this.setState({
            isLoaded : true,
            fechaInicio
        });
    }
    handleInputDateFinal = event =>{
        let fechaFinal = event.nativeEvent.target.value;
        this.setState({
            fechaFinal
        });
    }

    docChangeName(status){
        let result = '';
        switch (status) {
            case 'O':
                result = "Abierto";
                break;
            case 'C':
                result = "Cerrado";
                break;
            default:
                break;
        }
        return result;
    };

    docChangeNameFMB(target,base){
        let result = '';
        let response = target != -1 ? target : base
        
        switch (response) {
            // case 23:
            //     result = "Sin atender";
            //     break;
            case 17:
                result = "Atendido";
                break;
            case 15:
                result = "Surtido";
                break;
            case 13:
                result = "Facturado";
                break;
            default:
                result = 'Atendido';
                break;
        }
        return result;
    };

    render() {
        const { orders, order, fechaInicio, fechaFinal, fechamin, fechamax, guia} = this.state;
        const {configReducer:{currency}} = this.props;
        // const { orders,getData,handleInputDateInicio, handleInputDateFinal, fechaFinal,fechaInicio}  = this.props;
        return (
            <div className="content-fluid" style={{backgroundColor: config.Back.backgroundColor}}>
                <OrderDetailsModal order={order} guia={guia}/>
                <div className="row text-center" style={{marginBottom: 16, marginTop: 16}}>
                    <div className="col-md-12 pb-2">
                        <h2>PEDIDOS</h2>
                    </div>
                </div>
                <div className="row text-center" style={{marginBottom: 16, marginTop: 16}}>
                    <div className=" row col-md-4">
                        <h4 className="pr-2">Desde:</h4>
                        <input 
                            id="fechaInicio"
                            type="date" 
                            className="form-control col-md-6" 
                            name="fechauno" 
                            min={fechamin}
                            max={fechamax}
                            value = {!this.state.isLoaded ? fechamin : fechaInicio}
                            onChange = {(event) => this.handleInputDateInicio(event)}/>
                    </div>
                    <div className="row col-md-4 pb-3">
                        <h4 className="pr-2">Hasta:</h4>
                        <input 
                            id="FechaFin"
                            type="date" 
                            className="form-control col-md-6" 
                            name="fechados" 
                            min={fechamin}
                            max={fechamax}
                            value = {fechaFinal}
                            onChange = {(event) => this.handleInputDateFinal(event)}/>
                    </div>
                    <div className="col-md-2 pb-2">
                        <button
                            onClick={()=> this.getData()}
                            className="btn botonResumen" 
                            style={{
                                backgroundColor: config.navBar.menuCategoriesBackgroundHover,
                                color: config.navBar.textColor2,
                                fontWeight: "bold",
                            }}>
                            Ver pedidos
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 table-responsive tableReports">
                        <table id="tablaOrder" className="table table-striped ">
                            <thead>
                                <tr style={{backgroundColor: config.shoppingList.summaryList, color: "white"}}>
                                    <th scope="col-lg-auto">Fecha de creación</th>
                                    <th scope="col-lg-1">No. de pedido</th>
                                    <th scope="col-lg-1">Dirección de entrega</th>
                                    <th scope="col-lg-1">Estado del documento</th>
                                    <th scope="col-lg-1">Estado del pedido</th>
                                    {/* <th scope="col-lg-1">Total peso neto</th>
                                    <th scope="col-lg-1">Total peso bruto</th> */}
                                    <th scope="col-lg-1">Cant. de articulos</th>
                                    <th scope="col-lg-1">Cant. de unidades</th>
                                    <th scope="col-lg-1">Valor total</th>
                                    <th scope="col-lg-1"></th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map((order, index) => {
                                    return (<tr key={index}>
                                        <th scope="col-lg-auto row" style={{width: '250px'}}>{moment(order.TaxDate).utc().format('YYYY-MM-DD')}</th>
                                        <td className="col-lg-auto" style={{width: '250px'}}>{order.DocNum}</td>
                                        <td className="col-auto" style={{width: '250px'}}>{order.Address2}</td>
                                        <td className="col-lg-auto" style={{width: '250px'}}>{this.docChangeName(order.DocStatus)}</td>
                                        <td className="col-lg-auto" style={{width: '250px'}}>{this.docChangeNameFMB(order.TargetType, order.BaseType)}</td>
                                        {/* <td className="col-lg-auto" style={{width: '250px'}}>{order.Weight1 === null ? '0 KG' : parseFloat(order.Weight1).toFixed(2)+ ' KG'}</td>
                                        <td className="col-lg-auto" style={{width: '250px'}}>{order.Weight2 === null ? '0 KG' : parseFloat(order.Weight2).toFixed(2)+ ' KG'}</td> */}
                                        <td className="col-lg-auto" style={{width: '250px'}}>{parseInt(order.Cant)}</td>
                                        <td className="col-lg-auto" style={{width: '250px'}}>{parseInt(order.Unit)}</td>
                                        <td className="col-lg-auto text-right" style={{width: '250px'}}>
                                            <CurrencyFormat 
                                                value={order.DocTotal} 
                                                displayType={'text'} 
                                                thousandSeparator={true} 
                                                fixedDecimalScale={true} 
                                                decimalScale={2}
                                                prefix={currency.MainCurncy !== (order.DocCur === 'MXN' ? 'MXP' : order.DocCur)  ? '' : currency.DocCurrCod}
                                                suffix = {' ' + currency.MainCurncy}>
                                            </CurrencyFormat>
                                        </td>
                                        <td className="col-lg-auto" style={{width: '250px'}}>
                                            <span onClick={() => this.openOrder(order.DocEntry)}>
                                                <i className={config.icons.detail} style={{ color: config.shoppingList.summaryList, paddingRight: 6 }}></i>
                                                Detalle
                                            </span>
                                        </td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrdersView);