
class History {

    constructor(history){
        this.history = history;
    }

    goHome(){
        this.history.push('/');
    }

    goLogin(){
        this.history.push('/login');
    }
    
    goRedZone(){
        this.history.push('/redZone');
    }

    goResetPoints(){
        this.history.push('/resetPoints');
    }

    goProfile(){
        this.history.push('/profile');
    }

    goOrders(){
        this.history.push('/Orders');
    }
    goBill(){
        this.history.push('/Bill');
    }

    goItems(){
        this.history.push('/items');
    }

    goItemsDetails(){
        this.history.push('/itemsDetails');
    }

    goML(){
        this.history.push('/mercadoLibre');
    }

    goBackOrder(){
        this.history.push('/backOrder');
    }

    goShoppingCart(){
        this.history.push('/shoppingCart');
    }

    goAboutUs(){
        this.history.push('/aboutUs');
    }

    goContactUs(){
        this.history.push('/contactUs');
    }

    goSelectClient(){
        this.history.push('/selector');
    }
    goReports(){
        this.history.push('/reports');
    }

    goSelectAddress(){
        this.history.push('/selectAddress');
    }

    goValidateOrder(){
        this.history.push('/validateOrder');
    }

    goCreateOrder(){
        this.history.push('/createOrder');
    }

    goSelector(){
        this.history.push('/selector');
    }

    goMyProfile(){
        this.history.push('/myprofile')
    }

    goSubirArchivo(){
        this.history.push('/subirArchivo')
    }

    goAutorizaciones(){
        this.history.push('/autorizaciones')
    }

    goCanalModerno(){
        this.history.push('/canalModerno')
    }

    goClaim(){
        this.history.push('/claim');
    }

    goPolitics(){
        this.history.push('/politics');
    }

    goQuestions(){
        this.history.push('/questions');
    }

    goItemsMulos(){
        this.history.push('/itemsMulos');
    }
    
    goItemsBlanca(){
        this.history.push('/itemsBlanca');
    }
    
    goItemsRoutlet(){
        this.history.push('/itemsRoutlet');
    }

    goTerms(){
        this.history.push('/terms');
    }

    goAddress(){
        this.history.push('/addAddress');
    }

    goEditAddress(){
        this.history.push('/editAddress');
    }

    goAccountData(){
        this.history.push('/accountData');
    }

    goSellingPolices(){
        this.history.push('/sellingPolices');
    }

    goRedAliado(){
        this.history.push('/redAliado');
    }

    goPrivacy(){
        this.history.push('/privacy');
    }

    goJob(){
        this.history.push('/job');
    }

    goSucursales(){
        this.history.push('/sucursales');
    }

    goDevolution(){
        this.history.push('/devolutionView');
    }

    goPaymentMethodView(){
        this.history.push('/paymentMethodView');
    }

    goSafeShopping(){
        this.history.push('/safeShoppig');
    }

    goAboutRedZone(){
        this.history.push('/aboutRedZone');
    }

    goAdmiNewsBlog(){
        this.history.push('/admiNewsBlog');
    }

    goNewsBlog(){
        this.history.push('/newsBlog');
    }

    goSpecialPrices(){
        this.history.push('/specialPrices');
    }

    goPromocionales(){
        this.history.push('/promocionales');
    }

    goCatalogos(){
        this.history.push('/catalogo');
    }

    goAnalytics(){
        this.history.push('/analytics');
    }

    goItemsRefacciones(){
        this.history.push('/itemsRefacciones');
    }

    goItemsFood(){
        this.history.push('/itemsFood');
    }

    goItemsPaking(){
        this.history.push('/itemsPaking');
    }

    goItemsFerreteria(){
        this.history.push('/itemsFerreteria');
    }
    
    goQuestionshipping(){
        this.history.push('/questionshipping');
    }

    goBoletin(){
        this.history.push('/boletin');
    }
}

export default History;