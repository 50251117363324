import React, {Component} from 'react';
import {config, SERVICE_API, VIEW_NAME, DISPATCH_ID} from '../libs/utils/Const';
import {connect} from "react-redux";
import {ItemDetailsModal, Pagination, SideBarItems /*, ItemCotczacionModal*/} from "./index";
import CurrencyFormat from 'react-currency-format';
import "./ItemSlider.css";
import { animateScroll as scroll, scroller } from 'react-scroll';
import moment from 'moment';
import $ from "jquery";

class ItemsList extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentPage: null,
            card: true,
            list: false,
            itemSelect: '',
        };
        //this.addCartHover = React.createRef();
        this.mouseOverAddCart = this.mouseOverAddCart.bind(this);
        this.mouseOutAddCart = this.mouseOutAddCart.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.iconMouse = []; 
        this.iconMouseOver = [];     
    }

    componentDidMount = ()=>{
        this.scrollToBottom();
    }
    
    scrollToBottom() {
        scroll.scrollToTop({
            duration: 1500,
            delay: 100,
            smooth: 'easeOutQuart',
            isDynamic: true
          })
    }

    changeQuantity = (index, item, event) =>{
        const {changeQuantity, notificationReducer: {showAlert},itemsReducer: {items: itemsSearch}} = this.props; 
        
        let newQuantity;
        itemsSearch.map( itemFilter => {
            if (itemFilter.ItemCode === item.ItemCode) {
                newQuantity = itemFilter.quantity || '1';
            }
        });

        let onHand = item.OnHandPrincipal;
        if(Number(newQuantity) > Number(onHand)) {
            if (Number(onHand) !== 0 ) {
                showAlert({type: 'warning', message: "Se excede el número de articulos disponibles de este producto", timeOut: 2500});
            }
            newQuantity = onHand;
        } 

        // color azul rinti
        // document.getElementById(item.ItemCode).style.backgroundColor = "#005DA8";
        // document.getElementById(item.ItemCode).style.borderColor = "#005DA8";

        if(!newQuantity){
            changeQuantity(index,item, newQuantity, false); //delete
        }else{
            changeQuantity(index,item, newQuantity, true); // add
        }
    };

    setSepecialPrice = (itemParameter) =>{
        let item = itemParameter;
        const {itemsReducer: {specialPrice}} = this.props;
        
        let CardCode = specialPrice.CardCode;
        let PriceList = specialPrice.ListNum && specialPrice.ListNum !== '' ? specialPrice.ListNum : specialPrice.priceList;
        
        // Special Prices Validation
        //########################################################################################################################
        let priority2 = "*" + PriceList;
        let formattedDocDate = moment().format('YYYY/MM/DD');

        let flagSN = true; // Socio de negocio
        let flagPriceList = true; // Lista de precios
        let flagPricePriority = true;
        let maxQuantity = 0; // Cantidad maxima a alegir
        let priceItem = -1;
        let discount = -1;
        let priceBeforeDisc= -1;

        item.QuantitySpecial = item.quantity;
        item.PriceTest = -1;

        // Socio de negocios
        if(specialPrice.specialPrices.length > 0){
            specialPrice.specialPrices.map((itemPrice) => {
                if (item.ItemCode == itemPrice.ItemCode && itemPrice.CardCode == CardCode) {
                    if (itemPrice.children.length > 0) {
                        itemPrice.children.map((child) => {
                            let inicial = new Date(child.FromDate);
                            let final = new Date(child.ToDate || formattedDocDate);
                            let docDate = new Date(formattedDocDate);
                            
                            inicial.setMinutes(inicial.getMinutes() + inicial.getTimezoneOffset());
                            final.setMinutes(final.getMinutes() + final.getTimezoneOffset());

                            inicial.setHours(0, 0, 0, 0);
                            final.setHours(0, 0, 0, 0);
                            docDate.setHours(0, 0, 0, 0);

                            if (docDate >= inicial && docDate <= final) {
                                if (itemPrice.childrenCantidades.length > 0) {
                                    // Cantidades
                                    itemPrice.childrenCantidades.map((child2) => {
                                        let inicialCantidad = new Date(child2.FromDate);
                                        let finalCantidad = new Date(child2.ToDate || formattedDocDate);

                                        if(docDate >= inicialCantidad && docDate <= finalCantidad){
                                            if ((item.QuantitySpecial || 1) >= child2.Amount) {
                                                if(child2.Amount > maxQuantity) {
                                                    maxQuantity = child2.Amount;
                                                    item.DiscountPercentSpecial = child2.Discount;
                                                    flagPricePriority = false;
                                                    priceItem = parseFloat(child2.Price);
                                                    discount = parseFloat(child2.Discount);
                                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                                }
                                            }
                                        }
                                    });
                                    if(maxQuantity != 0){
                                        flagSN = false;
                                    }
                                    if(flagSN){
                                        // Fechas
                                        item.DiscountPercentSpecial = child.Discount;
                                        flagPricePriority = false;
                                        priceItem = parseFloat(child.Price);
                                        discount = parseFloat(child.Discount);
                                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                        flagSN = false;
                                    }
                                } else {
                                    // Fechas
                                    item.DiscountPercentSpecial = child.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(child.Price);
                                    discount = parseFloat(child.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                    flagSN = false;
                                }
                            } else {
                                // General
                                if(flagSN){
                                    item.DiscountPercentSpecial = itemPrice.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(itemPrice.Price);
                                    discount = parseFloat(itemPrice.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                }
                            } 
                        })
                    } else {                           
                        //General
                        item.DiscountPercentSpecial = itemPrice.Discount;
                        flagPricePriority = false;
                        priceItem = parseFloat(itemPrice.Price);
                        discount = parseFloat(itemPrice.Discount);
                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                    }
                }
            });
        }
        // Lista de inventario
        if(specialPrice.specialPricesList.length > 0 && flagPricePriority){
            specialPrice.specialPricesList.map((itemPrice2)  => {
                if(itemPrice2.ItemCode == item.ItemCode && itemPrice2.CardCode == priority2){
                    if (itemPrice2.children.length > 0) {
                        itemPrice2.children.map((child)=> {

                            let inicial = new Date(child.FromDate);
                            let final = new Date(child.ToDate || formattedDocDate);
                            let docDate = new Date(formattedDocDate);
                            
                            inicial.setMinutes(inicial.getMinutes() + inicial.getTimezoneOffset());
                            final.setMinutes(final.getMinutes() + final.getTimezoneOffset());

                            inicial.setHours(0, 0, 0, 0);
                            final.setHours(0, 0, 0, 0);
                            docDate.setHours(0, 0, 0, 0);
                            if (docDate >= inicial && docDate <= final) {
                                if (itemPrice2.childrenCantidades.length > 0) {
                                    // Cantidades
                                    itemPrice2.childrenCantidades.map((child2) => {
                                        let inicialCantidad = new Date(child2.FromDate);
                                        let finalCantidad = new Date(child2.ToDate || formattedDocDate);

                                        if(docDate >= inicialCantidad && docDate <= finalCantidad){
                                            if ((item.QuantitySpecial || 1) >= child2.Amount) {
                                                if(child2.Amount > maxQuantity) {
                                                    maxQuantity = child2.Amount;
                                                    item.DiscountPercentSpecial = child2.Discount;
                                                    flagPricePriority = false;
                                                    priceItem = parseFloat(child2.Price);
                                                    discount = parseFloat(child2.Discount);
                                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                                }
                                            }
                                        }
                                    });
                                    if(maxQuantity != 0){
                                        flagPriceList = false;
                                    }
                                    if(flagPriceList){
                                        // Fechas
                                        item.DiscountPercentSpecial = child.Discount;
                                        flagPricePriority = false;
                                        priceItem = parseFloat(child.Price);
                                        discount = parseFloat(child.Discount);
                                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                        flagPriceList = false;
                                    }
                                } else {
                                    // Fechas
                                    item.DiscountPercentSpecial = child.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(child.Price);
                                    discount = parseFloat(child.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                    flagPriceList = false;
                                }
                            } else {
                                // General
                                if(flagPriceList){
                                    item.DiscountPercentSpecial = itemPrice2.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(itemPrice2.Price);
                                    discount = parseFloat(itemPrice2.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                }
                            } 
                        })
                    } else {
                        // General
                        item.DiscountPercentSpecial = itemPrice2.Discount;
                        flagPricePriority = false;
                        priceItem = parseFloat(itemPrice2.Price);
                        discount = parseFloat(itemPrice2.Discount);
                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                    }
                }
            });
        } 
        //No tiene precio especial
        if(flagPricePriority){
            item.DiscountPercentSpecial = 0;
        }

        // Precios por descuentos especiales
        if(priceBeforeDisc != -1){
            item.Price = Number(priceItem);
            item.PriceTest = Number(priceItem);            
            item.PriceBeforeDiscount = Number(priceBeforeDisc);
        }
        
        let price = item.PriceTest !== -1 ? Number(item.Price).toFixed(2) : -1;
        return price;
    }

    changLocalQuantity = (index, item, event)=>{
        const {shoppingCartReducer: {items},itemsReducer: {items: itemsSearch}} = this.props;
        let newQuantity =  event.nativeEvent.target.value;

        // color success
        // document.getElementById(item.ItemCode).style.backgroundColor = "#28a745"; 
        // document.getElementById(item.ItemCode).style.borderColor = "#28a745"; 

        if (newQuantity % 1 == 0) {
            itemsSearch.map( itemFilter => {
                if (itemFilter.ItemCode === item.ItemCode) {
                    itemFilter.quantity = newQuantity;
                    let specialPrice = this.setSepecialPrice(itemFilter);
                    if(specialPrice !== -1){
                        itemFilter.Price = specialPrice;
                    }
                }
            });
            this.applyFilters(itemsSearch);
        } else {
            return;
        } 
    }
    
    applyFilters = data => {
        const {setItemsFilterSearch} = this.props;
        // enableSpinner(true);
        setItemsFilterSearch(data);
        // enableSpinner(false);

        // $("#menuCategories").removeClass('open-menu-categories');
    };

    searchPage = async ( page = 0)=>{
        const { setNextPage, itemsReducer: {searchByCategories,searchByKey,category,idCategory,location,searchByDash},items} = this.props;
        page = page === 1 ? 0 : ((page -1) * 50);
        if(location ==='menuCategories'){
            setNextPage(page);
            await searchByCategories(idCategory,page);
        }else if(location === 'navBar'){
            await searchByKey(page);
        }else if(location === 'redView'){
            await searchByKey(page,'redView');
        }else if(location === 'whiteView'){
            await searchByKey(page,'whiteView');
        }else if(location === 'outletView'){
            await searchByKey(page,'outletView');
        }else if(location === 'promocion'){
            await searchByKey(page,'promocion');
        }else if(location === 'masvendidos'){
            await searchByKey(page,'masvendidos');
        }else if(location === 'nuevosproductos'){
            await searchByKey(page,'nuevosproductos');
        }else if(location === 'remates'){
            await searchByKey(page,'remates');
        }else if(location === 'vitrinaView'){
            await searchByKey(page, 'vitrinaView');
        }
    }

    mouseOverAddCart = (index, flag, event)=>{
        if(this.iconMouse && this.iconMouse.length > 0){
            this.iconMouse[index].style.display = "none";
            this.iconMouseOver[index].style.display = "block";
        }
    }

    mouseOutAddCart = (index, flag, event)=>{
        if(flag == 'green'){
            event.currentTarget.style.backgroundColor = "#89c33f";
            event.currentTarget.className = "btn btn-block botonAgregar"
        } else if(flag == 'yellow'){
            event.currentTarget.style.backgroundColor = "#efc964";
            event.currentTarget.className = "btn btn-block botonAgregarYellow"
        } else {
            event.currentTarget.style.backgroundColor = "#e27753";
            event.currentTarget.className = "btn btn-block botonAgregarAgotado"
        }
        // Íconos
        if(this.iconMouse && this.iconMouse.length > 0){
            this.iconMouse[index].style.display = "block";
            this.iconMouseOver[index].style.display = "none";
        }
    }

    changeFilter = (type) => {
        this.setState({
            card: type === 'card' ? true : false,
            list: type === 'list' ? true : false
        });
    }

    /*Funcionalidad del boton de las caracteristicas*/
    barraSide=() =>{
        const linkFiltro = document.querySelectorAll(".linkFilter");
        const btnToggle = document.querySelector(".toggle-btn");

        btnToggle.addEventListener("click",(function(){
            document.getElementById("sidebar").classList.toggle("active");
            document.querySelector(".toggle-btn").classList.toggle("active");
        }()));

        linkFiltro.forEach((el) => el.addEventListener("click",function(){
            document.getElementById("sidebar").classList.remove("active");
            document.querySelector(".toggle-btn").classList.remove("active");
        }));
    }

    dejanosMensaje = (item) => {
        this.setState({
            itemSelect: item
        });
        setTimeout(() => {
            $('#itemCotizaModal').modal('show');
        }, 300);
        
    };

    render() {
        const {items, type, changeBackOrder, updateFavorite, deleteShoppingCart, openDetails, itemsReducer:{location,TotalPage,itemsCategories}, viewOne ,configReducer:{currency}, sessionReducer,  sessionReducer: { user }} = this.props;
        const {currentPage, card, list, itemSelect} = this.state;
        return ( 
        
        <div>
            <div id = "scrollDownPlease" >
                <div className="row justify-content-center">
                    <div className = "col-md-10">
                        <div className="row justify-content-end">
                            <div className = "col-md-auto bg-white p-2 justify-content-center text-center mr-md-2 mb-2">
                                <i onClick={() => { this.changeFilter('card') }} className={"fas fa-th-large mr-3"} style = {{color: card === true ? 'black' : 'grey', cursor:'pointer'}}/>
                                <i onClick={() => { this.changeFilter('list') }} className={"fas fa-th-list mr-3"} style = {{color: list === true ? 'black' : 'grey', cursor:'pointer'}}/>
                                <span className = "font-weight-bold"> {TotalPage } Productos </span>
                            </div> 
                        </div> 
                    </div>
                </div>
                <div className="container-fluid row" style={{margin: 0, display: "flex",justifyContent: "center", fontFamily: "Helvetica"}}>
                    {(!type || type !== 'RedCompensasView') && 
                        // <div className="col-md-3" style ={{height:"100%"}}> 
                        //     <SideBarItems totalRows = {TotalPage} dataCategories = {itemsCategories} viewOne = {viewOne ? viewOne : ''}/>
                        // </div>
                        <div className="col-md-2 colmd" style ={{height:"auto", marginBottom: "1.5rem", top:"1rem"}}> 
                            <div className="toggle-btn" onClick={()=>{this.barraSide()}}>
                                <span className="fa fas-filter">Filtros</span>
                            </div>

                            <div id="sidebar" style={{OverflowY:"scroll"}}>

                                <div style={{padding: "1rem 1rem 1rem 1rem"}}> 
                                    <SideBarItems totalRows = {TotalPage} dataCategories = {itemsCategories} viewOne = {viewOne ? viewOne : ''}/>
                                </div>
                            </div>
                        </div>
                    }
                    <div className={(!type || type !== 'RedCompensasView') ? "col-md-10 row colmd-r": "col-md-12 row"}>
                        {/* <ItemDetailsModal view={VIEW_NAME.ITEMS_VIEW}/> */}
                        {items && items.map((item, index) => {
                            item.ItemName = item.ItemName || '';
                            // Use state to change la manera en la que se ve
                            return (
                                // <div className="card-box">
                                //     <div className="carta">
                                        <div id="CardItem " className={card === true ? "imageCard" : 'row listCards justify-content-center align-items-center'} style={{margin: card === true ? 12 : "1rem", borderBottom: card === true ? "" : "1px solid #AFAFAF", position: "relative", textAlign: "center",}} key={item.ItemCode + item.ItemName + item.Price}>
                                            <div className={card === true ? "item card" : ' row justify-content-center align-items-center'} style={{width: card === true ? "303.41px" : "100%", minHeight: card === true ? "410px" : "",padding:"1rem"}}>
                                                    <div style={{display: (!item.U_FMB_Handel_Promo || item.U_FMB_Handel_Promo === '' || item.U_FMB_Handel_Promo == 0 ? 'none' : 'table'), }}>
                                                        <div className="font-weight-bold ribbon" onClick={() => openDetails(item)}>
                                                            <span><blink> PROMOCIÓN </blink></span>
                                                        </div>
                                                    </div>
                                                    <img onClick={() => openDetails(item)} className={card === true ? "card-img-top cardImg" : "card-img-top cardImg"}
                                                        src={item.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + item.PicturName) : require('../images/noImage.png')}
                                                        alt="Imagen del producto"
                                                    />
                                                    <div className={card === true ? "col-12 " : " col-8 detailsItemList"}>
                                                        <div className="card-body" style={{margin: 0, padding: "10px",height: card === true ? "154px" : "auto", color: config.itemsList.textColor}}>
                                                            <div className="overflow-auto">
                                                                <p className="card-title text-left ItemName font-weight-bold" style={{padding: 2, fontSize: 15, color:"#000000"}}>
                                                                    {item.ItemName}
                                                                    {/* {item.ItemName ? (item.ItemName).length > 32 ? (item.ItemName).substring(0, 31) + " ... ": item.ItemName : " "} */}
                                                                </p>
                                                            </div>
                                                            <span className="nameItemCardList">
                                                                <div>
                                                                    <div>
                                                                        <p className="card-subtitle text-left" style={{marginBottom: 5, fontSize: 13, marginLeft: card === true ? 0 : 0}}>
                                                                            <span className="font-weight-bold nameItemList" style={{color: "#757575"}}>{item.ItemCode}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                            <div>
                                                            <p className="card-subtitle font-weight-bold text-left" style={{fontSize: 25, margin: 1, color: config.itemsList.textPrice2}}>
                                                                {item.U_web != 1  
                                                                    ? <span style={{color: config.navBar.iconColor,fontSize: 20}}>Solicite su cotización</span> 
                                                                    :  
                                                                    <span className="precioCard">
                                                                        <div className=" precios">
                                                                            <div className="pricePromo col-md-auto" style={{padding: 0, color: config.navBar.iconColor,fontSize: 17, width: "200px"}}>
                                                                                <CurrencyFormat
                                                                                    value={item.Price}
                                                                                    displayType={'text'}
                                                                                    thousandSeparator={true}
                                                                                    fixedDecimalScale={true}
                                                                                    decimalScale={2}
                                                                                    prefix={currency.MainCurncy !== item.Currency  ? '' : currency.DocCurrCod}
                                                                                    suffix = {' ' + item.Currency}>
                                                                                </CurrencyFormat>
                                                                                <span> más IVA</span>
                                                                            </div>                                                                        
                                                                        </div>
                                                                    </span>                                                                                                                    
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="card-footer" style={{padding: 0, border: 'none'}}>
                                                            {
                                                                item.U_web === 0 || item.U_web === null ?
                                                                <div className="ItemName" style={{color:"#AFAFAF", fontSize: 15, margin: 0}}>
                                                                    <p style={{marginLeft: "14px"}}>LLámenos o envié un correo para cotización</p>
                                                                </div>
                                                                :
                                                                <div style={{width:"100%", display: "flex", justifyContent: "space-between", alignItems: "center"}}>
                                                                    <div style={{width:  "4rem", marginRight: card === true && 10 }}>
                                                                        {item.Unidades === "Unidades" ?
                                                                            <input
                                                                            disabled={!(!!item.OnHandPrincipal)}
                                                                            id={'input-quantity-' + item.ItemCode + index}
                                                                            type="number"
                                                                            min="1"
                                                                            value={item.quantity ? Number(item.quantity).toFixed(0) : ''}
                                                                            className="form-control"
                                                                            name={'quantity' + item.ItemCode}
                                                                            placeholder="1"
                                                                            style={{textAlign: "left", background: '#efefef'}}
                                                                            onChange={(event) => {this.changLocalQuantity(index, item, event)}}
                                                                            //onKeyDown={event => event.keyCode == 13 && this.addShoppingCart(item, 'input-quantity-' + item.ItemCode + index)}
                                                                        />
                                                                        :<input
                                                                            disabled={!(!!item.OnHandPrincipal)}
                                                                            id={'input-quantity-' + item.ItemCode + index}
                                                                            type="number"
                                                                            min="1"
                                                                            value={item.quantity ? item.quantity : ''}
                                                                            className="form-control"
                                                                            name={'quantity' + item.ItemCode}
                                                                            placeholder="1"
                                                                            style={{textAlign: "left", background: '#efefef'}}
                                                                            onChange={(event) => {this.changLocalQuantity(index, item, event)}}
                                                                            //onKeyDown={event => event.keyCode == 13 && this.addShoppingCart(item, 'input-quantity-' + item.ItemCode + index)}
                                                                            />
                                                                        }
                                                                    </div>
                                                                    <div className="botonAgregar">
                                                                        <button 
                                                                            style={{textAlign: "center", margin: 0}} 
                                                                            type="button" 
                                                                            readOnly = {item.OnHandPrincipal <= 0 ? true : false} 
                                                                            className ="btn btn-block botonAgregar"
                                                                            className= {item.flag === 'green' ? "btn btn-block botonAgregar" : "btn btn-block botonAgregarAgotado"}  
                                                                            value={(item.quantity ? Number(item.quantity): '1')} 
                                                                            onClick={(event)=>{this.changeQuantity(index, item, event)}} 
                                                                            // onMouseOver={(event)=>{this.mouseOverAddCart(index, item.flag, event)}} 
                                                                            // onMouseOut={(event)=>{this.mouseOutAddCart(index, item.flag, event)}}
                                                                        >                                                            
                                                                        </button>                                                        
                                                                    </div>
                                                                    {
                                                                        ((item.U_web !== 0 && item.U_web !== null) && (item.OnHandPrincipal == 0 || item.OnHandPrincipal == '')) && 
                                                                        <div className={"icoCarrito"} style={{fontSize: 33}}>
                                                                            <img ref={iconMouse => this.iconMouse[index] = iconMouse} src={config.shoppingCartIcons.carritoAgotado} style={{display: "block", color: "red", cursor: "pointer"}}/>
                                                                            {/* <i ref={iconMouseOver => this.iconMouseOver[index] = iconMouseOver} className={'fas fa-ban ml-md-2'} style={{display: "none", color: "red", cursor: "pointer"}}/> */}
                                                                            <img  ref={iconMouseOver => this.iconMouseOver[index] = iconMouseOver} src={config.shoppingCartIcons.camionAgotado} style={{display: "none", color: "red", cursor: "pointer"}}/>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        ((item.U_web !== 0 && item.U_web !== null) && item.OnHandPrincipal > 0 && (item.flag === 'green' || item.flag === 'yellow')) &&
                                                                        <div className={"icoCarrito"} style={{fontSize: 33}}>
                                                                            <img ref={iconMouse => this.iconMouse[index] = iconMouse}  src={item.flag === 'green' ? config.shoppingCartIcons.carritoVerde : config.shoppingCartIcons.carritoAmarillo} style={{display: "block", cursor: "pointer"}}/>
                                                                            <img ref={iconMouseOver => this.iconMouseOver[index] = iconMouseOver}  src={item.flag === 'green' ? config.shoppingCartIcons.camionVerde : config.shoppingCartIcons.camionAmarillo} style={{display: "none",cursor: "pointer"}}/>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        /* {item.U_web === 1 &&
                                                                            <div className="col-3" style={{color: "red", fontSize: 33,padding:0, textAlign: "right"}}>
                                                                                {item.quantity && <i className={config.icons.trash} style={{cursor: "pointer"}} onClick={() =>  deleteShoppingCart({item, deleteAll: false})}/>}
                                                                            </div>
                                                                        } */
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="cotizacion row text-center" style={{margin: 0, padding: 0}}>
                                                            {item.U_web === 0 || item.U_web === null ?
                                                                item.wishlist === 1 &&
                                                                <div className="col-12" style={{padding:0}}>
                                                                    <label style={{textAlign: "center", fontSize: 16}}>para cotización</label>
                                                                </div> :
                                                                item.wishlist === 1 &&
                                                                    <div className="col-10">
                                                                        <label style={{textAlign: "center", marginTop: 12, fontSize: 14}}>Lista de deseos</label>
                                                                    </div>
                                                            }
                                                            {item.U_web === 1 &&
                                                                item.wishlist === 1 &&
                                                                    <div className="col-2" style={{color: config.navBar.backgroundColor, fontSize: 20, padding: 0}}>
                                                                        {item.backOrder ? 
                                                                            <i className={config.icons.backOrderFalse} style={{cursor: "pointer", marginLeft: 7, marginTop: 15}} onClick={() => changeBackOrder(item, false)}/> :
                                                                            <i className={config.icons.backOrderTrue} style={{cursor: "pointer", marginLeft: 7, marginTop: 15}} onClick={() => changeBackOrder(item, true)}/>
                                                                        }
                                                                    </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                        </div>
                                    /* </div>
                                </div> */
                            );

                        })}
                    </div>

                </div>
                <div className="row"> 
                    <Pagination
                    refresh ={this.searchPage}
                    totalRowsRefresh = {TotalPage}/>
                </div>
            </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        itemsReducer: store.ItemsReducer,        
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setShoppingCart: value => dispatch({type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value}),
        setItemsFilterSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value}),
        setNextPage:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value}),
        searchByDashOption: value => dispatch({type: DISPATCH_ID.ITEMS_SET_SEARCH_BY_DASH_OPTION, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ItemsList);