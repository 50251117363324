import React, {Component} from 'react';
import {ApiClient} from "../libs/apiClient/ApiClient";
import {config, DISPATCH_ID, ROLES, SERVICE_API, SERVICE_RESPONSE} from "../libs/utils/Const";
import {connect} from "react-redux";
import Modal from './Modal';
import $ from 'jquery';
import moment from 'moment';

let modal = new Modal();

let apiClient = ApiClient.getInstance();

class SearchItemsActions extends Component {

    componentDidMount() {
        const {setSearchItemsByKeyReference, setSearchItemsByCategoriesReference, updateFavoriteReference, addShoppingCartReference, addBackOrderReference,
             deleteShoppingCartReference, deleteBackOrderReference, openItemDetailsReference} = this.props;
        setSearchItemsByKeyReference(this.searchItemsByKey);
        updateFavoriteReference(this.updateFavorite);
        addShoppingCartReference(this.addShoppingCart);
        addBackOrderReference(this.addBackOrder);
        deleteBackOrderReference(this.deleteBackOrder);
        deleteShoppingCartReference(this.deleteShoppingCart);
        openItemDetailsReference(this.openItemDetails);
        setSearchItemsByCategoriesReference(this.searchItemsByCategories);
        this.searchAutoComplete();
        this.getSpecialPrice();
        this.searchItemsByCategoriesHome2();
    }
    getSpecialPrice = async ()=>{
        const {sessionReducer: {role}, specialPrice, notificationReducer: {showAlert}, configReducer: {history}, enableSpinner} = this.props;
        let response = await apiClient.getSpecialPrice();
        response = response.data ? response.data : [];
        specialPrice(response);
    }

    searchAutoComplete = async () => {
        const {setItemsAutoComplete,setSearchCategory,setTags,setCurrency} = this.props;
      
        let suggestions = [];
        let searchCategory = [];
        let currency = {};
        let categories = localStorage.getItem(config.general.localStorageNamed + 'categories');
        categories = JSON.parse(categories);
        // setTags(categories || []);

        let apiResponse = await apiClient.AutoComplete();
        if(apiResponse.status === 0){
            return;
        }
        suggestions = apiResponse.data.data;
        setItemsAutoComplete(suggestions);
        
        // searchCategory = apiResponse.data.searchCategory;        
        // setTags(searchCategory);
        
        currency = apiResponse.data.currency;
        setCurrency(currency);
    }

    searchItemsByKey = async ( page = 0, view = null, isPagination = false) => {
        const {sessionReducer: {role}, itemsReducer,setItemsCategories, setItemsFilters, setItemsSearch,setTotalRows, notificationReducer: {showAlert}, configReducer: {history}, enableSpinner, sessionReducer} = this.props;
        let key = 'FindAll';
        let uniqueFilter = '';
        let user = {};
        let whs = ''
        // try {
        //     user = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser'));
        //     whs = user.U_FMB_Handel_ALMA || '01';
        // } catch (error) {
        //     whs = '01'
        // }
        // if(view === 'redView' || view === 'whiteView' || view === 'outletView' || view === 'vitrinaView'){
        //     key = 'FindAll';
        // } else {
            // Validate click on search icon
            // if(searchBar === 1){
                if (itemsReducer.search && itemsReducer.search !== '') {
                   
                    key = itemsReducer.search;
                }
            // }            
        // }
        // console.log("con(itemsReducer.uniqueFilter)",itemsReducer.uniqueFilter);
        if(itemsReducer.uniqueFilter && !isPagination){
            uniqueFilter = Object.entries(itemsReducer.uniqueFilter).length !== 0 ? itemsReducer.uniqueFilter : '';
            if(uniqueFilter.property != "precio" && uniqueFilter.property != undefined){
            let uniqueFilter2 = uniqueFilter.value.split(",");
            let unicFiltAux = uniqueFilter2.length;
            uniqueFilter.value = uniqueFilter2[unicFiltAux-1];
            }
        } else if(itemsReducer.uniqueFilter && isPagination == true){
            setItemsFilters({});
            uniqueFilter = '';
        }
        let localShoppingCart = undefined;
        if (role === ROLES.PUBLIC) {
            localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');
            localShoppingCart = JSON.parse(localShoppingCart) || [];
        }
        
        enableSpinner(true);
        let response = null;
        let search = {
            key: key,
            localShoppingCart: localShoppingCart,
            whs: whs,
            page: page,
            uniqueFilter: uniqueFilter,
            view:view,
        }
        // response = await apiClient.searchByKey(key, localShoppingCart, whs, page, uniqueFilter, view/*, categorias, priceMax, priceMin, marca, fabrica, aparato, refaccion, stock */);
 
        response = await apiClient.searchByKey(search);

        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.SUCCESS) {
            setItemsSearch(response.data.results);
            setTotalRows(response.data.totalRows);
            setItemsCategories(response.data.allCategories);
            this.applyFilters(response.data.results);

            let today = moment().format('YYYY-MM-DD');
            let time = moment().format('h:mm:ss a');
            let data = {
                cve_user : sessionReducer.user ? sessionReducer.user.CardCode : 'Desconocido',
                search : key,
                date : today,
                time : time,
                origin : 'Busqueda',
                num_results : response.data.results.length,
            }
            // await apiClient.Search(data);
            if(view === 'Refacciones'){
                history.goItemsRefacciones();
            }else if(view === 'Food' ){
                history.goItemsFood(); 
            }else if(view === 'Paking' ){
                history.goItemsPaking(); 
            }else if(view === 'Ferreteria' ){
                history.goItemsFerreteria();
            }else if(view === 'Mulos' ){
                history.goItemsMulos();
            }else{
                history.goItems();
            }
            return;
        }
        showAlert({type: 'error', message: response.message, timeOut: 0});
    };

    searchItemsByCategories = async (category, page = 0,view) => {
        const {sessionReducer: {role}, itemsReducer:{nextPage},setItemsCategories,setItemsSearch,setTotalRows, notificationReducer: {showAlert}, configReducer: {history}, enableSpinner, sessionReducer} = this.props;
        let whs = '';
        let user = {};

        let localShoppingCart = undefined;
        if (role === ROLES.PUBLIC) {
            localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');
            localShoppingCart = JSON.parse(localShoppingCart) || [];
        }
        let nameCategory =JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'NameCategory'));
        enableSpinner(true);
        
        let response = await apiClient.searchByCategory(category, localShoppingCart, whs, page,view);

        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.SUCCESS) {
            setItemsSearch(response.data.results);
            setTotalRows(response.data.totalRows);
            setItemsCategories(response.data.allCategories);
            this.applyFilters(response.data.results);
            let today = moment().format('YYYY-MM-DD');
            let time = moment().format('h:mm:ss a');
            let data = {
                cve_user : sessionReducer.user ? sessionReducer.user.CardCode : 'Desconocido',
                search : nameCategory,
                date : today,
                time : time,
                origin : 'Categorias',
                num_results : response.data.results.length,
            }
            // await apiClient.Search(data);
            setTimeout(() => {
                if(view === 'Refacciones'){
                    history.goItemsRefacciones();
                }else if(view === 'Food' ){
                    history.goItemsFood(); 
                }else if(view === 'Paking' ){
                    history.goItemsPaking(); 
                }else if(view === 'Ferreteria' ){
                    history.goItemsFerreteria();
                }else if(view === 'Mulos' ){
                    history.goItemsMulos();
                }else{
                    history.goItems();
                }
                
            }, 500);
            
            return;
        }
        showAlert({type: 'error', message: response.message, timeOut: 0});
    };
 
    applyFilters = data => {
        const {setItemsFilterSearch, enableSpinner} = this.props;
        // enableSpinner(true);
        setItemsFilterSearch(data);
        // enableSpinner(false);

        $("#menuCategories").removeClass('open-menu-categories');
    };

    searchItemsByCategoriesHome2 = async category => {
        const {sessionReducer: {role}, setItemsSearch1, setItemsSearch2, notificationReducer: {showAlert}, enableSpinner} = this.props;

        let localShoppingCart = undefined;
        let whs = '';
        let user = {};
        if (role === ROLES.PUBLIC) {
            localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');
            localShoppingCart = JSON.parse(localShoppingCart) || [];
        }
        try {
            user = JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser'));
            whs = user.U_FMB_Handel_ALMA || '';
        } catch (error) {
            whs = '01'
        }
        enableSpinner(true);

        let busqueda2 = {
            contenido: {
                opcion: "nuevosproductos",
                limit: 20
            }
        };

        await apiClient.ProductsEspecial(busqueda2,localShoppingCart, whs, 0).then(result => {
            setItemsSearch1(result.data.responseBody);
            this.applyFilters1(result.data.responseBody);
            setItemsSearch2(result.data.responseBody2);
            this.applyFilters2(result.data.responseBody2);
        });
        enableSpinner(false);
    };

    applyFilters1 = data => {
        const {setItemsFilterSearch1, enableSpinner} = this.props;
        enableSpinner(true);
        setItemsFilterSearch1(data);
        enableSpinner(false);

        $("#menuCategories").removeClass('open-menu-categories');
    };

    applyFilters2 = data => {
        const {setItemsFilterSearch2, enableSpinner} = this.props;
        enableSpinner(true);
        setItemsFilterSearch2(data);
        enableSpinner(false);

        $("#menuCategories").removeClass('open-menu-categories');
    };

    updateFavorite = async item => {
        const {sessionReducer: {role}, itemsReducer: {items}, notificationReducer: {showAlert}, enableSpinner} = this.props;


        if (role === ROLES.PUBLIC) {
            await this.needToCreateAccount();
            return;
        }
        enableSpinner(true);
        let response = await apiClient.updateFavorite(item);
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({type: 'error', message: response.message, timeOut: 0});
            return;
        }
        item.favorite = response.data.value; // change item details

        items.filter(itemFilter => {
            if (item.ItemCode === itemFilter.ItemCode) {
                itemFilter.favorite = response.data.value;
            }
        });

        this.applyFilters(items);

    };

    needToCreateAccount = () => {
        modal.loginModal('show');
    };

    addShoppingCart = async data => {
        const {enableSpinner,itemsReducer: {items: itemsSearch}, sessionReducer: {role}, shoppingCartReducer: {items}, notificationReducer: {showAlert}, setShoppingCart} = this.props;
        //item.U_FMB_Handel_Show100
        // if(Number(data.quantity) >= 100){            
        //     let flagAlert100 = false;
        //     itemsSearch.map(itemFilter => {                
        //         if (itemFilter.ItemCode === data.item.ItemCode) {
        //             // Validación para agregar más de 100 piezas
        //             if(itemFilter.U_FMB_Handel_Show100 == 1){
        //                 data.quantity = itemFilter.OnHandPrincipal >= data.quantity ? data.quantity : itemFilter.OnHandPrincipal;
        //             } else {
        //                 data.quantity = itemFilter.OnHandPrincipal >= 100 ? "100" : itemFilter.OnHandPrincipal;
        //                 flagAlert100 = true;
        //             }
        //         }
        //     });
        //     if(flagAlert100 === true){
        //         showAlert({type: 'warning', message: "Se excede el número de articulos de compra de este producto", timeOut: 2500});
        //     }
        // }

        if (role === ROLES.PUBLIC) {
            this.addShoppingCartPublic(data);
            return;
        }
        enableSpinner(true);
        let response = await apiClient.updateShoppingCart(data);
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({type: 'error', message: response.message, timeOut: 0});
            return;
        }

        let exist = response.data.value;

        if (!exist) {
            items.push({ItemCode: data.item.ItemCode, quantity: data.quantity});
            setShoppingCart(items);
        }

        let newItem = undefined;
        itemsSearch.map(itemFilter => {
            if (itemFilter.ItemCode === data.item.ItemCode) {
                itemFilter.quantity = data.quantity;
                newItem = data.item;
                newItem.quantity = data.quantity;
            }
        });

        this.applyFilters(itemsSearch);
    };

    addShoppingCartPublic = async data => {
        const {itemsReducer: {items: itemsSearch}, shoppingCartReducer: {items}, setShoppingCart} = this.props;

        let localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');
        localShoppingCart = JSON.parse(localShoppingCart) || [];

        let exist = localShoppingCart.filter((itemFilter) => {
            return (itemFilter.ItemCode === data.item.ItemCode)
        });

        if (!exist.length) {
            localShoppingCart.push({ItemCode: data.item.ItemCode, quantity: data.quantity});
            items.push({ItemCode: data.item.ItemCode, quantity: data.quantity});
        } else {
            localShoppingCart.map((itemMap) => {
                if (data.item.ItemCode === itemMap.ItemCode) {
                    itemMap.quantity = data.quantity;
                }
            });
            items.map((itemMap) => {
                if (data.item.ItemCode === itemMap.ItemCode) {
                    itemMap.quantity = data.quantity;
                }
            });
        }


        let newItem = undefined;
        itemsSearch.map(itemFilter => {
            if (itemFilter.ItemCode === data.item.ItemCode) {
                itemFilter.quantity = data.quantity;
                newItem = data.item;
                newItem.quantity = data.quantity;
            }
        });

        /*let newItemShooping = undefined;
        localShoppingCart.map( itemShopping => {
            if (itemShopping.ItemCode === data.item.ItemCode) {
                itemShopping.quantity = data.quantity;
                newItemShooping = data.item;
                newItemShooping.quantity = data.quantity;
            }
        });
*/
        items.map( itemFilter => {
            if(data.item.ItemCode === itemFilter.ItemCode){
                itemFilter.quantity = data.quantity;
            }
        });

        localStorage.setItem(config.general.localStorageNamed + 'shoppingCart', JSON.stringify(localShoppingCart));
        setShoppingCart(items);

        this.applyFilters(itemsSearch);
        //this.changeInfoDetails(newItem);
    };

    deleteShoppingCart = async data => {
        const {itemsReducer: {items: itemsSearch}, sessionReducer: {role}, shoppingCartReducer: {items}, notificationReducer: {showAlert}, setShoppingCart} = this.props;
        //("deleteShoppingCart", data);

        if (role === ROLES.PUBLIC) {
            this.deleteShoppingCartPublic(data);
            return;
        }

        let response = await apiClient.deleteShoppingCart(data);
        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({type: 'error', message: response.message, timeOut: 0});
            return;
        }

        let newItems = items.filter(itemFilter => {
            return (itemFilter.ItemCode !== data.item.ItemCode)
        });

        if (data.deleteAll) {
            newItems = [];
        }
        setShoppingCart(newItems);

        let newItem = undefined;
        itemsSearch.map(itemFilter => {
            if (itemFilter.ItemCode === data.item.ItemCode) {
                itemFilter.quantity = '';
                newItem = data.item;
                newItem.quantity = '';
            }
        });
        this.applyFilters(itemsSearch);
      //  this.changeInfoDetails(newItem);
    };

    deleteShoppingCartPublic = data => {
        const {itemsReducer: {items: itemsSearch},shoppingCartReducer: {items}, setItemsSearch, setShoppingCart} = this.props;
        //("deleteShoppingCart", data);

        let localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');

        localShoppingCart = JSON.parse(localShoppingCart) || [];

        let newItems = items.filter(itemFilter => {
            return (itemFilter.ItemCode !== data.item.ItemCode)
        });

        if (data.deleteAll) {
            newItems = [];
        }

        localStorage.setItem(config.general.localStorageNamed + 'shoppingCart', JSON.stringify(newItems));
        setShoppingCart(newItems);

        let newItem = undefined;
        itemsSearch.map(itemFilter => {
            if (itemFilter.ItemCode === data.item.ItemCode) {
                itemFilter.quantity = '';
                newItem = data.item;
                newItem.quantity = '';
            }
        });
        this.applyFilters(itemsSearch);
        //this.changeInfoDetails(newItem);
    };

    addBackOrder = async data => {
        const {itemsReducer: {items}, sessionReducer: {role}, shoppingCartReducer: {backOrder}, notificationReducer: {showAlert}, enableSpinner, setBackOrder} = this.props;
        //("addBackOrder", data);

        if(Number(data.quantity) >= 100){
            items.map(itemFilter => {
                if (itemFilter.ItemCode === data.item.ItemCode) {
                    data.quantity = itemFilter.OnHandPrincipal >= 100 ? "100" : itemFilter.OnHandPrincipal;
                }
            });
            
            showAlert({type: 'warning', message: 'Se excede el número maximo de articulos ', timeOut: 2500});
        }

        if (role === ROLES.PUBLIC) {
            this.needToCreateAccount();
            return;
        }

        enableSpinner(true);
        let response = await apiClient.updateBackOrder(data);
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({type: 'error', message: response.message, timeOut: 0});
            return;
        }

        let exist = response.data.value;
        if (!exist) {
            backOrder.push({ItemCode: data.item.ItemCode, quantity: data.quantity});
            setBackOrder(backOrder);
        }

        let newItem = undefined;
        items.map(itemFilter => {
            if (itemFilter.ItemCode === data.item.ItemCode) {
                itemFilter.backOrder = true;
                newItem = data.item;
                newItem.backOrder = true;
            }
        });
        this.applyFilters(items);
        //this.changeInfoDetails(newItem);
    };

    deleteBackOrder = async data => {
        const {itemsReducer: {items}, sessionReducer: {role}, shoppingCartReducer: {backOrder}, notificationReducer: {showAlert}, enableSpinner, setBackOrder} = this.props;
        //("deleteBackOrder", data);

        if (role === ROLES.PUBLIC) {
            this.needToCreateAccount();
            return;
        }

        enableSpinner(true);
        let response = await apiClient.deleteBackOrder(data);
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({type: 'error', message: response.message, timeOut: 0});
            return;
        }

        let newItems = backOrder.filter(itemFilter => {
            return (itemFilter.ItemCode !== data.item.ItemCode)
        });

        if (data.deleteAll) {
            newItems = [];
        }


        setBackOrder(newItems);

        let newItem = undefined;
        items.map(itemFilter => {
            if (itemFilter.ItemCode === data.item.ItemCode) {
                itemFilter.backOrder = false;
                newItem = data.item;
                newItem.backOrder = false;
            }
        });
        this.applyFilters(items);
        //this.changeInfoDetails(newItem);
    };

    openItemDetails = async item => {
        const {sessionReducer: {role}, setItemDetailsSearch, notificationReducer: {showAlert}, configReducer: {history}, enableSpinner} = this.props;

        let localShoppingCart = undefined;
        if (role === ROLES.PUBLIC) {
            localShoppingCart = localStorage.getItem(config.general.localStorageNamed + 'shoppingCart');
            localShoppingCart = JSON.parse(localShoppingCart) || [];
        }

        enableSpinner(true);
        let info = {
            itemCode: item.ItemCode, 
            shoppingCartPublic: JSON.stringify(localShoppingCart)
        }
        let response = await apiClient.openItemDetails(info);
        enableSpinner(false);
        if (response.status === SERVICE_RESPONSE.ERROR) {
            showAlert({type: 'error', message: response.message, timeOut: 0});
            return;
        }

        let images = [];
        images.push({path: (response.data.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + response.data.PicturName) : require('../images/noImage.png'))});

        let imagesArray = response.data.U_Handel_ImagesArray || '';
        imagesArray = imagesArray.split('|');

        imagesArray.map( image => {
            //("ccccccccccccccccccccccccccccccccc", image);
            if(image){
                images.push({path: (image ? (config.BASE_URL + SERVICE_API.getImage + '/' + image) : require('../images/noImage.png'))});
            }
        });

        let files = [];
        let fileArray = response.data.U_Handel_attachment || '';
        fileArray = fileArray.split(',');
        //("splittttttttt", fileArray)
        fileArray.map( file => {
            if(file){
                files.push({path: (file ? (config.BASE_URL + SERVICE_API.getFile + '/' + file) : '' ), name: file});
            }
        });

        response.data.images = images;
        response.data.files = files;

        setItemDetailsSearch(response.data);
        setTimeout(() => {
            // modal.itemDetails('show');
            history.goItemsDetails();
        }, 10)

    };

    changeInfoDetails = item => {
        const {setItemDetailsSearch} = this.props;
        if (item) {
            setItemDetailsSearch(item);
        }
    };

    render() {
        return (
            <div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        sessionReducer: store.SessionReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        openItemDetailsReference: value => dispatch({type: DISPATCH_ID.ITEMS_OPEN_ITEM_DETAILS_REFERENCE, value}),
        updateFavoriteReference: value => dispatch({type: DISPATCH_ID.ITEMS_UPDATE_FAVORITE_REFERENCE, value}),
        addShoppingCartReference: value => dispatch({type: DISPATCH_ID.ITEMS_ADD_SHOPPING_CART_REFERENCE, value}),
        addBackOrderReference: value => dispatch({type: DISPATCH_ID.ITEMS_ADD_BACK_ORDER_REFERENCE, value}),
        deleteBackOrderReference: value => dispatch({type: DISPATCH_ID.ITEMS_DELETE_BACK_ORDER_REFERENCE, value}),
        deleteShoppingCartReference: value => dispatch({type: DISPATCH_ID.ITEMS_DELETE_SHOPPING_CART_REFERENCE, value}),
        setSearchItemsByKeyReference: value => dispatch({type: DISPATCH_ID.ITEMS_SET_SEARCH_BY_KEY_REFERENCE, value}),
        setSearchItemsByCategoriesReference: value => dispatch({type: DISPATCH_ID.ITEMS_SET_SEARCH_BY_CATEGORIES_REFERENCE, value}),
        setItemsSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SET_ITEMS, value}),
        setItemsFilterSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value}),
        setTotalRows : value => dispatch({type: DISPATCH_ID.ITEMS_SET_TOTALROWS, value}),
        setItemDetailsSearch: value => dispatch({type: DISPATCH_ID.ITEMS_SET_ITEM_DETAILS, value}),
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
        setShoppingCart: value => dispatch({type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value}),
        setBackOrder: value => dispatch({type: DISPATCH_ID.SHOPPING_CART_SAVE_BACK_ORDER, value}),
        setItemsCategories: value => dispatch({type: DISPATCH_ID.ITEMS_SET_CATEGORIES, value}), 
        setItemsFilters: value => dispatch({type: DISPATCH_ID.ITEMS_SET_UNIQUE_FILTER, value}), 
        setItemsAutoComplete: value => dispatch({type: DISPATCH_ID.ITEMS_SET_AUTO_COMPLETE, value}), 
        specialPrice : value => dispatch({type: DISPATCH_ID.ITEMS_SPECIAL_PRICES, value}),
        setTags: value => dispatch({type: DISPATCH_ID.ITEMS_SAVE_TAGS, value}),
        setCurrency: value => dispatch({type: DISPATCH_ID.CONFIG_SET_CURRENCY, value}),
        setItemsSearch1: value => dispatch({type: DISPATCH_ID.ITEMS_SET_ITEMS1, value}),
        setItemsSearch2: value => dispatch({type: DISPATCH_ID.ITEMS_SET_ITEMS2, value}),
        setItemsFilterSearch1: value => dispatch({type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER1, value}),
        setItemsFilterSearch2: value => dispatch({type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER2, value}), 
        
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SearchItemsActions)