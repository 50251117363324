import React, {Component} from 'react';
import {Footer, NavBar, Session, DocumentModel} from "../../components";
//import {VIEW_NAME, config} from "../../libs/utils/Const";
import {DISPATCH_ID, SERVICE_API, SERVICE_RESPONSE, VIEW_NAME, config} from "../../libs/utils/Const";
import {ApiClient} from "../../libs/apiClient/ApiClient";
import {connect} from "react-redux";
import {CSVLink, CSVDownload} from "react-csv";
import * as XLSX from 'xlsx';
import $ from 'jquery';

let apiClient = ApiClient.getInstance();

class SubirArchivo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            Codigo : '',
            Nombre : '',
            Correo : '',
            Password : '',
            Promos : [],
            items : [],
            itemsSelected : [],
            itemsSelectedBonification: [],
            finishItems : [],
            arr : [],
            createPromo: {
                Name : '',
                buscarLike : '',
                buscarBoniLike : '',
            }
        };
    };

    async componentDidMount(){
        this.cargarDatos();

        this.handleInputChange = this.handleInputChange.bind(this)
    };

    async cargarDatos (){

        let user = localStorage.getItem(config.general.localStorageNamed + 'CurrentUser');
        user = JSON.parse(user) || {};
        this.setState({   
            Codigo: user.CardCode,
            Password: user.U_FMB_Handel_Pass || '',
            Nombre : user.CardName  || '',
            Correo : user.U_FMB_Handel_Email  || '' 
        });
    };

    async handleInputChange (event) {
        const target = event.target
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name
        const this2 = this
        this.setState({
            [name]: value
        })
        let hojas = []
        if (name === 'file') {
            let reader = new FileReader()
            
            reader.readAsArrayBuffer(target.files[0])
            reader.onloadend = (e) => {
                var data = new Uint8Array(e.target.result);
                var workbook = XLSX.read(data, {type: 'array'});
                workbook.SheetNames.forEach(function(sheetName) {
                    // Here is your object
                    var XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
                    hojas.push({
                        data: XL_row_object,
                        sheetName
                    })
                });
                this2.setState({
                    selectedFileDocument: target.files[0],
                    hojas
                });
                if(hojas[0]){
                    this.openDetail(hojas[0]);
                }
            }
        }
    };

    openDetail = async docEntry => {
        const {enableSpinner, notificationReducer: {showAlert}} = this.props;
        enableSpinner(true);
        let response = await apiClient.getDataDocuemt(docEntry);
        enableSpinner(false);

        if (response.status === SERVICE_RESPONSE.SUCCESS) {
            this.setState({
                items: response.data,
            });
            $('#docuementModal').modal('show');
            $("#file").val(null);
            return;
        }
        //setTimeout(()=> {
        showAlert({type: 'error', message: "Aviso: "+response.message})
        //},50);
    };

    render() {
        const {history} = this.props;
        const {order,items,dataCsv} = this.state;
        return (
            <div className="content-fluid" style={{marginTop: 66}}>
                <Session history={history} view={VIEW_NAME.PROFILE_VIEW}/>
                <NavBar/>
                <DocumentModel order={items}/>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <div className="container mb-4" style={{paddingTop: 60}}>
                    <div className="row">
                        <div className="col">
                            <div className="jumbotron">
                                <h1 className="text-center">Subir archivo OC "desde un Excel"</h1>
                            </div>
                        </div>    
                    </div>
                </div>
                <div class="container" style={{paddingBottom: 160}}>
                    <div class="row">
                        <div class="col-sm">
                        </div>
                        <div class="col-sm">
                            {/* <h2 className="align-middle text-center">Subir desde un excel</h2> */}
                            <input type="file" name="file" id="file" onChange={this.handleInputChange}  placeholder="Archivo de excel" className="form-control-file" ></input>
                        </div>
                        <div class="col-sm">
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubirArchivo);
