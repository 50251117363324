import React, {Component} from 'react';
import {Session, OrderDetailsModal} from "../../components";
import {DISPATCH_ID, SERVICE_API, SERVICE_RESPONSE, VIEW_NAME, config} from "../../libs/utils/Const";
import {ApiClient} from "../../libs/apiClient/ApiClient";
import {connect} from "react-redux";
import moment from 'moment';
import $ from 'jquery';
import CurrencyFormat from 'react-currency-format';

const apiClient = ApiClient.getInstance();

require('datatables.net-bs4');

class overdueViews extends Component {

    constructor(props) {
        super(props);
        this.state = {
            overdueTwo: [],
            overdueThree: [],
            overdueFour: [],
            overdueFive: [],
            data: [],
        };
        this.tableOne = null;
        this.tableTwo = null;
        this.tableThree = null;
        this.tableFour = null;
        this.tableFive = null;
    }

    async componentDidMount() {
        this.createDataTabletablaOver('tablaOverOne'); 
    }

    dataTablas = () => ({
        "paging": true,
        "info": false,
        "searching": false,
        //"bDestroy": true,	
        "order": [[ 0, 'desc' ]],
        "language": {
            "lengthMenu": "Mostrar _MENU_ registros por página",
            "zeroRecords": "No se encontraron registros",
            "info": "Mostrando página _PAGE_ de _PAGES_",
            "infoEmpty": "No existen registros",
            "infoFiltered": "(filtrado de _MAX_ entradas)",
            "loadingRecords": "Buscando...",
            "processing": "Procesando...",
            "search": "Buscar:",
            "paginate": {
                "first": "Primero",
                "last": "Último",
                "next": "Siguiente",
                "previous": "Anterior"
            }
        }
    })

    createDataTabletablaOver = (key) => {

        switch (key) {
             case 'tablaOverOne':
                 this.tableOne =  $(`#tablaOverOne`).DataTable(this.dataTablas());
             break;
             case 'tablaOverTwo':
                 this.tableTwo =  $(`#tablaOverTwo`).DataTable(this.dataTablas());
             break;
             case 'tablaOverThree':
                 this.tableThree =  $(`#tablaOverThree`).DataTable(this.dataTablas());
             break;
             case 'tablaOverFour':
                 this.tableFour =  $(`#tablaOverFour`).DataTable(this.dataTablas());
             break;
             case 'tablaOverFive':
                 this.tableFive =  $(`#tablaOverFive`).DataTable(this.dataTablas());
             break;
        } 
    }

    // Se vacia la tabla
    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        if (this.props.overdueOne != nextProps.overdueOne) {
            this.tableOne.destroy();
            this.tableOne = null;
        }
        if (this.state.overdueTwo != nextState.overdueTwo) {
            this.tableTwo.destroy();
            this.tableTwo = null;
        }
        if (this.state.overdueThree != nextState.overdueThree) {
            this.tableThree.destroy();
            this.tableThree = null;
        }
        if (this.state.overdueFour != nextState.overdueFour) {
            this.tableFour.destroy();
            this.tableFour = null;
        }
        if (this.state.overdueFive != nextState.overdueFive) {
            this.tableFive.destroy();
            this.tableFive = null;
        }
        return true;
    }

    // se llena la tabla
    componentDidUpdate(): void {
        
        if (this.tableOne == null) {
            this.createDataTabletablaOver('tablaOverOne');
        }
        if (this.tableTwo == null) {
            this.createDataTabletablaOver('tablaOverTwo');
        }
        if (this.tableThree == null) {
            this.createDataTabletablaOver('tablaOverThree');
        }
        if (this.tableFour == null) {
            this.createDataTabletablaOver('tablaOverFour');
        }
        if (this.tableFive == null) {
            this.createDataTabletablaOver('tablaOverFive');
        }
        
    }

    getDataOverDoue = async (opcion) =>{
        const {enableSpinner,notificationReducer: {showAlert}} = this.props;
        
        let responseTwo = [];
        let responseThree  = [];
        let responseFour  = [];
        let responseFive = [];
        enableSpinner(true);
        if(opcion === 1){
            responseTwo = await  apiClient.getOverdueTwo();
            //console.log("hi", responseTwo);
            if (!Array.isArray(responseTwo.data)){
                showAlert({type: 'error', message: 'Ocurrió un error al consultar la Información de saldos de 30'});
                return;
            }else{
                if (responseTwo.data.length === 0) {
                    showAlert({type: 'info', message: 'No hay Información por mostrar'});
                }
            }
        }else if(opcion === 2){
            responseThree = await apiClient.getOverdueThree();
            //console.log("hi***", responseThree);
            if (!Array.isArray(responseThree.data)){
                showAlert({type: 'error', message: 'Ocurrió un error al consultar la Información de saldos de 60'});
                return;
            }else{
                if (responseThree.data.length === 0) {
                    showAlert({type: 'info', message: 'No hay Información por mostrar'});
                }
            }
        }else if(opcion === 3){
            responseFour = await apiClient.getOverdueFour();
            //console.log("hi////", responseFour);
            if (!Array.isArray(responseFour.data)){
                showAlert({type: 'error', message: 'Ocurrió un error al consultar la Información de saldos de 90'});
                return;
            }else{
                if (responseFour.data.length === 0) {
                    showAlert({type: 'info', message: 'No hay Información por mostrar'});
                }
            }
        } else if(opcion === 4){
            responseFive = await apiClient.getOverdueFIve();
            if (!Array.isArray(responseFive.data)){
                showAlert({type: 'error', message: 'Ocurrió un error al consultar la Información de saldos de a mas de 90'});
                return;
            }else{
                if (responseFive.data.length === 0) {
                    showAlert({type: 'info', message: 'No hay Información por mostrar'});
                }
            }
        } 
        enableSpinner(false);

        // if (!Array.isArray(responseTwo.data) || !Array.isArray(responseThree.data) || !Array.isArray(responseFour.data) || !Array.isArray(responseFive.data)) {
        //     showAlert({type: 'error', message: 'Ocurrió un error al consultar la Información de saldos'});
        //     return;
        // }

        // if (responseTwo.data.length === 0 || responseThree.data.length === 0 || responseFour.data.length === 0 || responseFive.data.length === 0) {
        //     showAlert({type: 'info', message: 'No hay Información por mostrar'});

        // }
        this.setState({
            overdueTwo : responseTwo.data || [],
            overdueThree:responseThree.data || [],
            overdueFour: responseFour.data || [],
            overdueFive: responseFive.data || []
        });
    }

    render() {
        const {overdueOne,getData,configReducer:{currency}} = this.props;
        const {overdueTwo, overdueThree, overdueFour, overdueFive} = this.state;
        // console.log('con>',overdueTwo);
        return (
            <div className="content-fluid overdue" style={{backgroundColor: config.Back.backgroundColor}}>
                <div className="row text-center pb-2" style={{marginBottom: 16, marginTop: 16}}>
                    <div className="col-md-12 pb-2">
                        <h2>FACTURAS VENCIDAS</h2>
                    </div>
                </div>
                <div className="card" style={{background: 'transparent'}}>
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" id="vencer-tab" data-toggle="tab" href="#vencer" role="tab" aria-controls="vencer" onClick = {()=> getData(4)} aria-selected="true">Por vencer</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="uno-tab" data-toggle="tab" href="#uno" role="tab" aria-controls="uno" onClick = {()=> this.getDataOverDoue(1)} aria-selected="false">Saldo a 30 días</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="dos-tab" data-toggle="tab" href="#dos" role="tab" aria-controls="dos" onClick = {()=> this.getDataOverDoue(2)} aria-selected="false">Saldo a 60 días</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="tres-tab" data-toggle="tab" href="#tres" role="tab" aria-controls="tres" onClick = {()=> this.getDataOverDoue(3)} aria-selected="false">Saldo a 90 días</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="cuatro-tab" data-toggle="tab" href="#cuatro" role="tab" aria-controls="cuatro" onClick = {()=> this.getDataOverDoue(4)} aria-selected="false">Saldo a más de 90 días</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active table-responsive tableReports" id="vencer" role="tabpanel" aria-labelledby="vencer-tab">
                            <table id="tablaOverOne" className="table table-striped scrolltable">
                                <thead>
                                    <tr style={{backgroundColor: config.shoppingList.summaryList, color: "white"}}>
                                        <th scope="col">No. documento</th>
                                        <th scope="col">Fecha del documento</th>
                                        <th scope="col">Fecha del vencimiento</th>
                                        {/* <th scope="col">Total peso neto</th>
                                        <th scope="col">Total peso bruto</th> */}
                                        <th scope="col">Cant. de articulos</th>
                                        <th scope="col">Cant. de unidades</th>
                                        <th scope="col">Adeudo</th>
                                        <th scope="col">Días transcurridos</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {overdueOne.map((order, index)=>{
                                        return (
                                            <tr key={index}>
                                                <th scope="row">{order.DocNum}</th>
                                                <td>{moment(order.DocDate).utc().format('YYYY-MM-DD')}</td>
                                                <td>{moment(order.DocDueDate).utc().format('YYYY-MM-DD')}</td>
                                                {/* <td>{order.Weight1 === null ? '0 KG' : parseFloat(order.Weight1).toFixed(2)+ ' KG'}</td>
                                                <td>{order.Weight2 === null ? '0 KG' : parseFloat(order.Weight2).toFixed(2)+ ' KG'}</td> */}
                                                <td>{parseInt(order.Cant)}</td>
                                                <td>{parseInt(order.Unit)}</td>
                                                <td className="text-right">
                                                    <CurrencyFormat 
                                                        value={order.PORVENCER || 0} 
                                                        displayType={'text'} 
                                                        thousandSeparator={true} 
                                                        fixedDecimalScale={true} 
                                                        decimalScale={2} 
                                                        prefix={currency.MainCurncy !== (order.DocCur === 'MXN' ? 'MXP' : order.DocCur)  ? '' : currency.DocCurrCod}
                                                        suffix = {' ' + currency.MainCurncy}>
                                                    </CurrencyFormat>
                                                </td>
                                                <td className="text-center">{order.DIAS}</td>
                                                <th scope="row" className="text-right">
                                                    <CurrencyFormat 
                                                        value={order.DocTotal} 
                                                        displayType={'text'} 
                                                        thousandSeparator={true} 
                                                        fixedDecimalScale={true} 
                                                        decimalScale={2} 
                                                        prefix={currency.MainCurncy !== (order.DocCur === 'MXN' ? 'MXP' : order.DocCur)  ? '' : currency.DocCurrCod}
                                                        suffix = {' ' + currency.MainCurncy}>
                                                    </CurrencyFormat>
                                                </th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="tab-pane fade table-responsive tableReports" id="uno" role="tabpanel" aria-labelledby="uno-tab">
                            <table id="tablaOverTwo" className="table table-striped scrolltable">
                                <thead>
                                    <tr style={{backgroundColor: config.shoppingList.summaryList, color: "white"}}>
                                        <th scope="col">No. documento</th>
                                        <th scope="col">Fecha del documento</th>
                                        <th scope="col">Fecha del vencimiento</th>
                                        {/* <th scope="col">Total peso neto</th>
                                        <th scope="col">Total peso bruto</th> */}
                                        <th scope="col">Cant. de articulos</th>
                                        <th scope="col">Cant. de unidades</th>
                                        <th scope="col">Adeudo</th>
                                        <th scope="col">Días transcurridos</th>
                                        <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {overdueTwo.map((order, index)=>{
                                        return (
                                            <tr key={index}>
                                                <th scope="row">{order.DocNum}</th>
                                                <td>{moment(order.DocDate).utc().format('YYYY-MM-DD')}</td>
                                                <td>{moment(order.DocDueDate).utc().format('YYYY-MM-DD')}</td>
                                                {/* <td>{order.Weight1 === null ? '0 KG' : parseFloat(order.Weight1).toFixed(2)+ ' KG'}</td>
                                                <td>{order.Weight2 === null ? '0 KG' : parseFloat(order.Weight2).toFixed(2)+ ' KG'}</td> */}
                                                <td>{parseInt(order.Cant)}</td>
                                                <td>{parseInt(order.Unit)}</td>
                                                <td className="text-right">
                                                    <CurrencyFormat 
                                                        value={order.PORVENCER || 0} 
                                                        displayType={'text'} 
                                                        thousandSeparator={true} 
                                                        fixedDecimalScale={true} 
                                                        decimalScale={2} 
                                                        prefix={currency.MainCurncy !== (order.DocCur === 'MXN' ? 'MXP' : order.DocCur)  ? '' : currency.DocCurrCod}
                                                        suffix = {' ' + currency.MainCurncy}>
                                                    </CurrencyFormat>
                                                </td>
                                                <td className="text-center">{order.DIAS}</td>
                                                <th scope="row" className="text-right">
                                                    <CurrencyFormat 
                                                        value={order.DocTotal} 
                                                        displayType={'text'} 
                                                        thousandSeparator={true} 
                                                        fixedDecimalScale={true} 
                                                        decimalScale={2} 
                                                        prefix={currency.MainCurncy !== (order.DocCur === 'MXN' ? 'MXP' : order.DocCur)  ? '' : currency.DocCurrCod}
                                                        suffix = {' ' + currency.MainCurncy}>
                                                    </CurrencyFormat>
                                                </th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="tab-pane fade table-responsive tableReports" id="dos" role="tabpanel" aria-labelledby="dos-tab">
                            <table id="tablaOverThree" className="table table-striped scrolltable">
                                <thead>
                                    <tr style={{backgroundColor: config.shoppingList.summaryList, color: "white"}}>
                                    <th scope="col">No. documento</th>
                                    <th scope="col">Fecha del documento</th>
                                    <th scope="col">Fecha del vencimiento</th>
                                    {/* <th scope="col">Total peso neto</th>
                                    <th scope="col">Total peso bruto</th> */}
                                    <th scope="col">Cant. de articulos</th>
                                    <th scope="col">Cant. de unidades</th>
                                    <th scope="col">Adeudo</th>
                                    <th scope="col">Días transcurridos</th>
                                    <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {overdueThree.map((order, index)=>{
                                        return (
                                            <tr key={index}>
                                                <th scope="row">{order.DocNum}</th>
                                                <td>{moment(order.DocDate).utc().format('YYYY-MM-DD')}</td>
                                                <td>{moment(order.DocDueDate).utc().format('YYYY-MM-DD')}</td>
                                                {/* <td>{order.Weight1 === null ? '0 KG' : parseFloat(order.Weight1).toFixed(2)+ ' KG'}</td>
                                                <td>{order.Weight2 === null ? '0 KG' : parseFloat(order.Weight2).toFixed(2)+ ' KG'}</td> */}
                                                <td>{parseInt(order.Cant)}</td>
                                                <td>{parseInt(order.Unit)}</td>
                                                <td className="text-right">
                                                    <CurrencyFormat 
                                                        value={order.PORVENCER || 0} 
                                                        displayType={'text'} 
                                                        thousandSeparator={true} 
                                                        fixedDecimalScale={true} 
                                                        decimalScale={2} 
                                                        prefix={currency.MainCurncy !== (order.DocCur === 'MXN' ? 'MXP' : order.DocCur)  ? '' : currency.DocCurrCod}
                                                        suffix = {' ' + currency.MainCurncy}>
                                                    </CurrencyFormat>
                                                </td>
                                                <td className="text-center">{order.DIAS}</td>
                                                <th scope="row" className="text-right">
                                                    <CurrencyFormat 
                                                        value={order.DocTotal} 
                                                        displayType={'text'} 
                                                        thousandSeparator={true} 
                                                        fixedDecimalScale={true} 
                                                        decimalScale={2} 
                                                        prefix={currency.MainCurncy !== (order.DocCur === 'MXN' ? 'MXP' : order.DocCur)  ? '' : currency.DocCurrCod}
                                                        suffix = {' ' + currency.MainCurncy}>
                                                    </CurrencyFormat>
                                                </th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="tab-pane fade table-responsive tableReports" id="tres" role="tabpanel" aria-labelledby="tres-tab">
                            <table id="tablaOverFour" className="table table-striped scrolltable">
                                <thead>
                                <tr style={{backgroundColor: config.shoppingList.summaryList, color: "white"}}>
                                    <th scope="col">No. documento</th>
                                    <th scope="col">Fecha del documento</th>
                                    <th scope="col">Fecha del vencimiento</th>
                                    {/* <th scope="col">Total peso neto</th>
                                    <th scope="col">Total peso bruto</th> */}
                                    <th scope="col">Cant. de articulos</th>
                                    <th scope="col">Cant. de unidades</th>
                                    <th scope="col">Adeudo</th>
                                    <th scope="col">Días transcurridos</th>
                                    <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {overdueFour.map((order, index)=>{
                                        return (
                                            <tr key={index}>
                                                <th scope="row">{order.DocNum}</th>
                                                <td>{moment(order.DocDate).utc().format('YYYY-MM-DD')}</td>
                                                <td>{moment(order.DocDueDate).utc().format('YYYY-MM-DD')}</td>
                                                {/* <td>{order.Weight1 === null ? '0 KG' : parseFloat(order.Weight1).toFixed(2)+ ' KG'}</td>
                                                <td>{order.Weight2 === null ? '0 KG' : parseFloat(order.Weight2).toFixed(2)+ ' KG'}</td> */}
                                                <td>{parseInt(order.Cant)}</td>
                                                <td>{parseInt(order.Unit)}</td>
                                                <td className="text-right">
                                                    <CurrencyFormat 
                                                        value={order.PORVENCER || 0} 
                                                        displayType={'text'} 
                                                        thousandSeparator={true} 
                                                        fixedDecimalScale={true} 
                                                        decimalScale={2} 
                                                        prefix={currency.MainCurncy !== (order.DocCur === 'MXN' ? 'MXP' : order.DocCur)  ? '' : currency.DocCurrCod}
                                                        suffix = {' ' + currency.MainCurncy}>
                                                    </CurrencyFormat>
                                                </td>
                                                <td className="text-center">{order.DIAS}</td>
                                                <th scope="row" className="text-right">
                                                    <CurrencyFormat 
                                                        value={order.DocTotal} 
                                                        displayType={'text'} 
                                                        thousandSeparator={true} 
                                                        fixedDecimalScale={true} 
                                                        decimalScale={2} 
                                                        prefix={currency.MainCurncy !== (order.DocCur === 'MXN' ? 'MXP' : order.DocCur)  ? '' : currency.DocCurrCod}
                                                        suffix = {' ' + currency.MainCurncy}>
                                                    </CurrencyFormat>
                                                </th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div className="tab-pane fade table-responsive tableReports" id="cuatro" role="tabpanel" aria-labelledby="cuatro-tab">
                            <table id="tablaOverFive" className="table table-striped scrolltable">
                                <thead>
                                <tr style={{backgroundColor: config.shoppingList.summaryList, color: "white"}}>
                                    <th scope="col">No. documento</th>
                                    <th scope="col">Fecha del documento</th>
                                    <th scope="col">Fecha del vencimiento</th>
                                    {/* <th scope="col">Total peso neto</th>
                                    <th scope="col">Total peso bruto</th> */}
                                    <th scope="col">Cant. de articulos</th>
                                    <th scope="col">Cant. de unidades</th>
                                    <th scope="col">Saldo actual</th>
                                    <th scope="col">Días transcurridos</th>
                                    <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {overdueFive.map((order, index)=>{
                                        return (
                                            <tr key={index}>
                                                <th scope="row">{order.DocNum}</th>
                                                <td>{moment(order.DocDate).utc().format('YYYY-MM-DD')}</td>
                                                <td>{moment(order.DocDueDate).utc().format('YYYY-MM-DD')}</td>
                                                {/* <td>{order.Weight1 === null ? '0 KG' : parseFloat(order.Weight1).toFixed(2)+ ' KG'}</td>
                                                <td>{order.Weight2 === null ? '0 KG' : parseFloat(order.Weight2).toFixed(2)+ ' KG'}</td> */}
                                                <td>{parseInt(order.Cant)}</td>
                                                <td>{parseInt(order.Unit)}</td>
                                               <td className="text-right">
                                                    <CurrencyFormat
                                                        value={order.PORVENCER || 0} 
                                                        displayType={'text'} 
                                                        thousandSeparator={true} 
                                                        fixedDecimalScale={true} 
                                                        decimalScale={2} 
                                                        prefix={currency.MainCurncy !== (order.DocCur === 'MXN' ? 'MXP' : order.DocCur)  ? '' : currency.DocCurrCod}
                                                        suffix = {' ' + currency.MainCurncy}>
                                                    </CurrencyFormat>
                                                </td>
                                                 <td className="text-center">{order.DIAS}</td>
                                                 <th scope="row" className="text-right">
                                                    <CurrencyFormat 
                                                        value={order.DocTotal} 
                                                        displayType={'text'} 
                                                        thousandSeparator={true} 
                                                        fixedDecimalScale={true} 
                                                        decimalScale={2} 
                                                        prefix={currency.MainCurncy !== (order.DocCur === 'MXN' ? 'MXP' : order.DocCur)  ? '' : currency.DocCurrCod}
                                                        suffix = {' ' + currency.MainCurncy}>
                                                    </CurrencyFormat>
                                                </th>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(overdueViews);