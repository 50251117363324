import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import {
    NavBarLogo,
    NavBarShoppingCartIcon,
    NavbarSearchIcon,
    NavBarContentSearchItems,
    NavBarButton,
    LoginModal,
    LoginRegisterModal,
    MenuCategories, 
    DejanosMensajeModal
} from "./index";
import { config, ROLES, DISPATCH_ID } from '../libs/utils/Const';
import { Modal } from './index';
import $ from "jquery";
import './navBar.css';
import { ApiClient } from "../libs/apiClient/ApiClient";
import moment from 'moment';

let modal = new Modal();
let apiClient = ApiClient.getInstance();

class NavBar extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            marcas: [],
            aparatos: [],
            refacciones: [],
            fabricantes: [],
            materiales: [],
            seller: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser')),
            vendor: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'vendor')),
        };
      }
    //Evento para capturar los valores de los campos
    handelChange = ({ target }) => {
        const { name, value } = target;
        this.setState({
            [name]: value
        });
    };

    componentDidMount() {
        // this.fillDataSearch();
    }

    toggleMenuUser = () => {
        $("#menu-user-button").click(function () {
            $(".menu-user").toggle();
            $(".menu-user-responsive").focus();
        });
    }

    renderName = () => {
        const { configReducer, sessionReducer: { user } } = this.props;
        const { seller, vendor } = this.state;
        if (seller !== null) {
            return (
                <span className="ml-2" style={{ color: config.navBar.iconColor }}>{seller.firstName} {seller.lastName} {user.CardName}</span>
            );
        } else {
            return (
                <span className="ml-2" style={{ color: config.navBar.iconColor }}>{user.CardName}</span>
            );
        }
    }

    iconUser = (priceList)=>{
        let result = '';
        switch (priceList) {
            // case 4:
            //     result = config.Avatar.bronze1;
            //     break;
            // case 5:
            //     result = config.Avatar.silver1;
            //     break;
            // case 6:
            //     result = config.Avatar.gold1;
            //     break;
            // case 7:
            //     result = config.Avatar.platinum1;
            //     break;
            // case 9:
            //     result = config.Avatar.diamond1;
            //     break;
            // case 13:
            //     result = config.Avatar.mercado1; 
            //     break;
            default:
                result = config.Avatar.red1;
                break;
        }
        return result
    }

    // nivel = (priceList)=>{
    //     let result = '';
    //     switch (priceList) {
    //         case 4:
    //             result = 'Bronze';
    //             break;
    //         case 5:
    //             result = 'Silver';
    //             break;
    //         case 6:
    //             result = 'Gold';
    //             break;
    //         case 7:
    //             result = 'Platinum';
    //             break;
    //         case 9:
    //             result = 'Diamond';
    //             break;
    //         case 13:
    //             result = 'Filial';
    //             break;
    //         default:
    //             result = '';
    //             break;
    //     }
    //     return result
    // }

    renderClientOptionFirstNavBar = () => {
        const { configReducer, sessionReducer: { user } } = this.props;
        const { seller } = this.state;
        return (
            <ul className="navbar-nav miCuenta d-md-flex ml-4" style={{maxWidth: 200}}>
                <li className="nav-item">
                    <span className="imgAvatar row">
                        {/* <i id="IconUser" className={config.icons.user} aria-hidden="true" style={{ color: config.navBar.iconColor2, fontSize: 25 }} /> */}
                            <img src={this.iconUser(user.listNum)} style={{ color: config.navBar.iconColor}}/>
                        <a className="nav-link dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {/* <span className="ml-2" style={{widh:150, maxWidth: 200, color: "#0078C0"}}><strong>Bienvenido </strong><br/>{user.CardName}</span> */}
                            <div className="marq" ><span className="orig">{this.renderName()}</span></div><br/>
                            {/* <label className="justify-content-left" ><span className="text-left font-weight-bold">Nivel: {this.nivel(user.listNum)}</span></label> */}
                        </a>
                    {/* <div className="dropdown"> */}
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {/* {seller ? seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 ? 
                                <a className="dropdown-item" onClick={() => { configReducer.history.goSelectClient(); }}>Mis clientes</a>
                                : "" : ""
                            } */}
                            {/* {seller ? seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 ? 
                                <a className="dropdown-item" onClick={() => { configReducer.history.goBoletin(); }}>Ver correos del boletín</a>
                                : "" : ""
                            } */}
                            {/* {seller ? seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 ? 
                                <a className="dropdown-item" onClick={() => { configReducer.history.goAnalytics(); }}>Analytics</a>
                                : "" : ""
                            } */}
                            {/* {seller ? seller.U_FMB_Handel_Perfil != '0' && seller.U_FMB_Handel_Perfil != 5 && seller.U_FMB_Bonificaciones === "1" ?
                                <a className="dropdown-item" onClick={() => { configReducer.history.goPromocionales(); }}>Bonificaciones</a>
                                : "" : ""
                            } */}
                            {/* {Object.entries(user).length !== 0 && } */}
                            {config.modules.subirArchivoExcel && seller ? seller.U_FMB_Handel_Perfil != 5 && Object.entries(user).length !== 0 ? 
                                <Link to='/subirArchivo' className="dropdown-item" >Subir archivo OC</Link>
                                : "" : ""
                            }
                            {seller ? seller.U_FMB_Handel_Perfil != 5 && Object.entries(user).length !== 0 ?
                                <Link to='/reports' className="dropdown-item" >Mis reportes</Link>
                                : "" : ""
                            }
                            {/* <a className="dropdown-item" onClick={() => { configReducer.history.goOrders(); }}>Mis pedidos</a> */}
                            {user.wishlist === 1 &&
                                <a className="dropdown-item" onClick={() => { configReducer.history.goBackOrder(); }}>Lista de deseos</a>}
                            {/* {user.U_FMB_Handel_Admin === '1' &&
                                <a className="dropdown-item" onClick={() => { configReducer.history.goML(); }}>Admin ML</a>} */}
                            {/* {user.banners === "on" && */}
                            {seller ? seller.U_FMB_Banner == 1 ?
                                <Link to='/adminBanners' className="dropdown-item" >Adm. Banners</Link>
                                : "" : ""
                            }
                            {/* seller ? seller.U_FMB_AdmBlogNoticia == 1 ? */}
                            {/* 117 */}
                            {user ? parseInt(user.U_FMB_AdmBlogNoticia) == 1 ?
                                <Link to='/admiNewsBlog' className="dropdown-item" >Adm. Blog de noticias</Link>
                                : "" : ""
                            }
                            {seller ? seller.U_FMB_Handel_Perfil === 5
                                    ? 
                                    <a className="dropdown-item" onClick={() => { configReducer.history.goAutorizaciones(); }}>Autorizaciones</a>
                                    : "" : ""
                                }
                                {/* goMyProfile es el perfil de Pete, goProfile es de Missa */}
                            {seller ? seller.U_FMB_Handel_Perfil != 5 && Object.entries(user).length !== 0 ?
                                <a className="dropdown-item" onClick={() => { configReducer.history.goProfile(); }}>Mi Perfil</a>
                                : "" : ""
                            }
                                {/* <Link to='/reports' className="dropdown-item"  style={{ color: config.navBar.textColor }}>Mis reportes</Link> */}
                            {user.banners === "on" &&
                                <Link to='/adminBanners' className="dropdown-item" style={{ color: config.navBar.textColor }}>Adm. Banners</Link>
                            }
                            {user.U_FMB_Handel_Admin === '1' && config.modules.points &&
                                <a className="dropdown-item" onClick={() => { configReducer.history.goResetPoints(); }}>Periodo de puntos</a>}
                            {/* {user.U_FMB_Handel_Admin === '1' &&
                                <a className="dropdown-item" onClick={() => { configReducer.history.goSpecialPrices(); }}>Precios especiales</a>} */}

                                {/* <a className="dropdown-item" onClick={() => { configReducer.history.goProfile(); }}>Mi Perfil</a> */}
                            <a className="dropdown-item" onClick={() => { this.logOut(); }}>Cerrar Sesión</a>
                        </div>
                        </span>
                    {/* </div> */}
                </li>
            </ul>
        )
    };

    logOut = async () => {
        const { configReducer, setBusiness, setToken, setUser, setRememberUser, setRole, shoppingCartReducer: { getShoppingCart }, sessionReducer: { user } } = this.props;
        const { seller, vendor } = this.state;
        // ---------------------------------------------------------------------------------------------
        let today = moment().format('YYYY-MM-DD');
        let time = moment().format('h:mm:ss a');
        let data = [];
        if(seller && seller.U_FMB_Handel_Perfil == 2 ){
            data = {
                CardCode : seller.salesPrson,
                CardName : seller.lastName + ' ' + seller.firstName,
                Date : today, 
                Time : time,
                TypeUser : 'Vendedor',
                Email : seller.email,
                IP : '192.168.0.139', 
                Business : 'Irco',
                Session : 0
            }
        }else if(seller.U_FMB_Handel_Perfil == 0){
            data = {
                CardCode : user.CardCode,
                CardName : user.CardName,
                Date : today, 
                Time : time,
                TypeUser : 'Cliente',
                Email : user.U_FMB_Handel_Email,
                Business : 'Irco',
                Session : 0
            }
        }
        if( Object.keys(data).length > 0){
            await apiClient.sendData(data);
        }
        
       
        // ---------------------------------------------------------------------------------------------
        localStorage.removeItem(config.general.localStorageNamed + 'Role');
        localStorage.removeItem(config.general.localStorageNamed + 'Token');
        localStorage.removeItem(config.general.localStorageNamed + 'CurrentUser');
        localStorage.removeItem(config.general.localStorageNamed + 'PartnerUser');
        localStorage.removeItem(config.general.localStorageNamed + 'RememberUser');
        localStorage.removeItem(config.general.localStorageNamed + 'vendor');
        localStorage.clear();
        configReducer.history.goHome();

        setRole(ROLES.PUBLIC);
        setBusiness(config.general.business);
        setToken(undefined);
        setUser({});
        setRememberUser(false);

        setTimeout(async () => {
            await getShoppingCart(true);
        }, 300);
    };

    renderPublicOptionFirstNavBar = () => {
        const { configReducer: {history} } = this.props;
        return (
            <ul className="navbar-nav d-none d-md-flex">
                <li className="nav-item" >
                    <a className="nav-link" style={{ color: config.navBar.iconColor }}>
                        <h4 className = "font-weigth-bold mr-sm-2 miCuenta" style={{ color: config.navBar.iconColor }} onClick={() => { /*modal.loginModal('show')*/ history.goLogin(); }}><img src={config.navBar.avatar} alt="avatar" style={{width: "28px"}} /> Inicio de sesión</h4>
                    </a>
                </li>
                {/* <li className="nav-item">
                    <a className="nav-link" style={{ color: config.navBar.textColor }}>
                        <span onClick={() => { modal.loginRegisterModal('show') }}>Registrarse</span>
                    </a>
                </li> */}
            </ul>
        )
    };

    renderFirstNavBar = () => {
        const { sessionReducer, configReducer } = this.props;
        return (
            <div style={{zIndex: 10}}>
                <div className="row justify-content-end p-2" style={{backgroundColor: config.navBar.iconColor, fontSize: '14pt'}}>
                    {/* <div className="col-auto">
                        <a href= {config.footer.info.linkPhoneWhats} target="_blank">
                            <img className="img-fluid" width='25px' src={config.BarraTelefono.Whats} style={{ color: config.footer.iconColor, padding: 1 }}></img>&nbsp;
                            <span style={{color:"white"}}>{config.footer.info.phoneWhats}</span>
                        </a>
                    </div> */}
                    <div className="col-auto">
                        <a>
                            <img className="img-fluid" width='25px' src={config.BarraTelefono.IconCel} style={{ color: config.footer.iconColor, padding: 1 }}></img>&nbsp;
                            <span style={{color:"white"}}>{config.footer.info.phone}</span>
                        </a>
                    </div>
                    {/* <div className="col-auto">
                        <a onClick={() => { configReducer.history.goSucursales(); }} target="_blank">
                            <img className="img-fluid" width='25px' src={config.BarraTelefono.IconUbicacion} style={{ color: config.footer.iconColor, padding: 1 }}></img>&nbsp;
                            <span style={{color:"white"}}>Una sucursal cerca de ti</span>
                        </a>
                    </div> */}
                </div>
                <nav id="navFirst" className="​navbar navbar-expand-sm primerNav" style={{ border: "none", background: config.navBar.backgroundColor }} >
                    <ul className="navbar-nav elements">
                        <li className="nav-link menu-categories-button text-center" style={{ color: config.navBar.textColor, cursor: "pointer" }}>
                            <img id="buttonMenu" src={config.navBar.menu} className="Img-fluid" style={{ color: config.navBar.iconColor}} />
                            <div className="navbar-brand" >
                                <NavBarLogo/>       
                            </div> 
                        </li>                       
                        <li className="nav-item searchItems d-md-block d-none" style={{ textAlign: "center" }}>
                            <NavBarContentSearchItems className="nav justify-content-center" icon={config.icons.search} iconColor={config.navBar.iconColor} textColor={config.navBar.backgroundColor} fillDataSearch ={this.fillDataSearch} />

                        </li>
                        <li className="nav-item text-center d-md-none d-lg-none searchAdvance" > 
                            {/* <span className="buttonSearchAdvanced" > <img src={config.navBar.iconSearch} onClick={() => this.fillDataSearch()} className="Img-fluid"/> </span>  */}
                            {/* <span className="d-md-none" ><img src={config.navBar.searchAdvanced} className="Img-fluid" onClick={() => { modal.searchModal('show') }}/></span> */}
                        </li>
                        <li className="shopp nav-item mr-sm-auto d-md-flex row" id="navbarFirst" style={{marginRight: "5px"}}>    
                            <span >
                                <span>{sessionReducer.role === ROLES.PUBLIC ? this.renderPublicOptionFirstNavBar() : this.renderClientOptionFirstNavBar()}</span>
                            </span>
                            <span className="ml-2">
                                <NavbarSearchIcon
                                    icon={config.icons.search}
                                    iconColor={config.navBar.iconColor}
                                    textColor={config.navBar.textColor}
                                />
                                <NavBarShoppingCartIcon
                                    icon={config.icons.shoppingCart}
                                    iconColor={config.navBar.iconColor2}
                                    textColor={config.navBar.textColor}
                                />
                            </span>                         
                        </li>
                     </ul>
                </nav>
                {/* <div className="fixed-top navbar navbar-expand-sm navbar-dark" style={{ border: 'none', padding: 0, height: 45, background: config.navBar.backgroundColor, marginTop: 30 }}>
                    <ul className="navbar-nav " style={{ paddingLeft: 16, paddingBottom: 16 }}>
                        <li className="nav-link menu-categories-button" style={{ color: config.navBar.textColor, cursor: "pointer" }}>
                            <i className={config.icons.menu} style={{ color: config.navBar.iconColor, fontsize: 15 }} />
                        </li>
                    </ul>
                    <NavBarLogo />
                    <ul className="navbar-nav  col-md-4 d-sm-block d-none  " style={{ paddingBottom: 5, marginLeft: 230, textAlign: "center" }}>
                        <li>
                            <NavBarContentSearchItems className="nav justify-content-center" icon={config.icons.search} iconColor={config.navBar.iconColor} textColor={config.navBar.backgroundColor} />
                        </li>
                    </ul>
                    <div className="row" >
                        <div className="col-md-12">
                            <button type="button"
                                className="btn btn-primary btn-sm btn-block"
                                style={{ fontsize: 25, background: config.navBar.iconColor, color: "white" }}
                                onClick={() => { modal.searchModal('show') }}>
                                Busqueda avanzada
                            </button>
                        </div>
                    </div>
                    <div className="navbar-nav ml-sm-auto d-md-flex" id="navbarFirst">
                        <span className="mr-2 p-1">
                            <span>{sessionReducer.role === ROLES.PUBLIC ? this.renderPublicOptionFirstNavBar() : this.renderClientOptionFirstNavBar()}</span>
                        </span>
                        <span className="p-1 mr-3">
                            <NavbarSearchIcon
                                icon={config.icons.search}
                                iconColor={config.navBar.iconColor}
                                textColor={config.navBar.textColor}
                            />&nbsp;
                        <NavBarShoppingCartIcon
                                icon={config.icons.shoppingCart}
                                iconColor={config.navBar.iconColor}
                                textColor={config.navBar.textColor}
                            />
                        </span>
                    </div>
                </div> */}
            </div >
        );
    };

    renderPublicOptionMenu = () => {
        const { configReducer: {history} } = this.props;
        return (
            <ul className="navbar-nav mr-auto" >
                <li className="nav-item" >
                    <a className="nav-link" style={{ color: config.navBar.textColor }}>
                        <h4 className = "font-weigth-bold" style={{ color: config.navBar.textColor }} onClick={() => { /*modal.loginModal('show')*/ history.goLogin(); }}><i className="fas fa-user-circle"/> Inicio de sesión </h4>
                    </a>
                </li>
                {config.aboutUs.active &&
                    <li className="nav-link nav-item text-white" style={{ cursor: "pointer" }} onClick={() => history.goAboutUs()}>
                        <span> ¿Quiénes somos?</span>
                    </li>}
                {config.contactUs.active &&
                    <li className="nav-link nav-item text-white" style={{  cursor: "pointer" }} onClick={() => history.goContactUs()}>
                        <span> Contáctanos </span>
                    </li>}
                {config.questions.active &&
                    <li className="nav-link nav-item text-white" style={{ color: config.navBar.textColor, cursor: "pointer" }} onClick={() => history.goQuestions()}>
                        <span> Preguntas frecuentes </span>
                    </li>}
            </ul>
        )
    };

    searchModal = () => {
        const { marcas, aparatos, refacciones, fabricantes, materiales } = this.state;
        return (
            <div className="modal fade" id="searchModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ border: "none", textAlign: "center" }}>
                <div className="modal-dialog" role="document" style={{ margin: "1.75rem auto" }}>
                    <div className="modal-content">
                        <div className="modal-header" style={{ background: '#0060aa'  }}>
                            <h5 className="modal-title " style={{ color: config.navBar.textColor2 }}>Búsqueda</h5>
                            <button type="button" style={{ color: config.navBar.textColor2 }} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body bg3">
                            <form onSubmit={this.handelSubmit} method="post" encType="text/plain" className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4>Marca</h4>
                                        <select name="marca" placeholder="Selecciona una marca" className="form-control text-left" onChange={this.handelChange} style={{ textAlign: "center", height: 30, padding: 0 }}>
                                            <option value="">Selecciona una marca</option>
                                            {marcas &&
                                                marcas.map(marca => {
                                                    return <option value={marca.Code} key={marca.Code}>{marca.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <h4>Aparato</h4>
                                        <select name="aparato" placeholder="Selecciona un aparato" className="form-control text-left" onChange={this.handelChange} style={{ textAlign: "center", height: 30, padding: 0 }}>
                                            <option value="">Selecciona un aparato</option>
                                            {aparatos &&
                                                aparatos.map(aparato => {
                                                    return <option value={aparato.Code} key={aparato.Code}>{aparato.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-md-12">
                                        <h4>Refacción</h4>
                                        <select name="refaccion" placeholder="Selecciona una refaccion" className="form-control text-left" onChange={this.handelChange} style={{ textAlign: "center", height: 30, padding: 0 }}>
                                            <option value="">Selecciona una refacción</option>
                                            {refacciones &&
                                                refacciones.map(refaccion => {
                                                    return <option value={refaccion.Code} key={refaccion.Code}>{refaccion.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    {/* <div className="col-md-12">
                                        <h4>Fabricante</h4>
                                        <select name="fabricante" placeholder="Selecciona un fabricante" className="form-control text-left" onChange={this.handelChange} style={{ textAlign: "center", height: 30, padding: 0 }}>
                                            <option value="">Selecciona un fabricante</option>
                                            {fabricantes &&
                                                fabricantes.map(fabricante => {
                                                    return <option value={fabricante.Code} key={fabricante.Code}>{fabricante.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div> */}
                                    <div className="col-md-12">
                                        <h4>Material</h4>
                                        <select name="material" placeholder="Selecciona un material" className="form-control text-left" onChange={this.handelChange} style={{ textAlign: "center", height: 30, padding: 0 }}>
                                            <option value="">Selecciona un material</option>
                                            {materiales &&
                                                materiales.map(material => {
                                                    return <option value={material.Code} key={material.Code}>{material.Name}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    {/* <div className="col-md-12">
                                        <h4>Nombre</h4>
                                        <input type="text" className="form-control" placeholder="Nombre(s)" name="nombre" onChange={this.handelChange} />
                                    </div> */}
                                    <div className="row">
                                        <div className="form-group col-md-12">
                                            <input type="submit" value="Buscar" className="btn mb-2 btn-block "
                                                style={{ fontsize: 25, background: '#0060aa' , color: "white", marginTop: 15 }} />
                                        </div>
                                    </div> 
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    fillDataSearch = async () => {
        const { enableSpinner } = this.props;
        //Busqueda de valores para los desplegables
        enableSpinner(true);
        await apiClient.getFiltros().then(result => {
            this.setState({
                marcas: result.data.marcas || [],
                aparatos: result.data.aparatos || [],
                refacciones: result.data.refacciones || [],
                fabricantes: result.data.fabricantes || [],
                materiales: result.data.materiales || [],
            });
            setTimeout(() => {
                modal.searchModal('show');
            }, 300);
           
        });
        enableSpinner(false);
        
    };

    handelSubmit = async (e) => {
        //Prevenimos un recargo de pagina
        e.preventDefault();
        //Definimos las variables que estan en el state
        const { marca, marcas, aparato, refaccion, fabricante, material, nombre } = this.state;
        const { enableSpinner, setItemsSearch, configReducer: { history }, setItemsFilterSearch,sessionReducer } = this.props;
        //Validamos que los campos esten llenos

        //Preparamos los datos para pasarlos al back
        let busqueda = {
            contenido: {
                marca: marca,
                aparato: aparato,
                refaccion: refaccion,
                fabricante: fabricante,
                material : material,
                nombre: nombre,
            }
        };
        //Hace la petición al back
        enableSpinner(true);
        await apiClient.searchAdvance(busqueda).then(result => {
            modal.searchModal('hide');
            enableSpinner(false);
            setItemsSearch(result.data);
            setItemsFilterSearch(result.data);
            // -----------------------------------
            let today = moment().format('YYYY-MM-DD');
            let time = moment().format('h:mm:ss a');
            let data = {
                cve_user : sessionReducer.user.CardCode || '',
                search :  marca+' '+aparato+' '+refaccion+' '+fabricante+' '+material+' '+nombre,
                date : today,
                time : time,
                origin : 'busqueda',
                num_results : result.data.length,
            }
            // await apiClient.Search(data);
            // -----------------------------------
            history.goItems();
            return;
        });
        //Validamos la respuesta del Back
    };

    renderClientOptionMenu = () => {
        const { configReducer , sessionReducer: { user } } = this.props;
        const { seller } = this.state;
        return (
            <ul className="navbar-nav mr-auto">
                <li className="nav-item" onClick={() => { configReducer.history.goProfile(); }}>
                    <a className="nav-link" style={{ color: config.navBar.iconColor }}>
                        <strong><span style={{color: config.navBar.iconColor2}}>{user.CardName}</span></strong>
                    </a>
                </li>
                <li className="nav-item" onClick={() => { configReducer.history.goProfile(); }}>
                    <a className="nav-link" style={{ color: config.navBar.textColor }}>
                        <span style={{color: config.navBar.textColor2}}>Mi Perfil</span>
                    </a>
                </li>
                <li className="nav-item" onClick={() => { configReducer.history.goReports(); }}>
                    <a className="nav-link" style={{ color: config.navBar.textColor }}>
                        <span  style={{color: config.navBar.textColor2}}>Mis reportes</span>
                    </a>
                </li>

                {config.aboutUs.active &&
                    <li className="nav-link nav-item text-white" style={{ cursor: "pointer" }} onClick={() => configReducer.history.goAboutUs()}>
                        <span> ¿Quiénes somos?</span>
                    </li>}
                {config.contactUs.active &&
                    <li className="nav-link nav-item text-white" style={{  cursor: "pointer" }} onClick={() => configReducer.history.goContactUs()}>
                        <span> Contáctanos </span>
                    </li>}
                {config.questions.active &&
                    <li className="nav-link nav-item text-white" style={{ color: config.navBar.textColor, cursor: "pointer" }} onClick={() => configReducer.history.goQuestions()}>
                        <span> Preguntas frecuentes </span>
                    </li>}

                {/* {user.banners === "on" && */}
                {seller ? seller.U_FMB_Banner == 1 ?
                    <li className="nav-item">
                        <a className="nav-link" style={{ color: config.navBar.textColor2 }}>
                            <span>
                                <Link to='/adminBanners' className="dropdown-item" style={{color: config.navBar.textColor2}} >Adm. Banners</Link>
                            </span>
                        </a>
                    </li>
                    : "" : ""
                }
                <li className="nav-item" onClick={() => { this.logOut(); }}>
                    <a className="nav-link" style={{ color: config.navBar.textColor }}>
                        <span  style={{color: config.navBar.textColor2}}>Cerrar Sesión</span>
                    </a>
                </li>
            </ul>
        )
    };

    dejanosMensaje = async () => {     
        setTimeout(() => {
            $('#dejanosMensajeModal').modal('show');
        }, 300);   
    };

    renderSecondNavBar = () => {
        const { configReducer: { history }, sessionReducer: { user }, sessionReducer } = this.props;
        
        return (
            <div style={{ width: "100%", zIndex: 90, background: "transparent"}}>
                <div className="d-block d-lg-none" style={{ width: "100%", height: 45, background: config.navBar.backgroundColor }}>
                    <nav className="navbar navbar-expand-lg navbar-two" style={{ background: config.navBar.iconColor }}>
                        <NavBarButton iconColor={config.navBar.iconColor2} icon={config.icons.user} ruta={this.iconUser(user.listNum)} style={{ fontsize: 25}} />  
                        <div className="collapse navbar-collapse" id="navbarSupportedContent1">
                            {sessionReducer.role === ROLES.PUBLIC ? this.renderPublicOptionMenu() : this.renderClientOptionMenu()}
                            <ul className="navbar-nav mr-auto">
{/*                                 {config.aboutUs.active &&
                                    <li className="nav-link nav-item text-white" style={{ cursor: "pointer" }} onClick={() => history.goAboutUs()}>
                                        <span> ¿Quiénes somos?</span>
                                    </li>}
                                {config.contactUs.active &&
                                    <li className="nav-link nav-item text-white" style={{  cursor: "pointer" }} onClick={() => history.goContactUs()}>
                                        <span> Contáctanos </span>
                                    </li>}
                                {config.questions.active &&
                                    <li className="nav-link nav-item text-white" style={{ color: config.navBar.textColor, cursor: "pointer" }} onClick={() => history.goQuestions()}>
                                        <span> Preguntas frecuentes </span>
                                    </li>} */}
                                {config.claim.active &&
                                    <li className="nav-link nav-item text-white" style={{ color: config.navBar.textColor, cursor: "pointer" }} onClick={() => history.goClaim()}>
                                        <span>Contáctanos</span>
                                    </li>}
                                {config.termPage.active &&
                                    <li className="nav-link nav-item text-white" style={{ color: config.navBar.textColor, cursor: "pointer" }} onClick={() => history.goPolitics()}>
                                        <span> Términos de uso </span>
                                    </li>}
                            </ul>
                        </div>
                    </nav>
                </div>
                <div className="d-12 d-none d-lg-flex" style={{ width: "100%", background: config.navBar.backgroundColor }}>
                    <nav className="navbar navbar-expand-md navbar-light navbar-two" style={{ width: "100%", backgroundColor: config.navBar.menuCategoriesBackground}}>
                        <div className="d-none d-lg-block col-md-12">
                            <ul className="navbar-nav justify-content-md-center">
                                <li className="nav-link col-auto text-navbar mt-2" style={{ color: config.navBar.textColor2, cursor: "pointer"}} onClick={() => history.goAboutUs()}>
                                    {/* <img src={config.NavarIconos.iconOne} className="Img-fluid"></img> */}
                                    <span>¿Quienes somos?</span>
                                </li>
                                <li className="nav-link col-auto text-navbar mt-2" style={{ color: config.navBar.textColor2, cursor: "pointer"}} onClick={() => history.goContactUs()}>
                                    {/* <img src={config.NavarIconos.iconThree} className="Img-fluid " ></img> */}
                                    <span>Contactanos</span>
                                </li>

                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        );
    };

    mostrarCategoria = (valor,seccion) => {
        const {setCategory,setIdCategory, setLocation,itemsReducer: {searchByCategories},setNextPage} = this.props;
        setIdCategory(valor);
        setNextPage(0);
        setLocation('menuCategories');
        searchByCategories(valor,0,seccion);
    };

    handleOutletZone = async ()=>{
        const { configReducer: { history }} = this.props;
        const {itemsReducer,setIdCategory,setLocation} = this.props;
        setIdCategory(null);
        setLocation('outletView');
        await itemsReducer.searchByKey(0, 'outletView');
    }
    handleWhiteZone = async ()=>{
        const { configReducer: { history }} = this.props;
        const {itemsReducer,setIdCategory,setLocation} = this.props;
        setIdCategory(null);
        setLocation('whiteView');
        await itemsReducer.searchByKey(0, 'whiteView');
    }
    handleRedPolarZone = async ()=>{
        const { configReducer: { history }} = this.props;
        const {itemsReducer,setIdCategory,setLocation} = this.props;
        setIdCategory(null);
        setLocation('redView');
        await itemsReducer.searchByKey(0,'redView');
    }

    render() {
        const { sessionReducer: { user }, sessionReducer } = this.props;
        return (
            <div>
                <MenuCategories />
                <LoginRegisterModal />
                <LoginModal />
                <DejanosMensajeModal
                    Usuario={user}
                    datos = {sessionReducer.role}
                ></DejanosMensajeModal>
                {this.searchModal()}
                <div className="fixed-top" style={{ background: config.navBar.backgroundColor, zIndex: 200}}>
                    {this.renderFirstNavBar()}
                    {this.renderSecondNavBar()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        itemsReducer: store.ItemsReducer,
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        shoppingCartReducer: store.ShoppingCartReducer
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_SPINNER, value }),
        setItemsSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SET_ITEMS, value }),
        setItemsFilterSearch: value => dispatch({ type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER, value }),

        setRole: value => dispatch({ type: DISPATCH_ID.SESSION_SET_ROLE, value }),
        addNotificationReference: value => dispatch({ type: DISPATCH_ID.NOTIFICATION_SET_ALERT_REFERENCE, value }),
        setBusiness: value => dispatch({ type: DISPATCH_ID.SESSION_SET_BUSINESS_ID, value }),
        setToken: value => dispatch({ type: DISPATCH_ID.SESSION_SET_TOKEN, value }),
        setUser: value => dispatch({ type: DISPATCH_ID.SESSION_SET_USER, value }),
        setRememberUser: value => dispatch({ type: DISPATCH_ID.SESSION_SET_REMEMBER_USER, value }),
        setLogOutReference: value => dispatch({ type: DISPATCH_ID.CONFIG_SET_LOG_OUT_REFERENCE, value }),
        setIdCategory: value => dispatch({type: DISPATCH_ID.ITEMS_SET_IDCATEGORY, value}),
        setNextPage:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_NEXTPAGE, value}),
        setLocation:  value => dispatch({type: DISPATCH_ID.ITEMS_SET_LOCATION, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NavBar);
