import React, { Component } from 'react';
import { config, SERVICE_API, VIEW_NAME, DISPATCH_ID} from '../libs/utils/Const';
import {connect} from "react-redux";
import {ItemDetailsModal} from "./index";
import CurrencyFormat from 'react-currency-format';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ItemSlider.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import moment from 'moment';
import $ from "jquery";

// definir las variables responsivas para el carrusel
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1920 },
        items: 4,
        partialVisibilityGutter: 80 // this is needed to tell the amount of px that should be visible.
    },
    desktop2: {
        breakpoint: { max: 1920, min: 1200 },
        items: 3,
        partialVisibilityGutter: 80 // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1200, min: 992 },
      items: 2,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    tablet1: {
      breakpoint: { max: 992, min: 767 },
      items: 2,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    tablet2: {
        breakpoint: { max: 767, min: 464 },
        items: 1,
        partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
      },
    mobile: {
      breakpoint: { max: 463, min: 0 },
      items: 1,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    }
}

class ItemSlider2 extends Component {

    constructor(props){
        super(props);
        this.state = {
            seller: JSON.parse(localStorage.getItem(config.general.localStorageNamed + 'PartnerUser')),
            itemSelect: '',
        };
        this.mouseOverAddCart = this.mouseOverAddCart.bind(this);
        this.mouseOutAddCart = this.mouseOutAddCart.bind(this);
        this.iconMouse = [];     
        this.iconMouseOver = []; 
    }

    changeQuantity = (index, item, event) =>{
        const {changeQuantity, notificationReducer: {showAlert},itemsReducer: {items2: itemsSearch}} = this.props; 
         let newQuantity;
        itemsSearch.map( itemFilter => {
            if (itemFilter.ItemCode === item.ItemCode) {
                newQuantity = itemFilter.quantity || '1';
            }
        });
        
        let onHand = item.OnHandPrincipal;
        if(Number(newQuantity) > Number(onHand)) {
            if (Number(onHand) !== 0 ) {
                showAlert({type: 'warning', message: "Se excede el número de articulos disponibles de este producto", timeOut: 2500});
            }
            newQuantity = onHand;
        } 
        

        if(!newQuantity){
            changeQuantity(index,item, newQuantity, false); //delete
        }else{
            changeQuantity(index,item, newQuantity, true); // add
        }      
    };

    changLocalQuantity = (index, item, event)=>{
        const {itemsReducer: {items2: itemsSearch}} = this.props;
        let newQuantity =  event.nativeEvent.target.value;
        itemsSearch.map( itemFilter => {
            if (itemFilter.ItemCode === item.ItemCode) {
                itemFilter.quantity = newQuantity;
            }
        });
        this.applyFilters(itemsSearch);
    }

    setSepecialPrice = (itemParameter) =>{
        let item = itemParameter;
        const {itemsReducer: {specialPrice}} = this.props;
        
        let CardCode = specialPrice.CardCode;
        let PriceList = specialPrice.ListNum && specialPrice.ListNum !== '' ? specialPrice.ListNum : specialPrice.priceList;
        
        // Special Prices Validation
        //########################################################################################################################
        let priority2 = "*" + PriceList;
        let formattedDocDate = moment().format('YYYY/MM/DD');

        let flagSN = true; // Socio de negocio
        let flagPriceList = true; // Lista de precios
        let flagPricePriority = true;
        let maxQuantity = 0; // Cantidad maxima a alegir
        let priceItem = -1;
        let discount = -1;
        let priceBeforeDisc= -1;

        item.QuantitySpecial = item.quantity;
        item.Price = -1;

        // Socio de negocios
        if(specialPrice.specialPrices.length > 0){
            specialPrice.specialPrices.map((itemPrice) => {
                if (item.ItemCode == itemPrice.ItemCode && itemPrice.CardCode == CardCode) {
                    if (itemPrice.children.length > 0) {
                        itemPrice.children.map((child) => {
                            let inicial = new Date(child.FromDate);
                            let final = new Date(child.ToDate || formattedDocDate);
                            let docDate = new Date(formattedDocDate);
                            
                            inicial.setMinutes(inicial.getMinutes() + inicial.getTimezoneOffset());
                            final.setMinutes(final.getMinutes() + final.getTimezoneOffset());

                            inicial.setHours(0, 0, 0, 0);
                            final.setHours(0, 0, 0, 0);
                            docDate.setHours(0, 0, 0, 0);

                            if (docDate >= inicial && docDate <= final) {
                                if (itemPrice.childrenCantidades.length > 0) {
                                    // Cantidades
                                    itemPrice.childrenCantidades.map((child2) => {
                                        let inicialCantidad = new Date(child2.FromDate);
                                        let finalCantidad = new Date(child2.ToDate || formattedDocDate);

                                        if(docDate >= inicialCantidad && docDate <= finalCantidad){
                                            if ((item.QuantitySpecial || 1) >= child2.Amount) {
                                                if(child2.Amount > maxQuantity) {
                                                    maxQuantity = child2.Amount;
                                                    item.DiscountPercentSpecial = child2.Discount;
                                                    flagPricePriority = false;
                                                    priceItem = parseFloat(child2.Price);
                                                    discount = parseFloat(child2.Discount);
                                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                                }
                                            }
                                        }
                                    });
                                    if(maxQuantity != 0){
                                        flagSN = false;
                                    }
                                    if(flagSN){
                                        // Fechas
                                        item.DiscountPercentSpecial = child.Discount;
                                        flagPricePriority = false;
                                        priceItem = parseFloat(child.Price);
                                        discount = parseFloat(child.Discount);
                                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                        flagSN = false;
                                    }
                                } else {
                                    // Fechas
                                    item.DiscountPercentSpecial = child.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(child.Price);
                                    discount = parseFloat(child.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                    flagSN = false;
                                }
                            } else {
                                // General
                                if(flagSN){
                                    item.DiscountPercentSpecial = itemPrice.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(itemPrice.Price);
                                    discount = parseFloat(itemPrice.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                }
                            } 
                        })
                    } else {                           
                        //General
                        item.DiscountPercentSpecial = itemPrice.Discount;
                        flagPricePriority = false;
                        priceItem = parseFloat(itemPrice.Price);
                        discount = parseFloat(itemPrice.Discount);
                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                    }
                }
            });
        }
        // Lista de inventario
        if(specialPrice.specialPricesList.length > 0 && flagPricePriority){
            specialPrice.specialPricesList.map((itemPrice2)  => {
                if(itemPrice2.ItemCode == item.ItemCode && itemPrice2.CardCode == priority2){
                    if (itemPrice2.children.length > 0) {
                        itemPrice2.children.map((child)=> {

                            let inicial = new Date(child.FromDate);
                            let final = new Date(child.ToDate || formattedDocDate);
                            let docDate = new Date(formattedDocDate);
                            
                            inicial.setMinutes(inicial.getMinutes() + inicial.getTimezoneOffset());
                            final.setMinutes(final.getMinutes() + final.getTimezoneOffset());

                            inicial.setHours(0, 0, 0, 0);
                            final.setHours(0, 0, 0, 0);
                            docDate.setHours(0, 0, 0, 0);
                            if (docDate >= inicial && docDate <= final) {
                                if (itemPrice2.childrenCantidades.length > 0) {
                                    // Cantidades
                                    itemPrice2.childrenCantidades.map((child2) => {
                                        let inicialCantidad = new Date(child2.FromDate);
                                        let finalCantidad = new Date(child2.ToDate || formattedDocDate);

                                        if(docDate >= inicialCantidad && docDate <= finalCantidad){
                                            if ((item.QuantitySpecial || 1) >= child2.Amount) {
                                                if(child2.Amount > maxQuantity) {
                                                    maxQuantity = child2.Amount;
                                                    item.DiscountPercentSpecial = child2.Discount;
                                                    flagPricePriority = false;
                                                    priceItem = parseFloat(child2.Price);
                                                    discount = parseFloat(child2.Discount);
                                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                                }
                                            }
                                        }
                                    });
                                    if(maxQuantity != 0){
                                        flagPriceList = false;
                                    }
                                    if(flagPriceList){
                                        // Fechas
                                        item.DiscountPercentSpecial = child.Discount;
                                        flagPricePriority = false;
                                        priceItem = parseFloat(child.Price);
                                        discount = parseFloat(child.Discount);
                                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                        flagPriceList = false;
                                    }
                                } else {
                                    // Fechas
                                    item.DiscountPercentSpecial = child.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(child.Price);
                                    discount = parseFloat(child.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                    flagPriceList = false;
                                }
                            } else {
                                // General
                                if(flagPriceList){
                                    item.DiscountPercentSpecial = itemPrice2.Discount;
                                    flagPricePriority = false;
                                    priceItem = parseFloat(itemPrice2.Price);
                                    discount = parseFloat(itemPrice2.Discount);
                                    priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                                }
                            } 
                        })
                    } else {
                        // General
                        item.DiscountPercentSpecial = itemPrice2.Discount;
                        flagPricePriority = false;
                        priceItem = parseFloat(itemPrice2.Price);
                        discount = parseFloat(itemPrice2.Discount);
                        priceBeforeDisc = ((100 * priceItem) / (100 - discount)).toFixed(2);
                    }
                }
            });
        } 
        //No tiene precio especial
        if(flagPricePriority){
            item.DiscountPercentSpecial = 0;
        }

        // Precios por descuentos especiales
        if(priceBeforeDisc != -1){
            item.Price = Number(priceItem);
            item.PriceBeforeDiscount = Number(priceBeforeDisc);
        }
        
        let priceTax = item.Price !== -1 ? Number(((item.Price * (16 / 100)) + item.Price).toFixed(2)) : -1;
        return priceTax;
    }

    // No aplica
    changeBackOrder= (item, addItem) => {
        const {itemsReducer : {deleteBackOrder, addBackOrder}} = this.props;
        if(addItem){
            addBackOrder({item, quantity: 1})
        }else{
            deleteBackOrder({item, deleteAll: false});
        }
    };
    
    applyFilters = data => {
        const {setItemsFilterSearch2} = this.props;
        setItemsFilterSearch2(data);
    };

    mouseOverAddCart = (index, flag, event)=>{
        if(this.iconMouse && this.iconMouse.length > 0){
            this.iconMouse[index].style.display = "none";
            this.iconMouseOver[index].style.display = "block";
        }
    }

    mouseOutAddCart = (index, flag, event)=>{
        if(flag == 'green'){
            event.currentTarget.style.backgroundColor = "#89c33f";
            event.currentTarget.className = "btn btn-block botonAgregar"
        } else if(flag == 'yellow'){
            event.currentTarget.style.backgroundColor = "#efc964";
            event.currentTarget.className = "btn btn-block botonAgregarYellow"
        } else {
            event.currentTarget.style.backgroundColor = "#e27753";
            event.currentTarget.className = "btn btn-block botonAgregarAgotado"
        }
        // Íconos
        if(this.iconMouse && this.iconMouse.length > 0){
            this.iconMouse[index].style.display = "block";
            this.iconMouseOver[index].style.display = "none";
        }
    }

    dejanosMensaje = (item) => {
        this.setState({
            itemSelect: item
        });
        setTimeout(() => {
            $('#itemCotizaModal').modal('show');
        }, 300);
        
    };

    render() {
        const {itemsReducer : { items2, searchItemsFilter, updateFavorite, deleteShoppingCart, openItemDetails }, dashboard, changeBackOrder, sessionReducer,  sessionReducer: { user } } = this.props;
        const { seller,itemSelect } = this.state;
        let valor = seller ? seller.U_FMB_Handel_Perfil : '0';

        return (
            <div> 
                <ItemDetailsModal view={VIEW_NAME.ITEMS_VIEW}/>
                <div className="SliderItems">
                    <Carousel autoPlay itemClass="carousel-item-padding-20-px" partialVisible={false} responsive={responsive} autoPlaySpeed={5000} removeArrowOnDeviceType={["tablet", "mobile"]} >
                        {items2 && items2.map((item, index) => {
                            item.ItemName = item.ItemName || '';
                            let dashboardForTwoSliders = dashboard ? dashboard : '';
                            let imagenShow = item.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + item.PicturName) : require('../images/noImage.png');                            return (
                                <div id="ItemsSlider" style={{display:"flex", justifyContent:"center", maxWidth:"27rem", textAlign:"center", position:"relative"}} key={index}>
                                <div className="item card" style={{position:"relative",width: "303.41px", marginBottom:"4px" , marginTop:"4px", minHeight:"420px",  backgroundColor: config.itemsList.backgroundColor}}>
                                    <div className="imageCard">
                                        <div style={{display: (!item.U_FMB_Handel_Promo || item.U_FMB_Handel_Promo === '' || item.U_FMB_Handel_Promo == 0 ? 'none' : 'table'), position:"relative"}}>
                                            <div className="font-weight-bold ribbon"  style={{position: "absolute"}} onClick={() => openItemDetails(item)}>
                                                <span>
                                                    <blink>PROMOCIÓN</blink>
                                                </span>
                                            </div>
                                        </div>
                                        <img
                                            onClick={() => openItemDetails(item)}
                                            className="card-img-top cardImg"
                                            src={imagenShow}
                                            alt="Imagen del articulo"
                                        />
                                        <div className="card-body" style={{marginLeft:"10px", padding: "10px",height: "154px", color: config.itemsList.textColor}}>
                                        <div className="overflow-auto ItemName">
                                                <p className="card-title text-left font-weight-bold" style={{ padding: 5, fontSize: 17, color:"#000000"/*marginLeft: 3,maxWidth: "100%", fontSize: 16, color:"#AFAFAF"*/}}>
                                                    {item.ItemName}
                                                    {/* {item.ItemName ? (item.ItemName).length > 32 ? (item.ItemName).substring(0, 31) + " ... ": item.ItemName : " "} */}
                                                </p>
                                            </div>
                                            <span className="nameItemCardList">
                                                <div>
                                                    <div>
                                                        <p className="card-subtitle text-left" style={{fontSize: 13, marginLeft: 0}}>
                                                            <span className="font-weight-bold nameItemList" style={{color: "#757575"}}>{item.ItemCode}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </span>
                                            <p className="card-subtitle font-weight-bold text-left" style={{fontSize: 25, margin: 1, color: config.itemsList.textPrice2}}>
                                                {item.U_web != 1  
                                                    ? <span style={{color: config.navBar.iconColor,fontSize: 20}}>Solicite su cotización</span> 
                                                    :  
                                                    <span className="precioCard">
                                                        <div className=" precios">
                                                            <div className="pricePromo col-md-auto" style={{padding: 0, color: config.navBar.iconColor,fontSize: 17, width: "200px"}}>
                                                                <CurrencyFormat
                                                                    value={item.Price}
                                                                    displayType={'text'}
                                                                    thousandSeparator={true}
                                                                    fixedDecimalScale={true}
                                                                    decimalScale={2}
                                                                    prefix={'$ '}
                                                                    suffix={' ' + config.general.currency}>
                                                                </CurrencyFormat>
                                                                <span> más IVA</span>
                                                            </div>                                                                        
                                                        </div>
                                                    </span>                                                                                                                    
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                        <div className="card-footer" style={{ paddingBottom:"10px", border: 'none', marginTop: "21px"/*borderTop: "transparent"*/}}>
                                            <div className="row text-center" style={{margin: 0, padding: 0}}>
                                                {item.U_web != 1 ? 
                                                    <div className="col-12" style={{padding:0}}>
                                                         <div className="ItemName" style={{color:"#AFAFAF", fontSize: 15, margin: 0}}>
                                                            <p style={{marginLeft: "14px"}}>LLámenos o envié un correo para cotización</p>
                                                        </div>
                                                    </div> 
                                                    :
                                                    <div className="col-3 cantidad" style={{padding:0}}>
                                                        {item.Unidades === "Unidades" ? 
                                                            <input
                                                                // disabled={!(!!item.OnHandPrincipal)}
                                                                id={'input-quantity-' + dashboard.toString() + item.ItemCode + index}
                                                                type="number"
                                                                min="1"
                                                                value={item.quantity ? Number(item.quantity).toFixed(0) : ''}
                                                                className="form-control"
                                                                name={'quantity' + item.ItemCode}
                                                                placeholder="1"
                                                                style={{textAlign: "left", marginTop: 1, height: 44.5}}
                                                                onChange={(event) => {this.changLocalQuantity(dashboard.toString() + index, item, event)}}
                                                                //onKeyDown={event => event.keyCode == 13 && this.addShoppingCart(item, 'input-quantity-' + item.ItemCode + index)}
                                                            />
                                                            :<input
                                                                // disabled={!(!!item.OnHandPrincipal)}
                                                                id={'input-quantity-' + dashboard.toString() + item.ItemCode + index}
                                                                type="number"
                                                                min="1"
                                                                value={item.quantity ? item.quantity : ''}
                                                                className="form-control"
                                                                name={'quantity' + item.ItemCode}
                                                                placeholder="1"
                                                                style={{textAlign: "left", height: 44.5}}
                                                                onChange={(event) => {this.changLocalQuantity(dashboard.toString() + index, item, event)}}
                                                                //onKeyDown={event => event.keyCode == 13 && this.addShoppingCart(item, 'input-quantity-' + item.ItemCode + index)}
                                                            />
                                                        }
                                                    </div>
                                                }
                                                {item.U_web != 1 ? 
                                                    <div>
                                                        
                                                    </div>
                                                    : 
                                                    <div className="col-6 botonAgregar">
                                                        <button style={{textAlign: "center"}} 
                                                            type="button" 
                                                            readOnly = {item.OnHandPrincipal === 0 || item.OnHandPrincipal === '' ? true : false}
                                                            className= {item.flag === 'green' ? "btn btn-block botonAgregar": "btn btn-block botonAgregarAgotado"}  
                                                            // className= {item.OnHandPrincipal === 0 ? "btn btn-block botonAgregarAgotado" : item.flag === 'green' ? "btn btn-block botonAgregar": "btn btn-block botonAgregarYellow"}  
                                                            value={(item.quantity ? Number(item.quantity): '1')} 
                                                            onClick={(event)=>{this.changeQuantity(index, item, event)}} 
                                                            // onMouseOver={(event)=>{this.mouseOverAddCart(dashboardForTwoSliders.toString() +index, item.flag, event)}} 
                                                            // onMouseOut={(event)=>{this.mouseOutAddCart(dashboardForTwoSliders.toString() +index, item.flag, event)}}
                                                        >
                                                        </button>
                                                    </div>
                                                }
                                                {((item.U_web == 1) && (item.OnHandPrincipal == 0 || item.OnHandPrincipal == '')) &&
                                                        <div className="icoCarrito col-3 text-center align-middle" style={{fontSize: 33,padding:0, textAlign: "right"}}>
                                                            <img ref={iconMouse => this.iconMouse[dashboardForTwoSliders.toString() +index] = iconMouse} src={config.shoppingCartIcons.carritoAgotado} style={{display: "block", color: "red", cursor: "pointer"}}/>
                                                            <img ref={iconMouseOver => this.iconMouseOver[dashboardForTwoSliders.toString() +index] = iconMouseOver} src={config.shoppingCartIcons.camionAgotado} style={{display: "none", color: "red", cursor: "pointer"}}/>
                                                        </div>
                                                }
                                                {((item.U_web == 1) && item.OnHandPrincipal > 0 && (item.flag === 'green' || item.flag === 'yellow')) &&
                                                        <div className="icoCarrito col-3 text-center align-middle" style={{fontSize: 33,padding:0, textAlign: "right"}}>
                                                            <img ref={iconMouse => this.iconMouse[dashboardForTwoSliders.toString() +index] = iconMouse} src={item.flag === 'green' ? config.shoppingCartIcons.carritoVerde : config.shoppingCartIcons.carritoAmarillo} style={{display: "block", cursor: "pointer"}}/>
                                                            <img ref={iconMouseOver => this.iconMouseOver[dashboardForTwoSliders.toString() +index] = iconMouseOver} src={item.flag === 'green' ? config.shoppingCartIcons.camionVerde : config.shoppingCartIcons.camionAmarillo} style={{display: "none", cursor: "pointer"}}/>
                                                        </div>
                                                }
                                            </div>
                                            <div className="row text-center" style={{ margin: 0, padding: 0 }}>
                                                {item.U_web === 1 &&
                                                    item.wishlist === 1 &&
                                                        <div className="col-2" style={{ color: config.navBar.textColor2, fontSize: 20, padding: 0 }}>
                                                            {item.backOrder
                                                                ? <i className={config.icons.backOrderFalse} style={{ cursor: "pointer", marginLeft: 7, marginTop: 15 }} onClick={() => changeBackOrder(item, false)} />
                                                                : <i className={config.icons.backOrderTrue} style={{ cursor: "pointer", marginLeft: 7, marginTop: 15 }} onClick={() => changeBackOrder(item, true)} />
                                                            }
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Carousel>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
        shoppingCartReducer: store.ShoppingCartReducer,
        itemsReducer: store.ItemsReducer,
        sessionReducer: store.SessionReducer,

    };
};

const mapDispatchToProps = dispatch => {
    return {
        setUser: value => dispatch({type: DISPATCH_ID.LOGIN_SET_USER, value}),
        setShoppingCart: value => dispatch({type: DISPATCH_ID.SHOPPING_CART_SAVE_CART, value}),
        setItemsFilterSearch2: value => dispatch({type: DISPATCH_ID.ITEMS_SAVE_ITEMS_FILTER2, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ItemSlider2);