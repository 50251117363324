import React, {Component} from 'react';
import {NavBar, Session, Footer} from "../../components";
import {config, DISPATCH_ID, VIEW_NAME} from "../../libs/utils/Const";
import {connect} from "react-redux";
import './CreateOrder.css';

class CreteOrderView extends Component {
    state = {
      docNum: '',
    };
    componentDidMount() {
        const {history} = this.props;
        let data = localStorage.getItem(config.general.localStorageNamed + 'createOrder');
        if (!data || data === 'null' || data === undefined) {
            history.push('/')
        }

        this.setState({
            docNum: data
        })
    }

    componentWillUnmount() {
        localStorage.setItem(config.general.localStorageNamed + 'createOrder', null);
    }

    render() {
        const {history} = this.props;
        const {docNum} = this.state;
        let flag = false;
        if(docNum ==='undefined'){
            flag = true;
        }
        return (
            <div className="content-fluid" style={{marginTop: 150,backgroundColor:"#EFEFEF", paddingLeft:0, paddingRight:0}}>
                <Session history={history} view={VIEW_NAME.CREATE_ORDER_VIEW}/>
                <NavBar/>
                <div className="container" style={{paddingBottom: 40,margin: 'auto', minHeight: '70vh', paddingTop: 80}}>
                    <div className="row justify-content-md-center">
                        <div className="col-md-12 text-center pagoRealizado">
                            {!flag ?
                                <p >
                                <i className="fas fa-check-circle" style={{marginRight:10, color :'green'}}></i>
                                    {/* <span>Se créo correctamente el documento con el No. <strong>{docNum}</strong></span> */}
                                    <span>{docNum}</span>
                                </p>
                                :
                                <p>
                                <i className="fas fa-check-circle" style={{marginRight:10, color :'green'}}></i>
                                <span>Tu carrito se ha guardado correctamente</span>
                                </p>
                            }
                            
                        </div>
                        <div className="col-md-12 text-center">
                            <button 
                            className="btn btn-lg btn-block"
                            onClick={() => history.push('/')}
                            style={{
                                backgroundColor: config.navBar.iconBackground,
                                color: config.navBar.iconModal,
                                fontWeight: "bold",}}
                            >
                                Seguir comprando
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreteOrderView);