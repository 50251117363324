import React, {Component} from 'react';
import {Footer, NavBar, Session,NotificationsModal} from "../../components";
//import {VIEW_NAME, config} from "../../libs/utils/Const";
import {DISPATCH_ID, SERVICE_API, SERVICE_RESPONSE, VIEW_NAME, config} from "../../libs/utils/Const";
import {ApiClient} from "../../libs/apiClient/ApiClient";
import CurrencyFormat from 'react-currency-format';
import {connect} from "react-redux";
import '../../index.css';
import * as XLSX from 'xlsx';
import {CSVLink, CSVDownload} from "react-csv";
import $ from 'jquery';
import moment from "moment";

let apiClient = ApiClient.getInstance();

class AutorizacionesView extends Component {
    // csvLink = React.createRef()
    constructor(props) {
        super(props);

        this.state = {
            Codigo : '',
            Autorizaciones : [],
            Tipo : 'W',
            order: [],
            csvData: [],
            checkboxDataTables: (new Map(): Map<string, boolean>),
            responseArray :[]
        };
        this.table = null;
    };

    async componentDidMount(){
        const {enableSpinner} = this.props;
        enableSpinner(true);
            this.cargarDatos();       
        enableSpinner(false);
    };

    async cargarDatos (){
        const {Tipo} = this.state;
        const {enableSpinner} = this.props;
        enableSpinner(true);
        let user = localStorage.getItem(config.general.localStorageNamed + 'CurrentUser');
        user = JSON.parse(user) || {};
        
        let usuario = user ? user.USERID : '1';
        document.getElementById("commentarios").value = '';
        let newdatas = await apiClient.getAutorizaciones(usuario,Tipo);
        this.setState({   
            Autorizaciones: newdatas.data.list,
            usuario : usuario,
            hola : newdatas.data,
            user : user
        });

        this.table = $('#tablaAutorizaciones').DataTable({
            "paging": false,
            "info": false,
            "searching": false
        });
        enableSpinner(false);
    };

    Details = async (valor, U_CurrStep, canal, tipo) => {
        const {enableSpinner} = this.props;
        const {usuario, Tipo,user} = this.state;
        enableSpinner(true);
        let editarAuto = false;
        let credit = false;
        if(tipo.substr(0,13) === 'OV-TRANSFGRAT' && canal === 'DISTRIBUIDORES'){
            editarAuto = true;
        }
        if(tipo === 'OV-LINEADECREDITO'){
            credit = true;
        }
        
        let newdatas = await apiClient.getAutorizaciones(usuario, Tipo);
        
        let articulos = '';
        newdatas.data.list.map(auto =>{
            if(auto.DocEntry === valor){
                articulos = auto.CartShop;
            }
        })
        let response = await apiClient.getDatailsAuto(valor);
        enableSpinner(false);
        let csvData = [];

        let infoCsv = response.data.body;
        infoCsv.map((item)=>{
            let row ={
                'Producto': item.ItemName,
                'Código SAP': item.ItemCode,
                'Peso KG': parseFloat(item.SWeight1 || 0).toFixed(2) + ' KG',
                'Cantidad': item.Quantity,
                'Total Peso Neto' : parseFloat(item.Quantity * item.SWeight1).toFixed(2) + ' KG', 
                'Total Peso Bruto' : parseFloat(item.Quantity * item.IWeight1).toFixed(2) + ' KG',
                'Precio': item.Currency+' '+parseFloat(item.Price).toFixed(2),
                'Total Precio': item.Currency+' '+parseFloat(item.LineTotal).toFixed(2),
                'Info': ' '
            }
            csvData.push(row);
        });

        for (let index = 0; index < 2; index++) {
            const element = csvData[index];
            let row ={
                'Info': ' '
            }
            csvData.push(row);
        }
        
        let hoy = new Date();
        var Hora = hoy.getHours();
        var Min = hoy.getMinutes();
        var Fecha = hoy.toLocaleDateString();

        // csvData[0].Info = "RUC "+response.data.body[0].CardCode.substring(1)+"        "+Fecha+"-"+Hora+":"+Min+"hs        "+usuario.CardName;
        // csvData[1].Info = "NRO.SOLICITUD: 0000014179 - FECHA : 12/02/2021";
        // csvData[2].Info = "CLIENTE: "+response.data.body[0].CardName+"        EMISIÓN: "+response.data.body[0].DocDate.substring(0,10)+" - F.DESPACHO : "+response.data.body[0].DocDueDate.substring(0,10)+" - O/C : "+response.data.body[0].NumAtCard;

        csvData[0].Info = "RUC "+response.data.body[0].CardCode.substring(1)+"        "+Fecha+"-"+Hora+":"+Min+"hs        "+user.CardName;
        csvData[1].Info = "NRO.SOLICITUD: "+response.data.body[0].DocEntry+" - FECHA : "+response.data.body[0].DocDueDate.substring(0,10);
        csvData[2].Info = "CLIENTE: "+response.data.body[0].CardName+"        EMISIÓN: "+response.data.body[0].DocDate.substring(0,10)+" - F.DESPACHO : "+response.data.body[0].DocDueDate.substring(0,10); // - O/C : "+response.data.body[0].NumAtCard;


        response = response ? response.data.body : []
            this.setState({
                order: response,
                DocEntry : valor,
                U_CurrStep : U_CurrStep,
                editar : editarAuto,
                tipo : tipo,
                csvData,
                credit,
                CreditLine : response[0].CreditLine,
                Balance : response[0].Balance,
                OrdersBal : response[0].OrdersBal,
            });
        $('#saveModal').modal('show');
    }

    Autorizar = async response => {
        const {enableSpinner, notificationReducer: {showAlert}} = this.props;
        const {DocEntry, usuario, U_CurrStep, tipo} = this.state;

        enableSpinner(true);

        let data = {
            DocEntry : DocEntry,
            Usuario : usuario,
            WstCode : U_CurrStep,
            tipo : tipo
        };
        let apiResponse = await apiClient.createAutorization(data);
        if (apiResponse.status === SERVICE_RESPONSE.SUCCESS) {
            enableSpinner(false);
            if(apiResponse.message === 'Documento autorizado para su creación'){
                showAlert({type: 'success', message: apiResponse.message});
            }else{
                showAlert({type: 'warning', message: apiResponse.message})
            }
            
            $('#saveModal').modal('hide');
            this.table.destroy();
            this.cargarDatos();
            return;
        }

        showAlert({type: 'error', message: "Aviso: "+apiResponse.message});
        enableSpinner(false)
    };

    Rechazar = async response => {
        const {enableSpinner, notificationReducer: {showAlert}} = this.props;
        const {DocEntry,usuario,U_CurrStep} = this.state;
        let comment = document.getElementById("commentarios").value;
        let data = '';
        if(comment.length <= 20){
            data = {
                DocEntry : DocEntry,
                Usuario : usuario,
                WstCode : U_CurrStep,
                Comentario : comment
            };
        }
        else{
            showAlert({ type: 'error', message: 'Has excedido más de 20 caracteres.' });
            return;
        } 

        enableSpinner(true);
        
        let apiResponse = await apiClient.rejectedAutorization(data);
        if (apiResponse.status === SERVICE_RESPONSE.SUCCESS) {
            enableSpinner(false);
            showAlert({type: 'error', message: "Aviso: "+apiResponse.message});
            $('#commentsModale').modal('hide');
            $('#saveModal').modal('hide');
            this.table.destroy();
            this.cargarDatos();
            return;
        }

        showAlert({type: 'error', message: "Aviso: "+apiResponse.message});
        enableSpinner(false)
    };

    async handleInputChange (event) {
        var archivo = event.target.files[0];
        if (!archivo) {
          return;
        }
        var lector = new FileReader();
        lector.onload = function(e) {
          var contenido = e.target.result;
          var lineas = contenido.split('\n');

          for(var linea of lineas) {
            if(linea.substr(0,3) === 'LIN') {
            }
          }
          //mostrarContenido(contenido);
        };
        lector.readAsText(archivo);
    };

    renderShoppingCartTotal = () => {
        const {order} = this.state;
        let subTotal = 0;
        let total = 0;
        let IVA = 0;
        let precios = 0;
        !!order && order.map(item => {
            subTotal = item.DocTotal - item.VatSum;
            precios += parseFloat(item.Price*item.Quantity);
            // subTotal = subTotal === 0 ? precios : subTotal;
            total = item.DocTotal;
            IVA = item.VatSum;
        });
        return (
            <div className="container" style={{fontSize: 18}}>
                <div className="row">
                    <div className="col-6" style={{padding: 0}}>
                        <span className="font-weight-bold">Subtotal: </span>
                    </div>
                    <div className="col-6 text-right" style={{padding: 0}}>
                        <span className="text-right">
                            <CurrencyFormat 
                                value={subTotal} 
                                displayType={'text'} 
                                thousandSeparator={true} 
                                fixedDecimalScale={true} 
                                decimalScale={2} 
                                prefix={config.general.currency}>

                            </CurrencyFormat>
                        </span>
                    </div>
                </div>
                <div className="row border-bottom">
                    <div className="col-6" style={{padding: 0}}>
                        <span className="font-weight-bold">
                            I.V.A.: 
                        </span>
                    </div>
                    <div className="col-6 text-right" style={{padding: 0}}>
                        <span className="text-right">
                            <CurrencyFormat 
                            //taxTotal
                                value={IVA} 
                                displayType={'text'} 
                                thousandSeparator={true} 
                                fixedDecimalScale={true} 
                                decimalScale={2} 
                                prefix={config.general.currency}>
                            </CurrencyFormat>
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6" style={{padding: 0}}>
                        <span className="font-weight-bold">Total: </span>
                    </div>
                    <div className="col-6 text-right" style={{padding: 0}}>
                        <span className="text-right">
                            {total === 0?
                                <CurrencyFormat 
                                    value={0} 
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    fixedDecimalScale={true} 
                                    decimalScale={2} 
                                    prefix={config.general.currency}>
                                </CurrencyFormat>:
                                <CurrencyFormat 
                                    value={total} 
                                    displayType={'text'} 
                                    thousandSeparator={true} 
                                    fixedDecimalScale={true} 
                                    decimalScale={2} 
                                    prefix={config.general.currency}>
                                </CurrencyFormat>
                            }
                        </span>
                    </div>
                </div>
            </div>
        )
    };

    renderCreditLimit = () => {
        const {CreditLine,Balance,OrdersBal, order} = this.state;
        return (
            <div className="container" style={{fontSize: 18}}>
                <div className="row">
                    <div className="col-7" style={{padding: 0}}>
                        <span className="font-weight-bold">Linea de crédito: </span>
                    </div>
                    <div className="col-5 text-right" style={{padding: 0}}>
                        <span className="text-right">
                            <CurrencyFormat 
                                value={CreditLine} 
                                displayType={'text'} 
                                thousandSeparator={true} 
                                fixedDecimalScale={true} 
                                decimalScale={2} 
                                prefix={config.general.currency}>
                            </CurrencyFormat>
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-7" style={{padding: 0}}>
                        <span className="font-weight-bold">Crédito utilizado: </span>
                    </div>
                    <div className="col-5 text-right" style={{padding: 0}}>
                        <span className="text-right">
                            <CurrencyFormat 
                            //taxTotal
                                value={Number(Balance) + Number(OrdersBal) + Number(order[0].DocTotal)} 
                                displayType={'text'} 
                                thousandSeparator={true} 
                                fixedDecimalScale={true} 
                                decimalScale={2} 
                                prefix={config.general.currency}>
                            </CurrencyFormat>
                        </span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-7" style={{padding: 0}}>
                        <span className="font-weight-bold">Exceso: </span>
                    </div>
                    <div className="col-5 text-right" style={{padding: 0, background:'red',color:'white'}}>
                        <span className="text-right font-weight-bold">
                            <CurrencyFormat 
                            //taxTotal
                                value={Number(CreditLine)-(Number(Balance) + Number(OrdersBal) + Number(order[0].DocTotal))} 
                                displayType={'text'} 
                                thousandSeparator={true} 
                                fixedDecimalScale={true} 
                                decimalScale={2} 
                                prefix={config.general.currency}>
                            </CurrencyFormat>
                        </span>
                    </div>
                </div>
                {/* <div className="row">
                    <div className="col-7" style={{padding: 0}}>
                        <span className="font-weight-bold">Exceso %: </span>
                    </div>
                    <div className="col-5 text-right" style={{padding: 0}}>
                        <span className="text-right">
                            <CurrencyFormat 
                            //taxTotal
                                value={Number(CreditLine) > 0 ? (Number(CreditLine)-(Number(Balance) + Number(OrdersBal) + Number(order[0].DocTotal))) / Number(CreditLine) * - (100) : 0} 
                                displayType={'text'} 
                                thousandSeparator={true} 
                                fixedDecimalScale={true} 
                                decimalScale={2} >
                            </CurrencyFormat>
                        </span>
                    </div>
                </div> */}
            </div>
        )
    };

    search = (event) => {        
        this.setState({
            Tipo: event.nativeEvent.target.value,
        })
        this.table.destroy();
        setTimeout(() => {
            this.cargarDatos()
        }, 150)
    };

    closeConfirme = async () => {
        $('#commentsModale').modal('hide');  
    }

    CheckboxActions = async (docEntryArray, action = null,usuario,U_CurrStep) => {
        const {enableSpinner, notificationReducer: {showAlert}, view} = this.props;
        
        let responseArray = [];

        for (let document in docEntryArray) {
            enableSpinner(true);

            let statusAuthorization: any = "";
            let message = "";
            if (action == 1) {
                message = "Autorizando";
                statusAuthorization = 1;
            }
            let Documento = docEntryArray[document].DocNum+'-'+docEntryArray[document].DocEntry;
            showAlert({type: 'info', message: message + ': ' + Documento, timeOut: 5000});

// auto.U_StepCode,auto.U_SYP_RICO_NCANAL,auto.U_Remarks

            let data = {
                DocEntry : docEntryArray[document].DocEntry,
                Usuario : usuario,
                WstCode :  docEntryArray[document].U_StepCode,
                tipo : docEntryArray[document].U_Remarks
            } 
            let response = "";

            if (action == 1) {
                response = await apiClient.createAutorization(data);
            }


            enableSpinner(false);
            try {
                responseArray.push({
                    docNum: Documento,//response.data ? response.data.DocNum : '',
                    message: response.message ? response.message : '',
                });
            } catch (e) {
                responseArray.push({
                    docNum: '',
                    message: '',
                });
            }
        }
        return responseArray;
    };

    AutorizarMasive = async response => {
        const {enableSpinner, notificationReducer: {showAlert}} = this.props;
        const {Autorizaciones, usuario, U_CurrStep,checkboxDataTables} = this.state;

        let docEntryArray = [];
        let responseArray = [];
        for (let [id, value] of checkboxDataTables.entries()) {
            if (value) {
                docEntryArray.push(Autorizaciones[id]);
            }
        }
        $('#estasSeguroModal').modal('hide');
        if(docEntryArray.length>0){
            responseArray = await this.CheckboxActions(docEntryArray,1,usuario,U_CurrStep);
            this.toggleDocumentSelectedAll(false);
            
            
            this.setState({
                checkboxDataTables: (new Map(): Map<string, boolean>),
                responseArray 
            })
            $('#notificationsModal').modal('show');
            this.table.destroy();
            this.cargarDatos();

        } else{
            showAlert({type: 'error', timeOut: 2000, message: "Seleccione un documento valido"});
            return;
        }

        // enableSpinner(true);
        // let apiResponse = '';
        // for (let index = 0; index < Autorizaciones.length; index++) {
        //     const masive = Autorizaciones[index];
        //     let data = {
        //         DocEntry : masive.DocEntry,
        //         Usuario : usuario,
        //         WstCode : U_CurrStep
        //     };
        //     apiResponse = await apiClient.createAutorization(data);            
        // }

        // if (apiResponse.status === SERVICE_RESPONSE.SUCCESS) {
        //     enableSpinner(false);
        //     showAlert({type: 'success', message: 'Todos los documentos han sido autorizados correctamente'});
        //     $('#estasSeguroModal').modal('hide');
        //     return;
        // }
        // showAlert({type: 'error', message: "Aviso: "+apiResponse.message});        
        // enableSpinner(false)
    };

    // Middleware = async response => {
    //     const {enableSpinner, notificationReducer: {showAlert}} = this.props;
    //     enableSpinner(true);
    //     let data = {
    //         DocEntry : '123'
    //     };
    //     let apiResponse = await apiClient.canalModerno(data);   
    //     showAlert({type: 'error', message: apiResponse.message});        
    //     enableSpinner(false)
    // };

    closemodalestasSeguro = async response => {
        $('#estasSeguroModal').modal('hide'); 
    }

    async addToShopingCart(){
        const {notificationReducer: {showAlert},configReducer,enableSpinner} = this.props;
        const {order} = this.state;
        let items = [];
        order.map(item => {
            if(item.ItemCode !== "ENVIO"){
                items.push({ItemCode: item.ItemCode, quantity: parseInt(item.Quantity)});
            }
        });
        enableSpinner(true);
        let responsesd = await apiClient.updateShoppingCartLocal(items);
        if(responsesd.status === 1){
            $('#saveModal').modal('hide');
            showAlert({type: 'success', message: ' Agregado a carrito correctamente ', timeOut: 0});
            configReducer.history.goShoppingCart();
        }
        enableSpinner(false);
    }

    // generateCSV = async () => {
    //     const {hola} = this.state;
    // //     let datacsv = [["","DESCRIPCIÓN","UNIDADES SOLICITADAS","CODIGO EAN","PTV-RINTI","desde"],
    // //     ["","DESCRIPCIÓN","UNIDADES SOLICITADAS","CODIGO EAN","PTV-RINTI","desde"]]

    // //     //let newdatas = await apiClient.getAutorizaciones("208","W");
    //     // const csvData = [
    //     //     ["firstname", "lastname", "email"],
    //     //     ["Ahmed", "Tomi", "ah@smthing.co.com"],
    //     //     ["Raed", "Labes", "rl@smthing.co.com"],
    //     //     ["Yezzi", "Min l3b", "ymin@cocococo.com"]
    //     //   ];
        
    // //     //let arreglo = newdatas ? newdatas.data : [];
    //     this.setState({dataCsv : hola})
    //     setTimeout(() => {
    //         this.csvLink.current.link.click();
    //         // document.getElementById("CSVLink").click();
    //     }, 500);
    // };
    
    toggleDocumentSelected = (id) => {
        this.setState(state => {
            const checkboxDataTables = new Map(state.checkboxDataTables);
            checkboxDataTables.set(id, !checkboxDataTables.get(id)); // toggle
            return {checkboxDataTables};
        });
    };

    toggleDocumentSelectedAll = (action) => {
        const {Autorizaciones} = this.state;
        let newCheckbox = (new Map(): Map<string, boolean>);
        Autorizaciones.map((register, index) => {
            if (register.U_Status != 'Y' && register.U_Status != 'N') {
                newCheckbox.set(index, action)
            }
        });
        this.setState({
            checkboxDataTables: newCheckbox
        });
    };

    render() {
        const {history} = this.props;
        const {Autorizaciones, order,Tipo, editar,dataCsv,csvData,usuario,checkboxDataTables,responseArray, credit} = this.state;

        let checkbox = {
            data: checkboxDataTables,
            selectOne: this.toggleDocumentSelected,
            selectAll: this.toggleDocumentSelectedAll,
        }
        
        return (
            <div className="content-fluid" style={{marginTop: 150,backgroundColor:"#EFEFEF"}}>
                <Session history={history} view={VIEW_NAME.AUTORIZA_VIEW}/>
              
                <NavBar/>
                <NotificationsModal
                responseArray ={responseArray}
                ></NotificationsModal>
                
                <div className="container mb-4 margenSelector">
                    <div className="row">
                        <div className="col">
                            <div className="jumbotron" style={{paddingTop:'10px', paddingBottom:'10px'}}>
                                <h2 className="display-6 text-center">{usuario === 167 || usuario === 208 || usuario === 206 ? 'Autorización de Transferencia Gratuita' : 'Autorización de Pedidos'}</h2>
                            </div>
                        </div>    
                    </div>
                </div>
                <div className="container col-md-10" >
                    <div className="row">
                        <div className="col-md-6 col-sm-12">

                        </div>
                        <div className="col-md-3 col-sm-12">
                            <select id="inputState" class="form-control btn-outline-secondary"
                                    style={{backgroundColor: 'transparent', borderColor: '#ced4da', color: '#000'}}
                                    onChange={(event) => this.search(event)}>
                                <option value='W'>Pendientes</option>
                                <option value='Y'>Aprobados</option>
                                <option value='N'>Rechazados</option>
                            </select>
                        </div>
                        <div className="col-md-3 col-sm-12">
                        {/* <button type="button" class="btn btn-success float-right" onClick={this.generateCSV}>Descargar plantilla</button> */}
                       
                            <button type="button" class="btn btn-primary float-right" style={{display:Tipo === 'W' ? 'block' : 'none' }} data-toggle="modal" data-target=".estasSeguro" onChange={this.AutorizarMasive}>Aprobación masiva</button>
                        </div>
                    </div>
                    <br></br>

                    <div className="table-responsive" style={{marginBottom: 0, height: 1110, maxHeight: 1110 , overflow: 'auto', fontSize:"13px", fontWeight:"bold"}}> 
                        <table id="tablaAutorizaciones" className="table table-hover scrolltable" >
                            <thead >
                                <tr className="text-light" style={{/*background: '#2d75bd', borderRadius: '0' */}} >
                                    <th >#</th>
                                    {/* <th className="sticky-header"  scope="col">DocNum</th> checkbox: style={{backgroundColor: "#e9ecef"}}*/}
                                    <th className="sticky-column" >
                                        <input type="checkbox" style={{minWidth: '100%'}} id="cbox2" value="second_checkbox"
                                            onChange={(event) => checkbox.selectAll(event.target.checked)}/>
                                    </th>
                                    <th className="text-center" scope="col">Documento</th>
                                    <th className="text-center" >Cliente</th>
                                    <th className="text-center" scope="col">Nombre cliente</th>
                                    {/* <th className="text-center" scope="col">Canal</th> */}
                                    <th className="text-center" scope="col">Comentarios</th>
                                    <th className="text-center" scope="col">Fecha</th>                                
                                    <th className="text-center" scope="col">Total doc.</th>
                                    <th className="text-center" scope="col">Peso total bruto</th>
                                    <th className="text-center" scope="col">Autorización</th>
                                    <th className="text-center" scope="col"></th>
                                </tr>
                            </thead>
                            <tbody style={{overflowY:'auto'}}>
                                {Autorizaciones.map((auto, index ) => {
                                    //if(index+1 <=20 ){       === 0 ? auto.DocTotal2 : auto.DocTotal
                                        return(
                                            <tr >
                                                <th >{index+1}</th>
                                                {/* <td>{auto.DocNum}</td> */}
                                                <td className="text-center">
                                                    <input type="checkbox" style={{minWidth: '100%'}} id="cbox2"
                                                        checked={!!checkbox.data.get(index)} onChange={() => checkbox.selectOne(index)}/>
                                                </td>
                                                <td className="text-center">{auto.DocNum+'-'+auto.DocEntry}</td>
                                                <td className="text-center">{auto.CardCode}</td>
                                                <td className="text-center">{auto.CardName}</td>
                                                {/* <td className="text-center">{auto.U_SYP_RICO_NCANAL}</td> */}
                                                <td className="text-center">{auto.Comments.substr(0,25)}</td>
                                                <td className="text-center">{auto.DocDate.substr(0,10)}</td>                                            
                                                <td className="text-center">S/. {parseFloat(auto.DocTotal).toFixed(2) === '0.00' ? parseFloat(auto.DocTotal2).toFixed(2) : parseFloat(auto.DocTotal).toFixed(2)}</td>
                                                <td className="text-center">{parseFloat(auto.Weight2).toFixed(2)} KG</td>
                                                <td className="text-center">{auto.U_Remarks}</td>
                                                <td>
                                                    <button
                                                        className="btn btn-sm"
                                                        type="button"
                                                        // data-toggle="modal" data-target=".bd-example-modal-lg"
                                                        style={{ backgroundColor: config.navBar.iconBackground, color: config.navBar.iconModal }}
                                                        onClick={() => this.Details(auto.DocEntry, auto.U_StepCode,auto.U_SYP_RICO_NCANAL,auto.U_Remarks )}> 
                                                       Ver detalles
                                                    </button>
                                                </td>
                                            </tr>
                                        )
                                    //}
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className="pb-4">
                        <button type="button" className="btn btn-primary float-right" style={{display:Tipo === 'W' ? 'block' : 'none' }} data-toggle="modal" data-target=".estasSeguro" onChange={this.AutorizarMasive}>Aprobación masiva</button>                        
                    </div>
                   
                   
                    <br></br>

                    <div class="modal fade bd-example-modal-xl" id="saveModal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-xl">
                            <div className="modal-content">
                                <div className="modal-header text-light" style={{background: '#2d75bd', borderRadius: '0' }}>
                                    <h4 className="modal-title" id="modal-basic-title ">Detalle de autorización</h4>

                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span className="text-white" aria-hidden="true">&times;</span>
                                    </button>
                                </div>

                                <div className="modal-body bg3">
                                    {!!order && order.map(item => {
                                        return <div key={item.ItemCode} className="row">
                                            <div className="col-md-3 text-center">
                                                <img className="img-fluid" style={{ backgroundColor: 'white', maxHeight: 130 }}
                                                    src={item.PicturName ? (config.BASE_URL + SERVICE_API.getImage + '/' + item.PicturName) : require('../../images/noImage.png')}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="col-md-8 table-responsive">
                                                <div className="container p-0">
                                                    <div className="row text-left">
                                                        <div className="col-md-12"  style={{borderBottom: '1px solid rgb(124, 124, 125)'}}>
                                                            {item.ItemName}
                                                        </div>
                                                    </div>
                                                    <div className="row font-weight-bold ">
                                                        <div className="col-md-3 text-center">
                                                            Código
                                                        </div>
                                                        <div className="col-md-3 text-center">
                                                            Cantidad
                                                        </div>
                                                        <div className="col-md-3 text-center">
                                                            Precio unitario
                                                        </div>
                                                        <div className="col-md-3 text-center">
                                                            Precio total
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-3 text-center">
                                                            {item.ItemCode}
                                                        </div>
                                                        <div className="col-md-3 text-center">
                                                            {parseInt(item.Quantity)}
                                                        </div>
                                                        <div className="col-md-3 text-center">
                                                            {item.Currency+' '+parseFloat(item.Price).toFixed(2)}
                                                        </div>
                                                        <div className="col-md-3 text-center">
                                                            {item.Currency+' '+parseFloat(item.LineTotal).toFixed(2)}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                    <div className="row">
                                        <div className="col-sm-1 col-md-3">

                                        </div>
                                        {credit ? 
                                            <div className="col-sm-7 col-md-5">
                                                <div className="card" style={{
                                                    borderColor: 'white',
                                                    backgroundColor: 'transparent',
                                                    border: '1px solid  rgba(124, 124, 125, 0.3)'}}>
                                                    <div className="card-header text-white"
                                                        style={{background: config.shoppingList.summaryList}}>
                                                        <div className="card-title">
                                                            <h5 style={{color: config.shoppingList.textsummaryList}}>Limite de crédito:</h5>
                                                        </div>
                                                    </div>

                                                    <div className="card-body" >
                                                        {this.renderCreditLimit()}
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="col-sm-7 col-md-5">
                                            </div>
                                        }
                                        
                                        <div className="col-sm-4 col-md-4">
                                            <div className="card" style={{
                                                borderColor: 'white',
                                                backgroundColor: 'transparent',
                                                border: '1px solid  rgba(124, 124, 125, 0.3)'}}>
                                                <div className="card-header text-white"
                                                    style={{background: config.shoppingList.summaryList}}>
                                                    <div className="card-title">
                                                        <h5 style={{color: config.shoppingList.textsummaryList}}>Resumen:</h5>
                                                    </div>
                                                </div>

                                                <div className="card-body">
                                                    {this.renderShoppingCartTotal()}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    
                                </div>
                            
                                <div className="modal-footer justify-content-right">
                                {/* {editar ? 
                                    <button type="button" className="btn btn-success" style={{display:Tipo === 'W' ? 'block' : 'none' }} onClick={()=> this.addToShopingCart()}>
                                        <i className="fas fa-cart-plus"/>
                                        &nbsp; Editar
                                    </button>
                                    :
                                    ''
                                } */}

                                 <CSVLink  
                                    className="btn btn-success float-right"
                                    data={csvData} filename={"Archivo.csv"}>
                                        Descargar orden CSV
                                </CSVLink>
                                <button type="button" class="btn btn-danger float-right" style={{display:Tipo === 'W' ? 'block' : 'none' }} disabled={Tipo === 'W' ? false : true} data-toggle="modal" data-target=".comentarios" >Rechazar</button>
                                <button type="button" class="btn btn-success float-right" style={{display:Tipo === 'W' ? 'block' : 'none' }} disabled={Tipo === 'W' ? false : true} onClick={this.Autorizar}>Autorizar</button>
                                
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="modal fade comentarios" tabindex="-1" role="dialog" id="commentsModale" >
                        <div class="modal-dialog" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Escribe aqui tu comentario de rechazo.</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>                            
                            </button>
                        </div>
                        <div class="modal-body">
                            <div className="row">
                                <div className="col-sm-12 col-md-12">
                                    <div class="form-group" style={{marginBottom: '2px'}}> 
                                        <input type="text" class="form-control" id="commentarios" placeholder="Escribe aqui" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={this.closeConfirme}>Cerrar</button>
                            <button type="button" className="btn btn-success" onClick={this.Rechazar}> Enviar </button>                            
                        </div>
                        </div>
                    </div>
                    </div>
                
                    <div class="modal fade estasSeguro" tabindex="-1" role="dialog" id="estasSeguroModal" >
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Mensaje de alerta.</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>                            
                                </button>
                            </div>
                            <div class="modal-body">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <h4>¿Estas seguro que deseas autorizar los documentos masivamente?</h4>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" className="btn btn-danger" onClick={this.closemodalestasSeguro}>Cerrar</button>
                                <button type="button" className="btn btn-success" onClick={this.AutorizarMasive}> Crear los documentos </button>                            
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AutorizacionesView);
