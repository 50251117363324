import React, {Component} from 'react';
import {Session, SaveDetailModel} from "../../components";
import {DISPATCH_ID, SERVICE_API, SERVICE_RESPONSE, VIEW_NAME, config} from "../../libs/utils/Const";
import {ApiClient} from "../../libs/apiClient/ApiClient";
import {connect} from "react-redux";
import moment from 'moment';
import $ from 'jquery';
import CurrencyFormat from 'react-currency-format';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'

let doc = new jsPDF();

const apiClient = ApiClient.getInstance();

require('datatables.net-bs4');

class savedViews extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
            order: {
                header: {},
                body: []
            },
            fechaInicio: '',
            fechaFinal: '',
        };
        this.table = null;
    }

    async componentDidMount() {
        
        let fechaInicio = moment(new Date()).format('YYYY-MM-DD')
        let fechaFinal = moment(new Date()).format('YYYY-MM-DD')
        this.setState({
            fechaInicio,
            fechaFinal
        });
        setTimeout(async  () => {
            await this.getData();
        },300);
        this.fillDataOrders();
    }

    fillDataOrders = () => {
        const {enableSpinner,data} = this.props;
        this.table =  $('#tablaSaved').DataTable({
                "paging": true,
                "info": false,
                "searching": false,
                //"bDestroy": true,	
                "order": [[ 0, 'desc' ]],
                "language": {
                    "lengthMenu": "Mostrar _MENU_ registros por página",
                    "zeroRecords": "No se encontraron registros",
                    "info": "Mostrando página _PAGE_ de _PAGES_",
                    "infoEmpty": "No existen registros",
                    "infoFiltered": "(filtrado de _MAX_ entradas)",
                    "loadingRecords": "Buscando...",
                    "processing": "Procesando...",
                    "search": "Buscar:",
                    "paginate": {
                        "first": "Primero",
                        "last": "Último",
                        "next": "Siguiente",
                        "previous": "Anterior"
                    }
                }
            });
            
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.props.orders != nextProps.orders) {
            //console.log("hay nuevos datos");
            this.table.destroy();
            this.table = null;
            doc = null;
        }
        return true;
    }

    componentDidUpdate() {
        if (this.table == null) {
            this.fillDataOrders();
        }
    }

    getData = async ()=>{

        const { fechaInicio,fechaFinal } = this.state;
        const {enableSpinner, notificationReducer: {showAlert}} = this.props;
        
        enableSpinner(true);
        let response = await apiClient.getSaveds(fechaInicio,fechaFinal);
        enableSpinner(false);

        if (!Array.isArray(response.data)) {
            showAlert({type: 'error', message: 'Ocurrió un error al consultar la Información'});
            return;
        }

        if (response.data.length === 0) {
            showAlert({type: 'info', message: 'No hay Información por mostrar'});
            return;
        }

        this.setState({
            orders: response.data,
        });
    }

    //Evento para capturar los valores de los campos
    handelChange = ({ target }) => {
        const { name, value } = target;
        this.setState({
            [name]: value
        });
    };

    openOrder = async docEntry => {
        const {enableSpinner, notificationReducer: {showAlert}} = this.props;
        enableSpinner(true);
        let response = await apiClient.getDataProduct(docEntry);
        enableSpinner(false);

        if (response.status === SERVICE_RESPONSE.SUCCESS) {
            this.setState({
                order: response.data,
            });

            $('#saveModal').modal('show');
            return;
        }

        showAlert({type: 'error', message: response.message})
    };

    handleInputDateInicio = event =>{
        let fechaInicio = event.nativeEvent.target.value;
        this.setState({
            fechaInicio
        });
    }
    handleInputDateFinal = event =>{
        let fechaFinal = event.nativeEvent.target.value;
        this.setState({
            fechaFinal
        });
    }

    exportPDFOrders = async (type,Cart,date) => {
        const { notificationReducer: {showAlert},sessionReducer: { user } } = this.props;
        let init = moment(date).utc().format('DD-MM-YYYY');
        setTimeout(async () => {
            let Carts = await apiClient.getDataProduct(Cart);
            if (Carts.data.body.length > 0){
                let bodyArray = [];
                let minNewOrders = [];
                let body2FullMinNewOrders = [];
                Carts.data.body.map((order, index) => {
                    minNewOrders.push(
                        {
                            "#": index+1,
                            "Código": order.ItemCode,
                            "Descripción": order.ItemName,
                            "Cantidad": order.Quantity,
                            "Precio": '$ ' + Number(order.Price).toFixed(2),
                            "Precio total": '$ ' + Number(order.Price * parseInt(order.Quantity)).toFixed(2),
                        }
                    );
                    bodyArray.push(Object.values(minNewOrders[0]));
                    body2FullMinNewOrders = minNewOrders.slice(0);
                    minNewOrders.length = 0;
                });

                doc = new jsPDF();

                doc.autoTable({
                    head: [
                        [
                        {
                            content: `CARRITO GUARDADO DEL CLIENTE ${user.CardName.toUpperCase()} FECHA: ${init}`,
                            colSpan: 6,
                            styles: { halign: 'center', fillColor: config.navBar.iconColor },
                        },
                        ],
                        Object.keys(body2FullMinNewOrders[0])
                    ],
                    body: bodyArray,
                    theme: 'striped',
                    headerStyles: {
                        fillColor: config.navBar.iconColor
                    },
                    alternateRowStyles: {
                        fillColor: "#cecece",
                    }
                });
                
                if(type === 1){
                    doc.save(`Carrito_${user.CardCode}_${init}.pdf`);
                } else {
                    doc.save(`Carrito_${user.CardCode}_${init}.pdf`);
                    window.open(doc.output('bloburl'));
                }
            } else {
                showAlert({type: 'info', message: 'No se ha podido generar sus archivo, porque no se encontraron resultado para su búsqueda'});
            }
            
        }, 500);
    }

    render() {
        const { order} = this.state;
        const { orders,getData,handleInputDateInicio, handleInputDateFinal, fechaFinal,fechaInicio, fechamin, fechamax, isLoaded}  = this.props;
       
        
        return (
            <div className="content-fluid" style={{backgroundColor: config.Back.backgroundColor}}>
                <SaveDetailModel order={order}/>
                <div className="row text-center" style={{marginBottom: 16, marginTop: 16}}>
                    <div className="col-md-12 pb-2">
                        <h2>CARRITOS GUARDADOS</h2>
                    </div>
                </div>
                <div className="row text-center" style={{marginBottom: 16, marginTop: 16}}>
                    <div className=" row col-md-4 ">
                        <h4 className="pr-2">Desde:</h4>
                        <input 
                            type="date" 
                            className="form-control col-md-6" 
                            name="fechauno" 
                            min={fechamin}
                            max={fechamax}
                            value = {!isLoaded ? fechamin : fechaInicio}
                            onChange = {(event) => handleInputDateInicio(event)}/>
                    </div>
                    <div className="row col-md-4 pb-3">
                        <h4 className="pr-2">Hasta:</h4>
                        <input 
                            type="date" 
                            className="form-control col-md-6" 
                            name="fechados" 
                            min={fechamin}
                            max={fechamax}
                            value = {fechaFinal}
                            onChange = {(event) => handleInputDateFinal(event)}/>
                    </div>
                    <div className="col-md-2 pb-2">
                        <button
                            onClick={()=>getData(1)}
                            className="btn botonResumen" 
                            style={{
                                backgroundColor: config.navBar.menuCategoriesBackgroundHover,
                                color: config.navBar.textColor2,
                                fontWeight: "bold",
                            }}>
                            Ver resultados
                        </button>
                    </div>                    
                </div>
                <div className="row">
                    <div className="col-md-12 table-responsive tableReports">
                        <table id="tablaSaved" className="table table-striped scrolltable">
                            <thead>
                                <tr style={{backgroundColor: config.shoppingList.summaryList, color: "white"}}>
                                    <th scope="col-lg-3" style={{width: '250px'}}>Fecha de creación</th>
                                    <th scope="col-lg-3" style={{width: '250px'}}>No. de carrito</th>
                                    <th scope="col-lg-3" style={{width: '250px'}}>Estado</th>
                                    <th scope="col-lg-3" style={{width: '250px'}}>Descargar PDF</th>
                                    <th scope="col-lg-3" style={{width: '250px'}}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {orders.map((order, index) => {
                                    return (<tr key={index}>
                                        <th scope="col-lg-3 row" style={{width: '250px'}}>{moment(order.DateCart).utc().format('DD-MM-YYYY')}</th>
                                        <td className="col-lg-3" style={{width: '250px'}}>{order.id}</td>
                                        <td className="col-lg-3" style={{width: '250px'}}>{order.Status}</td>
                                        <td className="col-lg-3" style={{width: '250px'}}>
                                            <button
                                                onClick={()=>this.exportPDFOrders(2,order.id,order.DateCart)}
                                                className="btn botonResumen" 
                                                style={{
                                                    backgroundColor: config.navBar.menuCategoriesBackgroundHover,
                                                    color: "white",
                                                    fontWeight: "bold",
                                                    
                                                }}>
                                                Imprimir &nbsp;&nbsp;
                                                <i className="fas fa-print 2x" style = {{color: 'white'}} aria-hidden="false"/>
                                            </button>
                                        </td>
                                        <td className="col-lg-3" style={{width: '250px'}}>
                                            <span onClick={() => this.openOrder(order.id)}>
                                                <i className={config.icons.detail} style={{color: config.shoppingList.summaryList, paddingRight: 6}}></i>
                                                Detalle
                                            </span>
                                        </td>
                                    </tr>)
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        sessionReducer: store.SessionReducer,
        configReducer: store.ConfigReducer,
        notificationReducer: store.NotificationReducer,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        enableSpinner: value => dispatch({type: DISPATCH_ID.CONFIG_SET_SPINNER, value}),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(savedViews);