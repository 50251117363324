import React, {Component} from 'react';
import { NavBar, Session} from "../../components";
import {VIEW_NAME, SERVICE_API, config} from "../../libs/utils/Const";
import { connect } from "react-redux";
import './catalogo.css'

class Catalogo extends Component {
    render() {
        const {history} = this.props;
        return (
            <div className="content-fluid" style={{marginTop: 150,backgroundColor:"#EFEFEF"}}>
                <Session history={history} view={VIEW_NAME.CATALOGOS_US_VIEW}/>
                <NavBar/>
                <div className="container" style={{paddingTop: 100, paddingBottom: 200}}>
                    <div className="row my-4">
                        <div className="col align-self-start">
                            <h1 className="tituloC">CATÁLOGOS</h1>
                        </div>
                    </div>
                    <div className="row my-4">
                        { config.catalogo.map( (catalogo, index) => {
                            return (
                                <div className="col-md-4" key={index}>
                                    <div class="card" style={{width: '18rem'}}>
                                        <img src={catalogo.imagen} class="card-img-top" alt={catalogo.nombre}/>
                                        <div class="card-body">
                                            <h5 class="card-title">{catalogo.nombre}</h5>
                                            <a href={`${config.BASE_URL}/${SERVICE_API.getPolitics}/${catalogo.pdf}`} target="_blank" download="catalogo.pdf">Ver catálogo</a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = store => {
    return {
        notificationReducer: store.NotificationReducer,
        configReducer: store.ConfigReducer
    };
};

export default connect(
    mapStateToProps
)(Catalogo);